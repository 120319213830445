import * as React from 'react'
import { ModalButton, WModal } from '../../../../../../wds/Modal'
import { WTextField } from '../../../../../../wds/TextField'
import { BoardSceneController } from '../BoardSceneController'
import { AddColumnDialogModel } from '../BoardSceneModel'

export class AddColumnDialog extends React.Component<AddColumnDialogProps> {

    private readonly handleCancelClick = () => {
        this.props.controller.onAddColumnDialogCancelClicked()
    }

    private readonly handleCreateClick = () => {
        this.props.controller.onAddColumnDialogSaveClicked()
    }

    public render() {
        return (
            <WModal
                title="Create column"
                buttons={[
                    new ModalButton("Cancel", this.handleCancelClick, "text"),
                    new ModalButton("Create", null, "contained", "primary"),
                ]}
                onSubmit={this.handleCreateClick}
            >
                <WTextField
                    label="Name"
                    model={this.props.model.nameModel}
                    helperText="The name of the new column."
                    autoFocus
                />
            </WModal>
        )
    }

}

export interface AddColumnDialogProps {

    model: AddColumnDialogModel
    controller: BoardSceneController

}