import TextField from '@material-ui/core/TextField';
import * as React from "react";
import { FieldModel } from "../components/FieldModel";

export class WTextField extends React.Component<WTextFieldProps, WTextFieldState> {

    public constructor(props: WTextFieldProps) {
        super(props)

        this.handleOnChange = this.handleOnChange.bind(this)
    }

    private handleOnChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (this.props.model != null) {
            this.props.model.value = event.target.value
        }
        if (this.props.onChange != null) {
            this.props.onChange(event.target.value)
        }
    }

    private handleChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.model.value = evt.target.value
        this.setState({})
        if (this.props.onChange != null) {
            this.props.onChange(evt.target.value)
        }
    }

    render() {
        return (
            <TextField
                style={{ width: '100%', height: this.props.height != null ? this.props.height : null }}
                error={this.props.model.error != null}
                label={this.props.label}
                defaultValue={this.props.model.value}
                helperText={this.props.model.error != null ? this.props.model.error : this.props.helperText}
                disabled={this.props.disabled}
                multiline={this.props.multiline}
                type={this.props.password ? 'password' : 'text'}
                placeholder={this.props.placeholder}
                required={this.props.required}
                variant="outlined"
                autoComplete={this.props.autoComplete ? "on" : 'off'}
                autoFocus={this.props.autoFocus}
                onChange={(e) => {
                    this.props.model.value = e.currentTarget.value;
                    if (this.props.onChange != null) {
                        this.props.onChange(this.props.model.value)
                    }
                }}

            />
        )
    }

}

export interface WTextFieldProps {
    label?: string
    placeholder?: string
    model: FieldModel<string>
    helperText?: string
    password?: boolean
    multiline?: boolean
    required?: boolean
    disabled?: boolean
    height?: string
    autoComplete?: true
    autoFocus?: true
    onChange?: (newValue: string) => void
}

interface WTextFieldState {

}