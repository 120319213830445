import { EHAlign } from 'components/styles'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { WButton } from './Button'
import { WHBox, WHBoxCell } from './HBox'
import { HorizontalLoadingIndicator } from './HorizontalLoadingIndicator'
import { WIcon } from './Icon'
import { WScrollBox } from './ScrollBox'
import { EColor } from './styles/WColor'
import { WVBox, WVBoxCell } from './VBox'

export class WModal extends React.Component<WModalProps, WModalState> {

    private handleOuterClick = () => {
        if (this.props.onCloseClicked != null) {
            this.props.onCloseClicked()
        }
    }

    private handleCloseClick = () => {
        if (this.props.onCloseClicked != null) {
            this.props.onCloseClicked()
        }
    }

    private readonly handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault()
        this.props.onSubmit()
    }

    render() {
        var width = "600px"
        if (this.props.width != null) {
            width = this.props.width
        }
        return (
            ReactDOM.createPortal((
                <div
                    style={{
                        position: "fixed",
                        left: "0px",
                        top: "0px",
                        right: "0px",
                        bottom: "0px",
                        backgroundColor: EColor.DialogFadeColor.color,
                        zIndex: 10000,
                        pointerEvents: 'all',

                    }}
                >
                    <WScrollBox>
                        <div
                            style={{
                                width: "100%",
                                minHeight: "100%",
                                padding: "64px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >


                            {/* <Popup onFadeClicked={this.handleOuterClick} fadeColor={EColor.DialogFadeColor.color} > */}
                            <div style={{ width: width, backgroundColor: EColor.DialogBgColor.color, boxShadow: "1px 1px 20px rgba(0,0,0, 0.5)" }}>
                                {/* header */}
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: 'center',
                                        backgroundColor: EColor.DialogHeaderBgColor.color,
                                        paddingLeft: '16px',
                                        paddingRight: '16px',
                                        paddingTop: '16px',
                                        paddingBottom: '16px',
                                    }}

                                >
                                    <div
                                        style={{
                                            flex: '1',
                                            fontSize: '18px',
                                            fontWeight: 600,
                                            color: EColor.DialogTitleColor.color,
                                            paddingRight: '24px'
                                        }}
                                    >
                                        {this.props.title}
                                    </div>

                                    {this.props.onCloseClicked != null &&
                                        <div
                                            style={{
                                                display: 'inline-flex',
                                                cursor: "pointer"
                                            }}
                                            onClick={this.handleCloseClick}
                                        >
                                            <WIcon icon="close" color={EColor.ButtonGreyColor.color} size="36px" />
                                        </div>

                                    }
                                </div>

                                {this.props.isLoading &&
                                    <HorizontalLoadingIndicator height="2px" />
                                }

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",
                                        opacity: this.props.isLoading ? "0.2" : "1.0",
                                        pointerEvents: this.props.isLoading ? "none" : "all"
                                    }}
                                >
                                    {/* global error */}
                                    {this.props.error &&
                                        <div
                                            style={{
                                                width: "100%",
                                                padding: "16px",
                                                backgroundColor: EColor.ErrorBg.color,
                                                color: "#fff",
                                                fontSize: "14px",
                                                fontWeight: 500
                                            }}
                                        >
                                            {this.props.error}
                                        </div>
                                    }

                                    {/* body */}
                                    <form onSubmit={this.props.onSubmit != null ? this.handleSubmit : undefined}>
                                        <div
                                            style={{
                                                width: "100%",
                                                padding: this.props.padding != null ? `${this.props.padding}px` : '32px'
                                            }}
                                        >
                                            <WVBox spacing={32}>
                                                {React.Children.map(this.props.children, (child, index) => {
                                                    return (
                                                        <WVBoxCell key={"vbc-" + index} hAlign={EHAlign.Left} >
                                                            {child}
                                                        </WVBoxCell>
                                                    )
                                                })}
                                            </WVBox>
                                        </div>


                                        {/* footer */}
                                        {this.props.buttons != null && this.props.buttons.length > 0 &&
                                            <div
                                                style={{
                                                    width: "100%",
                                                    padding: "32px",
                                                    borderTop: "1px solid #ccc"
                                                }}
                                            >
                                                <WHBox>
                                                    <WHBoxCell key={"btns-" + 100000} flex={1}></WHBoxCell>
                                                    {this.props.buttons.map((item, index) => {
                                                        var res: React.ReactNode[] = []
                                                        if (index > 0) {
                                                            res.push(<WHBoxCell key={"bntsep-" + index} width="16px"></WHBoxCell>)
                                                        }
                                                        res.push(
                                                            <WHBoxCell key={"btns-" + index}>
                                                                <WButton title={item.title} variant={item.variant} color={item.color} submitButton={item.handler == null} onClick={item.handler} />
                                                            </WHBoxCell>
                                                        )

                                                        return res
                                                    })}
                                                </WHBox>
                                            </div>


                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </WScrollBox>
                </div>
            ), document.getElementById("dialogs")))
    }

}

export interface WModalProps {
    title: string
    helperText?: string
    error?: string
    isLoading?: boolean
    buttons?: ModalButton[]
    width?: string
    padding?: number
    onSubmit?: () => void
    onCloseClicked?: () => void
}

export class ModalButton {

    public constructor(
        public readonly title: string,
        public readonly handler: () => void = null,
        public readonly variant: "contained" | "outlined" | "text" = "contained",
        public readonly color: "primary" | "secondary" | "default" = "default",
    ) {

    }

}

interface WModalState {

}