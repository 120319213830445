import { CurrentOrgSvc } from "backend/aaa/CurrentOrgSvc";
import { IBoardColumnOptions, IBoardData } from "shared/entities/projects/IBoardData";
import { transact } from "utils/Fs";

export const changeColumnOptionsFun = async (boardId: string, columnId: string, options: IBoardColumnOptions) => {
    await transact(async (t) => {
        const boardRef = CurrentOrgSvc.currentOrg.snapshot.ref
            .collection("Boards").doc(boardId)

        const board = await t.get(boardRef)
        if (!board.exists) {
            throw new Error("Can't find board!")
        }

        const boardD = board.data() as IBoardData

        const col = boardD.columns.find(col => col.id == columnId)
        if (col == null) {
            throw new Error("Can't find column!")
        }

        col.options = options

        t.update(boardRef, { columns: boardD.columns })
    }, null)
}