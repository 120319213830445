import { AScene } from '@eagerlogic/react-mvc';
import * as React from 'react';
import { AWibController, AWibHandler } from 'wibib-sdk-fe/WibHandlerBase';
import { MenuItem } from '../../../../wds/DropdownMenu';
import { BaseCommLayout, HeaderButton } from '../commmain/components/BaseCommLayout';
import { WibHandlerRegistry } from './WibHandlerRegistry';
import { WibSceneController } from './WibSceneController';
import { WibSceneModel } from './WibSceneModel';

export class WibScene extends AScene<WibSceneProps, WibSceneModel, WibSceneController> {

    private wibHandler: AWibHandler<{}>
    private wibViewRenderer: AWibController<{}>



    protected createControlller(): WibSceneController {
        return new WibSceneController(this.props.wibId, this.props.onCloseClick)
    }

    componentWillUnmount() {
        this.controller.destroy()
        if (this.wibViewRenderer != null) {
            this.wibViewRenderer.destory()
        }
    }

    render(): React.ReactNode {
        var buttons: HeaderButton[] = []
        var moreMenuItems: MenuItem[] = null
        var sidebar: React.ReactNode = null
        var isSubjectEditable = false
        if (!this.model.isLoading) {
            if (this.wibHandler == null) {
                this.wibHandler = WibHandlerRegistry.getHandlerByType(this.model.wib.type)
            }
            if (this.wibViewRenderer == null) {
                this.wibViewRenderer = this.wibHandler.createWibController(this.props.wibId, this.model.wib, this.model.messages.docs.map((item) => { return item.data() }), () => { this.setState({}) })
            }
            this.wibViewRenderer.wib = this.model.wib
            this.wibViewRenderer.messages = this.model.messages.docs.map((item) => { return item.data() })

            sidebar = this.wibViewRenderer.renderSidebar()
            buttons = this.wibViewRenderer.getHeaderButtons().buttons.map((item) => {
                return new HeaderButton(item.icon, item.color, item.title, item.type, item.isDisabled, item.onClick)
            })
            if (this.wibViewRenderer.getHeaderButtons().moreMenuItems.length > 0) {
                moreMenuItems = this.wibViewRenderer.getHeaderButtons().moreMenuItems
            }
            isSubjectEditable = this.wibViewRenderer.isSubjectEditable
        }
        return (
            <BaseCommLayout
                icon={this.props.wibIcon}
                iconColor={this.props.wibIconColor}
                title={this.props.wibSubject}
                isLoading={this.model.isLoading}
                onCloseClick={this.props.onCloseClick}
                rightSideBarRenderer={() => { return sidebar }}
                buttons={buttons}
                moreMenuItems={moreMenuItems}
                onTitleChanged={isSubjectEditable ? this.controller.handeChangeTitle : null}
                onFileDropped={this.controller.handleDropFile}
            >
                {!this.model.isLoading &&
                    this.wibViewRenderer.render()
                }
            </BaseCommLayout>
        )
    }
}

export interface WibSceneProps {
    wibId: string,
    wibSubject: string,
    wibIcon: string,
    wibIconColor: string,
    onCloseClick: () => void
}