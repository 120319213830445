import { AAction } from "./Jalidate"
import { ValidationException } from "./Jalidate"


export class RegexpValidator extends AAction<string, string> {

    public constructor(private readonly regexp: RegExp, private readonly errorMessage: string) {
        super()
    }

    public execute(input: string): string {
        if (!this.regexp.test(input)) {
            throw ValidationException.createWithMessage(this.errorMessage)
        }
        return input
    }

}

export class EmailValidator extends RegexpValidator {

    private static readonly REGEXP =
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/

    public constructor(errorMessage: string) {
        super(EmailValidator.REGEXP, errorMessage);
    }

}

export class NullConverter<GInput> extends AAction<GInput, GInput> {

    public constructor(private readonly defaultValue: GInput) {
        super()
    }

    public execute(input: GInput): GInput {
        return input == null ? this.defaultValue : input
    }
}

export class StringLengthValidator extends AAction<string, string> {

    public constructor(private readonly minLength: number, private readonly maxLength: number, private readonly errorMessage: string) {
        super()
    }

    public execute(input: string): string {
        if (this.minLength != null) {
            if (input.length < this.minLength) {
                throw ValidationException.createWithMessage(this.errorMessage)
            }
        }
        if (this.maxLength != null) {
            if (input.length > this.maxLength) {
                throw ValidationException.createWithMessage(this.errorMessage)
            }
        }
        return input
    }

}

export class StringToEnumConverter<GEnum> extends AAction<string, GEnum> {

    public constructor(
        private readonly errorMessage: string,
        private readonly resolver: (input: string) => GEnum
    ) {
        super()
    }

    public execute(input: string): GEnum {
        var res = this.resolver(input)
        if (res == null) {
            throw ValidationException.createWithMessage(this.errorMessage)
        }
        return res
    }

}

export class StringToIntConverter extends AAction<string, number> {

    public constructor(private readonly errorMessage: string, private readonly invalidToZero: boolean = false) {
        super()
    }

    public execute(input: string): number {
        try {
            var res = parseInt(input)
            if (res == null || isNaN(res)) {
                throw ValidationException.createWithMessage(this.errorMessage)
            }
            return res
        } catch (ex) {
            if (this.invalidToZero) {
                return 0
            } else {
                throw ValidationException.createWithMessage(this.errorMessage)
            }
        }
    }

}

export class StringToLowerCaseConverter extends AAction<string, string> {

    public execute(input: string): string {
        return input.toLowerCase()
    }
}

export class StringTrimConverter extends AAction<string, string> {

    public execute(input: string): string {
        return input.trim()
    }
}



