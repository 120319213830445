import { BoardSvc } from 'backend/projects/board/BoardService';
import { WibSvc } from 'backend/wib/WibService';
import { bugsnagClient } from 'index';
import * as React from 'react';
import { IWib } from 'shared/entities/wib/IWib';
import { ICardWibContent } from 'shared/entities/wib/wibs/ICardWibContentType';
import { UsersSvc } from '../../../../../../backend/aaa/UsersSvc';
import { WMembersList } from '../../../../../../wds/MembersList';
import { Snackbar } from '../../../../../../wds/Snackbar';
import { ACommonWibSidebarTabRenderer } from "../../../../comm/wibs/components/CommonWibSidebarView";

export class CardMembersTabViewRenderer extends ACommonWibSidebarTabRenderer {

    public constructor(
        public readonly wibId: string,
        public readonly wib: IWib<ICardWibContent>
    ) {
        super("group")
    }

    private readonly onMemberAdded = async (id: string) => {
        try {
            await WibSvc.addMember(this.wibId, id)
        } catch (ex) {
            Snackbar.show("danger", "Add participant failed: " + ex.message)
        }
    }

    private readonly onMemberRemoved = async (id: string) => {
        try {
            await BoardSvc.removeCardMember(this.wibId, id)
        } catch (ex) {
            Snackbar.show("danger", "Remove participant failed: " + ex.message)
        }
    }

    private readonly handleAssigneeChanged = async (userId: string, newValue: boolean) => {
        if (newValue) {
            try {
                await WibSvc.addAssignee(this.wibId, userId)
            } catch (ex) {
                Snackbar.show("danger", "Failed to add assignee: " + ex.message)
                if (bugsnagClient != null) {
                    bugsnagClient.notify(ex)
                }
            }
        } else {
            try {
                await BoardSvc.removeAssignee(this.wibId, userId)
            } catch (ex) {
                Snackbar.show("danger", "Failed to remove assignee: " + ex.message)
                if (bugsnagClient != null) {
                    bugsnagClient.notify(ex)
                }
            }
        }
    }

    renderContent(): React.ReactNode {
        return (
            <WMembersList
                isAssigneeSelectable
                defaultSelectedMembers={this.wib.members.map((m) => {
                    const u = UsersSvc.usersById[m]
                    return {
                        id: m,
                        name: u.data().name,
                        color: u.data().color,
                        image: u.data().profilePictureUrl,
                        isAssignee: this.wib.assignees.indexOf(m) > -1
                    }
                })}
                availableMembers={UsersSvc.users.map((u) => {
                    return {
                        id: u.id,
                        name: u.data().name,
                        color: u.data().color,
                        image: u.data().profilePictureUrl,
                        isAssignee: false
                    }
                })}
                onUserAdded={this.onMemberAdded}
                onUserRemoved={this.onMemberRemoved}
                onAssigneeChanged={this.handleAssigneeChanged}
            />
        )
    }



}
