import { IBoardColumnOptions } from "shared/entities/projects/IBoardData"
import { FieldModel } from "../../../../../components/FieldModel"

export class BoardSceneModel {

    public isLoading: boolean = true
    public boardModel: BoardModel = null
    public addColumnDialogModel: AddColumnDialogModel = null
    public editColumnNameDialogModel: EditColumnNameDialogModel = null

}

export class BoardModel {

    public constructor(
        public readonly id: string,
        public name: string,
        public colums: ColumnModel[]
    ) {

    }

    public columnIndexOf(columnId: string): number {
        return this.colums.findIndex((item) => item.id == columnId)
    }
}

export class AddColumnDialogModel {

    public readonly nameModel = new FieldModel("")

    public constructor(

    ) { }
}
export class EditColumnNameDialogModel {

    public readonly nameModel: FieldModel<string>

    public constructor(
        public readonly columnId: string,
        currentName: string
    ) {
        this.nameModel = new FieldModel(currentName)
    }
}

export class ColumnModel {

    public constructor(
        public readonly id: string,
        public name: string,
        public readonly cards: CardModel[],
        public readonly options: IBoardColumnOptions
    ) {

    }

    public cardIndexOf(cardId: string): number {
        return this.cards.findIndex((item) => item.id == cardId)
    }

}

export class CardModel {
    public constructor(
        public readonly id: string,
        public readonly title: string,
        public readonly members: string[]
    ) {

    }
}