import { CurrentOrgSvc } from 'backend/aaa/CurrentOrgSvc'
import { CurrentUserSvc } from 'backend/aaa/CurrentUserSvc'
import { UsersSvc } from 'backend/aaa/UsersSvc'
import * as firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import { IWib } from 'shared/entities/wib/IWib'
import { IWibMessage, VER_WibMessage } from 'shared/entities/wib/IWibMessage'
import { ICommentWibMessageContent, VER_CommentWibMessageContent } from 'shared/entities/wib/messages/ICommentWibMessageContent'
import { IFileAttachWibMessageContent, VER_IFileAttachWibMessageContent } from 'shared/entities/wib/messages/IFileAttachWibMessageContent'
import { IMetaInfoWibMessageContent, VER_MetaInfoWibMessageContent } from 'shared/entities/wib/messages/IMetaInfoWibMessageContent'
import { ENotiReasonColor, Wib } from 'shared/entities/wib/Wib'
import { NullConverter, StringLengthValidator, StringTrimConverter } from 'shared/jalidate/CommonValidators'
import { JalidatorBuilder, ValidationException } from 'shared/jalidate/Jalidate'
import { StringUtils } from 'shared/utils/StringUtils'
import { fs, FsEntity, FsQueryListener, transact } from '../../utils/Fs'



export class WibSvc {

    private static readonly wibTypeValidator = JalidatorBuilder
        .init(new NullConverter(""))
        .withAction(new StringTrimConverter())
        .withAction(new StringLengthValidator(1, 128, "The type must be minimum 1 maximum 128 characters!"))
        .build()

    private static readonly wibSubjectValidator = JalidatorBuilder
        .init(new NullConverter(""))
        .withAction(new StringTrimConverter())
        .withAction(new StringLengthValidator(1, 128, "The subject must be minimum 1 maximum 128 characters!"))
        .build()

    private static _onWibListChanged: FsQueryListener<IWib<{}>>

    public static get onWibListChanged() {
        if (this._onWibListChanged == null) {
            this._onWibListChanged = new FsQueryListener(
                CurrentOrgSvc.currentOrg.snapshot.ref
                    .collection("Wibs")
                    //.where('members', 'array-contains', CurrentUserSvc.currentUser.id)
                    .where(`memberNotifications.${CurrentUserSvc.currentUser.id}.notifiedAt`, ">", -2)
                    .orderBy(`memberNotifications.${CurrentUserSvc.currentUser.id}.notifiedAt`, "desc")
                    .limit(100)
            )
        }
        return this._onWibListChanged
    }

    private static _onTaskListChanged: FsQueryListener<IWib<{}>>

    public static get onTaskListChanged() {
        if (this._onTaskListChanged == null) {
            this._onTaskListChanged = new FsQueryListener(
                CurrentOrgSvc.currentOrg.snapshot.ref
                    .collection("Wibs")
                    // .where(`memberNotifications.${CurrentUserSvc.currentUser.id}.notifiedAt`, ">", -2)
                    .where('assignees', 'array-contains', CurrentUserSvc.currentUser.id)
                    // .orderBy(`memberNotifications.${CurrentUserSvc.currentUser.id}.notifiedAt`, "desc")
                    .limit(100)
            )
        }
        return this._onTaskListChanged
    }

    public static onWibMessagesChanged(wibId: string): FsQueryListener<IWibMessage<{}>> {
        const q = CurrentOrgSvc.currentOrg.snapshot.ref
            .collection("Wibs").doc(wibId)
            .collection("Messages")
            .where("deleted", "==", false)
            .orderBy("sentAt", "desc")
        return new FsQueryListener(q)
    }

    public static async findWibById(id: string): Promise<FsEntity<IWib<{}>>> {
        const wibRef = fs()
            .collection("Organizations")
            .doc(CurrentOrgSvc.currentOrg.id)
            .collection("Wibs")
            .doc(id)

        const wibE = await wibRef.get()
        return new FsEntity(wibE)
    }

    public static async createWib(wib: IWib<{}>, t: firebase.firestore.Transaction = null): Promise<string> {
        wib.type = this.wibTypeValidator.validateSafe(wib.type)

        const errors: { [key: string]: string } = {}
        wib.subject = this.wibSubjectValidator.validateToMap(wib.subject, "subject", errors)

        if (Object.keys(errors).length > 0) {
            throw ValidationException.createWithFieldErrors(errors)
        }

        // TODO validate members
        const now = Date.now()
        return transact(async (t) => {
            const ref = CurrentOrgSvc.currentOrg.snapshot.ref.collection("Wibs").doc()
            t.set(ref, wib)
            return ref.id
        }, t)
    }

    public static async addMember(wibId: string, memberId: string) {
        const u = UsersSvc.usersById[memberId]
        if (u == null) {
            throw new Error("Can't find user with id: " + memberId)
        }

        await fs().runTransaction(async (t) => {
            const wibRef = CurrentOrgSvc.currentOrg.snapshot.ref
                .collection("Wibs")
                .doc(wibId)

            const wibE = await t.get(wibRef)
            const wibD = wibE.data() as IWib<{}>
            const wib = new Wib(wibD)
            if (wib.isMember(memberId)) {
                return
            }
            wib.addMember(memberId, CurrentUserSvc.currentUser.id)

            t.update(wibRef, wib.updatedData)
        })

        if (memberId == CurrentUserSvc.currentUser.id) {
            await WibSvc.sendMetaInfo(wibId, `joined to this WIB.`)
        } else {
            await WibSvc.sendMetaInfo(wibId, `added ${u.data().name} to this WIB.`)
        }
    }

    public static async removeMember(wibId: string, memberId: string) {
        const u = UsersSvc.usersById[memberId]
        if (u == null) {
            throw new Error("Can't find user with id: " + memberId)
        }

        await fs().runTransaction(async (t) => {
            const wibRef = CurrentOrgSvc.currentOrg.snapshot.ref
                .collection("Wibs")
                .doc(wibId)

            const wibE = await t.get(wibRef)
            const wibD = wibE.data() as IWib<{}>
            const wib = new Wib(wibD)
            if (!wib.isMember(memberId)) {
                return
            }

            wib.removeMember(memberId)

            t.update(wibRef, wib.updatedData)
        })

        if (memberId == CurrentUserSvc.currentUser.id) {
            await WibSvc.sendMetaInfo(wibId, `left this WIB.`)
        } else {
            await WibSvc.sendMetaInfo(wibId, `removed ${u.data().name} from this WIB.`)
        }

    }

    public static async changeSubject(wibId: string, newSubject: string) {
        newSubject = this.wibSubjectValidator.validateSafe(newSubject)
        var oldSubject = null

        await firebase.firestore().runTransaction(async (t) => {
            const ref = fs().collection("Organizations").doc(CurrentOrgSvc.currentOrg.id).collection("Wibs").doc(wibId)
            const e = await t.get(ref)
            if (!e.exists) {
                throw new Error("Can't find wib with id: " + wibId)
            }
            oldSubject = e.data().subject

            const wib = new Wib(e.data() as IWib<{}>)
            wib.changeSubject(newSubject)
            wib.notifyMembers(CurrentUserSvc.currentUser.id, "changed the subject", ENotiReasonColor.ChangeContent)

            t.update(ref, wib.updatedData)
        })

        await this.sendMetaInfo(wibId, `Changed the subject from '${oldSubject}' to '${newSubject}'.`)
    }

    public static async changeContent(wibId: string, newContent: {}) {
        await firebase.firestore().runTransaction(async (t) => {
            const ref = fs().collection("Organizations").doc(CurrentOrgSvc.currentOrg.id).collection("Wibs").doc(wibId)
            const e = await t.get(ref)
            if (!e.exists) {
                throw new Error("Can't find wib with id: " + wibId)
            }
            const wibD = e.data() as IWib<{}>

            const wib = new Wib(wibD)
            wib.setContent(newContent)

            t.update(ref, wib.updatedData)
        })
    }

    public static async sendComment(wibId: string, comment: string) {
        if (comment == null) {
            comment = ""
        }
        comment = comment.trimRight()
        if (comment.length < 1) {
            return
        }

        const mentionResults = comment.match(/\[~accountid:([^\]]+)\]/g)
        const mentions: string[] = []
        if (mentionResults != null) {
            mentionResults.forEach((m) => {
                const uid = m.substring("[~accountid:".length, m.length - 1).trim()
                mentions.push(uid)
            })
        }

        fs().runTransaction(async (t) => {
            const wibRef = fs().collection("Organizations").doc(CurrentOrgSvc.currentOrg.id).collection("Wibs").doc(wibId)
            const wibSn = await t.get(wibRef)

            if (!wibSn.exists) {
                throw new Error("Can't find wib with id: " + wibId)
            }

            const now = Date.now()

            const msgData: IWibMessage<ICommentWibMessageContent> = {
                content: {
                    message: comment,
                    version: VER_CommentWibMessageContent
                },
                deleted: false,
                senderId: CurrentUserSvc.currentUser.id,
                sentAt: now,
                type: "wibib.comment",
                version: VER_WibMessage
            }

            const msgRef = fs().collection("Organizations").doc(CurrentOrgSvc.currentOrg.id).collection("Wibs").doc(wibId).collection("Messages").doc()

            t.set(msgRef, msgData)

            const wib = new Wib(wibSn.data() as IWib<{}>)
            mentions.forEach((m) => {
                if (UsersSvc.usersById[m] == null) {
                    return
                }
                wib.addMember(m, CurrentUserSvc.currentUser.id)
                wib.addAssignee(m)
                wib.notifyOneMember(m, CurrentUserSvc.currentUser.id, "mentioned you on", ENotiReasonColor.Mention)
            })
            wib.members.forEach((m) => {
                if (mentions.indexOf(m) > -1) {
                    return
                }
                wib.notifyOneMember(m, CurrentUserSvc.currentUser.id, "commented on", ENotiReasonColor.Comment)
            })

            t.update(wibRef, wib.updatedData)
        })
    }

    public static async sendMetaInfo(wibId: string, message: string) {
        if (message == null) {
            message = ""
        }
        message = message.trimRight()
        if (message.length < 1) {
            return
        }

        await fs().runTransaction(async (t) => {
            const wibRef = fs().collection("Organizations").doc(CurrentOrgSvc.currentOrg.id).collection("Wibs").doc(wibId)
            const wibSn = await t.get(wibRef)

            if (!wibSn.exists) {
                throw new Error("Can't find wib with id: " + wibId)
            }
            const wibD = wibSn.data() as IWib<{}>
            const wib = new Wib(wibD)

            const now = Date.now()

            const msgData: IWibMessage<IMetaInfoWibMessageContent> = {
                content: {
                    message: message,
                    version: VER_MetaInfoWibMessageContent
                },
                deleted: false,
                senderId: CurrentUserSvc.currentUser.id,
                sentAt: now,
                type: "wibib.meta_info_message",
                version: VER_WibMessage
            }

            wib.touch()



            const msgRef = fs().collection("Organizations").doc(CurrentOrgSvc.currentOrg.id).collection("Wibs").doc(wibId).collection("Messages").doc()

            t.set(msgRef, msgData)
            t.update(wibRef, wib.updatedData)
        })
    }

    public static async attachFile(wibId: string, fileName: string, data: Blob) {
        var finalFileName = fileName.trim()
        await transact(async (t) => {
            const wibRef = fs().collection("Organizations").doc(CurrentOrgSvc.currentOrg.id).collection("Wibs").doc(wibId)
            const wibSn = await t.get(wibRef)

            if (!wibSn.exists) {
                throw new Error("Can't find wib with id: " + wibId)
            }
            const wibD = wibSn.data() as IWib<{}>
            const wib = new Wib(wibD)

            fileName = fileName.trim()
            var fileCounter = 0
            while (wib.isFileExists(finalFileName)) {
                fileCounter++
                finalFileName = StringUtils.annotateFileName(fileName, " (" + fileCounter + ")")
            }

            const fileRef = firebase.storage().ref(`orgs/${CurrentOrgSvc.currentOrg.id}/wibs/${wibId}/${finalFileName}`)
            await fileRef.put(data)
            const downloadUrl: string = await fileRef.getDownloadURL()

            wib.addFile(finalFileName, downloadUrl)

            const attachMessageRef = wibRef.collection("Messages").doc()
            const attachMessageData: IWibMessage<IFileAttachWibMessageContent> = {
                content: {
                    fileName: finalFileName,
                    downloadUrl: downloadUrl,
                    version: VER_IFileAttachWibMessageContent
                },
                deleted: false,
                senderId: CurrentUserSvc.currentUser.id,
                sentAt: Date.now(),
                type: "wibib.attachment",
                version: VER_WibMessage
            }
            t.set(attachMessageRef, attachMessageData)

            wib.notifyMembers(CurrentUserSvc.currentUser.id, "attached a file", ENotiReasonColor.Comment)

            t.update(wibRef, wib.updatedData)
        }, null)
    }

    public static async notifyOthers(wibId: string, purpose: string, color: ENotiReasonColor) {
        if (purpose == null) {
            purpose = ""
        }
        purpose = purpose.trimRight()

        await fs().runTransaction(async (t) => {
            const wibRef = fs().collection("Organizations").doc(CurrentOrgSvc.currentOrg.id).collection("Wibs").doc(wibId)
            const wibSn = await t.get(wibRef)

            if (!wibSn.exists) {
                throw new Error("Can't find wib with id: " + wibId)
            }
            const wibD = wibSn.data() as IWib<{}>
            const wib = new Wib(wibD)
            wib.notifyMembers(CurrentUserSvc.currentUser.id, purpose, color)

            t.update(wibRef, wib.updatedData)
        })
    }

    public static async notifyWibSeen(wibId: string) {
        await fs().runTransaction(async (t) => {
            const wibRef = fs().collection("Organizations").doc(CurrentOrgSvc.currentOrg.id).collection("Wibs").doc(wibId)
            const wibSn = await t.get(wibRef)
            if (!wibSn.exists) {
                throw new Error("Can't find wib with id: " + wibId)
            }

            const wibD = wibSn.data()
            const wib = new Wib<{}>(wibD as IWib<{}>)

            wib.markAsSeen(CurrentUserSvc.currentUser.id)

            t.update(wibRef, wib.updatedData)

        })
    }

    public static async markAsUnread(wibId: string) {
        await transact(async (t) => {
            const wibRef = fs().collection("Organizations").doc(CurrentOrgSvc.currentOrg.id).collection("Wibs").doc(wibId)
            const wibSn = await t.get(wibRef)
            if (!wibSn.exists) {
                throw new Error("Can't find wib with id: " + wibId)
            }

            const wibD = wibSn.data()
            const wib = new Wib<{}>(wibD as IWib<{}>)

            wib.markAsUnread(CurrentUserSvc.currentUser.id)

            t.update(wibRef, wib.updatedData)
        }, null)
    }

    public static async addToTaskList(wibId: string) {
        await transact(async (t) => {
            const wibRef = fs().collection("Organizations").doc(CurrentOrgSvc.currentOrg.id).collection("Wibs").doc(wibId)
            const wibSn = await t.get(wibRef)
            if (!wibSn.exists) {
                throw new Error("Can't find wib with id: " + wibId)
            }

            const wibD = wibSn.data()
            const wib = new Wib<{}>(wibD as IWib<{}>)

            wib.addAssignee(CurrentUserSvc.currentUser.id)

            t.update(wibRef, wib.updatedData)
        }, null)
    }

    public static async removeFromTaskList(wibId: string) {
        await transact(async (t) => {
            const wibRef = fs().collection("Organizations").doc(CurrentOrgSvc.currentOrg.id).collection("Wibs").doc(wibId)
            const wibSn = await t.get(wibRef)
            if (!wibSn.exists) {
                throw new Error("Can't find wib with id: " + wibId)
            }

            const wibD = wibSn.data()
            const wib = new Wib<{}>(wibD as IWib<{}>)

            wib.removeAssignee(CurrentUserSvc.currentUser.id)

            t.update(wibRef, wib.updatedData)
        }, null)
    }

    public static async addAssignee(wibId: string, userId: string) {
        await transact(async (t) => {
            const wibRef = fs().collection("Organizations").doc(CurrentOrgSvc.currentOrg.id).collection("Wibs").doc(wibId)
            const wibSn = await t.get(wibRef)
            if (!wibSn.exists) {
                throw new Error("Can't find wib with id: " + wibId)
            }

            const wibD = wibSn.data()
            const wib = new Wib<{}>(wibD as IWib<{}>)

            wib.addAssignee(userId)

            t.update(wibRef, wib.updatedData)
        }, null)
    }

    public static async removeAssignee(wibId: string, userId: string) {
        await transact(async (t) => {
            const wibRef = fs().collection("Organizations").doc(CurrentOrgSvc.currentOrg.id).collection("Wibs").doc(wibId)
            const wibSn = await t.get(wibRef)
            if (!wibSn.exists) {
                throw new Error("Can't find wib with id: " + wibId)
            }

            const wibD = wibSn.data()
            const wib = new Wib<{}>(wibD as IWib<{}>)

            wib.removeAssignee(userId)

            t.update(wibRef, wib.updatedData)
        }, null)
    }

    public static async leaveWib(wibId: string) {
        this.removeMember(wibId, CurrentUserSvc.currentUser.id)
    }



    private constructor() { }

}