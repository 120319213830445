import { Menu, MenuItem } from '@material-ui/core'
import * as React from 'react'

export class WDropdownMenu extends React.Component<WDropdownMenuProps, WDropdownMenuState> {

    private readonly handleItemClick = (item: MenuItem) => {
        this.props.onClose()
        item.onClick()
    }

    render() {
        return (
            <Menu
                anchorEl={this.props.anchorEl}
                keepMounted
                open={this.props.anchorEl != null}
                onClose={this.props.onClose}
            >
                {this.props.items.map((item) => {
                    return (
                        <MenuItem key={item.id} onClick={() => { this.handleItemClick(item) }}>{item.title}</MenuItem>
                    )
                })}
            </Menu>
        )
    }

}

export interface WDropdownMenuProps {

    anchorEl: HTMLElement
    items: MenuItem[]
    onClose: () => void

}

interface WDropdownMenuState {

}

export interface MenuItem {
    id: string
    title: string
    onClick: () => void
}