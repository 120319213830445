import { CurrentOrgSvc } from "backend/aaa/CurrentOrgSvc"
import { CurrentUserSvc } from "backend/aaa/CurrentUserSvc"
import { handleAutoMembers } from "backend/projects/board/functions/handleAutoMembersFun"
import { WibSvc } from "backend/wib/WibService"
import { IBoardData } from "shared/entities/projects/IBoardData"
import { IWib } from "shared/entities/wib/IWib"
import { ENotiReasonColor, Wib } from "shared/entities/wib/Wib"
import { ICardWibContent } from "shared/entities/wib/wibs/ICardWibContentType"
import { transact } from "utils/Fs"

export const moveCardFun = async (cardId: string, srcBoardId: string, dstBoardId: string, dstColId: string, dstIndex: number) => {
    var boardName = "Unknown board"
    var colName = "Unknown column"
    var sendMeta = false
    await transact(async (t) => {
        const wibRef = CurrentOrgSvc.currentOrg.snapshot.ref
            .collection("Wibs")
            .doc(cardId)

        const wibE = await t.get(wibRef)
        if (!wibE.exists) {
            throw new Error("Can't find card with id: " + cardId)
        }

        const wibD = wibE.data() as IWib<ICardWibContent>
        const wib = new Wib(wibD)

        const srcBoardRef = CurrentOrgSvc.currentOrg.snapshot.ref
            .collection("Boards")
            .doc(srcBoardId)

        const dstBoardRef = CurrentOrgSvc.currentOrg.snapshot.ref
            .collection("Boards")
            .doc(dstBoardId)

        const srcBoard = await t.get(srcBoardRef)
        if (!srcBoard.exists) {
            throw new Error("Can't find srcBoard")
        }
        const srcBoardD = srcBoard.data() as IBoardData

        var dstBoardD = srcBoardD
        if (srcBoardRef.id != dstBoardRef.id) {
            const dstBoard = await t.get(dstBoardRef)
            if (!dstBoard.exists) {
                throw new Error("Can't find dstBoard")
            }
            dstBoardD = dstBoard.data() as IBoardData
        }

        var fromColIdx = -1
        var fromCardIdx = -1
        srcBoardD.columns.forEach((col, colIdx) => {
            if (fromCardIdx > -1) {
                return
            }
            col.cards.forEach((card, cardIdx) => {
                if (card == cardId) {
                    fromCardIdx = cardIdx
                }
            })
            if (fromCardIdx > -1) {
                fromColIdx = colIdx
            }
        })

        if (fromColIdx < 0 || fromCardIdx < 0) {
            throw new Error("Can't find card on src board")
        }

        const toColIdx = dstBoardD.columns.findIndex(col => col.id == dstColId)
        if (toColIdx < 0) {
            throw new Error("Can't find target column!")
        }

        const fromCol = srcBoardD.columns[fromColIdx]
        const toCol = dstBoardD.columns[toColIdx]
        var destIdx = dstIndex
        if (destIdx > toCol.cards.length) {
            destIdx = toCol.cards.length
        }

        fromCol.cards.splice(fromCardIdx, 1)
        toCol.cards.splice(destIdx, 0, cardId)

        if (srcBoardRef.id != dstBoardRef.id || fromColIdx != toColIdx) {
            sendMeta = true
            handleAutoMembers(wib, toCol, CurrentUserSvc.currentUser.id)
            wib.notifyMembers(CurrentUserSvc.currentUser.id, "moved to: " + toCol.name, ENotiReasonColor.MoveCard)
        }

        boardName = dstBoardD.name
        colName = toCol.name


        t.update(srcBoardRef, { columns: srcBoardD.columns })
        if (srcBoardRef.id != dstBoardRef.id) {
            t.update(dstBoardRef, { columns: dstBoardD.columns })
            wib.changeParentId(dstBoardRef.id)
        }

        t.update(wibRef, wib.updatedData)
    }, null)

    if (sendMeta) {
        await WibSvc.sendMetaInfo(cardId, `Moved this card to ${boardName} - ${colName}.`)
    }

}