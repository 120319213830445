import { ProfilePicture } from 'components/ProfilePicture'
import * as React from 'react'
import { style } from 'typestyle'
import { WScrollBox } from 'wds/ScrollBox'
import { EFontWeight } from 'wds/styles/EFontWeight'
import { WColorPalette } from 'wds/styles/WColor'


export class PrivateChatList extends React.Component<PrivateChatListProps> {

    private handleClick = (userId: string) => {
        this.props.onClick(userId)
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <WScrollBox>
                    <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
                        {this.props.model.items.map((item) => {
                            return (
                                <PrivateChatListItem
                                    key={'pcli-' + item.userId}
                                    model={item}
                                    isSelected={this.props.selectedId == item.userId}
                                    onClick={() => {
                                        this.handleClick(item.userId)
                                    }}
                                />
                            )
                        })}
                    </div>
                </WScrollBox>
            </div>
        )
    }

}

export interface PrivateChatListProps {
    model: PrivateChatListModel
    selectedId: string
    onClick: (userId: string) => void
}

export class PrivateChatListModel {

    public items: PrivateChatListItemModel[] = []

}

export class PrivateChatListItem extends React.Component<PrivateChatListItemProps> {

    private handleClick = (e: React.MouseEvent) => {
        this.props.onClick()
    }

    render() {
        var lastMessage = this.props.model.lastMessage
        if (lastMessage != null && !this.props.model.isLastMessageIncoming) {
            lastMessage = "Me: " + lastMessage
        }
        return (
            <div
                className={this.props.isSelected ? itemContainerSelectedClass : itemContainerClass}
                style={{ backgroundColor: this.props.model.unreadMessagesCount > 0 ? "rgba(64, 153, 215, 0.2 )" : null }}
                onClick={this.handleClick}
            >
                <div style={{ width: "32px", minWidth: "32px", height: "32px" }}>
                    <ProfilePicture
                        name={this.props.model.name}
                        profilePictureUrl={this.props.model.profilePictureUrl}
                        size={32}
                        backgroundColor={this.props.model.isOnline ? this.props.model.color : "#bbb"}
                        borderWidth={0}
                        bottomRightBadge={this.props.model.isOnline ? {
                            color: WColorPalette.Green.color,
                            size: 14,
                            strokeColor: "#eee",
                            strokeSize: 2
                        } : null}
                    />
                </div>

                <div style={{ width: "8px", minWidth: "8px" }} />

                <div style={{ flex: "1", display: "flex", flexDirection: "column" }}>
                    <div className={itemNameClass} style={{ fontWeight: this.props.model.unreadMessagesCount > 0 ? EFontWeight.Semibold.value : EFontWeight.Regular.value }}>
                        {this.props.model.name}
                    </div>

                    {lastMessage != null &&
                        <div className={lastMessageClass}>{lastMessage}</div>
                    }
                </div>

                <div style={{ width: "8px", minWidth: "8px" }} />

                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "16px",
                    minWidth: "16px",
                    height: "16px",
                    borderRadius: "8px",
                    fontSize: "11px",
                    color: "#fff",
                    backgroundColor: "#ff6d00",
                    visibility: this.props.model.unreadMessagesCount < 1 ? "hidden" : "visible"

                }}
                >
                    <div
                        style={{
                            display: "inline-flex",
                            fontSize: "11px",
                            color: "#fff",
                        }}
                    >
                        {this.props.model.unreadMessagesCount > 99 ? "99" : `${this.props.model.unreadMessagesCount}`}
                    </div>
                </div>
            </div>
        )
    }

}

const itemContainerClass = style({
    display: "flex",
    width: "100%",
    padding: "8px",
    alignItems: "center",
    cursor: "pointer",
    '$nest': {
        '&:hover': {
            backgroundColor: "#ddd"
        }
    }

})

const itemContainerSelectedClass = style({
    display: "flex",
    width: "100%",
    padding: "8px",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: '#eee'

})

const itemNameClass = style({
    width: "200px",
    maxWidth: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "16px",
    color: "#333"
})

const lastMessageClass = style({
    width: "200px",
    maxWidth: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "11px",
    fontWeight: 400,
    color: "#888"
})

export interface PrivateChatListItemProps {
    model: PrivateChatListItemModel
    isSelected: boolean,
    onClick: () => void
}

export class PrivateChatListItemModel {

    public constructor(
        public readonly userId: string,
        public readonly name: string,
        public readonly profilePictureUrl: string,
        public readonly color: string,
        public readonly lastMessage: string,
        public readonly isLastMessageIncoming: boolean,
        public readonly unreadMessagesCount: number,
        public readonly lastMessageAt: number,
        public readonly isOnline: boolean
    ) {

    }

}