import "firebase/analytics"
import "firebase/functions"
import * as React from 'react'
import { style } from 'typestyle'
import { WIcon } from 'wds/Icon'
import { ModalButton, WModal } from 'wds/Modal'
import { EFontWeight } from 'wds/styles/EFontWeight'
import { AWibCreator, AWibHandler } from 'wibib-sdk-fe/WibHandlerBase'
import { ValidationException } from '../../../../../../../functions/src/shared/jalidate/Jalidate'
import { FieldModel } from "../../../../../components/FieldModel"
import { WTextField } from "../../../../../wds/TextField"
import { WibHandlerRegistry } from '../WibHandlerRegistry'

export class NewWibDialogs extends React.Component<NewWibDialogsProps, NewWibDialogsState> {

    public constructor(props: NewWibDialogsProps) {
        super(props)

        this.state = {
            selectedType: null
        }
    }

    private readonly handleTypeSelected = (type: string) => {
        this.setState({ selectedType: type })
    }

    private readonly handleCloseClick = () => {
        this.props.onClose()
    }

    private readonly handleWibCreated = (wibId: string) => {
        this.props.onWibCreated(wibId)
    }

    render() {
        if (this.state.selectedType == null) {
            return (
                <NewWibTypeSelectorDialog
                    onCloseClicked={this.handleCloseClick}
                    onTypeSelected={this.handleTypeSelected}
                />
            )
        } else {
            return (
                <NewWibDialog
                    type={this.state.selectedType}
                    parentType={this.props.parentType}
                    parentId={this.props.parentId}
                    onClose={this.handleCloseClick}
                    onWibCreated={this.handleWibCreated}
                />
            )
        }
    }

}

export interface NewWibDialogsProps {

    parentType: 'wib' | null,
    parentId: string | null,
    onClose: () => void
    onWibCreated: (wibId: string) => void

}

interface NewWibDialogsState {

    selectedType: string

}

// ----------------------------------------------------------------

class NewWibTypeSelectorDialog extends React.Component<NewWibTypeSelectorDialogProps, NewWibTypeSelectorDialogState> {

    private readonly handleCloseClick = () => {
        this.props.onCloseClicked()
    }

    private readonly handleTypeSelected = (type: string) => {
        this.props.onTypeSelected(type)
    }

    render() {
        return (
            <WModal
                title="New WIB type"
                helperText="Select the type of the new WIB!"
                onCloseClicked={this.handleCloseClick}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    }}
                >
                    {WibHandlerRegistry.handlers.filter(item => item.creatable).map((item) => {
                        return (
                            <div key={"wt-" + item.type} className={typeItemContainerStyle} onClick={() => { this.handleTypeSelected(item.type) }}>
                                <div
                                    style={{
                                        width: '48px',
                                        height: '48px',
                                        display: 'inline-flex',
                                        borderRadius: '50%',
                                        backgroundColor: item.color,
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <WIcon icon={item.icon} color="#fff" size="24px" />
                                </div>
                                <div style={{ width: '8px' }} />
                                <div style={{ display: 'inline-flex', flex: '1', flexDirection: 'column', overflowX: 'hidden' }}>
                                    <div
                                        style={{
                                            fontSize: '18px',
                                            fontWeight: EFontWeight.Bold.value,
                                            color: "#222",
                                            width: '100%',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            paddingBottom: '4px'
                                        }}
                                    >
                                        {item.name}
                                    </div>
                                    <div
                                        style={{
                                            width: '100%',
                                            fontSize: '14px',
                                            color: '#888'
                                        }}
                                    >
                                        {item.description}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </WModal>
        )
    }

}

interface NewWibTypeSelectorDialogProps {

    onTypeSelected: (type: string) => void
    onCloseClicked: () => void

}

interface NewWibTypeSelectorDialogState {

}

const typeItemContainerStyle = style({
    display: 'flex',
    width: '100%',
    padding: '8px',
    cursor: 'pointer',
    alignItems: 'center',
    $nest: {
        '&:hover': {
            backgroundColor: "#eee"
        }
    }
})

// ----------------------------------------------------------------

export class NewWibDialog extends React.Component<NewWibDialogProps, NewWibDialogState> {

    private handler: AWibHandler<{}>
    private wibCreator: AWibCreator
    private readonly subjectModel = new FieldModel("")

    public constructor(props: NewWibDialogProps) {
        super(props)

        this.handler = WibHandlerRegistry.getHandlerByType(this.props.type)
        if (this.handler == null) {
            throw new Error("Invalid wib handler type: " + this.props.type)
        }
        this.wibCreator = this.handler.createWibCreator()

        const model = new NewWibDialogModel()

        this.state = {
            model: model
        }
    }

    private readonly handleCancelClick = () => {
        this.props.onClose()
    }

    private readonly handleCreateClick = async () => {
        this.state.model.error = null
        var subject = this.subjectModel.value
        subject = subject.trim()
        if (subject.length < 1) {
            this.subjectModel.error = "This field is mandatory!"
            this.setState({})
            return
        }

        this.state.model.isLoading = true
        this.setState({})

        var wibId: string = null
        try {
            wibId = await this.wibCreator.createWib(subject)
        } catch (ex) {
            if (ex instanceof ValidationException) {
                this.state.model.isLoading = false
                this.state.model.error = ex.message
            } else {
                this.state.model.isLoading = false
                this.state.model.error = "Internal server error occured! Reload the application and try it again!"
            }
            this.setState({})
            return
        }

        this.props.onWibCreated(wibId)
    }

    render() {
        return (
            <WModal
                title={`Create new ${this.handler.name}`}
                isLoading={this.state.model.isLoading}
                error={this.state.model.error}
                buttons={[
                    new ModalButton("Cancel", this.handleCancelClick, "text"),
                    new ModalButton("Create", this.handleCreateClick, "contained", "primary")
                ]}
            >
                <WTextField model={this.subjectModel} label="Subject" helperText="The subject of the new WIB!" required />

                {this.wibCreator.renderDialog()}

            </WModal>
        )
    }

    // private renderLeftColumn() {
    //     return (
    //         <div
    //             style={{
    //                 width: '350px',
    //                 display: 'inline-flex',
    //                 minHeight: '100%',
    //             }}
    //         >
    //             <WVBox spacing={32}>
    //                 <WVBoxCell>
    //                     <WTextField
    //                         model={this.state.model.subjectModel}
    //                         label="Subject"
    //                         helperText="The subject of the new Wib"
    //                     />
    //                 </WVBoxCell>

    //                 <WVBoxCell>
    //                     {this.renderRecipientSelector()}
    //                 </WVBoxCell>
    //             </WVBox>
    //         </div>
    //     )
    // }

    // private renderRightColumn() {
    //     return (
    //         <div
    //             style={{
    //                 flex: 1,
    //                 display: 'inline-flex',
    //                 height: '100%'
    //             }}
    //         >

    //             {this.wibCreator.renderDialog()}

    //         </div>
    //     )
    // }

    // private renderRecipientSelector() {
    //     return (
    //         <div
    //             style={{
    //                 width: "100%",
    //                 display: "flex",
    //                 flexDirection: "column"
    //             }}
    //         >
    //             <div
    //                 style={{
    //                     fontSize: "12px",
    //                     fontWeight: 700,
    //                     textTransform: "uppercase",
    //                     color: EColor.InputLabelColor.color,
    //                 }}
    //             >
    //                 Recipients
    //                 </div>

    //             <div
    //                 style={{
    //                     width: "100%",
    //                     maxHeight: "200px"
    //                 }}
    //             >
    //                 <WScrollBox>
    //                     {this.state.model.contacts
    //                         .filter((item) => {
    //                             if (this.props.allowedParitipants == null) {
    //                                 return true
    //                             }
    //                             return this.props.allowedParitipants.indexOf(item.id) > -1
    //                         })
    //                         .map((item) => {
    //                             return (
    //                                 <ContactListItem
    //                                     key={"cli-" + item.id}
    //                                     model={item}
    //                                     onClick={() => {
    //                                         if (item.isSelected) {
    //                                             this.handleRecipientDeselected(item.id)
    //                                         } else {
    //                                             this.handleRecipientSelected(item.id)
    //                                         }
    //                                     }}
    //                                 />
    //                             )
    //                         })}
    //                 </WScrollBox>
    //             </div>
    //         </div>
    //     )
    // }

}

export interface NewWibDialogProps {

    type: string
    parentType: string | null,
    parentId: string | null
    onClose: () => void
    onWibCreated: (wibId: string) => void

}

interface NewWibDialogState {

    model: NewWibDialogModel

}

class NewWibDialogModel {

    public error: string = null
    public isLoading: boolean = false

}

// --------------------------------------------------------------------------------------------

// class ContactListItem extends React.Component<ContactListItemProps> {

//     render() {
//         return (
//             <div
//                 style={{
//                     width: "100%",
//                     paddingTop: "4px",
//                     paddingBottom: "4px",
//                     display: "flex",
//                     alignItems: "center",
//                     cursor: "pointer"
//                 }}
//                 onClick={this.props.onClick}
//             >
//                 <ProfilePicture
//                     name={this.props.model.name}
//                     profilePictureUrl={this.props.model.profilePictureUrl}
//                     size={32}
//                     backgroundColor={this.props.model.isSelected ? "#0bd" : "#ccc"}
//                     borderColor={this.props.model.isSelected ? "#0bd" : "#ccc"}
//                     borderWidth={4}
//                 />
//                 <div style={{ width: "8px" }} />
//                 <div
//                     style={{
//                         flex: "1",
//                         fontSize: "16px",
//                         fontWeight: EFontWeight.Semibold.value,
//                         color: "#222",
//                         overflow: "hidden",
//                         textOverflow: "ellipsis"
//                     }}
//                 >
//                     {this.props.model.name}
//                 </div>
//             </div>
//         )
//     }

// }

// interface ContactListItemProps {

//     model: NewWibContactListItem
//     onClick: () => void

// }