import { BoardSvc } from 'backend/projects/board/BoardService'
import 'firebase/analytics'
import * as firebase from 'firebase/app'
import "firebase/firestore"
import * as React from 'react'
import { IBoardData } from 'shared/entities/projects/IBoardData'
import { IWib } from 'shared/entities/wib/IWib'
import { ICardWibContent } from 'shared/entities/wib/wibs/ICardWibContentType'
import { FieldModel } from '../../../../../../components/FieldModel'
import { ModalButton, WModal } from '../../../../../../wds/Modal'
import { SelectOption, WSelect } from '../../../../../../wds/Select'

export class MoveCardDialog extends React.Component<MoveCardDialogProps, MoveCardDialogState> {

    private currentBoardD: IBoardData = null

    state: MoveCardDialogState = {
        error: null,
        isLoading: true,
        boardModel: new FieldModel(""),
        boardOptions: [],
        columnModel: new FieldModel(""),
        columnOptions: [],
        positionModel: new FieldModel("top")
    }

    componentDidMount() {
        this.init()
    }

    private readonly init = async () => {
        const boards = await BoardSvc.listBoards()
        const boardOptions = boards.docs.map((e) => {
            return new SelectOption(e.id, e.data().name)
        })
        this.state.boardOptions = boardOptions
        this.setState({ isLoading: false })
    }

    private readonly handleSubmit = async () => {
        this.state.boardModel.error = null
        this.state.columnModel.error = null
        this.state.error = null

        var hasError = false
        if (this.state.boardModel.value == "") {
            hasError = true
            this.state.boardModel.error = "This field is mandatory!"
        }

        if (this.state.columnModel.value == "") {
            hasError = true
            this.state.columnModel.error = "This field is mandatory!"
        }

        if (hasError) {
            this.setState({})
            return
        }

        this.setState({ isLoading: true })
        try {
            await BoardSvc.moveCard(this.props.wibId, this.props.wib.parentId, this.state.boardModel.value, this.state.columnModel.value, this.state.positionModel.value == "top" ? 0 : 999999)

            firebase.analytics().logEvent('projects.board.card.wib.move_card_with_dialog')
            this.props.onClose()
        } catch (ex) {
            this.setState({ error: "Server error occured! Reload the page and try again!", isLoading: false })
        }
    }

    private readonly handleBoardChange = async () => {
        if (this.state.boardModel.value == null) {
            this.currentBoardD = null
            this.updateAvailableColumns(null)
            return
        }

        this.setState({ isLoading: true })

        const board = await BoardSvc.findboardById(this.state.boardModel.value)
        this.currentBoardD = board.data

        this.updateAvailableColumns(this.currentBoardD)
    }

    private updateAvailableColumns(boardD: IBoardData) {
        var cols: SelectOption[] = []

        if (boardD != null) {
            cols = boardD.columns.filter((col) => {
                if (col.options == null) {
                    return true
                }

                if (col.options.actionOptions == null) {
                    return true
                }

                return !col.options.actionOptions.disableDrop
            }).map((col) => {
                return new SelectOption(col.id, col.name)
            })
        }

        this.state.columnModel.value = ""
        this.setState({ columnOptions: cols, isLoading: false })
    }

    render() {
        return (
            <WModal
                title="Move card"
                isLoading={this.state.isLoading}
                error={this.state.error}
                buttons={[
                    new ModalButton("Cancel", this.props.onClose, "text", "default"),
                    new ModalButton("Move card", null, "contained", "primary")
                ]}
                onSubmit={this.handleSubmit}
            >

                <WSelect
                    label="Target board"
                    model={this.state.boardModel}
                    options={this.state.boardOptions}
                    required
                    helperText="The board which you want to move this card."
                    onChange={this.handleBoardChange}
                />
                <WSelect
                    label="Target column"
                    model={this.state.columnModel}
                    options={this.state.columnOptions}
                    required
                    helperText="The column which you want to move this card."
                />
                <WSelect
                    label="Position"
                    model={this.state.positionModel}
                    options={[
                        new SelectOption("top", "Top"),
                        new SelectOption("bottom", "Bottom"),
                    ]}
                    required
                    helperText="The position where you want to place the card inside the column."
                />

            </WModal>
        )
    }

}

export interface MoveCardDialogProps {

    wibId: string
    wib: IWib<ICardWibContent>
    currentBoardId: string
    onClose: () => void

}

interface MoveCardDialogState {

    isLoading: boolean
    error: string
    boardModel: FieldModel<string>
    boardOptions: SelectOption[]
    columnModel: FieldModel<string>
    columnOptions: SelectOption[]
    positionModel: FieldModel<string>


}