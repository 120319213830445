import * as React from 'react';

export class WHBox extends React.Component<WHBoxProps, {}> {

    render() {
        let height = "auto"
        if (this.props.height != null) {
            height = this.props.height
        }

        return (
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    height: height
                }}
            >
                {this.props.children}
            </div>
        )
    }

}

export interface WHBoxProps {

    height?: string

}

export class WHBoxCell extends React.Component<WHBoxCellProps, {}> {

    static defaultProps: WHBoxCellProps = {
        flex: null,
        width: null,
        vAlign: "middle"
    }

    render() {

        let vAlign = "center"
        if (this.props.vAlign == "top") {
            vAlign = "flex-start"
        } else if (this.props.vAlign == "bottom") {
            vAlign = "flex-end"
        }

        return (
            <div
                style={{
                    width: this.props.width,
                    minWidth: this.props.width,
                    maxWidth: this.props.width,
                    flex: this.props.flex,
                    flexBasis: this.props.flex != null ? "0px" : null,
                    alignSelf: vAlign,
                    overflowX: this.props.width != null || this.props.flex != null ? "hidden" : null
                }}
            >
                {this.props.children}
            </div>
        )
    }

}

export interface WHBoxCellProps {

    flex?: number
    width?: string
    height?: string
    vAlign?: "top" | "middle" | "bottom"

}