import { CurrentOrgSvc } from "backend/aaa/CurrentOrgSvc"
import { CurrentUserSvc } from "backend/aaa/CurrentUserSvc"
import { WibSvc } from "backend/wib/WibService"
import { IWib } from "shared/entities/wib/IWib"
import { ENotiReasonColor, Wib } from "shared/entities/wib/Wib"
import { ICardWibContent } from "shared/entities/wib/wibs/ICardWibContentType"
import { transact } from "utils/Fs"

export const changeDescriptionFun = async (cardId: string, newDesc: string) => {
    await transact(async (t) => {
        const wibRef = CurrentOrgSvc.currentOrg.snapshot.ref
            .collection("Wibs")
            .doc(cardId)

        const wibE = await t.get(wibRef)
        if (!wibE.exists) {
            throw new Error("Can't find card with id: " + cardId)
        }

        const wibD = wibE.data() as IWib<ICardWibContent>
        const wib = new Wib(wibD)
        wib.changeContent((c) => {
            c.description = newDesc
            return c
        })
        wib.notifyMembers(CurrentUserSvc.currentUser.id, "changed the description", ENotiReasonColor.ChangeContent)

        t.update(wibRef, wib.updatedData)
    }, null)

    await WibSvc.sendMetaInfo(cardId, `changed the description.`)
}