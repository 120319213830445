import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import * as React from 'react'
import { FieldModel } from '../components/FieldModel'

export class WMembersInput extends React.Component<WMembersInputProps, WMembersInputState> {

    state: WMembersInputState = {

    }

    render() {
        return (
            <Autocomplete
                multiple
                options={this.props.options}
                getOptionLabel={(option) => option.name}
                defaultValue={this.props.model.value}
                filterSelectedOptions
                onChange={(event, value) => {
                    this.props.model.value = value
                    if (this.props.onChange != null) {
                        this.props.onChange(value)
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label={this.props.label}
                        placeholder={this.props.placeholder}
                        helperText={this.props.model.error != null ? this.props.model.error : this.props.helperText}
                        error={this.props.model.error != null}
                    />
                )}
            />
        )
    }

}

export interface WMembersInputProps {

    label?: string
    placeholder?: string
    helperText?: string
    model: FieldModel<WMembersInputItem[]>
    options: WMembersInputItem[]
    onChange?: (selectedItems: WMembersInputItem[]) => void

}

interface WMembersInputState {

}

export interface WMembersInputItem {
    id: string,
    name: string,
}