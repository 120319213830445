import { AController } from "@eagerlogic/react-mvc";
import { CurrentUserSvc } from "backend/aaa/CurrentUserSvc";
import "firebase/analytics";
import * as firebase from "firebase/app";
import "firebase/functions";
import { EUserRole } from "shared/entities/aaa/EUserRole";
import { IInvitationData } from "shared/entities/aaa/IInvitationData";
import { IUserData } from "shared/entities/aaa/IUserData";
import { IInviteUserRpcReq, IInviteUserRpcResp } from "shared/modules/aaa/AaaFunctionDescriptors";
import { emailValidator, userRoleValidator } from "shared/modules/aaa/Validators";
import { ICloudFunction } from "utils/FbUtils";
import { fs } from "utils/Fs";
import { SelectOption } from "wds/Select";
import { Snackbar } from "wds/Snackbar";
import { InvitationDialogModel, TeamMemberInvitationModel, TeamMemberModel, TeamSettingsSceneModel } from "./TeamSettingsSceneModel";


export class TeamSettingsSceneController extends AController<TeamSettingsSceneModel> {

    private readonly membersQuery = fs().collection("Users").where("organizationId", "==", CurrentUserSvc.currentUser.data.organizationId)
    private members: firebase.firestore.QuerySnapshot<IUserData>

    private readonly invitationsQuery = fs().collection("Invitations").where("organizationId", "==", CurrentUserSvc.currentUser.data.organizationId)
    private invitations: firebase.firestore.QuerySnapshot<IInvitationData>

    public constructor() {
        super()
    }

    protected createInitialModel(): TeamSettingsSceneModel {
        return new TeamSettingsSceneModel()
    }

    async init() {
        this.model.isLoading = true
        this.render(this.model)

        this.members = await this.membersQuery.get() as firebase.firestore.QuerySnapshot<IUserData>

        this.invitations = await this.invitationsQuery.get() as firebase.firestore.QuerySnapshot<IInvitationData>

        this.membersQuery.onSnapshot((snapshot) => {
            this.members = snapshot as firebase.firestore.QuerySnapshot<IUserData>
            this.update()
        })

        this.invitationsQuery.onSnapshot((snapshot) => {
            this.invitations = snapshot as firebase.firestore.QuerySnapshot<IInvitationData>
            this.update()
        })

        this.update()

        firebase.analytics().logEvent('settings.team.scene_opened')
    }

    public readonly handleInviteUserClick = () => {
        this.model.invitationDialogModel = new InvitationDialogModel([new SelectOption("User", "User"), new SelectOption("Admin", "Admin"), new SelectOption("Owner", "Owner")])
        this.render(this.model)
        firebase.analytics().logEvent('settings.team.invitation_dialog_open')
    }

    public readonly handleInviteUserOkClick = async () => {
        var hasError: { hasError: boolean } = { hasError: false }

        var vEmail = emailValidator.validateToBind(this.model.invitationDialogModel.emailModel.value, this.model.invitationDialogModel.emailModel, hasError)
        var vRole = userRoleValidator.validateCustom(this.model.invitationDialogModel.roleModel.value, () => { })

        if (hasError.hasError) {
            this.render(this.model)
            return
        }

        this.model.invitationDialogModel.isLoading = true
        this.render(this.model)

        var baseUrl = window.location.protocol + "//" + window.location.host

        var inviteUserFun = firebase.app().functions("europe-west1").httpsCallable("inviteUser") as ICloudFunction<IInviteUserRpcReq, IInviteUserRpcResp>
        try {
            await inviteUserFun({
                baseUrl: baseUrl,
                email: vEmail,
                message: this.model.invitationDialogModel.messageModel.value,
                role: this.model.invitationDialogModel.roleModel.value
            })
            Snackbar.show("success", "Invitation sent to: " + vEmail)
            firebase.analytics().logEvent('settings.team.invitation_sent')
        } catch (ex) {
            this.model.invitationDialogModel.error = ex.message
        } finally {
            this.model.invitationDialogModel = null
            this.render(this.model)
        }
    }

    public readonly handleInviteUserCancelClick = () => {
        this.model.invitationDialogModel = null
        this.render(this.model)
        firebase.analytics().logEvent('settings.team.invitation_dialog_cancel')
    }

    private update() {
        this.model.isLoading = true
        this.render(this.model)

        this.model.invitations = this.invitations.docs.map((item) => {
            return new TeamMemberInvitationModel(item.data().email, EUserRole.findByName(item.data().role))
        })

        this.model.members = this.members.docs.map((item) => {
            return new TeamMemberModel(item.data().name, item.data().email, EUserRole.findByName(item.data().role))
        })

        this.model.isLoading = false
        this.render(this.model)
    }

}