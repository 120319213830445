import { ProfilePicture } from 'components/ProfilePicture'
import * as React from 'react'
import { StringUtils } from 'shared/utils/StringUtils'
import { style } from 'typestyle'
import { EFontWeight } from 'wds/styles/EFontWeight'

export class IncomingMessageView extends React.Component<IncomingMessageViewProps, IncomingMessageViewState> {

    render() {
        return (
            <div
                style={{
                    width: '100%',
                    paddingRight: '128px',
                    paddingLeft: '8px',
                    paddingTop: '12px',
                    paddingBottom: '12px',
                }}
            >
                <div className={baseContainerStyle}>
                    <div style={{ minWidth: '48px' }}>
                        <ProfilePicture
                            name={this.props.senderName}
                            profilePictureUrl={this.props.senderProfilePictureUrl}
                            size={48}
                            backgroundColor={this.props.userColor}
                            borderWidth={0}
                        />
                    </div>
                    <div style={{ width: '8px', minWidth: '8px' }} />
                    <div className={messageColumnStyle} style={{ alignItems: 'flex-start' }}>
                        <div className={messageBoxStyle} style={{ borderLeft: `4px solid ${this.props.userColor}` }}>
                            <div className={senderNameStyle}>{this.props.senderName}</div>
                            {(typeof this.props.content) == 'string' &&
                                <div className={messageStyle} dangerouslySetInnerHTML={{ __html: this.props.content as string }}></div>
                            }

                            {(typeof this.props.content) != 'string' &&
                                <div className={messageStyle}>
                                    {(this.props.content as (() => React.ReactNode))()}
                                </div>
                            }

                        </div>
                        <div className={sentAtStyle}>{StringUtils.convertTimestampToStr(this.props.sentAt)}</div>
                    </div>
                </div>

            </div>
        )
    }

}

export interface IncomingMessageViewProps {

    senderName: string
    senderProfilePictureUrl: string
    userColor: string
    sentAt: number
    content: string | (() => React.ReactNode)

}

interface IncomingMessageViewState {

}

// ---------------------------------------------------------------

export class OutgoingMessageView extends React.Component<OutgoingMessageViewProps, OutgoingMessageViewState> {

    render() {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    paddingRight: '8px',
                    paddingLeft: '128px',
                    paddingTop: '12px',
                    paddingBottom: '12px',
                }}
            >
                <div className={baseContainerStyle}>
                    <div className={messageColumnStyle} style={{ alignItems: 'flex-end' }}>
                        <div className={messageBoxStyle} style={{ borderRight: `4px solid ${this.props.userColor}` }}>
                            {(typeof this.props.content) == 'string' &&
                                <div className={messageStyle} dangerouslySetInnerHTML={{ __html: this.props.content as string }}></div>
                            }

                            {(typeof this.props.content) != 'string' &&
                                <div className={messageStyle}>
                                    {(this.props.content as (() => React.ReactNode))()}
                                </div>
                            }
                        </div>
                        <div className={sentAtStyle}>{StringUtils.convertTimestampToStr(this.props.sentAt)}</div>
                    </div>
                </div>

            </div>
        )
    }

}

export interface OutgoingMessageViewProps {

    senderName: string
    senderProfilePictureUrl: string
    userColor: string
    sentAt: number
    content: string | (() => React.ReactNode)

}

interface OutgoingMessageViewState {

}

const baseContainerStyle = style({
    display: 'flex',
    //width: '100%'
})

const messageColumnStyle = style({
    //flex: 1,
    display: 'flex',
    flexDirection: 'column',
})

const messageBoxStyle = style({
    //width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '8px'
})

const senderNameStyle = style({
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    fontWeight: EFontWeight.Semibold.value,
    color: '#222'
})

const messageStyle = style({
    width: '100%',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    fontSize: '14px',
    fontWeight: EFontWeight.Regular.value,
    color: '#222'
})

const sentAtStyle = style({
    display: 'inline-block',
    paddingTop: '4px',
    fontSize: '13px',
    fontWeight: EFontWeight.Regular.value,
    color: '#666'
})