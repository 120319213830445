import { FieldModel } from "components/FieldModel"

export class ProjectBoardListSceneModel {

    public isLoading: boolean = true
    public boards: BoardListItemModel[] = []
    public createNewBoardDialogModel: CreateNewBoardDialogModel = null
    public editBoardNameDialogModel: EditBoardNameDialogModel = null

}

export class BoardListItemModel {

    public constructor(
        public readonly id: string,
        public readonly name: string,
        public readonly ownerName: string,
        public readonly ownerColor: string,
        public readonly ownerPPUrl: string,
        public readonly color: string
    ) { }

}

export class CreateNewBoardDialogModel {

    public isLoading: boolean = false
    public error: string = null
    public nameModel = new FieldModel("")

}

export class EditBoardNameDialogModel {

    public error: string = null
    public isLoading: boolean = false
    public readonly nameModel: FieldModel<string> = new FieldModel("")

    public constructor(
        public readonly boardId: string,
        boardName: string
    ) {
        this.nameModel.value = boardName
    }

}