import { ProfilePicture } from 'components/ProfilePicture'
import * as React from 'react'

export class LastSeenView extends React.Component<LastSeenViewProps> {

    render() {
        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    paddingLeft: '16px',
                    paddingRight: '8px',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}
            >

                {this.props.users.map((item) => {
                    return (
                        <div
                            key={"ls-" + item.userId}
                            style={{
                                height: '16px',
                                minHeight: '16px',
                                paddingRight: '4px'
                            }}
                        >
                            <ProfilePicture
                                name={item.userName}
                                size={16}
                                profilePictureUrl={item.userPicture}
                                backgroundColor={item.userColor}
                                borderWidth={0}
                                popupText={item.userName}
                            />
                        </div>
                    )
                })}

            </div>
        )
    }

}

export interface LastSeenViewProps {
    users: LastSeenUserModel[]
}

export class LastSeenUserModel {

    public constructor(
        public readonly userId: string,
        public readonly userName: string,
        public readonly userColor: string,
        public readonly userPicture: string
    ) { }
}
