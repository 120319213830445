import * as React from 'react'
import { WIcon } from './Icon'
import { EColor } from './styles/WColor'

export class WLinkButton extends React.Component<WLinkButtonProps, WLinkButtonState> {

    public constructor(props: WLinkButtonProps) {
        super(props)

        this.state = {
            isDown: false,
            isHovered: false
        }
    }

    private handleMouseEnter = (evt) => {
        if (this.props.disabled) {
            return
        }

        this.setState({ isHovered: true, isDown: false })
    }

    private handleMouseLeave = (evt) => {
        if (this.props.disabled) {
            return
        }

        this.setState({ isHovered: false, isDown: false })
    }

    private handleMouseDown = (evt) => {
        if (this.props.disabled) {
            return
        }

        this.setState({ isHovered: true, isDown: true })
    }

    private handleMouseUp = (evt) => {
        if (this.props.disabled) {
            return
        }

        this.setState({ isHovered: true, isDown: false })
    }

    private handleClick = (evt) => {
        if (this.props.disabled) {
            return
        }

        if (this.props.onClick != null) {
            this.props.onClick()
        }
    }

    render() {
        const colorName = this.props.color == null ? "default" : this.props.color
        var textColor = EColor.ButtonDefaultColor.color
        if (colorName == "success") {
            textColor = EColor.ButtonSuccessColor.color
        } else if (colorName == "warning") {
            textColor = EColor.ButtonWarningColor.color
        } else if (colorName == "danger") {
            textColor = EColor.ButtonDangerColor.color
        } else if (colorName == "grey") {
            textColor = EColor.ButtonGreyColor.color
        }

        const sizeName = this.props.size == null ? "normal" : this.props.size
        var height = "40px"
        var fontSize = "16px"
        var iconSize = "20px"
        var fontWeight = 700
        var borderRadius = "20px"
        if (sizeName == "small") {
            height = "32px"
            borderRadius = "16px"
            fontSize = "14px"
            iconSize = "18px"
            fontWeight = 600
        } else if (sizeName == "large") {
            height = "48px"
            borderRadius = "24px"
            fontSize = "18px"
            iconSize = '24px'
            fontWeight = 900
        }

        var opacity = 1.0
        if (this.props.disabled) {
            opacity = 0.3
        } else if (this.state.isDown) {
            opacity = 0.5
        } else if (this.state.isHovered) {
            opacity = 0.75
        }

        return (
            <div style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: "24px", paddingRight: "24px",
                cursor: this.props.disabled == true ? "defalut" : "pointer",
                borderRadius: borderRadius,
                height: height,
                width: this.props.width,
                opacity: opacity

            }}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onMouseDown={this.handleMouseDown}
                onMouseUp={this.handleMouseUp}
                onClick={this.handleClick}
            >

                {this.props.icon != null &&
                    <>
                        <WIcon icon={this.props.icon} color={textColor} size={iconSize} />
                        <div style={{ display: 'inline-block', width: '8px' }} />
                    </>
                }

                <div style={{ display: "inline-block", fontSize: fontSize, fontWeight: fontWeight, color: textColor, textTransform: "uppercase" }}>
                    {this.props.title}
                </div>
            </div>
        )
    }

}

export interface WLinkButtonProps {
    title: string
    icon?: string
    color?: "default" | "success" | "warning" | "danger" | "grey"
    size?: "small" | "normal" | "large"
    disabled?: boolean
    width?: string
    onClick?: () => void

}

interface WLinkButtonState {
    isHovered: boolean
    isDown: boolean
}