import { Editor, EditorContext, WithEditorActions } from '@atlaskit/editor-core';
import { WikiMarkupTransformer } from '@atlaskit/editor-wikimarkup-transformer';
import { ADFEncoder, ReactRenderer } from '@atlaskit/renderer';
import "firebase/analytics";
import * as firebase from "firebase/app";
import { EditorView } from 'prosemirror-view';
import * as React from 'react';
import { style } from 'typestyle';
import { WIconButton } from '../../../../../wds/IconButton';
import { EFontWeight } from '../../../../../wds/styles/EFontWeight';


export class CommonDescriptionView extends React.Component<CommonDescriptionViewProps, CommonDescriptionViewState> {

    static getDerivedStateFromProps(nextProps: CommonDescriptionViewProps, prevState: CommonDescriptionViewState): CommonDescriptionViewState {
        return {
            isEditing: prevState.isEditing,
            content: nextProps.content,
            editingContent: prevState.editingContent
        }
    }

    public constructor(props: CommonDescriptionViewProps) {
        super(props)

        this.state = {
            isEditing: false,
            content: props.content,
            editingContent: props.content
        }
    }

    private readonly handleEditClick = () => {
        this.setState({ isEditing: true, editingContent: this.state.content })
    }

    private readonly handleSaveClick = (editorView: EditorView) => {
        var text = new WikiMarkupTransformer().encode(editorView.state.doc);
        this.setState({
            isEditing: false,
            content: text
        })
        if (this.props.onChange != null) {
            this.props.onChange(text)
        }
        firebase.analytics().logEvent('wib.description_changed')
    }

    private readonly handleCancelClick = () => {
        this.setState({ isEditing: false })
    }

    render() {
        const encoder = new ADFEncoder((schema) => new WikiMarkupTransformer(schema))
        const doc = encoder.encode(this.state.content)
        return (
            <div style={{ width: '100%' }}>
                <div className={labelContainerStyle}>
                    <div className={labelStyle}>{this.props.label}</div>
                    {this.props.isEditable && !this.state.isEditing &&
                        <WIconButton icon="edit" size="small" onClick={this.handleEditClick} />
                    }
                </div>

                {!this.state.isEditing && this.state.content.trim().length > 0 &&
                    <div>
                        <ReactRenderer document={doc} />
                    </div>
                }

                {!this.state.isEditing && this.state.content.trim().length < 1 &&
                    <div className={emptyDescriptionStyle} onClick={this.handleEditClick}>
                        Click here to add description
                    </div>
                }

                {this.state.isEditing &&
                    <EditorContext>
                        <WithEditorActions
                            render={actions => (
                                <Editor
                                    defaultValue={this.state.editingContent}
                                    contentTransformerProvider={schema =>
                                        new WikiMarkupTransformer(schema)
                                    }
                                    shouldFocus
                                    onCancel={this.handleCancelClick}
                                    onSave={this.handleSaveClick}
                                />
                            )}
                        />
                    </EditorContext>
                }
            </div>
        )
    }

}

export interface CommonDescriptionViewProps {

    label: string
    isEditable: boolean
    content: string
    onChange?: (newContent: string) => void

}

interface CommonDescriptionViewState {

    isEditing: boolean
    content: string
    editingContent: string

}

const labelContainerStyle = style({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '4px'
})

const labelStyle = style({
    maxWidth: '600px',
    paddingRight: '8px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '16px',
    fontWeight: EFontWeight.Semibold.value,
    color: '#666'
})

const emptyDescriptionStyle = style({
    width: '100%',
    padding: '16px',
    backgroundColor: '#eee',
    borderRadius: '4px',
    cursor: 'pointer',
    $nest: {
        '&:hover': {
            backgroundColor: '#ccc'
        }
    }
})