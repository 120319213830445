import { ProviderFactory } from '@atlaskit/editor-common';
import { CollapsedEditor, Editor, EditorContext, WithEditorActions } from '@atlaskit/editor-core';
// import { MarkdownTransformer } from '@atlaskit/editor-markdown-transformer';
import { WikiMarkupTransformer } from '@atlaskit/editor-wikimarkup-transformer';
import { MentionNameDetails, MentionNameStatus, ResolvingMentionProvider } from '@atlaskit/mention';
import { MentionContextIdentifier, MentionDescription, MentionProvider } from '@atlaskit/mention/resource';
import { AnalyticsCallback, ErrorCallback, InfoCallback, ResultCallback } from '@atlaskit/mention/types';
import { ADFEncoder, ReactRenderer } from '@atlaskit/renderer';
import { UsersSvc } from 'backend/aaa/UsersSvc';
import "firebase/analytics";
import * as firebase from "firebase/app";
import * as React from 'react';
import { IWib } from 'shared/entities/wib/IWib';
import { IWibMessage } from 'shared/entities/wib/IWibMessage';
import { ICommentWibMessageContent } from 'shared/entities/wib/messages/ICommentWibMessageContent';
import { IFileAttachWibMessageContent } from 'shared/entities/wib/messages/IFileAttachWibMessageContent';
import { IMetaInfoWibMessageContent } from 'shared/entities/wib/messages/IMetaInfoWibMessageContent';
import { style } from 'typestyle';
import { StringUtils } from '../../../../../../../functions/src/shared/utils/StringUtils';
import { FieldModel } from '../../../../../components/FieldModel';
import { ProfilePicture } from '../../../../../components/ProfilePicture';
import { WIcon } from '../../../../../wds/Icon';
import { WScrollBox } from '../../../../../wds/ScrollBox';
import { EFontWeight } from '../../../../../wds/styles/EFontWeight';
import { WSwitch } from '../../../../../wds/Switch';
import { ConversationMetaInfo } from '../../commmain/components/ConverstionMetaInfo';



class MyMentionProvider implements MentionProvider {

    private callback: ResultCallback<MentionDescription[]> = null
    private allCallback: ResultCallback<MentionDescription[]> = null
    private query: string = ""
    private data: MentionDescription[] = []

    filter(query?: string, contextIdentifier?: MentionContextIdentifier): void {
        this.query = query
        this.update()
    }
    recordMentionSelection(mention: MentionDescription, contextIdentifier?: MentionContextIdentifier): void {
    }
    shouldHighlightMention(mention: MentionDescription): boolean {
        mention.name = "Asd"
        mention.mentionName = "Asd"
        mention.nickname = "Asd"
        return true
    }
    isFiltering(query: string): boolean {
        return true
    }
    subscribe(key: string, callback?: ResultCallback<MentionDescription[]>, errCallback?: ErrorCallback, infoCallback?: InfoCallback,
        allResultsCallback?: ResultCallback<MentionDescription[]>, analyticsCallback?: AnalyticsCallback): void {
        this.callback = callback
        this.allCallback = allResultsCallback
        this.update()
    }
    unsubscribe(key: string): void {
    }

    private update() {
        this.data.splice(0, this.data.length)
        UsersSvc.users
            .filter((u) => {
                if (this.query == null || this.query.trim() == "") {
                    return true
                }
                const q = this.query.trim().toLocaleLowerCase()
                return u.data().name.toLocaleLowerCase().indexOf(q) > -1
            })
            .map((u) => {
                var isq = false
                var qs = 0
                var qe = 0
                if (this.query != null && this.query.trim() != "") {
                    var q = this.query.trim().toLocaleLowerCase()
                    var idx = u.data().name.toLocaleLowerCase().indexOf(q)
                    if (idx > -1) {
                        isq = true
                    }
                    qs = idx
                    qe = idx + q.length
                }
                return {

                    id: u.id,
                    avatarUrl: u.data().profilePictureUrl,
                    name: u.data().name,
                    mentionName: u.data().name,
                    nickname: u.data().name,
                    highlight: isq ? {
                        name: [{ start: qs, end: qe }],
                        mentionName: [{ start: qs, end: qe }],
                        nickname: [{ start: qs, end: qe }],
                    } : undefined
                }
            })
            .forEach((u) => {
                this.data.push(u)
            })

        this.callback(this.data)

    }

}

class RendererMentionProvider implements ResolvingMentionProvider {
    resolveMentionName(id: string): MentionNameDetails | Promise<MentionNameDetails> {
        id = id.substring('accountId:'.length, id.length)
        const user = UsersSvc.usersById[id]
        var name = "unknown"
        var status = MentionNameStatus.UNKNOWN
        if (user != null) {
            name = user.data().name
            status = MentionNameStatus.OK
        }
        const res: MentionNameDetails = {
            id: id,
            name: name,
            status: status
        }
        return res
    }
    cacheMentionName(id: string, mentionName: string): void {

    }
    supportsMentionNameResolving(): boolean {
        return true
    }
    filter(query?: string, contextIdentifier?: MentionContextIdentifier): void {

    }
    recordMentionSelection(mention: MentionDescription, contextIdentifier?: MentionContextIdentifier): void {

    }
    shouldHighlightMention(mention: MentionDescription): boolean {
        return true
    }
    isFiltering(query: string): boolean {
        return false
    }
    subscribe(key: string, callback?: ResultCallback<MentionDescription[]>, errCallback?: ErrorCallback, infoCallback?: InfoCallback, allResultsCallback?: ResultCallback<MentionDescription[]>, analyticsCallback?: AnalyticsCallback): void {

    }
    unsubscribe(key: string): void {

    }

}

const mp = Promise.resolve(new RendererMentionProvider())

const pf = ProviderFactory.create({
    mentionProvider: mp,
})

export class CommonWibView extends React.Component<CommonWibViewProps, CommonWibViewState> {

    state: CommonWibViewState = {
        isCommentComposerExpanded: false,
        detailsVisible: window.localStorage.getItem('wib.hideDetails') != 'true'
    }

    public constructor(props: CommonWibViewProps) {
        super(props)
    }

    render() {
        const creator = UsersSvc.usersById[this.props.wib.createdBy]
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                    backgroundColor: '#f8f8f8'
                }}
            >
                <WScrollBox>
                    <div className={headerStyle}>
                        {(typeof this.props.header) == 'string' &&
                            <div style={{}}>{this.props.header}</div>
                        }

                        {(typeof this.props.header) != 'string' &&
                            (this.props.header as () => React.ReactNode)()
                        }
                    </div>

                    <div className={historyContainerStyle}>
                        <div className={historyHeaderStyle}>
                            <WIcon size='32px' icon="history" color="#444" />
                            <div style={{ minWidth: '4px' }}></div>
                            <div className={historytitleStyle}>Activity</div>
                            <div style={{ minWidth: '16px' }}></div>
                            <div>
                                <WSwitch label="Hide details" labelPlacement="start" model={new FieldModel(!this.state.detailsVisible)} onChange={this.handleHideDetailsChanged} />
                            </div>

                        </div>

                        {this.renderCommentComposer()}

                        <div style={{ height: '16px' }}></div>

                        {this.props.messages
                            .filter(item => this.state.detailsVisible ? true : item.type != 'wibib.meta_info_message')
                            .map((msg) => {
                                var msgRenderer = this.props.messageResolver(msg)
                                if (msgRenderer == null) {
                                    const sndr = UsersSvc.usersById[msg.senderId]
                                    if (msg.type == "wibib.comment") {
                                        msgRenderer = new CommentMessageRenderer(
                                            msg as IWibMessage<ICommentWibMessageContent>
                                        )
                                    } else if (msg.type == "wibib.meta_info_message") {
                                        msgRenderer = new MetaInfoMessageRenderer(
                                            "mi-" + msg.senderId + "-" + msg.sentAt,
                                            sndr.data().name,
                                            sndr.data().color,
                                            sndr.data().profilePictureUrl,
                                            msg.sentAt,
                                            (msg.content as IMetaInfoWibMessageContent).message
                                        )
                                    } else if (msg.type == "wibib.attachment") {
                                        msgRenderer = new AttachmentMessageRenderer(msg as IWibMessage<IFileAttachWibMessageContent>)
                                    }
                                }
                                if (msgRenderer == null) {
                                    return
                                }
                                return (
                                    <div key={"msg-" + msg.senderId + "-" + msg.sentAt} style={{ width: '100%', paddingTop: '8px', paddingBottom: '8px' }}>
                                        {msgRenderer.render()}
                                    </div>
                                )
                            })}
                    </div>

                </WScrollBox>
            </div>
        )
    }

    private readonly expandCommentComposer = () => {
        this.setState({ isCommentComposerExpanded: true })
    }

    private readonly collapseCommentComposer = () => {
        this.setState({ isCommentComposerExpanded: false })
    }

    private readonly handleSaveComment = (text: string) => {
        //var text = new BitbucketTransformer().encode(editorView.state.doc);
        this.setState({ isCommentComposerExpanded: false })
        this.props.onSendComment(text)
        firebase.analytics().logEvent('wib.comment_sent')
    }

    private readonly handleHideDetailsChanged = (newValue: boolean) => {
        this.setState({ detailsVisible: !newValue })

        window.localStorage.setItem('wib.hideDetails', "" + (newValue))

        firebase.analytics().logEvent('wib.hide_details.' + newValue)
    }

    private readonly mp = async () => {
        return new MyMentionProvider()
    }

    private readonly renderCommentComposer = () => {
        return (
            <EditorContext>
                <WithEditorActions
                    render={(actions) => {
                        return (
                            <CollapsedEditor
                                placeholder="What would you like to say?"
                                isExpanded={this.state.isCommentComposerExpanded}
                                onFocus={this.expandCommentComposer}
                            >
                                <Editor
                                    appearance="comment"
                                    onSave={async () => { this.handleSaveComment(await actions.getValue()) }}
                                    onCancel={this.collapseCommentComposer}
                                    shouldFocus={true}
                                    mentionProvider={this.mp()}
                                    contentTransformerProvider={schema => new WikiMarkupTransformer(schema)}
                                />

                            </CollapsedEditor>
                        )
                    }}
                />
            </EditorContext>
        )
    }

}

export interface CommonWibViewProps {

    wibId: string
    wib: IWib<{}>
    messages: IWibMessage<{}>[]
    header: string | (() => React.ReactNode)
    messageResolver: ((message: IWibMessage<{}>) => AMessageRenderer)
    onSendComment: (message: string) => void

}

interface CommonWibViewState {
    isCommentComposerExpanded: boolean
    detailsVisible: boolean
}

const headerStyle = style({
    width: '100%',
    padding: '32px',
    backgroundColor: '#fff',
    borderBottom: '1px solid #ccc'
})

const historyContainerStyle = style({
    width: '100%',
    padding: '32px',
})

const historyHeaderStyle = style({
    width: '100%',
    display: 'flex',
    paddingBottom: '4px',
    alignItems: 'center'
})
const historytitleStyle = style({
    flex: '1',
    fontSize: '16px',
    fontWeight: EFontWeight.Semibold.value,
    color: '#222'
})
const hideDetailsTitleStyle = style({
    fontSize: '14px',
    color: '#666'
})

export abstract class AMessageRenderer {

    abstract render(): React.ReactNode

}

export class CommentMessageRenderer extends AMessageRenderer {

    public constructor(
        public readonly message: IWibMessage<ICommentWibMessageContent>
    ) {
        super()
    }

    render(): React.ReactNode {
        const user = UsersSvc.usersById[this.message.senderId]
        const encoder = new ADFEncoder((schema) => new WikiMarkupTransformer(schema))
        const doc = encoder.encode(this.message.content.message)
        return (
            <div
                style={{ width: '100%', padding: '8px', backgroundColor: '#fff', borderLeft: `4px solid ${user.data().color}` }}
            >
                <div style={{ width: '100%', marginBottom: '4px', display: 'flex', alignItems: 'center' }}>
                    <ProfilePicture
                        name={user.data().name}
                        profilePictureUrl={user.data().profilePictureUrl}
                        size={24}
                        backgroundColor={user.data().color}
                        borderWidth={0}
                    />
                    <div style={{ minWidth: '4px' }}></div>
                    <div
                        style={{
                            flex: '1',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '16px',
                            fontWeight: EFontWeight.Semibold.value,
                            color: '#222426'
                        }}
                    >
                        {user.data().name}
                    </div>
                    <div style={{ minWidth: '4px' }}></div>
                    <div style={{ fontSize: '12px', fontWeight: EFontWeight.Medium.value, color: '#888' }}>{StringUtils.convertTimestampToStr(this.message.sentAt)}</div>
                </div>

                <div style={{ width: '100%', paddingLeft: '32px' }}>
                    <ReactRenderer document={doc} dataProviders={pf} />
                </div>
            </div>
        )
    }

}

export class MetaInfoMessageRenderer extends AMessageRenderer {

    public constructor(
        public readonly id: string,
        public readonly senderName: string,
        public readonly senderColor: string,
        public readonly senderPicture: string,
        public readonly sentAt: number,
        public readonly message: string,

    ) {
        super()
    }

    render(): React.ReactNode {
        return (
            <ConversationMetaInfo
                key={"metamsg-" + this.id}
                message={this.message}
                sentAt={this.sentAt}
                sender={{
                    name: this.senderName,
                    color: this.senderColor,
                    profilePictureUrl: this.senderPicture
                }}
            />
        )
    }

}

export class AttachmentMessageRenderer extends AMessageRenderer {

    public constructor(
        private readonly msg: IWibMessage<IFileAttachWibMessageContent>
    ) {
        super()
    }

    render(): React.ReactNode {
        const fn = this.msg.content.fileName
        const fnlc = fn.toLowerCase()
        const user = UsersSvc.usersById[this.msg.senderId]
        if (fnlc.endsWith(".png") || fnlc.endsWith(".jpg") || fnlc.endsWith(".jpeg") || fnlc.endsWith(".gif")) {
            return (
                <div
                    style={{ width: '100%', padding: '8px', backgroundColor: '#fff', borderLeft: `4px solid ${user.data().color}` }}
                >
                    <div style={{ width: '100%', marginBottom: '4px', display: 'flex', alignItems: 'center' }}>
                        <ProfilePicture
                            name={user.data().name}
                            profilePictureUrl={user.data().profilePictureUrl}
                            size={24}
                            backgroundColor={user.data().color}
                            borderWidth={0}
                        />
                        <div style={{ minWidth: '4px' }}></div>
                        <div
                            style={{
                                flex: '1',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                fontSize: '16px',
                                fontWeight: EFontWeight.Semibold.value,
                                color: '#222426'
                            }}
                        >
                            {user.data().name}
                        </div>
                        <div style={{ minWidth: '4px' }}></div>
                        <div style={{ fontSize: '12px', fontWeight: EFontWeight.Medium.value, color: '#888' }}>{StringUtils.convertTimestampToStr(this.msg.sentAt)}</div>
                    </div>

                    <div style={{ width: '100%', paddingLeft: '32px', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'inline-flex' }}>
                            <a href={this.msg.content.downloadUrl} target="_blank">
                                <img src={this.msg.content.downloadUrl} style={{ maxWidth: '100%', height: 'auto' }} />
                            </a>
                        </div>
                        <div style={{ display: 'inline-flex' }}>
                            <a href={this.msg.content.downloadUrl} target="_blank">
                                {this.msg.content.fileName}
                            </a>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={metaInfoStyle}>
                    <ProfilePicture
                        name={user.data().name}
                        backgroundColor={user.data().color}
                        profilePictureUrl={user.data().profilePictureUrl}
                        size={16}
                        borderWidth={0}
                    />
                    <div style={{ minWidth: '8px' }}></div>
                    <div style={{ fontSize: '14px', fontWeight: EFontWeight.Bold.value, color: '#222' }}>{user.data().name}</div>
                    <div style={{ minWidth: '8px' }}></div>
                    <div style={{ fontSize: '14px', fontWeight: EFontWeight.Regular.value, color: '#222', flex: '1' }}>
                        attached a file: <a href={this.msg.content.downloadUrl} target="_blank">{this.msg.content.fileName}</a>
                    </div>
                    <div style={{ minWidth: '4px' }}></div>
                    <div style={{ fontSize: '12px', fontWeight: EFontWeight.Medium.value, color: '#888' }}>{StringUtils.convertTimestampToStr(this.msg.sentAt)}</div>

                </div>
            )
        }
    }

}

const metaInfoStyle = style({
    width: '100%',
    fontSize: '14px',
    fontWeight: EFontWeight.Medium.value,
    color: '#222',
    display: 'flex',
    alignItems: 'flex-start'
})