import * as React from 'react';
import { FieldModel } from '../../../../../../components/FieldModel';
import { ModalButton, WModal } from '../../../../../../wds/Modal';
import { WTextField } from '../../../../../../wds/TextField';

export class CreateCardDialog extends React.Component<CreateCardDialogProps, CreateCardDialogState> {

    private readonly titleModel = new FieldModel("")

    private readonly validateTitle = (title: string) => {

    }

    private validate(): boolean {
        var title = this.titleModel.value
        if (title == null) {
            title = ""
        }
        title = title.trim()
        if (title.length < 1) {
            this.titleModel.error = "This field is mandatory!"
            return false
        }
        if (title.length > 64) {
            this.titleModel.error = "Maximum 64 characters!"
            return false
        }

        return true
    }

    private readonly handleSubmit = () => {
        if (!this.validate()) {
            this.setState({})
            return
        }

        this.props.onSubmit({ title: this.titleModel.value.trim(), editAfterSave: false })
    }

    private readonly handleSubmitAndEdit = () => {
        if (!this.validate()) {
            this.setState({})
            return
        }

        this.props.onSubmit({ title: this.titleModel.value.trim(), editAfterSave: true })
    }

    render() {
        return (
            <WModal
                title="Create card"
                onSubmit={this.handleSubmit}
                buttons={[
                    new ModalButton("Cancel", this.props.onCancel, "text"),
                    new ModalButton("Create and edit", this.handleSubmitAndEdit, "contained", "default"),
                    new ModalButton("Create", null, "contained", "primary"),
                ]}
            >

                <WTextField
                    model={this.titleModel}
                    label="Subject"
                    helperText="The subject of the new card."
                    autoFocus
                />
            </WModal>
        )
    }

}

export interface CreateCardDialogProps {

    onCancel: () => void
    onSubmit: (data: ICreateCardDialogData) => Promise<ICreateCardDialogData | undefined>

}

interface CreateCardDialogState {

}

export interface ICreateCardDialogData {
    title: string
    editAfterSave: boolean
}