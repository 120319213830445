import * as React from 'react'
import { WVBox, WVBoxCell } from './VBox'
import { EHAlign } from 'components/styles'
import { EColor } from './styles/WColor'

export class WPaper extends React.Component<WPaperProps, WPaperState> {

    render() {
        var lineColor = this.props.lineColor
        return (
            <div style={{ padding: "32px", width: "100%" }}>
                <div style={{ width: "100%", backgroundColor: "#fff", boxShadow: "1px 1px 5px rgba(0,0,0, 0.3)", borderTop: lineColor == null ? "none" : "4px solid " + lineColor }}>
                    {this.props.title != null &&
                        <div style={{ paddingLeft: "48px", paddingRight: "48px", fontSize: "22px", fontWeight: 700, color: EColor.PaperTitleColor.color, paddingTop: "48px", paddingBottom: "0px" }}>
                            {this.props.title}
                        </div>
                    }
                    <div style={{ padding: "48px" }}>
                        <WVBox spacing={32}>
                            {React.Children.map(this.props.children, (child, index) => {
                                return (
                                    <WVBoxCell key={"vbc-" + index} hAlign={EHAlign.Left} >
                                        {child}
                                    </WVBoxCell>
                                )
                            })}
                        </WVBox>
                    </div>
                </div>
            </div>
        )
    }

}

export interface WPaperProps {
    title?: string
    lineColor?: string
}

interface WPaperState {

}