import { AScene } from '@eagerlogic/react-mvc';
import * as React from 'react';
import { EUserRole } from 'shared/entities/aaa/EUserRole';
import { Navigation } from 'utils/Navigation';
import { HorizontalLoadingIndicator } from 'wds/HorizontalLoadingIndicator';
import { CommMainScene } from '../comm/commmain/CommMainScene';
import { ProfileSettingsScene } from '../profilesettings/ProfileSettingsScene';
import { BoardScene } from '../projects/scenes/board/BoardScene';
import { ProjectBoardListScene } from '../projects/scenes/boardList/ProjectBoardListScene';
import { TeamSettingsScene } from '../settings/team/TeamSettingsScene';
import { MainMenu, MainMenuBottomItems, MainMenuItem, MainMenuSubItem, MainMenuTopItems } from './components/MainMenu';
import { MainSceneController } from './MainSceneController';
import { MainSceneModel } from './MainSceneModel';

export class MainScene extends AScene<MainSceneProps, MainSceneModel, MainSceneController> {
    protected createControlller(): MainSceneController {
        return new MainSceneController()
    }

    render(): React.ReactNode {
        if (this.model.isLoading) {
            return <div style={{ width: "100%", height: "8px" }}>
                <HorizontalLoadingIndicator />
            </div>
        }
        return (
            <>
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        minWidth: "100%",
                        minHeight: "100%",
                        maxWidth: "100%",
                        maxHeight: "100%",
                        overflow: "hidden",
                        filter: this.model.isBlur ? 'blur(4px)' : null
                    }}
                >

                    <MainMenu>
                        <MainMenuTopItems>
                            <MainMenuItem title="Projects" icon="view_week" minRole={EUserRole.User} link="/app/projects/index.rsc" selectedPath="/app/projects/" >
                            </MainMenuItem>
                        </MainMenuTopItems>

                        <MainMenuBottomItems>
                            <MainMenuItem title="Profile" icon="face" minRole={EUserRole.User} link="/app/profileSettings.rsc" selectedPath="/app/profileSettings.rsc" />
                            <MainMenuItem title="Settings" minRole={EUserRole.Admin} icon="settings" link="/app/settings/team.rsc" selectedPath="/app/settings/">
                                <MainMenuSubItem title="Team" minRole={EUserRole.Admin} link="/app/settings/team.rsc" />
                            </MainMenuItem>
                            <MainMenuItem title="Logout" icon="exit_to_app" minRole={EUserRole.User} link="/logout.rsc" selectedPath="/logout.rsc" />
                        </MainMenuBottomItems>
                    </MainMenu>

                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            minWidth: "100%",
                            minHeight: "100%",
                            maxWidth: "100%",
                            maxHeight: "100%",
                            paddingLeft: "64px",
                            paddingRight: "280px",
                            overflow: "hidden"
                        }}
                    >
                        {this.renderContent()}
                    </div>

                </div>

                <CommMainScene />

            </>
        )
    }

    private renderContent(): React.ReactNode {
        const cs = Navigation.currentState
        if (cs.path == "/app/settings/team.rsc") {
            return <TeamSettingsScene />
        } else if (cs.path == "/app/profileSettings.rsc") {
            return <ProfileSettingsScene />

        } else if (cs.path == "/app/projects/index.rsc") {
            return <ProjectBoardListScene />
        } else if (cs.path == "/app/projects/board.rsc") {
            const boardId: string = cs.params['id']
            return <BoardScene key={"board-" + boardId} boardId={boardId} />
        } else {
            return <div>Page not found</div>
        }
    }


}

export interface MainSceneProps {

}