import { FormControlLabel, Switch } from '@material-ui/core'
import * as React from 'react'
import { FieldModel } from '../components/FieldModel'

export class WSwitch extends React.Component<WSwitchProps, WSwitchState> {

    render() {
        return (
            <FormControlLabel
                label={this.props.label}
                labelPlacement={this.props.labelPlacement}
                control={<Switch
                    checked={this.props.model.value}
                    disabled={this.props.disabled}
                    color={this.props.color == null ? "primary" : this.props.color}
                    size={this.props.size}
                    onChange={(e) => {
                        this.props.model.value = e.currentTarget.checked
                        if (this.props.onChange != null) {
                            this.props.onChange(this.props.model.value)
                        }
                    }}
                />}
            />
        )
    }

}

export interface WSwitchProps {

    label: string
    model: FieldModel<boolean>
    onChange?: (newValue: boolean) => void
    color?: "primary" | "secondary" | "default"
    size?: "small" | "medium"
    disabled?: boolean
    labelPlacement?: "start" | "end"
}

interface WSwitchState {

}