import { AScene } from '@eagerlogic/react-mvc';
import { UsersSvc } from 'backend/aaa/UsersSvc';
import * as React from 'react';
import { IPCSendFileContent } from 'shared/entities/privateChat/IPrivateChatMessageData';
import { IFileDescriptor } from 'shared/entities/wib/IWib';
import { WScrollBox } from 'wds/ScrollBox';
import { BaseCommLayout } from '../commmain/components/BaseCommLayout';
import { LastSeenUserModel, LastSeenView } from '../commmain/components/LastSeenView';
import { MessageComposer } from '../commmain/components/MessageComposer';
import { IncomingMessageView, OutgoingMessageView } from '../commmain/components/MessageView';
import { ACommonWibSidebarTabRenderer, CommonWibSidebarView } from '../wibs/components/CommonWibSidebarView';
import { WibsList, WibsListItemModel } from '../wibs/components/WibsList';
import { PrivateChatSceneController } from './PrivateChatSceneController';
import { PrivateChatMessageModel, PrivateChatSceneModel } from './PrivateChatSceneModel';

export class PrivateChatScene extends AScene<PrivateChatSceneProps, PrivateChatSceneModel, PrivateChatSceneController> {

    private scrollableNodeRef = React.createRef()

    protected createControlller(): PrivateChatSceneController {
        return new PrivateChatSceneController(this.props.userId, this.props.privateChatId, this.props.remoteUserName, this.props.remoteProfilePictureUrl)
    }

    componentWillUnmount() {
        this.controller.destroy()
    }

    render(): React.ReactNode {
        return (
            <BaseCommLayout
                title={this.props.remoteUserName}
                icon="chat_bubble"
                iconColor="#0bd"
                isLoading={this.model.isLoading}
                buttons={[]}
                moreMenuItems={[]}
                onCloseClick={this.props.onCloseClick}
                rightSideBarRenderer={this.renderRightSidebar}
                onFileDropped={this.controller.handleDropFile}
            >
                {!this.model.isLoading &&

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: "1",
                            height: "100%",
                            backgroundColor: "#f4f4f4"
                        }}
                    >
                        <div
                            style={{
                                width: "100%",
                                maxWidth: "100%",
                                height: '100%',
                                borderBottom: "1px solid #ccc",
                                overflow: "hidden"
                            }}
                        >
                            <WScrollBox stayOnBottom>
                                <div
                                    style={{ width: "100%", maxWidth: "100%", overflow: "hidden", padding: "12px" }}
                                >
                                    {this.renderMessages()}
                                </div>
                            </WScrollBox>
                        </div>

                        <MessageComposer onSend={this.controller.sendMessage} />

                    </div>
                }
            </BaseCommLayout>



        )
    }

    private readonly renderRightSidebar = (): React.ReactNode => {
        return (
            <CommonWibSidebarView
                tabs={[
                    new FilesTabRenderer({ files: this.model.files })
                ]}

            />
        )
    }

    private renderMessages(): React.ReactNode {
        var messageBuffer: PrivateChatMessageModel
        var prevMsg: PrivateChatMessageModel
        var result: React.ReactNode[] = []

        const remoteUser = UsersSvc.usersById[this.props.userId]
        var remoteLastSeenModel = new LastSeenUserModel(this.props.userId, remoteUser.data().name, remoteUser.data().color, remoteUser.data().profilePictureUrl)

        this.model.messages.forEach((item) => {
            if ((prevMsg == null || prevMsg.sentAt <= this.model.remoteLastSeenAt) && item.sentAt > this.model.remoteLastSeenAt) {
                if (messageBuffer != null) {
                    result.push(this.renderMessage(messageBuffer))
                    messageBuffer = null
                }
                result.push(
                    <LastSeenView key={"pclsv"}
                        users={[remoteLastSeenModel]}
                    />
                )
            }
            prevMsg = item

            if (messageBuffer != null && messageBuffer.isIncoming == item.isIncoming && messageBuffer.sentAt > item.sentAt - (1000 * 60 * 5) && item.type != "attachment") {
                messageBuffer = new PrivateChatMessageModel(item.id, item.isIncoming, item.senderName, item.senderProfilePictureUrl, item.senderColor, item.type, item.content, messageBuffer.message + "\n\n" + item.message, item.dateStr, item.timeStr, item.sentAt)
            } else if (messageBuffer != null) {
                result.push(this.renderMessage(messageBuffer))
                if (item.type != 'attachment') {
                    messageBuffer = item
                } else {
                    messageBuffer = null
                    result.push(this.renderMessage(item))
                }
            } else {
                if (item.type != 'attachment') {
                    messageBuffer = item
                } else {
                    messageBuffer = null
                    result.push(this.renderMessage(item))
                }
            }
        })

        if (messageBuffer != null) {
            result.push(this.renderMessage(messageBuffer))
        }

        if ((prevMsg == null || prevMsg.sentAt <= this.model.remoteLastSeenAt)) {
            result.push(
                <LastSeenView
                    key={"pclsv"}
                    users={[remoteLastSeenModel]}
                />
            )
        }

        return result
    }

    private renderMessage(msg: PrivateChatMessageModel): React.ReactNode {
        var content: string | (() => React.ReactNode) = msg.message
        if (msg.type == "attachment") {
            const c = msg.content as IPCSendFileContent
            content = () => {
                const fnlc = c.fileName.toLowerCase()
                if (fnlc.endsWith(".png") || fnlc.endsWith(".jpg") || fnlc.endsWith(".jpeg") || fnlc.endsWith(".gif")) {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'inline-flex' }}>
                                <a href={c.downloadUrl} target="_blank">
                                    <img src={c.downloadUrl} style={{ maxWidth: '400px', height: 'auto' }} />
                                </a>
                            </div>
                            <div style={{ display: 'inline-flex' }}>
                                <a href={c.downloadUrl} target="_blank">
                                    {c.fileName}
                                </a>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'inline-flex' }}>
                                Sent a file:
                            </div>
                            <div style={{ display: 'inline-flex' }}>
                                <a href={c.downloadUrl} target="_blank">
                                    {c.fileName}
                                </a>
                            </div>
                        </div>
                    )
                }
            }
        }
        if (msg)
            if (msg.isIncoming) {
                return (
                    <IncomingMessageView
                        key={"iwibm-" + msg.sentAt}
                        senderName={msg.senderName}
                        senderProfilePictureUrl={msg.senderProfilePictureUrl}
                        userColor={msg.senderColor}
                        content={content}
                        sentAt={msg.sentAt}
                    />
                )
            } else {
                return (
                    <OutgoingMessageView
                        key={"owibm-" + msg.sentAt}
                        senderName={msg.senderName}
                        senderProfilePictureUrl={msg.senderProfilePictureUrl}
                        userColor={msg.senderColor}
                        content={content}
                        sentAt={msg.sentAt}
                    />
                )
            }
    }


}

export interface PrivateChatSceneProps {
    userId: string,
    privateChatId: string,
    remoteUserName: string,
    remoteProfilePictureUrl: string
    onCloseClick: () => void
    onRelatedWibClicked: (wibId: string) => void
}

class RelatedWibsTabRenderer extends ACommonWibSidebarTabRenderer {

    public constructor(
        public readonly wibs: WibsListItemModel[],
        public readonly onWibClicked: (wibId: string) => void
    ) {
        super(
            "all_inbox"
        )
    }

    renderContent(): React.ReactNode {
        return (
            <WibsList
                items={this.wibs}
                selectedItemId={null}
                emptyText="<<< Empty state text goes here >>>"
                onWibClicked={this.onWibClicked}
            />
        )
    }

}

export class FilesTabRenderer extends ACommonWibSidebarTabRenderer {

    public constructor(private readonly filesContainer: { files: IFileDescriptor[] }) {
        super("attachment")
    }

    renderContent(): React.ReactNode {
        var files: IFileDescriptor[] = []
        if (this.filesContainer != null && this.filesContainer.files != null) {
            files = this.filesContainer.files
        }
        if (files.length < 1) {
            return (
                <div
                    style={{
                        width: '100%',
                        padding: '16px',
                        textAlign: 'center',
                        fontSize: '14px',
                        color: '#888'
                    }}
                >
                    Drop files here to attach
                </div>
            )
        } else {
            return (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden'
                    }}
                >
                    <WScrollBox>
                        <div style={{ width: '100%', padding: '16px', overflowX: 'hidden' }}>
                            {files.map((f) => {
                                return (
                                    <div
                                        key={"file-" + f.uploadedAt}
                                        style={{
                                            width: '100%',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            paddingTop: '4px',
                                            paddingBottom: '4px',
                                            fontSize: '14px'
                                        }}
                                    >
                                        <a href={f.downloadUrl} target="_blank">{f.fileName}</a>
                                    </div>
                                )
                            })}
                        </div>
                    </WScrollBox>
                </div>
            )
        }
    }

}