export class EFontWeight {

    public static readonly Light = new EFontWeight(300)
    public static readonly Regular = new EFontWeight(400)
    public static readonly Medium = new EFontWeight(500)
    public static readonly Semibold = new EFontWeight(600)
    public static readonly Bold = new EFontWeight(700)

    private constructor(public readonly value: number) { }

}