export class EUserRole {

    private static readonly rolesByLevel: { [level: number]: EUserRole } = {}
    private static readonly rolesByName: { [name: string]: EUserRole } = {}
    private static readonly roles: EUserRole[] = []

    public static readonly User = new EUserRole("User", 100)
    public static readonly Admin = new EUserRole("Admin", 500)
    public static readonly Owner = new EUserRole("Owner", 1000)

    public static listRoles(): EUserRole[] {
        return this.roles.map((item) => {
            return item
        })
    }

    public static findByName(name: string): EUserRole {
        return this.rolesByName[name]
    }

    public static findByLevel(level: number): EUserRole {
        return this.rolesByLevel[level]
    }

    private constructor(
        public readonly name: string,
        public readonly level: number
    ) {
        EUserRole.rolesByName[name] = this
        EUserRole.rolesByLevel[level] = this
        EUserRole.roles.push(this)
    }

}