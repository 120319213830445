import { CurrentOrgSvc } from "backend/aaa/CurrentOrgSvc"
import { CurrentUserSvc } from "backend/aaa/CurrentUserSvc"
import { UsersSvc } from "backend/aaa/UsersSvc"
import { WibSvc } from "backend/wib/WibService"
import { IWib } from "shared/entities/wib/IWib"
import { Wib } from "shared/entities/wib/Wib"
import { ICardWibContent } from "shared/entities/wib/wibs/ICardWibContentType"
import { transact } from "utils/Fs"

export const removeCardMemberfun = async (cardId: string, memberId: string) => {
    const member = UsersSvc.usersById[memberId].data()
    if (member == null) {
        throw new Error("Can't find user with id: " + memberId)
    }
    await transact(async (t) => {
        const wibRef = CurrentOrgSvc.currentOrg.snapshot.ref
            .collection("Wibs")
            .doc(cardId)

        const wibE = await t.get(wibRef)
        if (!wibE.exists) {
            throw new Error("Can't find card with id: " + cardId)
        }

        const wibD = wibE.data() as IWib<ICardWibContent>
        const wib = new Wib(wibD)

        wib.removeMember(memberId)
        wib.changeContent((c) => {
            const aaidx = c.autoAssignees.indexOf(memberId)
            if (aaidx > -1) {
                c.autoAssignees.splice(aaidx, 1)
            }
            const amidx = c.autoMembers.indexOf(memberId)
            if (amidx > -1) {
                c.autoMembers.splice(amidx, 1)
            }
            return c
        })

        t.update(wibRef, wib.updatedData)
    }, null)

    if (memberId == CurrentUserSvc.currentUser.id) {
        await WibSvc.sendMetaInfo(cardId, `left this WIB.`)
    } else {
        await WibSvc.sendMetaInfo(cardId, `removed ${member.name} from this WIB.`)
    }
}