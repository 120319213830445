import { CurrentOrgSvc } from "backend/aaa/CurrentOrgSvc"
import { CurrentUserSvc } from "backend/aaa/CurrentUserSvc"
import { handleAutoMembers } from "backend/projects/board/functions/handleAutoMembersFun"
import { WibSvc } from "backend/wib/WibService"
import { IBoardData } from "shared/entities/projects/IBoardData"
import { IWib, VER_Wib } from "shared/entities/wib/IWib"
import { Wib } from "shared/entities/wib/Wib"
import { ICardWibContent, VER_CardWibContent } from "shared/entities/wib/wibs/ICardWibContentType"
import { transact } from "utils/Fs"

export const createNewCardFun = async (boardId: string, subject: string, colId: string): Promise<string> => {
    return transact(async (t) => {
        const boardRef = CurrentOrgSvc.currentOrg.snapshot.ref.collection("Boards").doc(boardId)
        const boardE = await t.get(boardRef)
        if (!boardE.exists) {
            throw new Error("Board does not exists!")
        }

        const baordData = boardE.data() as IBoardData
        const column = baordData.columns.find((col) => {
            return col.id == colId
        })
        if (column == null) {
            throw new Error("Column does not exists!")
        }

        const now = Date.now()

        const wibD: IWib<ICardWibContent> = {
            parentType: "wibib.projects.board",
            parentId: boardId,
            createdAt: now,
            createdBy: CurrentUserSvc.currentUser.id,
            deleted: false,
            subject: subject,
            type: "wibib.projects.board.card",
            version: VER_Wib,
            content: {
                version: VER_CardWibContent,
                autoMembers: [],
                autoAssignees: [],
                description: ""
            },
            allowedMembers: ["@all"],
            lastUpdatedAt: now,
            memberLastSeens: {},
            memberNotifications: {},
            members: [],
            assignees: [],
            files: []
        }

        const wib = new Wib<ICardWibContent>(wibD)
        handleAutoMembers(wib, column, CurrentUserSvc.currentUser.id)

        const cardId = await WibSvc.createWib(wib.data, t)

        column.cards.splice(0, 0, cardId)


        t.update(boardRef, baordData)

        return cardId
    }, null)

}