import { AScene } from '@eagerlogic/react-mvc';
import { CurrentOrgSvc } from 'backend/aaa/CurrentOrgSvc';
import { ProfilePicture } from 'components/ProfilePicture';
import * as React from 'react';
import { keyframes, style } from 'typestyle';
import { EFontWeight } from 'wds/styles/EFontWeight';
import { WColorPalette } from 'wds/styles/WColor';
import { CurrentUserSvc } from '../../../../backend/aaa/CurrentUserSvc';
import { WTab, WTabbedPane } from '../../../../wds/TabbedPane';
import { PrivateChatList } from '../privateChat/components/PrivateChatList';
import { PrivateChatScene } from '../privateChat/PrivateChatScene';
import { NewWibDialogs } from '../wibs/components/NewWibDialog';
import { WibsList } from '../wibs/components/WibsList';
import { WibScene } from '../wibs/WibScene';
import { CommMainSceneController } from './CommMainSceneController';
import { CommMainSceneModel } from './CommMainSceneModel';

export class CommMainScene extends AScene<{}, CommMainSceneModel, CommMainSceneController> {

    protected createControlller(): CommMainSceneController {
        return new CommMainSceneController()
    }

    componentWillUnmount() {

    }

    render(): React.ReactNode {
        // var containerCalculatedStyle = containerStyle
        var overlayCalculatedStyle = overlayStyle
        if (this.model.isEverOpened) {
            if (this.model.isOpen) {
                // containerCalculatedStyle += " " + containerOpenedStyle
                overlayCalculatedStyle = overlayOpenedStyle + " " + overlayCalculatedStyle
            } else {
                // containerCalculatedStyle += " " + containerClosedStyle
                overlayCalculatedStyle = overlayClosedStyle + " " + overlayCalculatedStyle
            }
        }

        if (this.model.isLoading) {
            return (<div
                className={containerStyle}
            >

            </div>)
        }


        const cu = CurrentUserSvc.currentUser
        const co = CurrentOrgSvc.currentOrg
        return (
            <>
                <div className={overlayCalculatedStyle} style={{ pointerEvents: this.model.isOpen ? "all" : "none" }} onClick={this.controller.onCloseCommSidebar}>
                    <div
                        className={containerStyle}
                        onClick={(e) => { e.stopPropagation() }}
                    >
                        <div style={{ width: "280px", minWidth: "280px", height: "100%", borderRight: '1px solid #ccc', display: 'flex', flexDirection: 'column', /*boxShadow: '1px 1px 5px rgba(0,0,0, 0.3)',*/ zIndex: 10 }}>

                            {/* self profile */}
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    height: '64px',
                                    alignItems: 'center',
                                    paddingLeft: '16px',
                                    paddingRight: '16px',
                                    borderBottom: '1px solid #ccc',
                                    backgroundColor: '#fff'
                                }}
                            >
                                <ProfilePicture name={cu.data.name} profilePictureUrl={cu.data.profilePictureUrl} size={40} backgroundColor={WColorPalette.Green.color} borderColor={WColorPalette.Green.color} borderWidth={3} />
                                <div style={{ width: '8px' }} />
                                <div
                                    style={{
                                        display: 'flex',
                                        flex: '1',
                                        flexDirection: 'column'
                                    }}
                                >

                                    <div
                                        style={{
                                            width: '100%',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            fontSize: '16px',
                                            fontWeight: EFontWeight.Medium.value,
                                            color: '#222'
                                        }}
                                    >
                                        {cu.data.name}
                                    </div>

                                    <div
                                        style={{
                                            width: '100%',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            fontSize: '14px',
                                            fontWeight: EFontWeight.Regular.value,
                                            color: '#888'
                                        }}
                                    >
                                        {co.data.name}
                                    </div>

                                </div>
                            </div>

                            {/* tabs */}
                            <div style={{ flex: '1', width: '100%', overflow: 'hidden' }}>
                                <WTabbedPane
                                    headBackground="#fff"
                                    tabs={[
                                        new WTab("TODOS", "list_alt", this.model.tasksTabNotificationCount, () => {
                                            return <WibsList
                                                items={this.model.tasks}
                                                selectedItemId={this.model.selectedWibModel == null ? null : this.model.selectedWibModel.wibId}
                                                emptyText="Here you will find your todos. You can add any WIB to this list from the WIBS tab."
                                                onNewWibClicked={() => { this.controller.onNewWibClicked(null, null) }}
                                                onWibClicked={this.controller.onWibClicked}
                                            />
                                        }),
                                        new WTab("WIBS", "forum", this.model.wibsTabNotificationCount, () => {
                                            return <WibsList
                                                items={this.model.wibs}
                                                selectedItemId={this.model.selectedWibModel == null ? null : this.model.selectedWibModel.wibId}
                                                emptyText="This is the place where all your conversations (WIBS) live. "
                                                onNewWibClicked={() => { this.controller.onNewWibClicked(null, null) }}
                                                onWibClicked={this.controller.onWibClicked}
                                            />
                                        }),
                                        new WTab("CHATS", "sms", this.model.chatsTabNotificationCount, () => {
                                            return <PrivateChatList model={this.model.privateChats} selectedId={this.model.selectedContactModel == null ? null : this.model.selectedContactModel.contactId} onClick={(userId: string) => {
                                                this.controller.onPrivateChatClicked(userId)
                                            }} />
                                        })
                                    ]}
                                >

                                </WTabbedPane>
                            </div>
                        </div>
                    </div>

                    {/* Sidebars goes here */}
                    {this.model.prevSelectedContactModel != null &&
                        <div key={"pcsb-" + this.model.prevSelectedContactModel.contactId} className={sidebarStyle + " " + sidebarClosedStyle}>
                            <div
                                style={{
                                    width: "1000px",
                                    minWidth: "1000px",
                                    height: "100%",
                                }}
                                onClick={(e) => { e.stopPropagation() }}
                            >
                                <PrivateChatScene
                                    key={this.model.prevSelectedContactModel.contactId + ":" + this.model.prevSelectedContactModel.pcId}
                                    userId={this.model.prevSelectedContactModel.contactId}
                                    privateChatId={this.model.prevSelectedContactModel.pcId}
                                    remoteUserName={this.model.prevSelectedContactModel.remoteUserName}
                                    remoteProfilePictureUrl={this.model.prevSelectedContactModel.remoteProfilePictureUrl}
                                    onCloseClick={this.controller.onCloseCommSidebar}
                                    onRelatedWibClicked={this.controller.onWibClicked}
                                />
                            </div>
                        </div>
                    }

                    {this.model.selectedContactModel != null &&
                        <div key={"pcsb-" + this.model.selectedContactModel.contactId} className={sidebarStyle + " " + sidebarOpenedStyle}>
                            <div
                                style={{
                                    width: "1000px",
                                    minWidth: "1000px",
                                    height: "100%",
                                }}
                                onClick={(e) => { e.stopPropagation() }}
                            >
                                <PrivateChatScene
                                    key={this.model.selectedContactModel.contactId + ":" + this.model.selectedContactModel.pcId}
                                    userId={this.model.selectedContactModel.contactId}
                                    privateChatId={this.model.selectedContactModel.pcId}
                                    remoteUserName={this.model.selectedContactModel.remoteUserName}
                                    remoteProfilePictureUrl={this.model.selectedContactModel.remoteProfilePictureUrl}
                                    onCloseClick={this.controller.onCloseCommSidebar}
                                    onRelatedWibClicked={this.controller.onWibClicked}
                                />
                            </div>
                        </div>
                    }

                    {this.model.prevSelectedWibModel != null &&
                        <div key={"wibsb-" + this.model.prevSelectedWibModel.wibId} className={sidebarStyle + " " + sidebarClosedStyle}>
                            <div
                                style={{
                                    width: "1000px",
                                    minWidth: "1000px",
                                    height: "100%",
                                }}
                                onClick={(e) => { e.stopPropagation() }}
                            >
                                <WibScene
                                    key={"wib-" + this.model.prevSelectedWibModel.wibId}
                                    wibId={this.model.prevSelectedWibModel.wibId}
                                    wibSubject={this.model.prevSelectedWibModel.wibSubject}
                                    wibIcon={this.model.prevSelectedWibModel.wibIcon}
                                    wibIconColor={this.model.prevSelectedWibModel.wibIconColor}
                                    onCloseClick={this.controller.onCloseCommSidebar}
                                />
                            </div>
                        </div>
                    }

                    {this.model.selectedWibModel != null &&
                        <div key={"wibsb-" + this.model.selectedWibModel.wibId} className={sidebarStyle + " " + sidebarOpenedStyle}>
                            <div
                                style={{
                                    width: "1000px",
                                    minWidth: "1000px",
                                    height: "100%",
                                }}
                                onClick={(e) => { e.stopPropagation() }}
                            >
                                <WibScene
                                    key={"wib-" + this.model.selectedWibModel.wibId}
                                    wibId={this.model.selectedWibModel.wibId}
                                    wibSubject={this.model.selectedWibModel.wibSubject}
                                    wibIcon={this.model.selectedWibModel.wibIcon}
                                    wibIconColor={this.model.selectedWibModel.wibIconColor}
                                    onCloseClick={this.controller.onCloseCommSidebar}
                                />
                            </div>
                        </div>
                    }

                </div>

                {
                    this.model.newWibDialogModel != null &&
                    <NewWibDialogs
                        parentType={this.model.newWibDialogModel.parentType}
                        parentId={this.model.newWibDialogModel.parentId}
                        onClose={this.controller.onNewWibDialogClose}
                        onWibCreated={this.controller.onNewWibCreated}
                    />
                }
            </>
        )
    }


}

const overlayOpenAnim = keyframes({
    'from': {
        backgroundColor: "rgba(0,0,0,0.0)"
    },
    'to': {
        backgroundColor: "rgba(0,0,0,0.7)"
    }
})

const overlayCloseAnim = keyframes({
    'from': {
        backgroundColor: "rgba(0,0,0,0.7)"
    },
    'to': {
        backgroundColor: "rgba(0,0,0,0.0)"
    },
})

const overlayOpenedStyle = style({
    animationDuration: "100ms",
    animationFillMode: "forwards",
    animationName: overlayOpenAnim
})

const overlayClosedStyle = style({
    animationDuration: "100ms",
    animationFillMode: "forwards",
    animationName: overlayCloseAnim
})

const overlayStyle = style({
    position: "fixed",
    left: "0px",
    top: "0px",
    right: "0px",
    bottom: "0px",
    zIndex: 2000,
    pointerEvents: "none",
    backgroundColor: "rgba(0,0,0,0.0)"
})

const containerStyle = style({
    display: "flex",
    width: "280px",
    right: "0px",
    top: "0px",
    bottom: "0px",
    overflow: "hidden",
    position: "fixed",
    zIndex: 5000,
    backgroundColor: "#fff",
    boxShadow: "0px 0px 20px rgba(0,0,0, 0.5)",
    pointerEvents: "all"
})

const sidebarOpenAnimation = keyframes({
    'from': { width: '0px' },
    'to': { width: '1000px' }
})

const sidebarCloseAnimation = keyframes({
    'from': { width: '1000px' },
    'to': { width: '0px' }
})

const sidebarOpenedStyle = style({
    zIndex: 4500,
    animationDuration: '100ms',
    animationFillMode: 'forwards',
    animationName: sidebarOpenAnimation
})

const sidebarClosedStyle = style({
    zIndex: 4000,
    animationDuration: '100ms',
    animationFillMode: 'forwards',
    animationName: sidebarCloseAnimation
})

const sidebarStyle = style({
    position: 'fixed',
    top: '0px',
    bottom: '0px',
    right: '280px',
    backgroundColor: '#eee',
    boxShadow: '0px 0px 10px rgba(0,0,0, 0.5)',
    overflow: 'hidden'
})