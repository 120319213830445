import * as React from 'react'
import { style } from 'typestyle'
import { WIcon } from './Icon'
import { EFontWeight } from './styles/EFontWeight'
import { EColor } from './styles/WColor'


export class WTabbedPane extends React.Component<WTabbedPaneProps, WTabbedPaneState> {

    public constructor(props: WTabbedPaneProps) {
        super(props)

        var selectedIndex = 0
        if (props.selectedTabIndex != null) {
            selectedIndex = props.selectedTabIndex
        }
        if (selectedIndex < 0) {
            selectedIndex = 0
        }
        if (selectedIndex >= this.props.tabs.length) {
            selectedIndex = this.props.tabs.length - 1
        }
        this.state = { selectedTabIndex: selectedIndex }
    }

    private handleTabClick = (index: number) => {
        this.setState({ selectedTabIndex: index })
        if (this.props.onSelectedTabChanged != null) {
            this.props.onSelectedTabChanged(index)
        }
    }

    render() {
        var currentTab: WTab = null
        if (this.state.selectedTabIndex > -1) {
            currentTab = this.props.tabs[this.state.selectedTabIndex]
        }
        return (
            <div
                style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", overflow: 'hidden' }}
            >
                {this.renderHeader()}
                {this.renderBody(currentTab, this.state.selectedTabIndex)}
            </div>
        )
    }

    private renderHeader(): React.ReactNode {
        return (
            <div
                style={{
                    width: "100%", display: "flex",
                    borderBottom: '1px solid #ddd', backgroundColor: this.props.headBackground,
                }}
            >
                {this.props.tabs.map((item, index) => {
                    return this.renderTitle(index)
                })}
            </div>
        )
    }

    private renderTitle(index: number): React.ReactNode {
        var nc = this.props.tabs[index].notificationCount
        if (nc > 99) {
            nc = 99
        }
        const tab = this.props.tabs[index]
        return (

            <div
                key={"tabTitle-" + index}
                style={{
                    position: "relative",
                    display: "flex",
                    width: "100%",
                    alignItems: "stretch"
                }}
            >
                <div
                    className={this.state.selectedTabIndex == index ? selectedTabTitleContainerClass : normalTabTitleContainerClass}
                    onClick={() => { this.handleTabClick(index) }}
                >
                    {tab.icon &&
                        <WIcon icon={tab.icon} color={this.state.selectedTabIndex == index ? EColor.Primary.color : "#888"} size="24px" />
                    }
                    {tab.title &&
                        <div
                            className={this.state.selectedTabIndex == index ? selectedTabTextClass : normalTabTextClass}
                            style={{ paddingTop: tab.icon != null ? '4px' : '0px' }}
                        >
                            {tab.title}
                        </div>
                    }
                </div>

                {nc > 0 &&
                    <div
                        style={{
                            position: "absolute",
                            top: "16px",
                            right: "12px",
                            display: "inline-flex",
                            minWidth: "16px",
                            minHeight: "16px",
                            borderRadius: "8px",
                            paddingLeft: "4px",
                            paddingRight: "4px",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#ff6d00"
                        }}
                    >
                        <div
                            style={{
                                display: "inline-block",
                                fontSize: "9px",
                                fontWeight: EFontWeight.Medium.value,
                                color: "#fff",
                            }}
                        >

                            {nc}
                        </div>
                    </div>
                }

            </div>
        )
    }

    private renderBody(tab: WTab, index: number): React.ReactNode {
        if (tab == null) {
            return (<></>)
        }
        return (
            <div key={"tabbody-" + index} style={{ width: "100%", flex: "1", overflow: 'hidden' }}>
                {tab != null && tab.renderer()}
            </div>
        )
    }

}

export interface WTabbedPaneProps {

    tabs: WTab[]
    selectedTabIndex?: number
    headBackground?: string
    onSelectedTabChanged?: (index: number) => void

}

interface WTabbedPaneState {
    selectedTabIndex: number
}

export class WTab {

    public constructor(
        public readonly title: string,
        public readonly icon: string,
        public readonly notificationCount: number,
        public readonly renderer: () => React.ReactNode
    ) { }

}

const normalTabTitleContainerClass = style({
    cursor: "pointer",
    borderTop: "4px solid transparent",
    borderBottom: "4px solid transparent",
    display: 'flex',
    flexDirection: "column",
    flex: "1",
    flexBasis: "0px",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    padding: "12px",
    $nest: {
        '&:hover': {
            opacity: 0.6
        }
    }
})

const selectedTabTitleContainerClass = style({
    cursor: "pointer",
    borderTop: "4px solid transparent",
    borderBottom: "4px solid " + EColor.Primary.color,
    display: 'flex',
    flexDirection: "column",
    flex: "1",
    flexBasis: "0px",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    padding: "12px",
})

const normalTabTextClass = style({
    fontSize: "14px",
    fontWeight: EFontWeight.Bold.value,
    color: "#888",

})

const selectedTabTextClass = style({
    fontSize: "14px",
    fontWeight: EFontWeight.Bold.value,
    color: EColor.Primary.color,

})

