import { AController } from "@eagerlogic/react-mvc";
import { UsersSvc } from "backend/aaa/UsersSvc";
import { PcSvc } from "backend/privateChat/PcService";
import { WibSvc } from "backend/wib/WibService";
import "firebase/analytics";
import "firebase/functions";
import { updateNotificationsCount } from "index";
import { IPrivateChatData } from "shared/entities/privateChat/IPrivateChatData";
import { IWib } from "shared/entities/wib/IWib";
import { Wib } from "shared/entities/wib/Wib";
import { StringUtils } from "shared/utils/StringUtils";
import { FsEntity } from "utils/Fs";
import { WColorPalette } from "wds/styles/WColor";
import { WibActionButton } from "wibib-sdk-fe/WibHandlerBase";
import { CurrentUserSvc } from "../../../../backend/aaa/CurrentUserSvc";
import { IWibSidebarItem, WibSidebar } from "../../../../wibib-sdk-fe/WibSidebar";
import { PrivateChatListItemModel, PrivateChatListModel } from "../privateChat/components/PrivateChatList";
import { WibsListItemModel } from "../wibs/components/WibsList";
import { WibHandlerRegistry } from "../wibs/WibHandlerRegistry";
import { CommMainSceneModel, NewWibDialogModel, SelectedContactModel, SelectedWibModel } from "./CommMainSceneModel";

export class CommMainSceneController extends AController<CommMainSceneModel> {

    private pcsSnapshot: firebase.firestore.QuerySnapshot<IPrivateChatData> = null
    private wibsSnapshot: firebase.firestore.QuerySnapshot<IWib<{}>> = null
    private tasksSnapshot: firebase.firestore.QuerySnapshot<IWib<{}>> = null
    private titleNotificationTimerHandler: number = null
    private titleNotificationVisible: boolean = false

    protected createInitialModel(): CommMainSceneModel {
        return new CommMainSceneModel()
    }

    async init() {
        WibSidebar.onChange.addListener(this.onSidebarItemChanged)
        this.model.selfName = CurrentUserSvc.currentUser.data.name
        this.model.profilePictureUrl = CurrentUserSvc.currentUser.data.profilePictureUrl

        UsersSvc.onChange.addListener(() => {
            this.refresh()
        })

        PcSvc.onPcListChanged.addChangeListener((snapshot) => {
            this.pcsSnapshot = snapshot
            this.refresh()
        })

        WibSvc.onWibListChanged.addChangeListener((snapshot) => {
            this.wibsSnapshot = snapshot
            this.refresh()
        })

        WibSvc.onTaskListChanged.addChangeListener((snapshot) => {
            this.tasksSnapshot = snapshot
            this.refresh()
        })
    }

    private readonly onSidebarItemChanged = (item: IWibSidebarItem) => {
        if (item == null) {
            this.onSidebarClosed()
            return
        }

        if (item.type == "wib") {
            this.onWibSidebarOpened(item.id)
        } else if (item.type == "private_chat") {
            this.onPrivateChatSidebarOpened(item.id)
        } else {
            this.onSidebarClosed()
        }
    }

    private async onWibSidebarOpened(wibId: string) {
        this.model.isOpen = true
        this.model.isEverOpened = true
        var wibE = this.findWibById(wibId)
        if (wibE == null) {
            wibE = await WibSvc.findWibById(wibId)
        }
        const wib = new Wib(wibE.data)
        const handler = WibHandlerRegistry.getHandlerByType(wibE.data.type)
        this.model.prevSelectedContactModel = this.model.selectedContactModel
        this.model.selectedContactModel = null
        this.model.prevSelectedWibModel = this.model.selectedWibModel
        this.model.selectedWibModel = new SelectedWibModel(
            wibId,
            wibE.data.subject,
            handler.icon,
            handler.color
        )

        this.render(this.model)

        window.setTimeout(() => {
            this.model.prevSelectedContactModel = null
            this.model.prevSelectedWibModel = null
            this.render(this.model)
        }, 500)

        if (wib.isMember(CurrentUserSvc.currentUser.id)) {
            if (wib.getMemberNotification(CurrentUserSvc.currentUser.id).unreadNotificationsCount > 0) {
                this.notifyWibSeen(wibId)
            }
        }
    }

    private onPrivateChatSidebarOpened(contactId: string) {
        this.model.isOpen = true
        this.model.isEverOpened = true
        this.model.prevSelectedWibModel = this.model.selectedWibModel
        this.model.selectedWibModel = null
        this.model.prevSelectedContactModel = this.model.selectedContactModel

        var pc = this.findPrivateChatByUserId(contactId)
        var c = this.findContactByUserId(contactId)

        var m = new SelectedContactModel(
            contactId,
            pc == null ? null : pc.id,
            c.data().name,
            c.data().profilePictureUrl
        )
        this.model.selectedContactModel = m
        this.render(this.model)

        window.setTimeout(() => {
            this.model.prevSelectedContactModel = null
            this.model.prevSelectedWibModel = null
            this.render(this.model)
        }, 500)

        if (pc != null) {
            this.notifyPrivateChatSeen(pc.id)
        }
    }

    private onSidebarClosed() {
        this.model.isOpen = false
        this.model.prevSelectedContactModel = this.model.selectedContactModel
        this.model.selectedContactModel = null
        this.model.prevSelectedWibModel = this.model.selectedWibModel
        this.model.selectedWibModel = null
        this.render(this.model)
        window.setTimeout(() => {
            this.model.prevSelectedContactModel = null
            this.model.prevSelectedWibModel = null
            this.render(this.model)
        }, 500)
    }

    public readonly onWibClicked = (wibId: string) => {
        WibSidebar.showWib(wibId)
    }

    public readonly onNewWibClicked = (parentType: 'wib' | null, parentId: string | null) => {
        this.model.newWibDialogModel = new NewWibDialogModel(parentType, parentId)
        this.render(this.model)
    }

    public readonly onNewWibDialogClose = () => {
        this.model.newWibDialogModel = null
        this.render(this.model)
    }

    public readonly onNewWibCreated = (wibId: string) => {
        this.model.newWibDialogModel = null
        this.render(this.model)
    }

    public readonly onPrivateChatClicked = (contactId: string) => {
        WibSidebar.showPrivateChat(contactId)
    }

    public readonly onCloseCommSidebar = () => {
        WibSidebar.close()
    }

    private readonly handleAddToTaskList = (wibId: string) => {
        WibSvc.addToTaskList(wibId)
    }

    private readonly handleRemoveFromTaskList = (wibId: string) => {
        WibSvc.removeFromTaskList(wibId)
    }

    private readonly handleMarkAsUnread = (wibId: string) => {
        WibSvc.markAsUnread(wibId)
    }

    private readonly handleLeaveWib = (wibId: string) => {
        WibSvc.leaveWib(wibId)
    }

    private refresh() {
        // private chats
        var chatsNotificationCount = 0
        this.model.privateChats = new PrivateChatListModel()
        if (this.pcsSnapshot != null) {
            for (var c of UsersSvc.users) {
                if (c.id == CurrentUserSvc.currentUser.id) {
                    continue
                }
                var pc = this.findPrivateChatByUserId(c.id)
                var uc = 0
                if (pc != null) {
                    if (pc.data().users[0] == c.id) {
                        uc = pc.data().unreadMessageCounts[1]
                    } else {
                        uc = pc.data().unreadMessageCounts[0]
                    }
                }
                if (uc > 0) {
                    chatsNotificationCount++
                }
                var cm = new PrivateChatListItemModel(c.id, c.data().name, c.data().profilePictureUrl, c.data().color, pc != null ? pc.data().lastMessage : null, pc != null ? pc.data().lastMessageSenderId != CurrentUserSvc.currentUser.id : false, uc, pc == null ? 0 : pc.data().lastMessageAt, c.data().activeSessionsCount > 0)
                this.model.privateChats.items.push(cm)

                if (uc > 0 && pc != null && this.model.selectedContactModel != null) {
                    if (c.id == this.model.selectedContactModel.contactId && !document.hidden) {
                        this.notifyPrivateChatSeen(pc.id)
                    }
                }
            }
            this.model.privateChats.items = this.model.privateChats.items.sort((item1, item2) => {
                return item2.lastMessageAt - item1.lastMessageAt
            })
        }
        this.model.chatsTabNotificationCount = chatsNotificationCount

        // wibs
        var wibNotificationCount = 0
        if (this.wibsSnapshot != null) {
            this.model.wibs = this.wibsSnapshot.docs.filter(item => WibHandlerRegistry.getHandlerByType(item.data().type) != null)
                .map((item) => {
                    var handler = WibHandlerRegistry.getHandlerByType(item.data().type)

                    const wib = new Wib(item.data())
                    const userNoti = wib.getMemberNotification(CurrentUserSvc.currentUser.id)

                    var lastMessageAtStr = StringUtils.convertTimestampToStr(userNoti.notifiedAt)
                    // const cuidx = item.data().notifiedUsers.findIndex(userId => userId == CurrentUserSvc.currentUser.id)
                    var purpose = userNoti.reason.message
                    var purposeColor = userNoti.reason.color

                    const unreadMessagesCount = userNoti.unreadNotificationsCount

                    var res = new WibsListItemModel(item.id, item.data().subject, handler.color,
                        lastMessageAtStr, userNoti.senderId, unreadMessagesCount, purpose, purposeColor,
                        item.data().assignees.indexOf(CurrentUserSvc.currentUser.id) < 0 ?
                            new WibActionButton("Add to Todo list", 'playlist_add', WColorPalette.Green.color, "outlined", false, () => { this.handleAddToTaskList(item.id) }) :
                            new WibActionButton("Remove from Todo list", 'playlist_add_check', WColorPalette.Green.color, "filled", false, () => { this.handleRemoveFromTaskList(item.id) }),
                        ""
                    )

                    if (unreadMessagesCount > 0) {
                        wibNotificationCount++
                    }

                    if (this.model.selectedWibModel != null && this.model.selectedWibModel.wibId == item.id) {
                        if (unreadMessagesCount > 0 && !document.hidden) {
                            this.notifyWibSeen(item.id)
                        }
                    }

                    return res
                })
        }
        this.model.wibsTabNotificationCount = wibNotificationCount

        const cuidx = CurrentUserSvc.currentUser.id

        // tasks
        var taskNotificationsCount = 0
        if (this.tasksSnapshot != null) {
            this.model.tasks = this.tasksSnapshot.docs.filter(item => WibHandlerRegistry.getHandlerByType(item.data().type) != null)
                .sort((a, b) => b.data().memberNotifications[cuidx].notifiedAt - a.data().memberNotifications[cuidx].notifiedAt)
                .map((item) => {
                    var handler = WibHandlerRegistry.getHandlerByType(item.data().type)

                    const wib = new Wib(item.data())
                    const userNoti = wib.getMemberNotification(CurrentUserSvc.currentUser.id)

                    var lastMessageAtStr = StringUtils.convertTimestampToStr(userNoti.notifiedAt)
                    // const cuidx = item.data().notifiedUsers.findIndex(userId => userId == CurrentUserSvc.currentUser.id)
                    var purpose = userNoti.reason.message
                    var purposeColor = userNoti.reason.color

                    const unreadMessagesCount = userNoti.unreadNotificationsCount
                    var res = new WibsListItemModel(item.id, item.data().subject, handler.color,
                        lastMessageAtStr, userNoti.senderId, unreadMessagesCount, purpose, purposeColor,
                        new WibActionButton("Done (remove from Todo list)", 'done', WColorPalette.Green.color, "outlined", false, () => { this.handleRemoveFromTaskList(item.id) }),
                        ""
                    )

                    if (unreadMessagesCount > 0) {
                        taskNotificationsCount++
                    }

                    if (this.model.selectedWibModel != null && this.model.selectedWibModel.wibId == item.id) {
                        if (unreadMessagesCount > 0 && !document.hidden) {
                            this.notifyWibSeen(item.id)
                        }
                    }

                    return res
                })
        }
        this.model.tasksTabNotificationCount = taskNotificationsCount


        this.model.isLoading = false
        this.render(this.model)

        this.updateTitleNotification(chatsNotificationCount + wibNotificationCount)
    }

    public readonly notifyPrivateChatSeen = async (pcId: string) => {
        PcSvc.notifySeen(pcId)
    }

    public readonly notifyWibSeen = async (wibId: string) => {
        WibSvc.notifyWibSeen(wibId)
    }

    private findPrivateChatByUserId(userId: string): firebase.firestore.DocumentSnapshot<IPrivateChatData> {
        if (this.pcsSnapshot == null) {
            return null
        }
        return this.pcsSnapshot.docs.find((item) => {
            return item.data().users[0] == userId || item.data().users[1] == userId
        })
    }

    private findContactByUserId(userId: string) {
        return UsersSvc.users.find((item) => {
            return item.id == userId
        })
    }

    private findWibById(wibId: string): FsEntity<IWib<{}>> {
        const s = this.wibsSnapshot.docs.find((item) => {
            return item.id == wibId
        })

        if (s == null) {
            return null
        }

        return new FsEntity(s)
    }

    private updateTitleNotification(count: number) {
        updateNotificationsCount(count)
    }

}