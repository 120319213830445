import { CurrentOrgSvc } from "backend/aaa/CurrentOrgSvc"
import { WibSvc } from "backend/wib/WibService"
import { IBoardData } from "shared/entities/projects/IBoardData"
import { IWib } from "shared/entities/wib/IWib"
import { Wib } from "shared/entities/wib/Wib"
import { ICardWibContent } from "shared/entities/wib/wibs/ICardWibContentType"
import { transact } from "utils/Fs"

export const deleteCardFun = async (cardId: string) => {
    await transact(async (t) => {
        const wibRef = CurrentOrgSvc.currentOrg.snapshot.ref
            .collection("Wibs")
            .doc(cardId)

        const wibE = await t.get(wibRef)
        if (!wibE.exists) {
            throw new Error("Can't find card with id: " + cardId)
        }
        const wibD = wibE.data() as IWib<ICardWibContent>
        const wib = new Wib(wibD)

        const boardRef = CurrentOrgSvc.currentOrg.snapshot.ref
            .collection("Boards")
            .doc(wib.parentId)

        const board = await t.get(boardRef)
        const boardD = board.data() as IBoardData

        var foundColIdx = -1
        var foundCardIdx = -1
        boardD.columns.forEach((col, colIdx) => {
            if (foundCardIdx > -1) {
                return
            }
            col.cards.forEach((card, cardIdx) => {
                if (card == cardId) {
                    foundCardIdx = cardIdx
                }
            })
            if (foundCardIdx > -1) {
                foundColIdx = colIdx
            }
        })

        if (foundColIdx < 0 || foundCardIdx < 0) {
            throw new Error("Can't find card on board!")
        }
        boardD.columns[foundColIdx].cards.splice(foundCardIdx, 1)
        t.update(boardRef, { columns: boardD.columns })

        // wib.members.forEach((user) => {
        //     wib.snoozeUser(user)
        // })
        // wib.watchingUsers.forEach((user) => {
        //     wib.removeWatcher(user)
        // })
        wib.delete()

        t.update(wibRef, wib.updatedData)
    }, null)

    await WibSvc.sendMetaInfo(cardId, `Deleted this card.`)
}