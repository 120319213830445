import { CurrentUserSvc } from 'backend/aaa/CurrentUserSvc'
import * as React from 'react'
import { EUserRole } from 'shared/entities/aaa/EUserRole'
import SimpleBar from 'simplebar-react'
import { Navigation } from 'utils/Navigation'
import { ReactUtils } from 'utils/ReactUtils'
import { WIcon } from 'wds/Icon'
import { EFontWeight } from 'wds/styles/EFontWeight'



export class MainMenu extends React.Component<MainMenuProps, MainMenuState> {

    render() {
        return (
            <div
                style={{
                    position: "fixed",
                    width: "64px",
                    left: "0px",
                    top: "0px",
                    bottom: "0px",
                    backgroundColor: "#414141",
                    boxShadow: "rgba(0, 0, 0, 0.5) 1px 1px 5px",
                    zIndex: 1000,
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                {/* header */}
                <div
                    style={{
                        width: "100%",
                        paddingTop: "16px",
                        paddingBottom: "0px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",

                    }}
                >

                    <img src="/app/images/logo_small.png" style={{
                        width: "auto",
                        height: "30px"
                    }}
                    />

                </div>

                {/* top items */}
                <div
                    style={{
                        width: "100%",
                        flex: "1",
                        overflow: "hidden",
                        position: "relative"
                    }}
                >
                    <SimpleBar style={{ maxHeight: "100%" }}>
                        <div style={{
                            width: "100%",
                            paddingTop: "16px",
                            paddingBottom: "16px",
                        }}>
                            {this.getTopItems()}
                            <div
                                style={{
                                    width: "100%",
                                    height: "1px",
                                    backgroundColor: "rgba(255,255,255, 0.1)"
                                }}
                            />
                        </div>
                    </SimpleBar>

                    <div style={{
                        position: "absolute",
                        width: "100%",
                        height: "16px",
                        left: "0px",
                        top: "0px",
                        backgroundImage: "linear-gradient(rgba(65,65,65, 1.0), rgba(65,65,65, 0.0))",
                    }} />

                    <div style={{
                        position: "absolute",
                        width: "100%",
                        height: "16px",
                        left: "0px",
                        bottom: "0px",
                        backgroundImage: "linear-gradient(rgba(65,65,65, 0.0), rgba(65,65,65, 1.0))",
                    }} />




                </div>

                {/* bottom items */}
                <div
                    style={{
                        width: "100%",
                        paddingTop: "16px"
                    }}
                >
                    {this.getBottomItems()}
                </div>

            </div>
        )
    }

    private getTopItems(): React.ReactNode {
        var res: React.ReactNode = null
        React.Children.forEach(this.props.children, (item) => {
            if (ReactUtils.checkComponentType(item, MainMenuTopItems)) {
                res = item
            }
        })

        return res
    }

    private getBottomItems(): React.ReactNode {
        var res: React.ReactNode = null
        React.Children.forEach(this.props.children, (item) => {
            if (ReactUtils.checkComponentType(item, MainMenuBottomItems)) {
                res = item
            }
        })

        return res
    }

}

export interface MainMenuProps {

}

interface MainMenuState {

}

// ------------------------------------------------------------------------------------------

export class MainMenuTopItems extends React.Component<any, any> {

    render() {
        return this.getMenuItems()
    }

    private getMenuItems(): React.ReactNode[] {
        var res: React.ReactNode[] = []
        React.Children.forEach(this.props.children, (item) => {
            if (ReactUtils.checkComponentType(item, MainMenuItem)) {
                res.push(item)
            }
        })

        return res
    }

}

export class MainMenuBottomItems extends React.Component<any, any> {

    render() {
        return this.getMenuItems()
    }

    private getMenuItems(): React.ReactNode[] {
        var res: React.ReactNode[] = []
        React.Children.forEach(this.props.children, (item) => {
            if (ReactUtils.checkComponentType(item, MainMenuItem)) {
                res.push(item)
            }
        })

        return res
    }

}


// ------------------------------------------------------------------------------------------


export class MainMenuItem extends React.Component<MainMenuItemProps, MainMenuItemState> {

    public constructor(props: MainMenuItemProps) {
        super(props)
        this.state = { hovered: false }
    }

    private handleMouseEnter = () => {
        this.setState({ hovered: true })
    }

    private handleMouseLeave = () => {
        this.setState({ hovered: false })
    }

    private handleClick = () => {
        Navigation.pushState(null, this.props.link)
    }

    render() {
        var isSelected: boolean = Navigation.currentState.path.startsWith(this.props.selectedPath)
        var subItems = this.getSubItems()
        if (EUserRole.findByName(CurrentUserSvc.currentUser.data.role).level < this.props.minRole.level) {
            return (<></>)
        }
        return (
            <div
                style={{
                    width: "100%",
                    borderTop: "1px solid rgba(255,255,255, 0.1)",
                    display: "flex",
                    backgroundColor: isSelected ? "rgba(0,0,0, 0.2)" : "transparent"
                }}
            >

                {/* select indicator */}
                <div
                    style={{
                        width: "6px",
                        alignSelf: "stretch",
                        backgroundColor: isSelected ? "#2cbbb2" : "transparent"
                    }}
                >

                </div>

                <div style={{
                    display: "flex",
                    flex: "1",
                    flexDirection: "column",
                }}>

                    <div
                        style={{
                            width: "100%",
                            display: "inline-flex",
                            alignItems: "center",
                            height: "64px",
                            cursor: "pointer",
                            opacity: this.state.hovered ? (isSelected ? "1.0" : "0.5") : "1.0"
                        }}
                        onMouseEnter={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseLeave}
                        onClick={this.handleClick}
                    >

                        {/* icon */}
                        <div
                            style={{
                                paddingLeft: "12px",
                                width: "36px",
                                display: "inline-flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <WIcon icon={this.props.icon} size="24px" color={isSelected ? "#2cbbb2" : "rgba(255,255,255, 0.3)"} />
                        </div>

                        {/* title */}
                        {/* <div
                            style={{
                                paddingLeft: "16px",
                                paddingRight: "16px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                flex: "1",
                                fontSize: "16px",
                                fontWeight: EFontWeight.Semibold.value,
                                color: isSelected ? "#2cbbb2" : "rgba(255,255,255, 0.8)",
                            }}
                        >
                            {this.props.title}
                        </div> */}

                    </div>


                    {/* {isSelected && subItems.length > 0 &&
                        subItems
                    } */}

                </div>

            </div >
        )
    }

    private getSubItems(): React.ReactNode[] {
        var res: React.ReactNode[] = []

        React.Children.forEach(this.props.children, (item) => {
            if (ReactUtils.checkComponentType(item, MainMenuSubItem)) {
                res.push(item)
            }
        })

        return res
    }

}

export interface MainMenuItemProps {

    title: string
    icon: string
    minRole: EUserRole
    link: string
    selectedPath: string

}

interface MainMenuItemState {

    hovered: boolean

}

// ---------------------------------------------------------------------------------------

export class MainMenuSubItem extends React.Component<MainMenuSubItemProps, MainMenuSubItemState> {

    public constructor(props: MainMenuSubItemProps) {
        super(props)
        this.state = { isHovered: false }
    }

    private handleMouseEnter = () => {
        this.setState({ isHovered: true })
    }

    private handleMouseLeave = () => {
        this.setState({ isHovered: false })
    }

    private handleClick = () => {
        Navigation.pushState(null, this.props.link)
    }

    render() {
        if (EUserRole.findByName(CurrentUserSvc.currentUser.data.role).level < this.props.minRole.level) {
            return (<></>)
        }
        var isSelected: boolean = Navigation.currentState.path == this.props.link
        return (
            <div
                style={{
                    width: "100%",
                    height: "40px",
                    display: "flex",
                    cursor: "pointer",
                    alignItems: "center",
                    borderTop: "1px solid rgba(255,255,255, 0.1)",
                    opacity: this.state.isHovered && !isSelected ? "0.5" : "1.0"

                }}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onClick={this.handleClick}
            >

                {/* icon */}
                <div
                    style={{
                        paddingLeft: "12px",
                        width: "36px",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >

                    <WIcon icon="trip_origin" size="12px" color={isSelected ? "#2cbbb2" : "rgba(255,255,255, 0.2)"} />

                </div>

                {/* title */}
                <div
                    style={{
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        flex: "1",
                        fontSize: "14px",
                        fontWeight: EFontWeight.Regular.value,
                        color: isSelected ? "#2cbbb2" : "rgba(255,255,255, 0.7)",
                    }}
                >
                    {this.props.title}
                </div>

            </div >

        )
    }

}

export interface MainMenuSubItemProps {

    title: string
    minRole: EUserRole
    link: string

}

export interface MainMenuSubItemState {

    isHovered: boolean

}