import { IFileDescriptor } from "shared/entities/wib/IWib";

export const VER_PrivateChatData = 1

export interface IPrivateChatData {
    version: number
    users: string[]
    lastSeens: number[]
    unreadMessageCounts: number[]
    lastMessage: string,
    lastMessageSenderId: string
    lastMessageAt: number
    files: IFileDescriptor[]
}