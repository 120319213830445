
export enum EHAlign {

    Left,
    Center,
    Right

}

export enum EVAlign {

    Top,
    Middle,
    Bottom

}