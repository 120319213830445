import { CurrentOrgSvc } from 'backend/aaa/CurrentOrgSvc';
import { changeColumnOptionsFun } from 'backend/projects/board/functions/changeColumnOptionsFun';
import { changeDescriptionFun } from 'backend/projects/board/functions/changeDescriptionFun';
import { deleteCardFun } from 'backend/projects/board/functions/deleteCardFun';
import { findBoardByIdFun } from 'backend/projects/board/functions/findBoardByIdFun';
import { moveCardFun } from 'backend/projects/board/functions/moveCardFun';
import { moveColumnFun } from 'backend/projects/board/functions/moveColumnFun';
import { removeAssigneeFun } from 'backend/projects/board/functions/removeAssigneeFun';
import { removeCardMemberfun } from 'backend/projects/board/functions/removeCardMemberFun';
import { IBoardData } from 'shared/entities/projects/IBoardData';
import { IWib } from 'shared/entities/wib/IWib';
import { ICardWibContent } from 'shared/entities/wib/wibs/ICardWibContentType';
import { FsEntity, FsQueryListener } from 'utils/Fs';
import { changeBoardNameFun } from './functions/changeBoardNameFun';
import { changeColumnNameFun } from './functions/changeColumnNameFun';
import { createBoardFun } from './functions/createBoardFun';
import { createColumnFun } from './functions/createColumnFun';
import { createNewCardFun } from './functions/createNewCardFun';
import { deleteBoardFun } from './functions/deleteBoardFun';
import { deleteColumnFun } from './functions/deleteColumnFun';
import { listBoardsFun } from './functions/listBoardsFun';

export class BoardSvc {

    public static async getBoardListener(baordId: string): Promise<FsEntity<IBoardData>> {
        return new FsEntity<IBoardData>(
            await CurrentOrgSvc.currentOrg.snapshot.ref
                .collection("Boards")
                .doc(baordId)
                .get()
        )
    }

    public static async getCardsListener(baordId: string): Promise<FsQueryListener<IWib<ICardWibContent>>> {
        return new FsQueryListener(
            CurrentOrgSvc.currentOrg.snapshot.ref
                .collection("Wibs")
                .where("parentType", "==", "wibib.projects.board")
                .where("parentId", "==", baordId)
                .where("deleted", "==", false)
        )
    }

    public static readonly findboardById = findBoardByIdFun
    public static readonly listBoards = listBoardsFun
    public static readonly createBoard = createBoardFun
    public static readonly changeBoardName = changeBoardNameFun
    public static readonly deleteBoard = deleteBoardFun

    public static readonly createColumn = createColumnFun
    public static readonly changeColumnName = changeColumnNameFun
    public static readonly changeColumnOptions = changeColumnOptionsFun
    public static readonly moveColumn = moveColumnFun
    public static readonly deleteColumn = deleteColumnFun

    public static readonly createNewCard = createNewCardFun
    public static readonly moveCard = moveCardFun
    public static readonly changeDescription = changeDescriptionFun
    public static readonly deleteCard = deleteCardFun
    public static readonly removeCardMember = removeCardMemberfun
    public static readonly removeAssignee = removeAssigneeFun

    private constructor() { }

}