import { AController } from "@eagerlogic/react-mvc";
import { BoardSvc } from "backend/projects/board/BoardService";
import "firebase/analytics";
import * as firebase from "firebase/app";
import { IBoardData } from "shared/entities/projects/IBoardData";
import { ChangeListenerCanceler } from "utils/Fs";
import { UsersSvc } from "../../../../../backend/aaa/UsersSvc";
import { Navigation } from "../../../../../utils/Navigation";
import { Snackbar } from "../../../../../wds/Snackbar";
import { BoardListItemModel, CreateNewBoardDialogModel, EditBoardNameDialogModel, ProjectBoardListSceneModel } from "./ProjectsBoardListSceneModel";

export class ProjectBoardListSceneController extends AController<ProjectBoardListSceneModel> {

    private boards: firebase.firestore.QuerySnapshot<IBoardData>
    private readonly boardListListenerCanceler: ChangeListenerCanceler = null

    protected createInitialModel(): ProjectBoardListSceneModel {
        return new ProjectBoardListSceneModel()
    }

    public async init() {
        firebase.analytics().logEvent("projects.board_list_opened")
        this.refresh()
    }

    private async refresh() {
        this.model.isLoading = true;
        this.render(this.model)

        var boardsSnapshot = (await BoardSvc.listBoards()).docs.sort((a, b) => {
            return a.data().name.toLocaleLowerCase().localeCompare(b.data().name.toLocaleLowerCase())
        })

        this.model.boards = boardsSnapshot.map((item) => {
            const owner = UsersSvc.usersById[item.data().createdBy]
            return new BoardListItemModel(item.id, item.data().name, owner.data().name, owner.data().color, owner.data().profilePictureUrl, item.data().color != null ? item.data().color : "#888")
        })

        this.model.isLoading = false;
        this.render(this.model)
    }

    public readonly onCreateNewBoardClick = () => {
        this.model.createNewBoardDialogModel = new CreateNewBoardDialogModel()
        this.render(this.model)
        firebase.analytics().logEvent("projects.create_board_dialog_opened")
    }

    public readonly onDeleteBoard = async (id: string) => {
        if (!confirm("Are you sure you want to delete this board? This can't be undone.")) {
            return
        }

        this.model.isLoading = true
        this.render(this.model)

        try {
            await BoardSvc.deleteBoard(id)

            firebase.analytics().logEvent("projects.board_deleted")
            this.refresh()
        } catch (ex) {
            Snackbar.show('danger', "Delete board failed: " + ex.message)
            this.model.isLoading = false
            this.render(this.model)
        }
    }

    public readonly onEditBoardClick = (boardId: string, name: string) => {
        this.model.editBoardNameDialogModel = new EditBoardNameDialogModel(boardId, name)
        this.render(this.model)
    }

    public readonly onEditBoardCancelClick = () => {
        this.model.editBoardNameDialogModel = null
        this.render(this.model)
    }

    public readonly onEditBoardSubmit = async () => {
        this.model.editBoardNameDialogModel.nameModel.error = null
        this.model.editBoardNameDialogModel.error = null
        this.model.editBoardNameDialogModel.isLoading = true
        this.render(this.model)

        var name = this.model.editBoardNameDialogModel.nameModel.value
        if (name == null) {
            name = ""
        }
        name = name.trim()
        if (name.length < 1) {
            this.model.editBoardNameDialogModel.nameModel.error = "This field is mandatory!"
            this.model.editBoardNameDialogModel.isLoading = false
            this.render(this.model)
            return
        }

        try {
            await BoardSvc.changeBoardName(this.model.editBoardNameDialogModel.boardId, name)

            this.model.editBoardNameDialogModel = null
            this.refresh()
        } catch (ex) {
            this.model.editBoardNameDialogModel.isLoading = false
            this.model.editBoardNameDialogModel.error = "Server error occured! Reload the page and try again!"
            this.render(this.model)
        }
    }

    public readonly onCreateNewBoardCancelClick = () => {
        this.model.createNewBoardDialogModel = null
        this.render(this.model)
        firebase.analytics().logEvent("projects.create_board_dialog_canceled")
    }

    public readonly onCreateNewBoardCreateClick = async () => {
        this.model.createNewBoardDialogModel.error = null
        this.model.createNewBoardDialogModel.nameModel.error = null
        this.model.createNewBoardDialogModel.isLoading = true
        this.render(this.model)

        var name = this.model.createNewBoardDialogModel.nameModel.value
        if (name == null) {
            name = ""
        }
        name = name.trim()
        if (name.length < 2) {
            this.model.createNewBoardDialogModel.nameModel.error = "The board name must be minimum 2 characters!"
            this.model.createNewBoardDialogModel.isLoading = false
            this.render(this.model)
            return
        }

        try {
            const boardId = await BoardSvc.createBoard(name)

            firebase.analytics().logEvent("projects.board_created")
            Navigation.pushState(null, "/app/projects/board.rsc?id=" + boardId)
        } catch (ex) {
            this.model.createNewBoardDialogModel.isLoading = false
            this.model.createNewBoardDialogModel.error = ex.message ? ex.message : "Internal server error occured!"
            this.render(this.model)
        }
    }

    public readonly onBoardClick = (boardId: string) => {
        Navigation.pushState(null, "/app/projects/board.rsc?id=" + boardId)
    }

}