import { CurrentOrgSvc } from "backend/aaa/CurrentOrgSvc";
import { CurrentUserSvc } from "backend/aaa/CurrentUserSvc";
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { IPrivateChatData, VER_PrivateChatData } from "shared/entities/privateChat/IPrivateChatData";
import { IPCMessageContent, IPCSendFileContent, IPrivateChatMessageData, VER_IPCMessageContent, VER_IPCSendFileContent, VER_PrivateChatMessageData } from "shared/entities/privateChat/IPrivateChatMessageData";
import { IFileDescriptor } from "shared/entities/wib/IWib";
import { StringUtils } from "shared/utils/StringUtils";
import { fs, FsEntity, FsQueryListener } from "utils/Fs";

export class PcSvc {

    private static _onPcListChanged: FsQueryListener<IPrivateChatData>

    public static get onPcListChanged() {
        if (this._onPcListChanged == null) {
            this._onPcListChanged = new FsQueryListener(
                fs()
                    .collection("Organizations")
                    .doc(CurrentOrgSvc.currentOrg.id)
                    .collection("PrivateChats")
                    .where("users", "array-contains", CurrentUserSvc.currentUser.id)
            )
        }
        return this._onPcListChanged
    }

    public static onPcMessagesChangeEvent(pcId: string): FsQueryListener<IPrivateChatMessageData<{}>> {
        return new FsQueryListener(
            fs()
                .collection("Organizations")
                .doc(CurrentOrgSvc.currentOrg.id)
                .collection("PrivateChats")
                .doc(pcId)
                .collection("Messages")
                .orderBy("sentAt", "desc")
                .limit(100)
        )
    }

    public static async notifySeen(pcId: string) {

        await fs().runTransaction(async (t) => {
            const pcR = fs()
                .collection("Organizations")
                .doc(CurrentOrgSvc.currentOrg.id)
                .collection("PrivateChats")
                .doc(pcId)

            const pcS = await t.get(pcR)
            if (!pcS.exists) {
                throw new Error("Can't find private chat with id: " + pcId)
            }

            const pcD = pcS.data() as IPrivateChatData
            var cuidx = -1
            if (pcD.users[0] == CurrentUserSvc.currentUser.id) {
                cuidx = 0
            } else if (pcD.users[1] == CurrentUserSvc.currentUser.id) {
                cuidx = 1
            } else {
                throw new Error("Access denied for private chat: " + pcId)
            }

            const pcU: Partial<IPrivateChatData> = {
                lastSeens: [
                    cuidx == 0 ? Date.now() : pcD.lastSeens[0],
                    cuidx == 1 ? Date.now() : pcD.lastSeens[1],
                ],
                unreadMessageCounts: [
                    cuidx == 0 ? 0 : pcD.unreadMessageCounts[0],
                    cuidx == 1 ? 0 : pcD.unreadMessageCounts[1],
                ]
            }

            t.update(pcR, pcU)
        })
    }

    public static async findById(id: string): Promise<FsEntity<IPrivateChatData>> {
        const pcRef = fs()
            .collection("Organizations")
            .doc(CurrentOrgSvc.currentOrg.id)
            .collection("PrivateChats")
            .doc(id)

        const pcE = await pcRef.get()

        if (pcE == null) {
            return null
        }

        return new FsEntity(pcE)
    }

    public static async sendMessage(pcId: string, remoteUserId: string, msg: string): Promise<string> {
        if (msg == null) {
            msg = ""
        }
        msg = msg.trimRight()
        if (msg.length < 1) {
            return
        }

        return await fs().runTransaction(async (t) => {
            var pcRef: firebase.firestore.DocumentReference = null
            if (pcId == null) {
                const npcd: IPrivateChatData = {
                    users: [CurrentUserSvc.currentUser.id, remoteUserId],
                    lastSeens: [Date.now(), -1],
                    unreadMessageCounts: [0, 1],
                    lastMessage: msg,
                    lastMessageAt: Date.now(),
                    lastMessageSenderId: CurrentUserSvc.currentUser.id,
                    version: VER_PrivateChatData,
                    files: []
                }

                pcRef = fs()
                    .collection("Organizations")
                    .doc(CurrentOrgSvc.currentOrg.id)
                    .collection("PrivateChats")
                    .doc()

                t.set(
                    pcRef,
                    npcd
                )
            } else {
                pcRef = fs()
                    .collection("Organizations")
                    .doc(CurrentOrgSvc.currentOrg.id)
                    .collection("PrivateChats")
                    .doc(pcId)

                const pcE = await t.get(pcRef)
                const pcD = pcE.data() as IPrivateChatData

                var cuidx = -1
                if (pcD.users[0] == CurrentUserSvc.currentUser.id) {
                    cuidx = 0
                } else if (pcD.users[1] == CurrentUserSvc.currentUser.id) {
                    cuidx = 1
                }
                if (cuidx == -1) {
                    throw new Error("Private chat access denied: " + pcId)
                }

                const pcud: Partial<IPrivateChatData> = {
                    lastSeens: [
                        cuidx == 0 ? Date.now() : pcD.lastSeens[0],
                        cuidx == 1 ? Date.now() : pcD.lastSeens[1],
                    ],
                    unreadMessageCounts: [
                        cuidx == 0 ? 0 : pcD.unreadMessageCounts[0] + 1,
                        cuidx == 1 ? 0 : pcD.unreadMessageCounts[1] + 1,
                    ],
                    lastMessage: msg,
                    lastMessageAt: Date.now(),
                    lastMessageSenderId: CurrentUserSvc.currentUser.id
                }

                t.update(pcRef, pcud)
            }

            const msgD: IPrivateChatMessageData<IPCMessageContent> = {
                message: msg,
                senderId: CurrentUserSvc.currentUser.id,
                sentAt: Date.now(),
                version: VER_PrivateChatMessageData,
                type: 'message',
                content: {
                    version: VER_IPCMessageContent
                },
            }

            t.set(
                pcRef.collection("Messages").doc(),
                msgD
            )

            return pcRef.id
        })
    }

    public static async sendFile(pcId: string, remoteUserId: string, fileName: string, data: Blob): Promise<string> {
        fileName = fileName.trim()
        var finalFileName = fileName
        return await fs().runTransaction(async (t) => {
            var pcRef: firebase.firestore.DocumentReference = null
            var files: IFileDescriptor[] = null
            //var pcE: firebase.firestore.DocumentSnapshot<IPrivateChatData> = null
            //var pcD: IPrivateChatData = null
            if (pcId == null) {
                pcRef = fs()
                    .collection("Organizations")
                    .doc(CurrentOrgSvc.currentOrg.id)
                    .collection("PrivateChats")
                    .doc()
            } else {
                pcRef = fs()
                    .collection("Organizations")
                    .doc(CurrentOrgSvc.currentOrg.id)
                    .collection("PrivateChats")
                    .doc(pcId)

                let pcE = await t.get(pcRef) as firebase.firestore.DocumentSnapshot<IPrivateChatData>
                let pcD = pcE.data() as IPrivateChatData
                files = pcD.files
            }

            if (files == null) {
                files = []
            }

            var fileCounter = 0
            while (files.findIndex((f) => f.fileName == finalFileName) > -1) {
                fileCounter++
                finalFileName = StringUtils.annotateFileName(fileName, " (" + fileCounter + ")")
            }

            const fileRef = firebase.storage().ref(`orgs/${CurrentOrgSvc.currentOrg.id}/privateChats/${pcRef.id}/${finalFileName}`)
            await fileRef.put(data)
            const downloadUrl: string = await fileRef.getDownloadURL()

            files.push({
                fileName: finalFileName,
                downloadUrl: downloadUrl,
                uploadedAt: Date.now()
            })

            if (pcId == null) {
                const npcd: IPrivateChatData = {
                    users: [CurrentUserSvc.currentUser.id, remoteUserId],
                    lastSeens: [Date.now(), -1],
                    unreadMessageCounts: [0, 1],
                    lastMessage: "sent a file",
                    lastMessageAt: Date.now(),
                    lastMessageSenderId: CurrentUserSvc.currentUser.id,
                    version: VER_PrivateChatData,
                    files: files
                }

                t.set(
                    pcRef,
                    npcd
                )
            } else {
                pcRef = fs()
                    .collection("Organizations")
                    .doc(CurrentOrgSvc.currentOrg.id)
                    .collection("PrivateChats")
                    .doc(pcId)

                const pcE = await t.get(pcRef)
                const pcD = pcE.data() as IPrivateChatData

                var cuidx = -1
                if (pcD.users[0] == CurrentUserSvc.currentUser.id) {
                    cuidx = 0
                } else if (pcD.users[1] == CurrentUserSvc.currentUser.id) {
                    cuidx = 1
                }
                if (cuidx == -1) {
                    throw new Error("Private chat access denied: " + pcId)
                }

                const pcud: Partial<IPrivateChatData> = {
                    lastSeens: [
                        cuidx == 0 ? Date.now() : pcD.lastSeens[0],
                        cuidx == 1 ? Date.now() : pcD.lastSeens[1],
                    ],
                    unreadMessageCounts: [
                        cuidx == 0 ? 0 : pcD.unreadMessageCounts[0] + 1,
                        cuidx == 1 ? 0 : pcD.unreadMessageCounts[1] + 1,
                    ],
                    lastMessage: "sent a file",
                    lastMessageAt: Date.now(),
                    lastMessageSenderId: CurrentUserSvc.currentUser.id,
                    files: files
                }

                t.update(pcRef, pcud)
            }

            const msgD: IPrivateChatMessageData<IPCSendFileContent> = {
                content: {
                    fileName: finalFileName,
                    downloadUrl: downloadUrl,
                    version: VER_IPCSendFileContent
                },
                message: "sent a file",
                senderId: CurrentUserSvc.currentUser.id,
                sentAt: Date.now(),
                type: "attachment",
                version: VER_PrivateChatMessageData
            }

            t.set(
                pcRef.collection("Messages").doc(),
                msgD
            )

            return pcRef.id
        })
    }

    private constructor() { }

}