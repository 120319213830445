import { Fab, Icon } from '@material-ui/core';
import { UsersSvc } from 'backend/aaa/UsersSvc';
import { ProfilePicture } from 'components/ProfilePicture';
import * as React from 'react';
import { style } from 'typestyle';
import { WIcon } from 'wds/Icon';
import { WScrollBox } from 'wds/ScrollBox';
import { EFontWeight } from 'wds/styles/EFontWeight';
import { WColorPalette } from 'wds/styles/WColor';
import { WibActionButton } from 'wibib-sdk-fe/WibHandlerBase';

export class WibsList extends React.Component<WibsListProps, WibsListState> {

    public constructor(props: WibsListProps) {
        super(props)

        this.state = {
            isNewWibsPopperVisible: false
        }
    }

    private readonly handleNewWibClicked = () => {
        if (this.props.onNewWibClicked != null) {
            this.props.onNewWibClicked()
        }
    }

    private readonly handleWibClicked = (wibId: string) => {
        this.props.onWibClicked(wibId)
    }

    render() {
        return (
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    overflow: 'hidden'
                }}
            >
                <div style={{ width: "100%", flex: "1", overflow: 'hidden' }}>
                    <WScrollBox>
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            {this.props.items.length < 1 &&
                                <div
                                    style={{
                                        width: "100%",
                                        padding: "32px",
                                        textAlign: "center",
                                        fontSize: "14px",
                                        color: "#888"
                                    }}
                                >
                                    {this.props.emptyText}
                                </div>
                            }

                            {this.props.items.map((item, index) => {
                                return (
                                    <WibsListItem
                                        key={"wli-" + item.id}
                                        model={item}
                                        isSelected={item.id == this.props.selectedItemId}
                                        onClick={() => { this.handleWibClicked(item.id) }}
                                    />
                                )
                            })}
                        </div>
                    </WScrollBox>

                    <Fab style={{ position: "relative", transform: "translate(208px, -72px)" }} color="primary" onClick={this.props.onNewWibClicked}>
                        <Icon>add</Icon>
                    </Fab>
                </div>
            </div>
        )
    }

}

export interface WibsListProps {

    items: WibsListItemModel[]
    selectedItemId: string
    emptyText: string
    onNewWibClicked?: () => void
    onWibClicked: (wibId: string) => void

}

interface WibsListState {

}

export class WibsListItemModel {

    public constructor(
        public readonly id: string,
        public readonly subject: string,
        public readonly color: string,
        public readonly lastMessageAtStr: string,
        public readonly lastMessageSenderId: string,
        public readonly unreadMessagesCount: number,
        public readonly reason: string,
        public readonly reasonColor: string,
        public readonly primaryAction: WibActionButton,
        public readonly metaInfo: string
    ) { }

}

class WibsListItem extends React.Component<WibsListItemProps, WibsListItemState> {

    private readonly handleClick = () => {
        this.props.onClick()
    }

    render() {
        const cu = UsersSvc.usersById[this.props.model.lastMessageSenderId]
        return (
            <div
                className={this.props.isSelected ? itemRootSelectedClass : itemRootNormalClass}
                style={{ borderLeft: "8px solid " + this.props.model.color }}
                onClick={this.handleClick}
            >
                <div className={itemContainerClass}>
                    <div className={itemSubjectClass} style={{ fontWeight: this.props.model.unreadMessagesCount > 0 ? EFontWeight.Bold.value : EFontWeight.Regular.value }}>{this.props.model.subject}</div>

                    <div style={{ display: "flex", width: "100%", overflow: "hidden", alignItems: "center", paddingBottom: "2px" }}>


                        {/* profile picture */}
                        <div style={{ marginRight: "4px" }}>
                            <ProfilePicture
                                name={cu.data().name}
                                profilePictureUrl={cu.data().profilePictureUrl}
                                size={16}
                                backgroundColor={cu.data().color}
                                borderWidth={0}
                                popupText={cu.data().name}
                            />
                        </div>

                        {/* reason */}
                        <div style={{ flex: "1", marginRight: "8px", overflow: "hidden", display: "flex", alignItems: "center" }}>
                            <div
                                style={{
                                    display: "inline-flex",
                                    maxWidth: "100%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    fontSize: "11px",
                                    color: "#444",
                                    backgroundColor: this.props.model.reasonColor, //WColorPalette.LightBlue_100.color, //this.props.model.color, //
                                    borderRadius: "2px",
                                    paddingLeft: "8px",
                                    paddingRight: "8px",
                                    paddingTop: "2px",
                                    paddingBottom: "2px"
                                }}
                            >
                                {this.props.model.reason}
                            </div>
                        </div>

                        {/* date time */}
                        <div style={{ fontSize: "12px", color: "#888" }}>{this.props.model.lastMessageAtStr}</div>
                    </div>

                </div>

                <div style={{ width: "26px", minWidth: "26px", paddingLeft: "8px", display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
                    {/* notification count */}
                    {this.props.model.unreadMessagesCount > 0 &&
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", justifySelf: "flex-start", width: "18px", height: "18px", borderRadius: "50%", backgroundColor: WColorPalette.DeepOrange.color, fontSize: "11px", color: "#fff" }}>
                            {this.props.model.unreadMessagesCount > 9 ? "9+" : this.props.model.unreadMessagesCount}
                        </div>
                    }

                    <div style={{ flex: 1 }} />

                    {this.props.model.primaryAction != null &&
                        <PrimaryButtonView
                            model={this.props.model.primaryAction}
                        />
                    }
                </div>


            </div>
        )
    }

}

interface WibsListItemProps {

    model: WibsListItemModel
    isSelected: boolean
    onClick: () => void

}

interface WibsListItemState {

}



const itemSubjectClass = style({
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: "15px",
    color: "#000"
})

const itemRootNormalClass = style({
    width: "100%",
    minHeight: "64px",
    overflow: "hidden",
    position: "relative",
    display: "flex",
    padding: "12px",
    borderBottom: "1px solid #ccc",
    cursor: "pointer",
    $nest: {
        '&:hover': {
            backgroundColor: "#eee"
        }
    }
})
const itemRootSelectedClass = style({
    width: "100%",
    minHeight: "56px",
    overflow: "hidden",
    position: "relative",
    display: "flex",
    padding: "8px",
    borderBottom: "1px solid #ccc",
    cursor: "pointer",
    backgroundColor: "#eee",
})

const itemContainerClass = style({
    flex: "1",
    overflow: "hidden",
})

class PrimaryButtonView extends React.Component<{ model: WibActionButton }> {

    render() {
        var bgColor = "#fff"
        var borderColor = this.props.model.color
        var iconColor = this.props.model.color
        var cursor = 'pointer'
        if (this.props.model.type == "filled") {
            bgColor = this.props.model.color
            iconColor = "#fff"
        }
        if (this.props.model.isDisabled) {
            bgColor = "#eee"
            borderColor = "#ccc"
            iconColor = "#ccc"
            cursor = null
            if (this.props.model.type == 'filled') {
                bgColor = '#ccc'
                iconColor = '#eee'
            }
        }
        return (
            <div style={{ display: 'flex' }}>
                <div
                    className={headerButtonRootStyle}
                    style={{
                        backgroundColor: bgColor,
                        border: `2px solid ${borderColor}`,
                        cursor: cursor
                    }}
                    title={this.props.model.title}
                    onClick={this.props.model.isDisabled ? null : (e) => { this.props.model.onClick(), e.preventDefault(), e.stopPropagation() }}
                >
                    <WIcon icon={this.props.model.icon} color={iconColor} size="12px" />
                </div>
            </div>
        )
    }

}

const headerButtonRootStyle = style({
    width: '18px',
    minWidth: '18px',
    height: '18px',
    minHeight: '18px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    $nest: {
        '&:hover': {
            opacity: 0.7
        },
        '&:active': {
            opacity: 0.5
        }
    }
})