import * as React from 'react'
import { Animation, EAnimationLoopType } from './Animation'
import { EColor, WColorPalette } from './styles/WColor'

export class HorizontalLoadingIndicator extends React.Component<HLIProps, HLIState> {

    public constructor(props: HLIProps) {
        super(props)

        this.state = {
            animPos: 0,
            anim: new Animation(0, 500, 500, (value) => {
                this.setState({ animPos: value })
            }, 0, EAnimationLoopType.PingPong)
        }
    }

    componentDidMount() {
        this.state.anim.start()
    }

    componentWillUnmount() {
        this.state.anim.stop()
    }

    render() {
        var height = this.props.height
        if (height == null) {
            height = "8px"
        }
        return (
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    height: height,
                    backgroundColor: WColorPalette.Grey_200.color
                }}
            >
                <div
                    style={{
                        width: `${this.state.animPos / 10}%`,
                        height: "100%"
                    }}
                />

                <div
                    style={{
                        width: "50%",
                        height: "100%",
                        backgroundColor: EColor.Wibib.color
                    }}
                />
            </div>
        )
    }

}

export interface HLIProps {

    height?: string

}

interface HLIState {

    animPos: number
    anim: Animation

}