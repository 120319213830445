import { CurrentUserSvc } from 'backend/aaa/CurrentUserSvc'
import * as React from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { style } from 'typestyle'
import { WIconButton } from '../../../../../../wds/IconButton'
import { WIconDropdownMenu } from '../../../../../../wds/IconDropdownMenu'
import { WScrollBox } from '../../../../../../wds/ScrollBox'
import { EFontWeight } from '../../../../../../wds/styles/EFontWeight'
import { ColumnModel } from '../BoardSceneModel'
import { CardView } from './CardView'

export class ColumnView extends React.Component<ColumnViewProps, ColumnViewState> {

    state: ColumnViewState = {
        isColumnPopupOpen: false
    }

    private readonly handlePutyukaClicked = () => {
        this.setState({ isColumnPopupOpen: !this.state.isColumnPopupOpen })
    }

    private readonly handlePopupClosed = () => {
        this.setState({ isColumnPopupOpen: false })
    }

    private readonly handleDeleteColumn = () => {
        this.setState({ isColumnPopupOpen: false })
        this.props.onDeleteColumn()
    }

    render() {
        const opt = this.props.model.options
        var dragDisabled = false
        var dropDisabled = false
        if (opt != null && opt.actionOptions != null) {
            dragDisabled = opt.actionOptions.disableDrag
            dropDisabled = opt.actionOptions.disableDrop
        }
        return (
            <Draggable draggableId={this.props.model.id} index={this.props.index}>
                {(provided) => {
                    return (
                        <div
                            className={columnStyle}
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                        >
                            <div
                                {...provided.dragHandleProps}

                                className={headerStyle}
                            >
                                <div
                                    className={titleStyle}
                                    style={{ cursor: 'pointer' }}
                                    onClick={this.props.onTitleClick}
                                >
                                    {this.props.model.name}
                                </div>
                                <WIconButton icon="add" size="medium" color="primary" onClick={this.props.onAddCardClicked} />
                                <WIconDropdownMenu
                                    icon="more_vert"
                                    size="small"
                                    items={[
                                        { id: "mnu-settings", title: "Settings", onClick: this.props.onSettingsClicked },
                                        { id: "mnu-delete", title: "Delete column", onClick: this.handleDeleteColumn },
                                    ]}
                                />

                            </div>
                            <div className={cardListContainerStyle}>
                                <WScrollBox>
                                    <Droppable
                                        droppableId={this.props.model.id}
                                        type="card"
                                        isDropDisabled={dropDisabled}
                                    >
                                        {(provided) => {
                                            return <div className={cardListStyle} ref={provided.innerRef} {...provided.droppableProps}>
                                                {this.props.model.cards.filter(item => this.props.showMyCardsOnly ? item.members.indexOf(CurrentUserSvc.currentUser.id) > -1 : true).map((item, index) => {
                                                    return <CardView key={item.id} model={item} index={index} dragDisabled={dragDisabled} />
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        }}
                                    </Droppable>
                                </WScrollBox>
                            </div>
                        </div>
                    )
                }}
            </Draggable>
        )
    }

}

export interface ColumnViewProps {

    index: number
    model: ColumnModel
    showMyCardsOnly: boolean
    onTitleClick: () => void
    onAddCardClicked: () => void
    onDeleteColumn: () => void
    onSettingsClicked: () => void

}

interface ColumnViewState {

    isColumnPopupOpen: boolean

}

const columnStyle = style({
    backgroundColor: '#f2f1f6',
    width: '270px',
    minWidth: '270px',
    overflow: 'hidden',
    borderTop: `3px solid #61b8d1`,
    display: 'inline-flex',
    flexDirection: 'column',
    marginRight: '8px',
})

const headerStyle = style({
    width: '100&',
    padding: '8px',
    display: 'flex',
    alignItems: 'center'
})

const titleStyle = style({
    flex: '1',
    marginRight: '8px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '18px',
    fontWeight: EFontWeight.Semibold.value,
    color: '#333A6C',
})

const cardListContainerStyle = style({
    width: '100%',
    flex: '1',
    overflow: 'hidden',
})

const cardListStyle = style({
    width: '100%',
    minHeight: '100%',
    padding: '8px',
})