export class MiniCRM {

    private static API_URL: string = "https://r3.minicrm.hu/Api/Signup"
    private static FORM_HASH: string = "31177-0y2td20xc91dvlb3msfe"

    public static async registerUser(userName: string, email: string, companyName: string) {
        var body: string = `Contact[1780][Email]=${encodeURIComponent(email)}&Contact[1780][FirstName]=${encodeURIComponent(userName)}&Contact[1779][Name]=${encodeURIComponent(companyName)}&FormHash=${encodeURIComponent(this.FORM_HASH)}&SignupPage=${encodeURIComponent('https://r3.minicrm.hu/31177/')}`

        await fetch(this.API_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            },
            body: body
        })
    }

    private constructor() { }

}