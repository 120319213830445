import { Event } from "./Event";

export class Navigation {

    public static readonly onStateChanged: Event<void> = new Event<void>();

    public static init() {
        window.onpopstate = () => {
            this.fireEvent();
        }
    }

    public static pushState(data: any, url: string) {
        if (url == null) {
            throw new Error("The url parameter can not be null!");
        }

        window.history.pushState(data, "", url); 
        this.fireEvent();
    }

    public static replaceState(data: any, url: string) {
        if (url == null) {
            throw new Error("The url parameter can not be null!"); 
        }

        window.history.replaceState(data, "", url);
        this.fireEvent();
    }

    public static forward() {
        history.forward();
        this.fireEvent();
    }

    public static back() {
        history.back();
        this.fireEvent();
    }

    public static get currentState(): HistoryItem {
        return HistoryItem.current;
    }

    private static fireEvent() {
        this.onStateChanged.fire();
    }


    private constructor() {}

}

export class HistoryItem {

    public static get current(): HistoryItem {
        const path = window.location.pathname;
        const qStr = window.location.search;
        const data = history.state;

        const params = parseQuery(qStr);

        return new HistoryItem(data, path, params);
    }

    private _data: any;
    private _path: string;
    private _params: {[key: string]: string};

    public constructor(data: any, path: string, params: {[key: string]: string}) {
        this._data = data;
        this._path = path;
        this._params = params;
    }

    public get data(): any {
        return this._data;
    }

    public get path(): string {
        return this._path;
    }

    public get params(): {[key: string]: string} {
        return this._params;
    }

}

function parseQuery(input: string): {[key: string]: string} {
    var res: {[key: string]: string} = {};

    if (input == null) {
        return res;
    }

    input = input.trim();

    if (input.length < 1) {
        return res;
    }

    if (input.indexOf('?') == 0) {
        input = input.substring(1);
    }

    var params = input.split('&');
    for (var p of params) {
        var kv = p.split('=');
        if (kv.length != 2) {
            continue;
        }

        var key = kv[0];
        var value = kv[1];

        res[key] = value;
    }

    return res;
}