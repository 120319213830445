import * as React from 'react'
import { style } from 'typestyle'
import { EFontWeight } from 'wds/styles/EFontWeight'
import { StringUtils } from '../../../../../../../functions/src/shared/utils/StringUtils'
import { ProfilePicture } from '../../../../../components/ProfilePicture'

export class ConversationMetaInfo extends React.Component<ConversationMetaInfoProps> {

    render() {
        return (
            <div className={metaInfoStyle}>
                <ProfilePicture
                    name={this.props.sender.name}
                    backgroundColor={this.props.sender.color}
                    profilePictureUrl={this.props.sender.profilePictureUrl}
                    size={16}
                    borderWidth={0}
                />
                <div style={{ minWidth: '8px' }}></div>
                <div style={{ fontSize: '14px', fontWeight: EFontWeight.Bold.value, color: '#222' }}>{this.props.sender.name}</div>
                <div style={{ minWidth: '8px' }}></div>
                <div style={{ fontSize: '14px', fontWeight: EFontWeight.Regular.value, color: '#222', flex: '1' }}>{this.props.message}</div>
                <div style={{ minWidth: '4px' }}></div>
                <div style={{ fontSize: '12px', fontWeight: EFontWeight.Medium.value, color: '#888' }}>{StringUtils.convertTimestampToStr(this.props.sentAt)}</div>

            </div>
        )
    }

}

export interface ConversationMetaInfoProps {

    sender: ConversationMetaInfoSenderModel
    message: string
    sentAt: number

}

export interface ConversationMetaInfoSenderModel {

    name: string
    color: string
    profilePictureUrl: string

}

const metaInfoStyle = style({
    width: '100%',
    fontSize: '14px',
    fontWeight: EFontWeight.Medium.value,
    color: '#222',
    display: 'flex',
    alignItems: 'flex-start'
})
