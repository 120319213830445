import { BoardSvc } from "backend/projects/board/BoardService";
import "firebase/analytics";
import * as firebase from "firebase/app";
import * as React from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { IBoardColumnOptions } from "shared/entities/projects/IBoardData";
import { style } from 'typestyle';
import { FieldModel } from "../../../../../../components/FieldModel";
import { WButton } from '../../../../../../wds/Button';
import { Snackbar } from '../../../../../../wds/Snackbar';
import { WSwitch } from "../../../../../../wds/Switch";
import { BoardSceneController } from '../BoardSceneController';
import { BoardModel } from '../BoardSceneModel';
import { EditColumnSettingsDialog, IColumnSettingsDialogModel } from './columnSettingsDialog/EditColumnSettingsDialog';
import { ColumnView } from './ColumnView';
import { CreateCardDialog, ICreateCardDialogData } from './CreateCardDialog';

export class BoardView extends React.Component<BoardViewProps, BoardViewState> {

    public constructor(props: BoardViewProps) {
        super(props)

        this.state = {
            newCardDialogModel: null,
            showOnlyMyCards: false,
            columnSettingsDialogModel: null
        }
    }

    private readonly handleDragEnd = (result: DropResult) => {
        if (result.destination == null) {
            return
        }

        if (result.source.droppableId == result.destination.droppableId && result.source.index == result.destination.index) {
            return
        }

        if (result.type == "card") {
            this.props.controller.onCardDropped(result)
        } else if (result.type == "column") {
            this.props.controller.onColumnDropped(result)
        }
    }

    private readonly handleAddCardClicked = (columnId: string) => {
        this.setState({ newCardDialogModel: { columnId: columnId } })
        firebase.analytics().logEvent('projects.board.create_card_dialog.open')
    }

    private readonly handleAddCardCancelClicked = () => {
        this.setState({ newCardDialogModel: null })
        firebase.analytics().logEvent('projects.board.create_card_dialog.cancel')
    }

    private readonly handleNewCardSubmit = (columnId: string, data: ICreateCardDialogData): Promise<ICreateCardDialogData | undefined> => {
        this.setState({ newCardDialogModel: null })
        firebase.analytics().logEvent('projects.board.create_card_dialog.save')
        this.props.controller.onCreateNewCard(columnId, data.title, data.editAfterSave)
        return
    }

    private readonly handleOnlyMyCardsChanged = (newValue: boolean) => {
        this.setState({ showOnlyMyCards: newValue })
        firebase.analytics().logEvent('projects.board.show_only_my_cards.' + newValue)
    }

    private readonly handleColumnSettingsClicked = (columnId: string) => {
        this.setState({
            columnSettingsDialogModel: {
                currentBoardId: this.props.model.id,
                currentColumnId: columnId,
                options: this.props.model.colums[this.props.model.columnIndexOf(columnId)].options,
                columns: this.props.model.colums
            }
        })

        firebase.analytics().logEvent('projects.board.column_settings_dialog.open')
    }

    private readonly handleColumnOptionsCancelClick = () => {
        this.setState({ columnSettingsDialogModel: null })
        firebase.analytics().logEvent('projects.board.column_settings_dialog.cancel')
    }

    private readonly handleColumnOptionsSaveClick = async (newOptions: IBoardColumnOptions) => {
        const colId = this.state.columnSettingsDialogModel.currentColumnId
        this.setState({ columnSettingsDialogModel: null })
        try {
            await BoardSvc.changeColumnOptions(this.props.model.id, colId, newOptions)
            firebase.analytics().logEvent('projects.board.column_settings_dialog.save')
        } catch (ex) {
            var msg = "Save column options faild: " + ex.message
            Snackbar.show('danger', msg)
        }
    }


    render() {
        return (
            <>
                <DragDropContext onDragEnd={this.handleDragEnd}>
                    <Droppable
                        droppableId="all-columns"
                        direction="horizontal"
                        type="column"
                    >
                        {(provided) => {
                            return (
                                <div
                                    className={containerStyle}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {this.props.model.colums.map((item, index) => {
                                        return <ColumnView
                                            key={item.id}
                                            model={item}
                                            index={index}
                                            showMyCardsOnly={this.state.showOnlyMyCards}
                                            onTitleClick={() => { this.props.controller.onEditColumnNameClicked(item.id) }}
                                            onAddCardClicked={() => { this.handleAddCardClicked(item.id) }}
                                            onDeleteColumn={() => { this.props.controller.onDeleteColumn(item.id) }}
                                            onSettingsClicked={() => { this.handleColumnSettingsClicked(item.id) }}
                                        />
                                    })}
                                    {provided.placeholder}
                                    <WButton title="" icon="add" size="small" color="default" onClick={this.props.controller.onAddColumnClicked} />
                                </div>
                            )
                        }}
                    </Droppable>
                </DragDropContext>

                {this.state.newCardDialogModel != null &&
                    <CreateCardDialog
                        onSubmit={(data) => this.handleNewCardSubmit(this.state.newCardDialogModel.columnId, data)}
                        onCancel={this.handleAddCardCancelClicked}
                    />
                }

                {this.state.columnSettingsDialogModel != null &&
                    <EditColumnSettingsDialog
                        model={this.state.columnSettingsDialogModel}
                        onCancel={this.handleColumnOptionsCancelClick}
                        onSave={this.handleColumnOptionsSaveClick}
                    />
                }

                <div style={{ position: 'fixed', left: '220px', top: '64px', right: '280px', height: '64px', padding: '16px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ boxSizing: 'initial' }}>
                        <WSwitch model={new FieldModel(this.state.showOnlyMyCards)} label="Show only my cards" labelPlacement="start" onChange={this.handleOnlyMyCardsChanged} />
                    </div>
                </div>
            </>
        )
    }

}

export interface BoardViewProps {

    model: BoardModel
    controller: BoardSceneController

}

interface BoardViewState {

    newCardDialogModel: INewCardDialogModel
    columnSettingsDialogModel: IColumnSettingsDialogModel
    showOnlyMyCards: boolean

}

interface INewCardDialogModel {
    columnId: string
}

const containerStyle = style({
    display: 'flex',
    minWidth: '100%',
    height: '100%',
    overflowY: 'hidden',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '16px',
    paddingTop: '56px',
})