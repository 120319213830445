import * as React from 'react';
import { ModalButton, WModal } from 'wds/Modal';
import { WTextField } from 'wds/TextField';
import { ProjectBoardListSceneController } from './ProjectBoardListSceneController';
import { CreateNewBoardDialogModel } from './ProjectsBoardListSceneModel';

export class CreateNewBoardDialog extends React.Component<CreateNewBoardDialogProps, CreateNewBoardDialogState> {

    private readonly handleCancelClick = () => {
        this.props.controller.onCreateNewBoardCancelClick()
    }

    private readonly handleCreateClick = () => {
        this.props.controller.onCreateNewBoardCreateClick()
    }

    public render() {
        return (
            <WModal
                title="Create new board"
                error={this.props.model.error}
                isLoading={this.props.model.isLoading}
                onSubmit={this.handleCreateClick}
                buttons={[
                    new ModalButton("Cancel", this.handleCancelClick, "text"),
                    new ModalButton("Create", null, "contained", "primary"),
                ]}
            >
                <WTextField
                    label="Name"
                    model={this.props.model.nameModel}
                    helperText="The name of the new board."
                    autoFocus
                />
            </WModal>
        )
    }

}

export interface CreateNewBoardDialogProps {

    model: CreateNewBoardDialogModel
    controller: ProjectBoardListSceneController

}

interface CreateNewBoardDialogState {

}

