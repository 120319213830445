import * as React from 'react'
import { IdGenerator } from 'utils/IdGenerator'
import { WLinkButton } from './LinkButton'

export class FileSelectButton extends React.Component<FileSelectButtonProps> {

    private id: string = "fu-" + IdGenerator.getNextId()

    private readonly handleButtonClick = () => {
        (document.getElementById(this.id)!! as HTMLInputElement).click()
    }

    private readonly handleFileChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        var fu = document.getElementById(this.id) as HTMLInputElement
        var f: File = null
        if (fu.files != null && fu.files.length > 0) {
            f = fu.files.item(0)
        }

        this.props.onChange(f)
    }


    render() {
        return (
            <>
                <input id={this.id} type="file" accept=".png,.jpg" style={{ display: "none" }} onChange={this.handleFileChange} />
                <WLinkButton title="Change" onClick={this.handleButtonClick} />
            </>
        )
    }

}

export interface FileSelectButtonProps {

    title: string,
    onChange: (file: File) => void

}