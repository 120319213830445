import * as React from "react"
import { style } from "typestyle"
import { IdGenerator } from "utils/IdGenerator"

export class WScrollBox extends React.Component<WScrollBoxProps, WScrollBoxState> {

    private readonly id = "sb-" + IdGenerator.getNextId()
    private sc: HTMLDivElement = null
    private isBottom: boolean = true
    private updateTimerHandler: number = null
    private prevScrollHeight: number = -1

    public constructor(props: WScrollBoxProps) {
        super(props)

        this.state = {
            buttonHeight: 100,
            buttonTop: 0,
            buttonVisible: false
        }
    }

    componentDidMount() {


    }

    componentWillUnmount() {

    }

    private readonly scrollToBottom = () => {
        if (this.sc == null) {
            this.sc = document.getElementById(this.id) as HTMLDivElement
            if (this.sc == null) {
                return
            }
        }
        console.log("scroll to bottom")
        if (this.props.stayOnBottom) {
            if (this.sc.scrollTop < this.sc.scrollHeight - this.sc.clientHeight) {
                this.sc.scrollTop = this.sc.scrollHeight - this.sc.clientHeight
                this.isBottom = true
                this.updateScrollThumb()
            }
        }
    }

    private readonly handleScroll = () => {
        window.setTimeout(() => {
            if (this.sc == null) {
                this.sc = document.getElementById(this.id) as HTMLDivElement
                if (this.sc == null) {
                    return
                }
            }

            if (this.prevScrollHeight != this.sc.scrollHeight) {
                // size changed
                console.log("size changed")
                this.prevScrollHeight = this.sc.scrollHeight
                this.scrollToBottom()
            } else {
                // user scrolled
                console.log("user scroll")
                this.isBottom = this.sc.scrollTop >= (this.sc.scrollHeight - this.sc.clientHeight) - 16
                console.log(this.isBottom)
                this.updateScrollThumb()
            }
        }, 10)
    }

    private updateScrollThumb() {
        if (this.sc == null) {
            this.sc = document.getElementById(this.id) as HTMLDivElement
            if (this.sc == null) {
                return
            }
        }

        var bh = this.sc.clientHeight * (this.sc.clientHeight / this.sc.scrollHeight)
        const bt = this.sc.clientHeight * (this.sc.scrollTop / this.sc.scrollHeight)
        const bv = this.sc.scrollHeight > this.sc.clientHeight
        if (bh != this.state.buttonHeight || bt != this.state.buttonTop || this.state.buttonVisible != bv) {
            this.setState({
                buttonHeight: bh,
                buttonTop: bt,
                buttonVisible: bv
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps != this.props) {
            window.setTimeout(() => {
                this.scrollToBottom()
            }, 100)
        }
    }

    render() {

        return (
            <div style={{ width: "100%", height: "100%", overflow: 'hidden', position: 'relative' }}>
                <div
                    id={this.id}
                    className={scrollBarStyle}
                    style={{
                        width: "200%",
                        height: '100%',
                        overflowX: 'hidden',
                        overflowY: 'scroll'
                    }}
                    onScroll={this.handleScroll}
                >
                    <div style={{ width: '50%', maxWidth: '50%', overflow: 'visible' }}>
                        {this.props.children}
                    </div>
                </div>
                {this.state.buttonVisible &&
                    <div style={{ width: '2px', height: `${this.state.buttonHeight}px`, top: `${this.state.buttonTop}px`, right: '2px', position: 'absolute', backgroundColor: 'rgba(0,0,0, 0.5)' }}></div>
                }
            </div>
        )
        // return (
        //     <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
        //         <SimpleBar id={this.id} style={{ width: "100%", maxWidth: "100%", height: "100%", maxHeight: "100%" }}>
        //             {this.props.children}
        //         </SimpleBar>
        //     </div>
        // )
    }

}

export interface WScrollBoxProps {
    stayOnBottom?: boolean

}

export interface WScrollBoxState {
    buttonVisible: boolean
    buttonTop: number,
    buttonHeight: number
}

// interface ScrollTumbProps {
//     updateReg: (fun: (top: number, height: number) => void) => void
// }

const scrollBarStyle = style({
    "-ms-overflow-style": "none",
    $nest: {
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    }
})