export class IdGenerator {

    private static nextId: number = 0

    public static getNextId() {
        return this.nextId++
    }

    private constructor() { }

}