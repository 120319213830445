import { AController } from "@eagerlogic/react-mvc";
import { BoardSvc } from "backend/projects/board/BoardService";
import "firebase/analytics";
import * as firebase from "firebase/app";
import { DropResult } from "react-beautiful-dnd";
import { IBoardData } from "shared/entities/projects/IBoardData";
import { IWib } from "shared/entities/wib/IWib";
import { ICardWibContent } from "shared/entities/wib/wibs/ICardWibContentType";
import { ChangeListenerCanceler, FsEntity } from "utils/Fs";
import { v4 as uuidv4 } from 'uuid';
import { Snackbar } from "../../../../../wds/Snackbar";
import { WibSidebar } from "../../../../../wibib-sdk-fe/WibSidebar";
import { AddColumnDialogModel, BoardModel, BoardSceneModel, CardModel, ColumnModel, EditColumnNameDialogModel } from "./BoardSceneModel";
import { ICreateCardDialogData } from "./components/CreateCardDialog";

export class BoardSceneController extends AController<BoardSceneModel> {

    private board: FsEntity<IBoardData> = null
    private boardListenerCanceler: ChangeListenerCanceler = null
    private cards: firebase.firestore.QuerySnapshot<IWib<ICardWibContent>> = null
    private cardsListenerCanceler: ChangeListenerCanceler = null

    public constructor(
        public readonly boardId: string
    ) {
        super()
    }

    protected createInitialModel(): BoardSceneModel {
        return new BoardSceneModel()
    }

    async init() {
        firebase.analytics().logEvent('projects.board.open')
        this.board = await BoardSvc.getBoardListener(this.boardId)
        this.boardListenerCanceler = this.board.addChangeListener((boardSnapshot) => {
            this.board = boardSnapshot
            this.refresh()
        })

        const cardsListener = await BoardSvc.getCardsListener(this.boardId)

        this.cardsListenerCanceler = cardsListener.addChangeListener((snapshot) => {
            this.cards = snapshot
            this.refresh()
        })

        this.refresh()

    }

    public readonly destory = () => {
        if (this.boardListenerCanceler != null) {
            this.boardListenerCanceler.cancel()
        }

        if (this.cardsListenerCanceler != null) {
            this.cardsListenerCanceler.cancel()
        }
    }

    public readonly onDeleteColumn = async (columnId: string) => {
        if (!confirm("Are you sure yu want to delete this column? This will also delet all of it's cards!")) {
            return
        }

        this.model.boardModel.colums = this.model.boardModel.colums.filter(col => col.id != columnId)
        this.render(this.model)

        try {
            await BoardSvc.deleteColumn(this.boardId, columnId)

            firebase.analytics().logEvent("projects.board.delete_column")
        } catch (ex) {
            var msg = ex.message ? "" + ex.message : "Internal server error occured!"
            Snackbar.show("danger", "Delete column failed: " + msg)
            this.refresh()
        }
    }

    public readonly onEditColumnNameClicked = (columnId: string) => {
        const colIdx = this.model.boardModel.columnIndexOf(columnId)
        const currentName = this.model.boardModel.colums[colIdx].name
        this.model.editColumnNameDialogModel = new EditColumnNameDialogModel(columnId, currentName)
        this.render(this.model)
        firebase.analytics().logEvent('projects.board.edit_column_name_dialog.open')
    }

    public readonly onEditColumnNameDialogCancelClicked = () => {
        this.model.editColumnNameDialogModel = null
        this.render(this.model)
        firebase.analytics().logEvent('projects.board.edit_column_name_dialog.cancel')
    }

    public readonly onEditColumnNameDialogSaveClicked = async () => {
        const colIdx = this.model.boardModel.columnIndexOf(this.model.editColumnNameDialogModel.columnId)
        const col = this.model.boardModel.colums[colIdx]

        this.model.editColumnNameDialogModel.nameModel.error = null
        this.render(this.model)

        var name = this.model.editColumnNameDialogModel.nameModel.value
        if (name == null) {
            name = ""
        }
        name = name.trim()

        if (name.length < 1) {
            this.model.editColumnNameDialogModel.nameModel.error = "The name can not be empty!"
            this.render(this.model)
            return
        }

        col.name = name
        this.model.editColumnNameDialogModel = null
        this.render(this.model)

        try {
            await BoardSvc.changeColumnName(this.boardId, col.id, name)
            firebase.analytics().logEvent("projects.board.edit_column_name_dialog.save")
        } catch (ex) {
            var msg = ex.message ? "" + ex.message : "Internal server error occured!"
            Snackbar.show("danger", "Rename column failed: " + msg)
            this.refresh()
        }
    }

    public readonly onAddColumnClicked = () => {
        this.model.addColumnDialogModel = new AddColumnDialogModel()
        this.render(this.model)
        firebase.analytics().logEvent('projects.board.add_column_dialog.open')
    }

    public readonly onAddColumnDialogCancelClicked = () => {
        this.model.addColumnDialogModel = null
        this.render(this.model)
        firebase.analytics().logEvent('projects.board.add_column_dialog.cancel')
    }

    public readonly onAddColumnDialogSaveClicked = async () => {
        this.model.addColumnDialogModel.nameModel.error = null
        this.render(this.model)

        var name = this.model.addColumnDialogModel.nameModel.value
        if (name == null) {
            name = ""
        }
        name = name.trim()

        if (name.length < 1) {
            this.model.addColumnDialogModel.nameModel.error = "This field is mandatory!"
            this.render(this.model)
            return
        }

        const colId = uuidv4()

        // insert local
        this.model.boardModel.colums.push(
            new ColumnModel(
                colId,
                name,
                [],
                null
            )
        )

        this.model.addColumnDialogModel = null

        this.render(this.model)

        try {
            await BoardSvc.createColumn(this.boardId, colId, name)
            firebase.analytics().logEvent('projects.board.add_column_dialog.save')
        } catch (ex) {
            var msg = ex.message ? "" + ex.message : "Internal server error occured!"
            Snackbar.show("danger", "Create column failed: " + msg)
            this.refresh()
        }
    }

    public readonly onCreateNewCard = async (columnId: string, title: string, editAfterCreate: boolean): Promise<ICreateCardDialogData | undefined> => {
        // const col = this.model.boardModel.colums.find(col => col.id == columnId)
        // col.cards.splice(0, 0, new CardModel(uuidv4(), title, []))
        // this.render(this.model)


        try {
            var res = await BoardSvc.createNewCard(this.boardId, title, columnId)
            firebase.analytics().logEvent('projects.board.new_card_created')
            if (editAfterCreate) {
                WibSidebar.showWib(res)
            }
        } catch (ex) {
            var msg = ex.message ? ex.message.toString() : "Internal error occured!"
            Snackbar.show("danger", "Create card failed: " + msg)
            this.refresh()
        }
        return
    }

    public readonly onCardDropped = async (result: DropResult) => {
        const srcColIdx = this.model.boardModel.columnIndexOf(result.source.droppableId)
        const srcCol = this.model.boardModel.colums[srcColIdx]
        const dstColIdx = this.model.boardModel.columnIndexOf(result.destination.droppableId)
        const dstCol = this.model.boardModel.colums[dstColIdx]
        const movedCard = srcCol.cards[result.source.index]
        srcCol.cards.splice(result.source.index, 1)
        dstCol.cards.splice(result.destination.index, 0, movedCard)

        this.render(this.model)

        try {
            await BoardSvc.moveCard(movedCard.id, this.boardId, this.boardId, dstCol.id, result.destination.index)
            firebase.analytics().logEvent("projects.board.move_card")
        } catch (ex) {
            var msg = ex.message ? "" + ex.message : "Internal server error occured!"
            Snackbar.show("danger", "Move card failed: " + msg)
            this.refresh()
            console.error(ex)
        }
    }

    public readonly onColumnDropped = async (result: DropResult) => {

        const movedColumn = this.model.boardModel.colums[result.source.index]
        this.model.boardModel.colums.splice(result.source.index, 1)
        this.model.boardModel.colums.splice(result.destination.index, 0, movedColumn)

        var nextColumnId: string = null
        if (result.destination.index + 1 < this.model.boardModel.colums.length) {
            nextColumnId = this.model.boardModel.colums[result.destination.index + 1].id
        }

        this.render(this.model)

        try {
            await BoardSvc.moveColumn(this.boardId, movedColumn.id, nextColumnId)
            firebase.analytics().logEvent("projects.board.move_column")
        } catch (ex) {
            var msg = ex.message ? "" + ex.message : "Internal server error occured!"
            Snackbar.show("danger", "Move column failed: " + msg)
            this.refresh()
        }
    }

    private refresh() {
        this.model.isLoading = true
        if (this.board == null || this.cards == null) {
            this.render(this.model)
            return
        }

        this.model.isLoading = false

        this.model.boardModel = new BoardModel(
            this.board.id,
            this.board.data.name,
            this.board.data.columns.map((col) => {
                return new ColumnModel(
                    col.id,
                    col.name,
                    col.cards.filter((c) => this.cards.docs.find((card) => card.id == c) != null).map((cardId) => {
                        const card = this.cards.docs.find((card) => card.id == cardId)
                        return new CardModel(
                            card.id, card.data().subject, card.data().members
                        )
                    }),
                    col.options
                )
            })
        )

        this.render(this.model)
    }

}