import { AController } from "@eagerlogic/react-mvc";
import { CurrentUserSvc } from "backend/aaa/CurrentUserSvc";
import 'firebase/analytics';
import * as firebase from "firebase/app";
import 'firebase/storage';
import { ChangeListenerCanceler } from "utils/Fs";
import { ProfileSettingsSceneModel } from "./ProfileSettingsSceneModel";

export class ProfileSettingsSceneController extends AController<ProfileSettingsSceneModel> {

    private changeListenerCanceler: ChangeListenerCanceler

    protected createInitialModel(): ProfileSettingsSceneModel {
        var res = new ProfileSettingsSceneModel()
        this.updateModel(res)
        return res
    }

    init() {
        this.changeListenerCanceler = CurrentUserSvc.currentUser.addChangeListener(this.onProfileChange)
        firebase.analytics().logEvent('profile_setttings.open')
    }

    public destroy() {
        this.changeListenerCanceler.cancel()
    }

    public readonly handleNewPictureSelected = async (file: File) => {
        if (file == null) {
            return
        }
        this.model.hasUploadError = false
        this.model.isUploading = true
        this.render(this.model)

        var ppid = `${CurrentUserSvc.currentUser.id}-${Date.now()}.jpg`
        try {
            var resizedFile = await this.resizeImage(file, ppid)
            var imgRef = firebase.storage().ref().child(`pp/${ppid}`)
            await imgRef.put(resizedFile)
            var user = CurrentUserSvc.currentUser
            var ppUrl: string = await new Promise<string>((resolve, reject) => {
                imgRef.getDownloadURL().then((url) => {
                    resolve(url)
                }).catch(() => {
                    reject(Error("Get download URL failed!"))
                })
            })
            await user.snapshot.ref.update({ profilePictureUrl: ppUrl })
            firebase.analytics().logEvent('profile_settings.new_picture_uploaded')
        } catch (e) {
            console.error(e)
            this.model.hasUploadError = true
        } finally {
            this.model.isUploading = false
        }
        this.render(this.model)
    }

    private readonly onProfileChange = () => {
        this.updateModel(this.model)
        this.render(this.model)
    }

    private updateModel(model: ProfileSettingsSceneModel) {
        model.userName = CurrentUserSvc.currentUser.data.name
        model.profilePictureUrl = CurrentUserSvc.currentUser.data.profilePictureUrl
    }

    private readonly resizeImage = async (file: File, filename: string): Promise<File> => {
        return new Promise<File>((resolver, rejecter) => {
            var reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onerror = () => {
                rejecter(Error("Read image failed!"))
                return
            }
            reader.onload = (evt) => {
                const img = new Image()
                img.src = evt.target.result as string
                img.onerror = () => {
                    rejecter(Error("Open image failed!"))
                    return
                }
                img.onload = () => {
                    const canvas = document.createElement("canvas") as HTMLCanvasElement
                    canvas.width = 128
                    canvas.height = 128
                    const ctx = canvas.getContext("2d")

                    var l = 0
                    var t = 0
                    var w = 0
                    var h = 0

                    var imgRatio = img.width / img.height
                    if (imgRatio > 1) {
                        var scale = canvas.height / img.height
                        var nImgWidth = img.width * scale
                        var nImgHeight = img.height * scale
                        ctx.drawImage(img, -((nImgWidth - canvas.width) / 2), 0, nImgWidth, nImgHeight)
                    } else if (imgRatio < 1) {
                        var scale = canvas.width / img.width
                        var nImgWidth = img.width * scale
                        var nImgHeight = img.height * scale
                        ctx.drawImage(img, 0, -((nImgHeight - canvas.height) / 2), nImgWidth, nImgHeight)
                    } else {
                        ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
                    }

                    ctx.canvas.toBlob((blob) => {
                        var res = new File([blob], filename, {
                            type: 'image/jpeg',
                            lastModified: Date.now()
                        })
                        resolver(res)
                    }, "image/jpeg", 1)
                }
            }
        })


    }

}