import InlineEdit from '@atlaskit/inline-edit';
import Textfield from '@atlaskit/textfield';
import * as React from 'react';
import { style } from 'typestyle';
import { WDropTarget } from 'wds/DropTarget';
import { HorizontalLoadingIndicator } from 'wds/HorizontalLoadingIndicator';
import { WIcon } from 'wds/Icon';
import { WIconButton } from 'wds/IconButton';
import { EFontWeight } from 'wds/styles/EFontWeight';
import { MenuItem } from '../../../../../wds/DropdownMenu';
import { WIconDropdownMenu } from '../../../../../wds/IconDropdownMenu';
import { Snackbar } from '../../../../../wds/Snackbar';

export class BaseCommLayout extends React.Component<BaseCommLayoutProps, BaseCommLayoutState> {

    state: BaseCommLayoutState = {
        title: this.props.title
    }

    private readonly handleCloseClick = () => {
        this.props.onCloseClick()
    }

    private readonly handleTitleChanged = (newValue: string) => {
        if (newValue == null) {
            newValue = ""
        }
        newValue = newValue.trim()

        if (newValue.length < 1) {
            Snackbar.show("danger", "The subject can not be empty!")
            return "This field is mandatory"
        }
        if (newValue.length > 64) {
            Snackbar.show("danger", "The subject can be maximum 64 characters!")
            return "Maximum 64 characters"
        }
        this.setState({ title: newValue })
        this.props.onTitleChanged(newValue)
    }

    private readonly validateTitle = (newValue: string): string | undefined => {
        if (newValue == null) {
            newValue = ""
        }
        newValue = newValue.trim()

        if (newValue.length < 1) {
            return "This field is mandatory"
        }
        if (newValue.length > 64) {
            return "Maximum 64 characters"
        }

        return
    }

    private readonly handleDrop = (evt: React.DragEvent<HTMLDivElement>) => {
        if (this.props.onFileDropped != null) {
            this.props.onFileDropped(evt)
        }
    }

    render() {
        var sidebar: React.ReactNode = null
        if (this.props.rightSideBarRenderer != null) {
            sidebar = this.props.rightSideBarRenderer()
        }
        return (
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    flexDirection: 'column'
                }}
            >
                {/* header */}
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        height: '64px',
                        minHeight: '64px',
                        backgroundColor: '#f4f4f4',
                        borderBottom: '1px solid #ccc',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        alignItems: 'center'
                    }}
                >
                    {/* icon */}
                    <div
                        style={{
                            width: '32px',
                            height: '32px',
                            backgroundColor: this.props.iconColor,
                            borderRadius: '50%',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <WIcon icon={this.props.icon} color="#fff" size="18px" />
                    </div>

                    <div style={{ width: '12px' }} />

                    {/* title */}
                    {this.props.onTitleChanged == null &&
                        <div
                            style={{
                                flex: '1',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                fontSize: '18px',
                                fontWeight: EFontWeight.Medium.value,
                                color: "#222"
                            }}
                        >
                            {this.state.title}
                        </div>
                    }

                    {this.props.onTitleChanged != null &&
                        <InlineEdit
                            defaultValue={this.state.title}
                            editView={fieldProps => <div style={{ marginTop: '-8px' }}><Textfield {...fieldProps} autoFocus minLength={1} maxLength={64} /></div>}
                            hideActionButtons
                            readView={() => (
                                <div
                                    style={{
                                        flex: '1',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        fontSize: '18px',
                                        fontWeight: EFontWeight.Medium.value,
                                        color: "#222",
                                        marginTop: '-8px'
                                    }}
                                >
                                    {this.state.title}
                                </div>
                            )}
                            onConfirm={this.handleTitleChanged}
                        />
                    }

                    {this.props.buttons.map((item, index) => {
                        return (
                            <HeaderButtonView key={'hbtn-' + index} model={item} />
                        )
                    })}

                    {this.props.moreMenuItems != null && this.props.moreMenuItems.length > 0 &&
                        <>
                            <div style={{ width: '8px' }}></div>
                            <WIconDropdownMenu icon="more_vert" items={this.props.moreMenuItems} />
                        </>
                    }

                    <div style={{ width: '12px' }} />

                    <WIconButton icon="close" size="medium" onClick={this.handleCloseClick} />

                </div>

                {this.props.isLoading &&
                    <HorizontalLoadingIndicator height="2px" />
                }

                {!this.props.isLoading &&
                    <WDropTarget
                        onDrop={this.handleDrop}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                overflow: 'hidden',
                                display: 'flex',
                                position: 'relative'
                            }}
                        >

                            {/* content */}
                            <div
                                style={{
                                    flex: 1,
                                    height: '100%',
                                    overflow: 'hidden'
                                }}
                            >

                                {this.props.children}
                            </div>

                            {/* right side bar */}
                            {sidebar != null &&
                                <div
                                    style={{
                                        width: '260px',
                                        height: '100%',
                                        overflow: 'hidden',
                                        backgroundColor: '#fff',
                                        borderLeft: '1px solid #ddd'
                                    }}
                                >
                                    {this.props.rightSideBarRenderer()}
                                </div>
                            }
                        </div>
                    </WDropTarget>
                }

            </div>
        )
    }

}

export interface BaseCommLayoutProps {

    title: string,
    icon: string,
    iconColor: string,
    isLoading: boolean
    buttons: HeaderButton[],
    moreMenuItems: MenuItem[],
    rightSideBarRenderer?: () => React.ReactNode
    onCloseClick: () => void
    onTitleChanged?: (newValue: string) => void
    onFileDropped: (evt: React.DragEvent<HTMLDivElement>) => void

}

interface BaseCommLayoutState {

    title: string

}

export class HeaderButton {

    public constructor(
        public readonly icon: string,
        public readonly color: string,
        public readonly title: string,
        public readonly type: "filled" | 'outlined',
        public readonly isDisabled: boolean,
        public readonly onClick: () => void,

    ) { }

}

class HeaderButtonView extends React.Component<{ model: HeaderButton }> {

    render() {
        var bgColor = "#fff"
        var borderColor = this.props.model.color
        var iconColor = this.props.model.color
        var cursor = 'pointer'
        if (this.props.model.type == "filled") {
            bgColor = this.props.model.color
            iconColor = "#fff"
        }
        if (this.props.model.isDisabled) {
            bgColor = "#eee"
            borderColor = "#ccc"
            iconColor = "#ccc"
            cursor = null
            if (this.props.model.type == 'filled') {
                bgColor = '#ccc'
                iconColor = '#eee'
            }
        }
        return (
            <div style={{ display: 'inline-flex', paddingLeft: '12px' }}>
                <div
                    className={headerButtonRootStyle}
                    style={{
                        backgroundColor: bgColor,
                        border: `2px solid ${borderColor}`,
                        cursor: cursor
                    }}
                    title={this.props.model.title}
                    onClick={this.props.model.isDisabled ? null : this.props.model.onClick}
                >
                    <WIcon icon={this.props.model.icon} color={iconColor} size="16px" />
                </div>
            </div>
        )
    }

}

const headerButtonRootStyle = style({
    width: '32px',
    minWidth: '32px',
    height: '32px',
    minHeight: '32px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    $nest: {
        '&:hover': {
            opacity: 0.5
        }
    }
})

const dragOverClass = style({
    opacity: 0.5
})