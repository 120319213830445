import { CurrentOrgSvc } from "backend/aaa/CurrentOrgSvc"
import { IBoardData } from "shared/entities/projects/IBoardData"
import { transact } from "utils/Fs"

export const moveColumnFun = async (boardId: string, colId: string, nextColId: string) => {
    if (colId == nextColId) {
        throw new Error("colId and nextColId can not be the same")
    }
    await transact(async (t) => {
        const boardRef = CurrentOrgSvc.currentOrg.snapshot.ref.collection("Boards").doc(boardId)
        const boardEntity = await t.get(boardRef)
        if (!boardEntity.exists) {
            throw new Error("Can't find board with the given id!")
        }
        const boardData = boardEntity.data() as IBoardData
        const colIdx = boardData.columns.findIndex((item) => {
            return item.id == colId
        })
        if (colIdx < 0) {
            throw new Error("Can't find column with the given id!")
        }
        var nextColIdx = -1
        if (nextColId != null) {
            nextColIdx = boardData.columns.findIndex((item) => {
                return item.id == nextColId
            })
            if (nextColIdx < 0) {
                throw new Error("Can't find next column with the given id!")
            }
        }
        const col = boardData.columns.splice(colIdx, 1)[0]

        if (nextColId == null) {
            boardData.columns.push(col)
        } else {
            nextColIdx = boardData.columns.findIndex((item) => {
                return item.id == nextColId
            })
            if (nextColIdx < 0) {
                throw new Error("Can't find next column with the given id!")
            }
            boardData.columns.splice(nextColIdx, 0, col)
        }

        t.update(boardRef, {
            columns: boardData.columns
        })

    }, null)
}