export const VER_Board = 1

export interface IBoardData {

    version: number
    name: string
    createdBy: string
    columns: IBoardColumn[]
    createdAt: number
    color: string
    deleted: boolean

}

export interface IBoardColumn {
    id: string
    name: string
    cards: string[]
    options: IBoardColumnOptions
}

export interface IBoardColumnOptions {

    actionOptions: IBoardColumnActionsOptions
    memberOptions: IBoardColumnMemberOptions

}

export interface IBoardColumnActionsOptions {

    disableDrag: boolean
    disableDrop: boolean
    disableCreate: boolean
    actions: IBoardColumnAction[]

}

export interface IBoardColumnAction {
    id: string
    title: string
    color: string
    targetBoardId: string
    targetColumnId: string
}

export interface IBoardColumnMemberOptions {

    autoMembers: IBoardColumnAutoMemberOptionItem[]

}

export interface IBoardColumnAutoMemberOptionItem {
    userId: string,
    isAssignee: boolean
}