import * as React from 'react'

export class WDropTarget extends React.Component<WDropTargetProps, WDropTargetState> {

    private constructor(props: WDropTargetProps) {
        super(props)
        this.state = {
            isDragOver: false
        }
    }

    private readonly handleDragOver = (evt: React.DragEvent<HTMLDivElement>) => {
        if (!this.state.isDragOver) {
            this.setState({ isDragOver: true })
        }
        evt.preventDefault()
        evt.stopPropagation()

    }

    private readonly handleDragLeave = (evt: React.DragEvent<HTMLDivElement>) => {
        this.setState({ isDragOver: false })
        evt.preventDefault()
        evt.stopPropagation()
    }

    private readonly handleDrop = (evt: React.DragEvent<HTMLDivElement>) => {
        this.setState({ isDragOver: false })
        evt.preventDefault()
        evt.stopPropagation()
        this.props.onDrop(evt)
    }

    render() {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden'
                }}
                onDragOver={this.handleDragOver}
                onDrop={this.handleDrop}
                onDragLeave={this.handleDragLeave}
            >

                {this.state.isDragOver &&
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            padding: '16px',
                            backgroundColor: '#fff',
                            pointerEvents: 'none'
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                border: '8px dashed #888',
                                borderRadius: '32px',
                                fontSize: '24px',
                                fontWeight: 900,
                                color: '#888',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            Drop files here
                        </div>
                    </div>
                }

                {!this.state.isDragOver && this.props.children}
            </div>
        )
    }

}

export interface WDropTargetProps {
    onDrop: (evt: React.DragEvent<HTMLDivElement>) => void
}

interface WDropTargetState {
    isDragOver: boolean
}