import { Icon, IconButton } from '@material-ui/core'
import * as React from 'react'

export class WIconButton extends React.Component<WIconButtonProps, WIconButtonState> {

    public constructor(props: WIconButtonProps) {
        super(props)
    }

    render() {
        return (
            <IconButton color={this.props.color} disabled={this.props.disabled} size={this.props.size} onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                if (this.props.onClick != null) {
                    this.props.onClick()
                }
            }}>
                <Icon color={this.props.color} fontSize={this.props.size == 'small' ? 'small' : 'default'}>{this.props.icon}</Icon>
            </IconButton>
        )
    }

}

export interface WIconButtonProps {
    icon: string
    color?: "primary" | "secondary"
    size?: "small" | "medium"
    disabled?: boolean
    onClick?: () => void

}

interface WIconButtonState {
}