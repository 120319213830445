export class FieldModel<$Value> {

    public error: string = null

    public constructor(public value: $Value, error?: string) {
        if (error != null) {
            this.error = error
        }
    }

}