import * as React from 'react'
import { WIcon } from 'wds/Icon'
import { EFontWeight } from 'wds/styles/EFontWeight'

export class ProfilePicture extends React.Component<ProfilePictureProps> {

    render() {
        var borderWidth = 3
        var borderColor = "#0bd"
        var innerSize = this.props.size - borderWidth * 2
        var backgroundColor = this.props.backgroundColor
        if (backgroundColor == null) {
            backgroundColor = "#0ac"
        }
        if (this.props.borderWidth != null) {
            borderWidth = this.props.borderWidth
            innerSize = this.props.size - borderWidth * 2
        }
        if (this.props.borderColor != null) {
            borderColor = this.props.borderColor
        }

        var names = this.props.name.trim().split(" ")
        var mg = ""
        for (var i = 0; mg.length < 2 && i < names.length; i++) {
            if (names[i].length < 1) {
                continue
            }
            mg += names[i].charAt(0).toUpperCase()
        }

        if (this.props.profilePictureUrl == null) {
            return (
                <div
                    title={this.props.popupText}
                    style={{
                        width: this.props.size + "px",
                        height: this.props.size + "px",
                        backgroundColor: backgroundColor,
                        border: borderWidth > 0 ? `${borderWidth}px solid ${borderColor}` : "none",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: 'relative',
                        cursor: 'default'
                    }}
                >
                    <div
                        style={{
                            color: "#fff",
                            fontSize: `${(innerSize * 0.4) | 0}px`,
                            fontWeight: EFontWeight.Bold.value
                        }}
                    >
                        {mg}
                    </div>

                    {this.props.topLeftBadge != null &&
                        <ProfilePictureBadgeView
                            model={this.props.topLeftBadge}
                            left={-4}
                            top={-4}
                        />
                    }

                    {this.props.topRightBadge != null &&
                        <ProfilePictureBadgeView
                            model={this.props.topRightBadge}
                            right={-4}
                            top={-4}
                        />
                    }

                    {this.props.bottomLeftBadge != null &&
                        <ProfilePictureBadgeView
                            model={this.props.bottomLeftBadge}
                            left={-4}
                            bottom={-4}
                        />
                    }

                    {this.props.bottomRightBadge != null &&
                        <ProfilePictureBadgeView
                            model={this.props.bottomRightBadge}
                            right={-4}
                            bottom={-4}
                        />
                    }

                </div>
            )

        } else {
            return (
                <div
                    title={this.props.popupText}
                    style={{
                        width: this.props.size + "px",
                        height: this.props.size + "px",
                        border: borderWidth > 0 ? `${borderWidth}px solid ${borderColor}` : "none",
                        borderRadius: "50%",
                        backgroundImage: `url('${this.props.profilePictureUrl}')`,
                        backgroundSize: "cover",
                        position: 'relative'
                    }}
                >

                    {this.props.topLeftBadge != null &&
                        <ProfilePictureBadgeView
                            model={this.props.topLeftBadge}
                            left={-4}
                            top={0}
                        />
                    }

                    {this.props.topRightBadge != null &&
                        <ProfilePictureBadgeView
                            model={this.props.topRightBadge}
                            right={-4}
                            top={0}
                        />
                    }

                    {this.props.bottomLeftBadge != null &&
                        <ProfilePictureBadgeView
                            model={this.props.bottomLeftBadge}
                            left={-4}
                            bottom={0}
                        />
                    }

                    {this.props.bottomRightBadge != null &&
                        <ProfilePictureBadgeView
                            model={this.props.bottomRightBadge}
                            right={-4}
                            bottom={0}
                        />
                    }

                </div>
            )
        }

    }
}

export interface ProfilePictureProps {

    name: string,
    profilePictureUrl: string,
    size: number,
    backgroundColor?: string
    borderWidth?: number,
    borderColor?: string,
    topLeftBadge?: ProfilePictureBadge,
    topRightBadge?: ProfilePictureBadge,
    bottomLeftBadge?: ProfilePictureBadge,
    bottomRightBadge?: ProfilePictureBadge,
    popupText?: string

}

class ProfilePictureBadgeView extends React.Component<ProfilePictureBadgeViewProps> {

    render() {
        return (
            <div
                style={{
                    position: 'absolute',
                    left: this.props.left,
                    top: this.props.top,
                    right: this.props.right,
                    bottom: this.props.bottom,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    borderRadius: '50%',
                    width: `${this.props.model.size}px`,
                    height: `${this.props.model.size}px`,
                    minWidth: `${this.props.model.size}px`,
                    minHeight: `${this.props.model.size}px`,
                    maxWidth: `${this.props.model.size}px`,
                    maxHeight: `${this.props.model.size}px`,
                    backgroundColor: this.props.model.color,
                    border: this.props.model.strokeSize != null && this.props.model.strokeColor != null ? `${this.props.model.strokeSize}px solid ${this.props.model.strokeColor}` : null
                }}
            >
                {this.props.model.icon != null &&
                    <WIcon
                        icon={this.props.model.icon}
                        color="#fff"
                        size={`${(this.props.model.size * 0.7) | 0}px`}
                    />
                }

                {this.props.model.icon == null && this.props.model.content != null &&
                    <div
                        style={{
                            display: 'inline-flex',
                            fontSize: `${(this.props.model.size * 0.7) | 0}px`,
                            color: '#fff'
                        }}
                    >
                        {this.props.model.content}
                    </div>
                }

            </div>
        )
    }

}

interface ProfilePictureBadgeViewProps {
    model: ProfilePictureBadge,
    left?: number,
    right?: number,
    top?: number,
    bottom?: number
}

export interface ProfilePictureBadge {

    size: number
    color: string
    icon?: string
    content?: string
    strokeSize?: number
    strokeColor?: string

}