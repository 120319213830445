import * as React from 'react'

export class ReactUtils {

    public static checkComponentType(node: React.ReactNode, type: typeof React.Component): boolean {
        return (node as any).type == type
    }

    private constructor() { }

}