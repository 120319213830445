import { AController } from "@eagerlogic/react-mvc";
import { CurrentUserSvc } from "backend/aaa/CurrentUserSvc";
import { UsersSvc } from "backend/aaa/UsersSvc";
import { PcSvc } from "backend/privateChat/PcService";
import "firebase/analytics";
import * as firebase from "firebase/app";
import { bugsnagClient } from "index";
import { IPrivateChatData } from "shared/entities/privateChat/IPrivateChatData";
import { IPrivateChatMessageData } from "shared/entities/privateChat/IPrivateChatMessageData";
import { StringUtils } from "shared/utils/StringUtils";
import { ChangeListenerCanceler, FsEntity } from "utils/Fs";
import { Snackbar } from "wds/Snackbar";
import { PrivateChatMessageModel, PrivateChatSceneModel } from "./PrivateChatSceneModel";

export class PrivateChatSceneController extends AController<PrivateChatSceneModel> {

    private static dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

    private pc: FsEntity<IPrivateChatData>
    private pcListenerCanceler: ChangeListenerCanceler
    private pcmSnapshot: firebase.firestore.QuerySnapshot<IPrivateChatMessageData<{}>>
    private pcmListenerCanceler: ChangeListenerCanceler

    public constructor(public readonly userId: string, public pcId: string, public readonly remoteName: string, public readonly remoteProfilePictureUrl: string) {
        super()
    }

    protected createInitialModel(): PrivateChatSceneModel {
        return new PrivateChatSceneModel()
    }

    async init() {
        this.model.isLoading = true
        this.render(this.model)
        this.updateListener()

        window.setTimeout(() => {
            var e = document.getElementById("pmMsgComposer")
            if (e != null) {
                (e as HTMLInputElement).focus()
            }
        }, 500)

        firebase.analytics().logEvent("private_chat.opened")
    }

    destroy() {
        if (this.pcmListenerCanceler != null) {
            this.pcmListenerCanceler.cancel()
        }
        if (this.pcListenerCanceler != null) {
            this.pcListenerCanceler.cancel()
        }
    }

    public readonly handleDropFile = async (evt: React.DragEvent<HTMLDivElement>) => {
        this.model.isLoading = true
        this.render(this.model)

        try {
            evt.persist()
            if (evt.dataTransfer.items) {
                // Use DataTransferItemList interface to access the file(s)
                for (var i = 0; i < evt.dataTransfer.items.length; i++) {
                    // If dropped items aren't files, reject them
                    if (evt.dataTransfer.items[i].kind === 'file') {
                        var file = evt.dataTransfer.items[i].getAsFile();
                        if (file.size > 25 * 1024 * 1024) {
                            Snackbar.show("danger", "Maximum file size is 25MB: " + file.name)
                            continue;
                        }
                        try {
                            this.pcId = await PcSvc.sendFile(this.pcId, this.userId, file.name, file)
                        } catch (ex) {
                            Snackbar.show("danger", "Upload failed: " + file.name)
                            console.error(ex)
                            if (bugsnagClient != null) {
                                bugsnagClient.notify(ex)
                            }
                        }
                    }
                }
            } else {
                // Use DataTransfer interface to access the file(s)
                for (var i = 0; i < evt.dataTransfer.files.length; i++) {
                    var file = evt.dataTransfer.files[i]
                    if (file.size > 25 * 1024 * 1024) {
                        Snackbar.show("danger", "Maximum file size is 25MB: " + file.name)
                        continue;
                    }
                    try {
                        this.pcId = await PcSvc.sendFile(this.pcId, this.userId, file.name, file)
                    } catch (ex) {
                        Snackbar.show("danger", "Upload failed: " + file.name)
                        console.error(ex)
                        if (bugsnagClient != null) {
                            bugsnagClient.notify(ex)
                        }
                    }
                }
            }
        } finally {
            this.model.isLoading = false
            this.render(this.model)

            if (this.pc == null) {
                await this.updateListener()
            }
        }
    }

    public readonly sendMessage = async (msg: string) => {
        this.pcId = await PcSvc.sendMessage(this.pcId, this.userId, msg)

        firebase.analytics().logEvent("private_chat.message_sent")

        if (this.pc == null) {
            await this.updateListener()
        }
    }

    private async updateListener() {
        if (this.pcId == null) {
            this.update()
            return
        }

        this.pc = await PcSvc.findById(this.pcId)

        if (this.pc == null) {
            this.update()
            return
        }

        if (this.pcListenerCanceler != null) {
            this.pcListenerCanceler.cancel()
        }

        if (this.pcmListenerCanceler != null) {
            this.pcmListenerCanceler.cancel()
        }

        this.pcListenerCanceler = this.pc.addChangeListener((e) => {
            this.pc = e
            this.update()
        })

        this.pcmListenerCanceler = PcSvc.onPcMessagesChangeEvent(this.pcId).addChangeListener((s) => {
            this.pcmSnapshot = s
            this.update()
        })
    }

    private update() {
        if (this.pcmSnapshot == null) {
            this.model.isLoading = false
            this.model.exists = false
            this.model.messages = []
            this.model.files = []
            this.render(this.model)
            return
        }
        this.model.isLoading = false
        this.model.exists = true
        this.model.messages = []
        var ruIdx = 0
        if (this.pc.data.users[0] == CurrentUserSvc.currentUser.id) {
            ruIdx = 1
        }
        var currentDate = new Date()
        for (var m of this.pcmSnapshot.docs) {
            var isIncoming: boolean = m.data().senderId != CurrentUserSvc.currentUser.id
            var sentDate = new Date(m.data().sentAt)
            var sentAtStr = this.fillLeadingZeros(sentDate.getHours().toString(), 2) + ":" + this.fillLeadingZeros(sentDate.getMinutes().toString(), 2)
            var dayIdx = sentDate.getDay()
            var sentMDay = sentDate.getDate()
            var currentMDay = currentDate.getDate()
            var dateStr = PrivateChatSceneController.dayNames[dayIdx]
            if (sentMDay == currentMDay) {
                dateStr = "Today"
            }
            if (sentMDay == currentMDay - 1) {
                dateStr = "Yesterday"
            }
            if (sentMDay < currentMDay - 6 || sentDate.getMonth() != currentDate.getMonth() || sentDate.getFullYear() != currentDate.getFullYear()) {
                dateStr = sentDate.toDateString()
            }
            var mm = new PrivateChatMessageModel(
                m.id,
                isIncoming,
                UsersSvc.usersById[m.data().senderId].data().name,
                UsersSvc.usersById[m.data().senderId].data().profilePictureUrl,
                UsersSvc.usersById[m.data().senderId].data().color,
                m.data().type,
                m.data().content,
                StringUtils.replaceLink(StringUtils.escapeHtml(m.data().message)),
                dateStr,
                sentAtStr,
                m.data().sentAt
            )
            this.model.messages.push(mm)
        }
        this.model.messages = this.model.messages.reverse()
        this.model.remoteLastSeenAt = this.pc.data.lastSeens[ruIdx]
        this.model.files = []
        if (this.pc.data.files != null) {
            this.model.files = this.pc.data.files.sort((f1, f2) => {
                return f2.uploadedAt - f1.uploadedAt
            })
        }

        this.render(this.model)
    }

    private fillLeadingZeros(input: string, length: number) {
        while (input.length < length) {
            input = "0" + input
        }
        return input
    }

}