import { CurrentOrgSvc } from "backend/aaa/CurrentOrgSvc"
import { transact } from "utils/Fs"

export const deleteBoardFun = async (boardId: string) => {
    return transact(async (t) => {
        t.update(
            CurrentOrgSvc.currentOrg.snapshot.ref
                .collection("Boards")
                .doc(boardId),
            { deleted: true }
        )
    }, null)
}