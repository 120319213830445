import { AController } from "@eagerlogic/react-mvc";
import { MiniCRM } from "backend/aaa/MiniCRM";
import "firebase/analytics";
import * as firebase from "firebase/app";
import "firebase/functions";
import { bugsnagClient } from "index";
import { ICreateCompanyRpcRequest, ICreateCompanyRpcResponse } from "shared/modules/aaa/AaaFunctionDescriptors";
import { ICloudFunction } from "utils/FbUtils";
import { Navigation } from "utils/Navigation";
import { RegisterSceneModel } from "./RegisterSceneModel";

export class RegisterSceneController extends AController<RegisterSceneModel> {

    public constructor() {
        super()
        this.onCancelClick = this.onCancelClick.bind(this)
        this.onRegisterClick = this.onRegisterClick.bind(this)
    }

    protected createInitialModel(): RegisterSceneModel {
        firebase.analytics().setCurrentScreen("RegisterScene")
        firebase.analytics().logEvent("register_scene_open")
        return new RegisterSceneModel()
    }

    init() {
        firebase.analytics().logEvent('register_scene.open')
    }

    public onCancelClick() {
        Navigation.pushState(null, "/app/index.rsc")
        firebase.analytics().logEvent('register_scene.cancel')
    }

    public async onRegisterClick() {
        this.model.globalError = null
        this.model.companyName.error = null
        this.model.name.error = null
        this.model.email.error = null
        this.model.password.error = null

        var cn = this.model.companyName.value.trim()
        var name = this.model.name.value.trim()
        var email = this.model.email.value.trim().toLowerCase()
        var pw = this.model.password.value.trim()

        var hasError = false
        if (cn.length < 2) {
            this.model.companyName.error = "This field is mandatory!"
            hasError = true
        }

        if (name.length < 2) {
            this.model.name.error = "Minimum 2 characters!"
            hasError = true
        }

        if (email.length < 5) {
            this.model.email.error = "Invalid email address!"
            hasError = true
        }

        if (email.indexOf("@") < 1) {
            this.model.email.error = "Invalid email address!"
            hasError = true
        }

        if (pw.length < 6) {
            this.model.password.error = "Minimum 6 characters!"
            hasError = true
        }

        if (hasError) {
            this.model.globalError = "There are some errors in the entered data! Correct it and try it again!"
            this.render(this.model)
            firebase.analytics().logEvent("register_validation_failed")
            return
        }

        this.model.isLoading = true;
        this.render(this.model)

        var createOrganizationFun = firebase.app().functions("europe-west1").httpsCallable("createOrganization") as ICloudFunction<ICreateCompanyRpcRequest, ICreateCompanyRpcResponse>
        try {
            await createOrganizationFun({
                email: email,
                organizationName: cn,
                userName: name,
                password: pw
            })

            var cred = firebase.auth().signInWithEmailAndPassword(email, pw)

            try {
                await MiniCRM.registerUser(name, email, cn)
            } catch (ex) {
                if (bugsnagClient != null) {
                    bugsnagClient.notify(ex)
                }
            }

            Navigation.pushState(null, "/app/index.rsc")
            firebase.analytics().logEvent('register_scene.register_success')
            return
        } catch (e) {
            this.model.globalError = "" + e
            this.model.isLoading = false
            this.render(this.model)
            return
        }
    }

}