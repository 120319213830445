import { CurrentOrgSvc } from "backend/aaa/CurrentOrgSvc"
import { IBoardData } from "shared/entities/projects/IBoardData"
import { transact } from "utils/Fs"

export const changeBoardNameFun = async (boardId: string, newName: string) => {
    return transact(async (t) => {
        const boardRef = CurrentOrgSvc.currentOrg.snapshot.ref
            .collection("Boards")
            .doc(boardId)

        const ud: Partial<IBoardData> = {
            name: newName
        }

        t.update(boardRef, ud)
    }, null)
}