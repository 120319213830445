import { IFileDescriptor } from "shared/entities/wib/IWib"

export class PrivateChatSceneModel {
    public isLoading: boolean = true
    public messages: PrivateChatMessageModel[] = []
    public exists: boolean = false
    public remoteLastSeenAt: number = -1
    public files: IFileDescriptor[] = []
}

export class PrivateChatMessageModel {

    public constructor(
        public readonly id: string,
        public readonly isIncoming: boolean,
        public readonly senderName: string,
        public readonly senderProfilePictureUrl: string,
        public readonly senderColor: string,
        public readonly type: string,
        public readonly content: {},
        public message: string,
        public dateStr: string,
        public timeStr: string,
        public sentAt: number,
    ) {

    }

}