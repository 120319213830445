import { Editor, EditorContext, WithEditorActions } from '@atlaskit/editor-core'
import WikiMarkupTransformer from '@atlaskit/editor-wikimarkup-transformer'
import { CurrentUserSvc } from 'backend/aaa/CurrentUserSvc'
import { UsersSvc } from 'backend/aaa/UsersSvc'
import { WibSvc } from 'backend/wib/WibService'
import { bugsnagClient } from 'index'
import { FilesTabRenderer } from 'modules/app/comm/privateChat/PrivateChatScene'
import { EditorView } from 'prosemirror-view'
import * as React from 'react'
import { IWib, IWibNotification, VER_Wib } from 'shared/entities/wib/IWib'
import { IWibMessage } from 'shared/entities/wib/IWibMessage'
import { ENotiReasonColor } from 'shared/entities/wib/Wib'
import { IConversationWibContent, VER_ConversationWibContent } from 'shared/entities/wib/wibs/IConversationWibContent'
import { style } from 'typestyle'
import { Snackbar } from 'wds/Snackbar'
import { AWibController, AWibCreator, AWibHandler, WibActionButton, WibHeaderButtons } from 'wibib-sdk-fe/WibHandlerBase'
import { FieldModel } from '../../../../../../components/FieldModel'
import { WMembersInput, WMembersInputItem } from '../../../../../../wds/MembersInput'
import { EFontWeight } from '../../../../../../wds/styles/EFontWeight'
import { CommonDescriptionView } from '../../components/CommonDescriptionView'
import { CommonMembersTabViewRenderer } from '../../components/CommonMembersTabViewRenderer'
import { CommonWibSidebarView } from '../../components/CommonWibSidebarView'
import { AMessageRenderer, CommonWibView } from '../../components/CommonWibView'


export class ConversationWibHandler extends AWibHandler<IConversationWibContent> {

    public constructor(type: string, name: string, icon: string, color: string, description: string, creatable: boolean) {
        super(type, name, icon, color, description, creatable)
    }

    createWibCreator(): AWibCreator {
        return new ConversationWibCreator()
    }

    createWibController(wibId: string, wib: IWib<IConversationWibContent>, messages: IWibMessage<{}>[], changeListener: () => void): AWibController<IConversationWibContent> {
        return new ConversationWibController(wibId, wib, messages, true, changeListener)
    }

}

export class ConversationWibCreator extends AWibCreator {

    private participantsModel = new FieldModel<WMembersInputItem[]>([])
    private description: string = ""

    public constructor() {
        super()
    }

    renderDialog(): React.ReactNode {
        return (
            <ConversationWibCreatorView participantsModel={this.participantsModel} onDescChange={(desc) => { this.description = desc }} />
        )
    }

    async createWib(subject: string): Promise<string> {
        var msg = this.description
        msg = msg.trimRight()

        const convD: IConversationWibContent = {
            description: msg,
            version: VER_ConversationWibContent
        }

        const now = Date.now()

        const memberNotifications: { [memberId: string]: IWibNotification } = {}
        memberNotifications[CurrentUserSvc.currentUser.id] = {
            notifiedAt: now,
            reason: {
                message: "created this conversation",
                color: ENotiReasonColor.Created.color,
            },
            senderId: CurrentUserSvc.currentUser.id,
            unreadNotificationsCount: 0
        }
        this.participantsModel.value.forEach((p) => {
            memberNotifications[p.id] = {
                notifiedAt: now,
                reason: {
                    message: "created this conversation",
                    color: ENotiReasonColor.Created.color,
                },
                senderId: CurrentUserSvc.currentUser.id,
                unreadNotificationsCount: 1
            }
        })

        const memberLastSeens: { [memberId: string]: number } = {}
        memberLastSeens[CurrentUserSvc.currentUser.id] = now
        this.participantsModel.value.forEach((p) => {
            memberLastSeens[p.id] = -1
        })

        const wibId = await WibSvc.createWib({
            content: convD,
            createdAt: now,
            createdBy: CurrentUserSvc.currentUser.id,
            deleted: false,
            parentId: null,
            parentType: null,
            subject: subject,
            type: "wibib.conversation",
            version: VER_Wib,
            allowedMembers: ["@all"],
            lastUpdatedAt: now,
            members: [CurrentUserSvc.currentUser.id, ...this.participantsModel.value.map((p) => p.id)],
            memberNotifications: memberNotifications,
            assignees: [],
            memberLastSeens: memberLastSeens,
            files: []
        })

        return wibId
    }

}

class ConversationWibCreatorView extends React.Component<ConversationWibCreatorViewProps, ConversationWibCreatorViewState> {

    public constructor(props: ConversationWibCreatorViewProps) {
        super(props)

        this.state = {
            participantsModel: new FieldModel([])
        }
    }

    private readonly handleChange = (editorView: EditorView) => {
        var text = new WikiMarkupTransformer().encode(editorView.state.doc);
        this.props.onDescChange(text)
    }

    render() {
        const availableParticipants: WMembersInputItem[] = UsersSvc.users.filter((user) => {
            if (user.id == CurrentUserSvc.currentUser.id) {
                return false
            }

            return true
        })
            .map((user) => {
                return {
                    id: user.id,
                    name: user.data().name
                }
            })
        return (
            <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                <div style={{ marginBottom: '32px' }}>
                    {/* participants */}
                    <WMembersInput label="Participants" placeholder="Search participants" model={this.props.participantsModel} options={availableParticipants} />
                </div>

                <div style={{}}>
                    {/* description */}
                    <div style={{ width: '100%', padding: '4px', border: '1px solid #ccc', borderRadius: '4px' }}>
                        <EditorContext>
                            <WithEditorActions
                                render={actions => (
                                    <Editor
                                        appearance="full-width"
                                        contentTransformerProvider={schema =>
                                            new WikiMarkupTransformer(schema)
                                        }
                                        onChange={this.handleChange}
                                    />
                                )}
                            />
                        </EditorContext>
                    </div>
                </div>
            </div>
        )
    }

}

interface ConversationWibCreatorViewProps {

    participantsModel: FieldModel<WMembersInputItem[]>
    onDescChange: (desc: string) => void

}

interface ConversationWibCreatorViewState {



}

const fieldLabelStyle = style({
    fontSize: '14px',
    fontWeight: EFontWeight.Medium.value,
    color: '#666',
    marginBottom: '4px'
})

export class ConversationWibController extends AWibController<IConversationWibContent> {


    public constructor(wibId: string, wib: IWib<IConversationWibContent>, messages: IWibMessage<{}>[], isSubjectEditable: boolean, changeListener: () => void) {
        super(wibId, wib, messages, isSubjectEditable, changeListener)
    }

    private readonly handleDescChange = async (newDesc: string) => {
        const c: IConversationWibContent = {
            description: newDesc,
            version: VER_ConversationWibContent
        }
        try {
            await WibSvc.changeContent(this.wibId, c)
            await WibSvc.notifyOthers(this.wibId, "changed the description", ENotiReasonColor.ChangeContent)
            await WibSvc.sendMetaInfo(this.wibId, "changed the description.")
        } catch (ex) {
            Snackbar.show("danger", "Failed to change description: Server error occured")
            bugsnagClient.notify(ex)
        }
    }

    private readonly handleSendComment = async (comment: string) => {
        try {
            await WibSvc.sendComment(this.wibId, comment)
        } catch (ex) {
            Snackbar.show("danger", "Failed to send comment: Server error occured")
            bugsnagClient.notify(ex)
        }
    }

    getHeaderButtons(): WibHeaderButtons {
        var buttons: WibActionButton[] = []

        return new WibHeaderButtons(buttons, [])
    }

    render(): React.ReactNode {
        return (
            <CommonWibView
                header={() => {
                    var content = ""
                    if (this.wib.content != null && this.wib.content.description != null) {
                        content = this.wib.content.description
                    }
                    return (
                        <CommonDescriptionView isEditable label="Description" content={content} onChange={this.handleDescChange} />
                    )
                }}
                messageResolver={this.messageResolver}
                messages={this.messages}
                wibId={this.wibId}
                onSendComment={this.handleSendComment}
                wib={this.wib}

            />
        )
    }

    renderSidebar(): React.ReactNode {
        return (
            <CommonWibSidebarView
                tabs={[
                    new CommonMembersTabViewRenderer(
                        true,
                        this.wib.members.map((m) => {
                            return {
                                id: m,
                                isAssignee: this.wib.assignees.indexOf(m) > -1
                            }
                        }),
                        this.handleMemberAdded,
                        this.handleMemberRemoved,
                        this.handleAssigneeChanged
                    ),
                    new FilesTabRenderer(this.wib)
                ]}
            />
        )
    }

    private readonly handleMemberAdded = async (id: string) => {
        try {
            await WibSvc.addMember(this.wibId, id)
        } catch (ex) {
            Snackbar.show("danger", "Failed to add participant: Server error occured!")
            throw ex
        }
    }

    private readonly handleMemberRemoved = async (id: string) => {
        try {
            await WibSvc.removeMember(this.wibId, id)
        } catch (ex) {
            Snackbar.show("danger", "Failed to add participant: Server error occured!")
            throw ex
        }
    }

    private readonly handleAssigneeChanged = async (userId: string, newValue: boolean) => {
        try {
            if (newValue) {
                await WibSvc.addAssignee(this.wibId, userId)
            } else {
                await WibSvc.removeAssignee(this.wibId, userId)
            }
        } catch (ex) {
            Snackbar.show("danger", "Failed to add assignee: Server error occured!")
            throw ex
        }
    }

    private readonly messageResolver = (message: IWibMessage<{}>): AMessageRenderer => {
        return null;
    }

}




