import { MenuItem, TextField } from '@material-ui/core'
import { FieldModel } from 'components/FieldModel'
import * as React from 'react'
import { IdGenerator } from '../utils/IdGenerator'

export class WSelect extends React.Component<WSelectProps, WSelectState> {

    private id = IdGenerator.getNextId()

    private handleChange = (evt: React.ChangeEvent<{ name: string, value: string }>) => {
        this.props.model.value = evt.target.value
        this.setState({})
        if (this.props.onChange != null) {
            this.props.onChange(evt.target.value)
        }
    }

    render() {
        return (
            <TextField
                style={{ width: '100%' }}
                error={this.props.model.error != null}
                label={this.props.label}
                value={this.props.model.value}
                helperText={this.props.model.error != null ? this.props.model.error : this.props.helperText}
                disabled={this.props.disabled}
                placeholder={this.props.placeholder}
                required={this.props.required}
                variant="outlined"
                select
                onChange={(e) => {
                    this.props.model.value = e.target.value;
                    this.setState({})
                    if (this.props.onChange != null) {
                        this.props.onChange(this.props.model.value)
                    }
                }}
            >
                {this.props.options.map((opt) => {
                    return <MenuItem key={"mi-" + opt.value} value={opt.value}>{opt.title}</MenuItem>
                })}

            </TextField>
            // <FormControl style={{ width: '100%' }} error={this.props.model.error != null}>
            //     <InputLabel id={"label-" + this.id}>{this.props.label}</InputLabel>
            //     <Select
            //         id="label"
            //         labelId={"label-" + this.id}
            //         //value={this.props.model.value}
            //         onChange={this.handleChange}
            //         //placeholder={this.props.placeholder}
            //         //required={this.props.required}
            //         //disabled={this.props.disabled}
            //         variant="outlined"
            //     >
            //         {this.props.options.map((opt) => {
            //             return <MenuItem key={"mi-" + opt.value} value={opt.value}>{opt.title}</MenuItem>
            //         })}
            //     </Select>
            //     <FormHelperText>{this.props.model.error != null ? this.props.model.error : this.props.helperText}</FormHelperText>
            // </FormControl>
        )
    }

}

export interface WSelectProps {
    label?: string
    placeholder?: string
    model: FieldModel<string>
    options: SelectOption[]
    helperText?: string
    required?: boolean
    disabled?: boolean
    onChange?: (newValue: string) => void
}

interface WSelectState {

}

export class SelectOption {

    public constructor(public readonly value: string, public readonly title: string) { }

}