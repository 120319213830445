export const VER_PrivateChatMessageData = 1

export interface IPrivateChatMessageData<$ContentType> {

    version: number
    senderId: string
    message: string
    sentAt: number
    type: string
    content: $ContentType

}

export const VER_IPCMessageContent = 1

export interface IPCMessageContent {
    version: number
}

export const VER_IPCSendFileContent = 1

export interface IPCSendFileContent {
    fileName: string,
    downloadUrl: string,
    version: number
}