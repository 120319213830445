import { CurrentOrgSvc } from "backend/aaa/CurrentOrgSvc"
import { IBoardData } from "shared/entities/projects/IBoardData"
import { FsEntity } from "utils/Fs"

export const findBoardByIdFun = async (boardId: string): Promise<FsEntity<IBoardData>> => {
    const boardRef = CurrentOrgSvc.currentOrg.snapshot.ref
        .collection("Boards")
        .doc(boardId)

    const boardE = (await boardRef.get()) as firebase.firestore.DocumentSnapshot<IBoardData>
    if (!boardE.exists) {
        return null
    }

    return new FsEntity<IBoardData>(boardE)
}