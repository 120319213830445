import * as React from 'react'
import { keyframes, style } from 'typestyle'
import { Event } from 'utils/Event'
import { WIconButton } from './IconButton'
import { EFontWeight } from './styles/EFontWeight'
import { EColor } from './styles/WColor'

export class Snackbar {

    public static readonly onShow = new Event<SnackBarItemModel>()

    public static show(type: "info" | "success" | "warning" | "danger", message: string) {
        Snackbar.onShow.fire(new SnackBarItemModel(Date.now(), type, message))
    }

    private constructor() { }

}

export class WSnackBarContainer extends React.Component<{}, SnackBarContainerState> {

    public constructor(props: {}) {
        super(props)

        this.state = { currentItem: null }
    }

    componentDidMount() {
        Snackbar.onShow.addListener(this.showHandler)
    }

    componentWillUnmount() {
        Snackbar.onShow.removeListener(this.showHandler)
    }

    private readonly showHandler = (item: SnackBarItemModel) => {
        this.setState({ currentItem: item })
    }

    private handleCloseClick = () => {
        this.setState({ currentItem: null })
    }

    render() {
        if (this.state.currentItem == null) {
            return (<></>)
        }
        return (
            <WSnackBarItem model={this.state.currentItem} key={"snackbar-" + this.state.currentItem.id} onCloseClick={this.handleCloseClick} />
        )
    }

}

interface SnackBarContainerState {

    currentItem: SnackBarItemModel

}

class SnackBarItemModel {

    public constructor(
        public readonly id: number,
        public readonly type: "info" | "success" | "warning" | "danger",
        public readonly message: string
    ) { }

}

class WSnackBarItem extends React.Component<SnackbarItemProps, SnackbarItemState> {

    public constructor(props: SnackbarItemProps) {
        super(props)

        this.state = { isClosing: false, isHovered: false }
    }

    private timerHandler: number = null

    componentDidMount() {
        this.restartTimer()
    }

    componentWillUnmount() {
        this.stopTimer()
    }

    private restartTimer() {
        this.stopTimer()

        this.timerHandler = window.setTimeout(() => {
            this.setState({ isClosing: true })
        }, 5000)
    }

    private stopTimer() {
        if (this.timerHandler != null) {
            window.clearTimeout(this.timerHandler)
        }
    }

    private handleMouseEnter = () => {
        this.stopTimer()
        this.setState({ isClosing: false, isHovered: true })
    }

    private handleMouseLeave = () => {
        this.restartTimer()
    }

    private handleCloseClick = () => {
        if (this.props.onCloseClick != null) {
            this.props.onCloseClick()
        }
    }

    render() {
        var snackbarStyle = snackBarItemStyle
        if (this.state.isClosing) {
            snackbarStyle += " " + snackbarCloseStyle
        } else if (this.state.isHovered) {
            snackbarStyle += " " + snackbarHoverStyle
        } else {
            snackbarStyle += " " + snackbarOpenStyle
        }

        var bgColor = EColor.ButtonDefaultColor.color
        if (this.props.model.type == "success") {
            bgColor = EColor.ButtonSuccessColor.color
        } else if (this.props.model.type == "warning") {
            bgColor = EColor.ButtonWarningColor.color
        } else if (this.props.model.type == "danger") {
            bgColor = EColor.ButtonDangerColor.color
        }

        return (
            <div
                className={snackbarStyle}
                style={{ backgroundColor: bgColor }}
                onMouseEnter={() => { this.handleMouseEnter() }}
                onMouseLeave={() => { this.handleMouseLeave() }}
            >
                <div
                    style={{
                        flex: "1",
                        color: "#fff",
                        fontSize: "16px",
                        fontWeight: EFontWeight.Medium.value,
                        alignItems: "center"
                    }}
                >
                    {this.props.model.message}
                </div>
                <div style={{ width: "8px" }} />
                <WIconButton icon="close" size="small" onClick={this.handleCloseClick} />
            </div>
        )
    }

}

interface SnackbarItemProps {
    model: SnackBarItemModel
    onCloseClick: () => void
}

interface SnackbarItemState {
    isClosing: boolean
    isHovered: boolean
}

const snackbarOpenAnim = keyframes({
    'from': {
        right: "-432px"
    },
    'to': {
        right: "32px"
    }
})

const snackabarCloseAnim = keyframes({
    '0%': {
        opacity: "1.0",
        pointerEvents: "all"
    },
    '99%': {
        opacity: "0.0",
        pointerEvents: "all"
    },
    'to': {
        opacity: "0.0",
        pointerEvents: "none"
    },
})

const snackbarOpenStyle = style({
    animationDuration: "300ms",
    animationFillMode: "forwards",
    animationName: snackbarOpenAnim
})

const snackbarCloseStyle = style({
    right: "32px",
    animationDuration: "3s",
    animationFillMode: "forwards",
    animationName: snackabarCloseAnim
})

const snackbarHoverStyle = style({
    opacity: 1.0,
    right: "32px"
})

const snackBarItemStyle = style({
    position: "fixed",
    display: "flex",
    bottom: "32px",
    width: "400px",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "1px 1px 10px rgba(0,0,0, 0.5)",
    zIndex: 100000
})