import * as React from 'react'
import { EHAlign, EVAlign } from '../components/styles'

export class WVBox extends React.Component<WVBoxProps, {}> {

    render() {
        return (
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column"
                }}
            >

                {React.Children.map(this.props.children, (child, index) => {
                    if (this.props.spacing != null && this.props.spacing > 0 && index > 0) {
                        return (
                            <>
                                <div style={{ height: this.props.spacing + "px" }} />
                                {child}
                            </>
                        )
                    } else {
                        return child
                    }
                })}
            </div>
        )
    }
}

export interface WVBoxProps {

    spacing?: number

}

export class WVBoxCell extends React.Component<WVBoxCellProps, {}> {

    static defaultProps = {
        hAlign: EHAlign.Center,
        vAlign: EVAlign.Middle
    }

    render() {
        let height = "auto"
        let grow = 0
        let flexBasis = "auto"
        if (this.props.height != null) {
            height = this.props.height + "px"
        }

        let hAlign = "center"
        if (this.props.hAlign == EHAlign.Left) {
            hAlign = "flex-start"
        } else if (this.props.hAlign == EHAlign.Center) {
            hAlign = "center"
        } else {
            hAlign = "flex-end"
        }

        let vAlign = "center"
        if (this.props.vAlign == EVAlign.Top) {
            vAlign = "flex-start"
        } else if (this.props.vAlign == EVAlign.Middle) {
            vAlign = "center"
        } else {
            vAlign = "flex-end"
        }

        return (
            <div
                style={{
                    display: "flex",
                    height: height,
                    width: "100%",
                    flexGrow: grow,
                    flexBasis: flexBasis,
                    justifyContent: hAlign,
                    alignItems: vAlign
                }}
            >
                {this.props.children}
            </div>
        )
    }

}

export interface WVBoxCellProps {

    height?: number
    hAlign: EHAlign
    vAlign: EVAlign

}