import * as React from 'react'
import { ModalButton, WModal } from '../../../../../../wds/Modal'
import { WTextField } from '../../../../../../wds/TextField'
import { BoardSceneController } from '../BoardSceneController'
import { EditColumnNameDialogModel } from '../BoardSceneModel'

export class EditcolumnNameDialog extends React.Component<EditcolumnNameDialogProps> {

    private readonly handleCancelClick = () => {
        this.props.controller.onEditColumnNameDialogCancelClicked()
    }

    private readonly handleSaveClick = () => {
        this.props.controller.onEditColumnNameDialogSaveClicked()
    }

    public render() {
        return (
            <WModal
                title="Edit column name"
                buttons={[
                    new ModalButton("Cancel", this.handleCancelClick, "text"),
                    new ModalButton("Save", null, "contained", "primary"),
                ]}
                onSubmit={this.handleSaveClick}
            >
                <WTextField
                    label="Name"
                    model={this.props.model.nameModel}
                    helperText="The new name of the column."
                />
            </WModal>
        )
    }

}

export interface EditcolumnNameDialogProps {

    model: EditColumnNameDialogModel
    controller: BoardSceneController

}