import { AScene } from "@eagerlogic/react-mvc";
import * as React from 'react';
import { ModalButton, WModal } from 'wds/Modal';
import { WTextField } from 'wds/TextField';
import { RegisterSceneController } from "./RegisterSceneController";
import { RegisterSceneModel } from "./RegisterSceneModel";

export class RegisterScene extends AScene<RegisterSceneProps, RegisterSceneModel, RegisterSceneController> {
    protected createControlller(): RegisterSceneController {
        return new RegisterSceneController()
    }

    private handleCancelClick = () => {
        this.controller.onCancelClick()
    }

    private handleSaveClick = () => {
        this.controller.onRegisterClick()
    }

    render(): React.ReactNode {
        return (
            <WModal
                title="Register organization"
                helperText="Fill the form below to register your organization!"
                error={this.model.globalError}
                isLoading={this.model.isLoading}
                buttons={[
                    new ModalButton("Cancel", this.handleCancelClick, "text"),
                    new ModalButton("Register", this.handleSaveClick, "contained", "primary"),
                ]}
            >
                <WTextField
                    label="Organization name"
                    required={true}
                    helperText="The name of your company or organization"
                    model={this.model.companyName}
                />
                <WTextField
                    label="Your name"
                    required={true}
                    helperText="This will be visible for other users"
                    model={this.model.name}
                />
                <WTextField
                    label="Your email"
                    required={true}
                    helperText="You will use this email to sign in"
                    model={this.model.email}
                />
                <WTextField
                    label="Choose password"
                    required={true}
                    helperText="You will use this password to sign in"
                    model={this.model.password}
                    password={true}
                />
            </WModal>
        )
    }


}

export interface RegisterSceneProps {

}