import { AWibHandler } from "wibib-sdk-fe/WibHandlerBase";
import { WColorPalette } from "../../../../wds/styles/WColor";
import { CardWibHandler } from "../../projects/scenes/board/wib/CardWibHandler";
import { ConversationWibHandler } from "./handlers/conversation/ConversationWibHandler";

export class WibHandlerRegistry {

    public static readonly handlers: AWibHandler<{}>[] = [
        new ConversationWibHandler('wibib.conversation', "Conversation", 'chat', WColorPalette.LightGreen.color, "Creates a new conversation between one or more participants.", true),
        // new ConversationWibHandler('wibib.task', "Task", 'done', WColorPalette.Green.color, "Creates a new task to one or more participants."),
        // new ConversationWibHandler('wibib.question', "Question", 'live_help', WColorPalette.Indigo.color, "Creates a new question to one or more participants."),
        // new PollWibHandler(),
        new CardWibHandler()
    ]

    public static getHandlerByType(type: string): AWibHandler<{}> {
        return this.handlers.find((value) => {
            return value.type == type
        })
    }

    private constructor() { }

}