import { ProfilePicture } from 'components/ProfilePicture';
import { BoardListItemModel } from 'modules/app/projects/scenes/boardList/ProjectsBoardListSceneModel';
import * as React from 'react';
import { style } from 'typestyle';
import { WIconButton } from 'wds/IconButton';

export class BoardListItem extends React.Component<BoardListItemProps, BoardListItemState> {

    render() {
        return (
            <div
                className={rootClass}
                style={{
                    backgroundColor: this.props.model.color,

                }}
                onClick={this.props.onClick}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flex: '1'
                    }}
                >
                    <ProfilePicture
                        name={this.props.model.ownerName}
                        backgroundColor={this.props.model.ownerColor}
                        profilePictureUrl={this.props.model.ownerPPUrl}
                        size={48}
                        borderWidth={3}
                        borderColor="rgba(255,255,255, 0.8)"
                    />
                    {/* <Icon style={{ fontSize: '48px', color: 'rgba(255,255,255, 0.8)' }} >view_week</Icon> */}
                </div>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px',
                        backgroundColor: '#fff',
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px'
                    }}
                >
                    <div
                        style={{
                            flex: '1',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}
                    >
                        {this.props.model.name}
                    </div>

                    <WIconButton icon="edit" size="small" onClick={this.props.onEditBoardClick} />
                    <WIconButton icon="delete" size="small" onClick={this.props.onDeleteBoardClick} />
                </div>
            </div>
        )
    }

}

export interface BoardListItemProps {

    model: BoardListItemModel,
    onClick: () => void,
    onEditBoardClick: () => void,
    onDeleteBoardClick: () => void

}

interface BoardListItemState {

}

const rootClass = style({
    width: "256px",
    height: '128px',
    margin: '16px',
    borderRadius: '8px',
    boxShadow: '1px 1px 5px rgba(0,0,0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    $nest: {
        '&:hover': {
            opacity: '0.7'
        },
        '&:active': {
            opacity: '0.5'
        },
    }
})