import { Button, Icon } from '@material-ui/core'
import * as React from 'react'

export class WButton extends React.Component<WButtonProps, WButtonState> {

    public constructor(props: WButtonProps) {
        super(props)

        this.state = {
            isDown: false,
            isHovered: false
        }
    }

    // private handleMouseEnter = (evt) => {
    //     if (this.props.disabled) {
    //         return
    //     }

    //     this.setState({ isHovered: true, isDown: false })
    // }

    // private handleMouseLeave = (evt) => {
    //     if (this.props.disabled) {
    //         return
    //     }

    //     this.setState({ isHovered: false, isDown: false })
    // }

    // private handleMouseDown = (evt) => {
    //     if (this.props.disabled) {
    //         return
    //     }

    //     this.setState({ isHovered: true, isDown: true })
    // }

    // private handleMouseUp = (evt) => {
    //     if (this.props.disabled) {
    //         return
    //     }

    //     this.setState({ isHovered: true, isDown: false })
    // }

    // private handleClick = (evt) => {
    //     if (this.props.disabled) {
    //         return
    //     }

    //     if (this.props.onClick != null) {
    //         this.props.onClick()
    //     }
    // }

    render() {
        const variant = this.props.variant != null ? this.props.variant : 'contained'
        const color = this.props.color != null ? this.props.color : 'default'
        const size = this.props.size != null ? this.props.size : 'medium'
        return (
            <Button
                variant={variant}
                size={size}
                color={color}
                startIcon={this.props.icon != null ? (<Icon>{this.props.icon}</Icon>) : null}
                disabled={this.props.disabled}
                style={{ width: this.props.width != null ? this.props.width : null }}
                onClick={this.props.onClick}
                type={this.props.submitButton ? "submit" : "button"}
            >
                {this.props.title}
            </Button>
        )
    }

}

export interface WButtonProps {
    title: string
    icon?: string
    color?: "default" | "primary" | "secondary"
    size?: "small" | "medium" | "large"
    variant?: 'contained' | 'outlined' | 'text'
    disabled?: boolean
    width?: string
    submitButton?: boolean
    onClick?: () => void

}

interface WButtonState {
    isHovered: boolean
    isDown: boolean
}