import * as firebase from "firebase/app"
import 'firebase/firestore'
import { IWib } from "shared/entities/wib/IWib"
import { IWibMessage } from "shared/entities/wib/IWibMessage"


export class WibSceneModel {

    public isLoading: boolean = true
    public type: string
    public subject: string
    public icon: string
    public color: string
    public wib: IWib<{}>
    public messages: firebase.firestore.QuerySnapshot<IWibMessage<{}>>

}