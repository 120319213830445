

export class Event<$Args> {

    private listeners: IEventListener<$Args>[] = [];

    public addListener(listener: IEventListener<$Args>): void {
        if (listener == null) {
            throw new Error('The listener parameter can not be null!');
        }

        if (this.hasListener(listener)) {
            throw new Error('This listener is already registered!');
        }

        this.listeners.push(listener);
    }

    public removeListener(listener: IEventListener<$Args>): void {
        if (listener == null) {
            throw new Error('The listener parameter can not be null!');
        }

        this.listeners = this.listeners.filter((item) => {
            if (item == listener) {
                return false;
            }

            return true;
        });
    }

    public hasListener(listener: IEventListener<$Args>): boolean {
        if (listener == null) {
            throw new Error('The listener parameter can not be null!');
        }

        for (var item of this.listeners) {
            if (item === listener) {
                return true;
            } 
        }
        return false;
    }

    public hasAnyListener(): boolean {
        return this.listeners.length > 0
    }

    public clearListeners() {
        this.listeners = [];
    }

    public fire(args: $Args) {
        for (var listener of this.listeners) {
            listener(args);
        }
    }

}

export interface IEventListener<$Args> {

    (args: $Args): void;

}