import "firebase/analytics"
import * as firebase from "firebase/app"
import 'firebase/firestore'
import * as React from 'react'
import { IBoardColumnAutoMemberOptionItem, IBoardColumnOptions, IBoardData } from "shared/entities/projects/IBoardData"
import { style } from 'typestyle'
import { v4 as uuidv4 } from 'uuid'
import { CurrentOrgSvc } from "../../../../../../../backend/aaa/CurrentOrgSvc"
import { UsersSvc } from "../../../../../../../backend/aaa/UsersSvc"
import { FieldModel } from '../../../../../../../components/FieldModel'
import { EHAlign } from '../../../../../../../components/styles'
import { WButton } from '../../../../../../../wds/Button'
import { WCheckBox } from '../../../../../../../wds/Checkbox'
import { WIconButton } from '../../../../../../../wds/IconButton'
import { WMembersList } from "../../../../../../../wds/MembersList"
import { ModalButton, WModal } from '../../../../../../../wds/Modal'
import { SelectOption, WSelect } from '../../../../../../../wds/Select'
import { EFontWeight } from '../../../../../../../wds/styles/EFontWeight'
import { WColorPalette } from '../../../../../../../wds/styles/WColor'
import { WTab, WTabbedPane } from "../../../../../../../wds/TabbedPane"
import { WTable, WTableCell, WTableHeadCell, WTableHeadRow, WTableRow } from '../../../../../../../wds/Table'
import { WTextField } from '../../../../../../../wds/TextField'
import { WVBox, WVBoxCell } from '../../../../../../../wds/VBox'
import { ColumnModel } from '../../BoardSceneModel'

export class EditColumnSettingsDialog extends React.Component<EditColumnSettingsDialogProps, EditColumnSettingsDialogState> {

    private boards: firebase.firestore.QuerySnapshot<IBoardData> = null

    public constructor(props: EditColumnSettingsDialogProps) {
        super(props)

        this.state = {
            isAddActionFormVisible: false,
            editingOptions: this.props.model.options = JSON.parse(JSON.stringify(this.props.model.options))
        }

        this.init()
    }

    private readonly init = async () => {
        this.boards = await CurrentOrgSvc.currentOrg.snapshot.ref.collection("Boards").get() as firebase.firestore.QuerySnapshot<IBoardData>
        this.setState({})
    }

    private readonly handleDisableDropChanged = (newValue: boolean) => {
        this.state.editingOptions.actionOptions.disableDrop = newValue
        this.setState({})
        firebase.analytics().logEvent('projects.board.column_settings_dialog.disable_drop.' + newValue)
    }

    private readonly handleDisableDragChanged = (newValue: boolean) => {
        this.state.editingOptions.actionOptions.disableDrag = newValue
        this.setState({})
        firebase.analytics().logEvent('projects.board.column_settings_dialog.disable_drag.' + newValue)
    }

    private readonly handleAddActionClick = () => {
        this.setState({ isAddActionFormVisible: true })
        firebase.analytics().logEvent('projects.board.column_settings_dialog.add_action_dialog.open')
    }

    private readonly handleSaveNewActionClick = (title: string, color: string, boardId: string, columnId: string) => {
        this.state.editingOptions.actionOptions.actions.push({
            id: uuidv4(),
            color: color,
            title: title,
            targetBoardId: boardId,
            targetColumnId: columnId
        })
        this.setState({ isAddActionFormVisible: false })
        firebase.analytics().logEvent('projects.board.column_settings_dialog.add_action_dialog.save')
    }

    private readonly handleCancelNewActionClick = () => {
        this.setState({ isAddActionFormVisible: false })
        firebase.analytics().logEvent('projects.board.column_settings_dialog.add_action_dialog.cancel')
    }

    private readonly handleDeleteActionClick = (actionIndex: number) => {
        this.state.editingOptions.actionOptions.actions.splice(actionIndex, 1)
        this.setState({})
        firebase.analytics().logEvent('projects.board.column_settings_dialog.delete_action')
    }

    private readonly handleCancelClick = () => {
        this.props.onCancel()
    }

    private readonly handleSaveClick = () => {
        this.props.onSave(this.state.editingOptions)
    }

    render() {

        return (
            <WModal
                title="Column settings"
                padding={0}
                buttons={this.state.isAddActionFormVisible ? null : [
                    new ModalButton("Cancel", this.handleCancelClick, 'text'),
                    new ModalButton("Save", null, "contained", "primary"),
                ]}
                onSubmit={this.handleSaveClick}
            >
                <WTabbedPane
                    headBackground="#f8f8f8"
                    tabs={[
                        new WTab("Actions", null, 0, () => {
                            return this.renderActionsTab()
                        }),
                        new WTab("Auto participants", null, 0, () => {
                            return this.renderParticipantsTab()
                        }),
                    ]}
                />
            </WModal>
        )
    }

    private renderActionsTab() {
        return (
            <div style={{ padding: '32px' }}>
                <WVBox spacing={16}>
                    <WVBoxCell>
                        <div className={tabDescriptionClass}>
                            Actions are custom buttons shown in the card dialog. It can be used to move a card from this column to an another column by clicking the named action button, without the need to open the board.
                        </div>
                    </WVBoxCell>
                    <WVBoxCell hAlign={EHAlign.Left}>
                        <div>
                            <WCheckBox
                                title="Cards can enter by actions only"
                                model={new FieldModel(this.state.editingOptions.actionOptions.disableDrop)}
                                onChange={this.handleDisableDropChanged}
                            />
                            <div className={checkboxDescriptionClass}>
                                Select this opton if you want to prevent to drag & drop cards in to this column. <br /> It can be used to enforce the workflow defined by actions.
                            </div>
                            <WCheckBox
                                title="Cards can leave by actions only"
                                model={new FieldModel(this.state.editingOptions.actionOptions.disableDrag)}
                                onChange={this.handleDisableDragChanged}
                            />
                            <div className={checkboxDescriptionClass}>
                                Select this opton if you want to prevent to drag cards out of this column. <br /> It can be used to enforce the workflow defined by actions.
                            </div>
                        </div>
                    </WVBoxCell>

                    <WVBoxCell>
                        {this.renderActionButtonItems()}
                    </WVBoxCell>

                    {!this.state.isAddActionFormVisible &&
                        <WVBoxCell>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                                <WButton title="Add action button" icon="add" size="small" variant="text" color="primary" onClick={this.handleAddActionClick} />
                            </div>
                        </WVBoxCell>
                    }

                    {this.state.isAddActionFormVisible &&
                        <WVBoxCell>
                            <AddActionForm currentColumnId={this.props.model.currentColumnId} onCancel={this.handleCancelNewActionClick} onSave={this.handleSaveNewActionClick} />
                        </WVBoxCell>
                    }
                </WVBox>
            </div>
        )
    }

    private renderActionButtonItems(): React.ReactNode {

        return (
            <WTable>
                <WTableHeadRow>
                    <WTableHeadCell title="Title" />
                    <WTableHeadCell title="Target board" />
                    <WTableHeadCell title="Target column" />
                    <WTableHeadCell title="" />
                </WTableHeadRow>
                {this.state.editingOptions.actionOptions.actions.map((action, index) => {
                    var targetBoardE: firebase.firestore.DocumentSnapshot<IBoardData> = null
                    var targetBoard = ""
                    var targetColumn = ""
                    if (this.boards != null) {
                        targetBoardE = this.boards.docs.find((b) => {
                            return b.id == action.targetBoardId
                        })
                    }
                    if (targetBoardE != null) {
                        targetBoard = targetBoardE.data().name
                        targetColumn = targetBoardE.data().columns.find((col) => { return col.id == action.targetColumnId }).name
                    }
                    return (
                        <WTableRow key={action.id}>
                            <WTableCell>{action.title}</WTableCell>
                            <WTableCell>{targetBoard}</WTableCell>
                            <WTableCell>{targetColumn}</WTableCell>
                            <WTableCell><WIconButton icon="delete" size="small" color="secondary" onClick={() => { this.handleDeleteActionClick(index) }} /></WTableCell>
                        </WTableRow>
                    )
                })}
            </WTable>
        )
    }

    private renderParticipantsTab() {
        var members: IBoardColumnAutoMemberOptionItem[] = []
        if (this.state.editingOptions.memberOptions != null && this.state.editingOptions.memberOptions.autoMembers != null) {
            members = this.state.editingOptions.memberOptions.autoMembers.map(m => m)
        }
        return (
            <div style={{ padding: '32px' }}>
                <div className={tabDescriptionClass} style={{ paddingBottom: '32px' }}>
                    Auto participants can be used to automatically add participants and assignees to a card which enters this column. Participants and assignees which automatically added in previous column will be removed, but manually added participnats and assignees will remain.
                </div>
                <WMembersList
                    isAssigneeSelectable
                    defaultSelectedMembers={members.map((m) => {
                        const u = UsersSvc.usersById[m.userId]
                        return {
                            id: m.userId,
                            name: u.data().name,
                            color: u.data().color,
                            image: u.data().profilePictureUrl,
                            isAssignee: m.isAssignee
                        }
                    })}
                    availableMembers={UsersSvc.users.map((u) => {
                        return {
                            id: u.id,
                            name: u.data().name,
                            color: u.data().color,
                            image: u.data().profilePictureUrl,
                            isAssignee: false
                        }
                    })}
                    onUserAdded={this.handleMemberAdded}
                    onUserRemoved={this.handleMemberRemoved}
                    onAssigneeChanged={this.handleAssigneeChanged}
                />
            </div>
        )
    }

    private readonly handleMemberAdded = (userId: string) => {
        if (this.state.editingOptions.memberOptions.autoMembers.find((m) => m.userId == userId) != null) {
            return
        }

        this.state.editingOptions.memberOptions.autoMembers.push({ userId: userId, isAssignee: false })
        this.setState({})
    }

    private readonly handleMemberRemoved = (userId: string) => {
        const idx = this.state.editingOptions.memberOptions.autoMembers.findIndex(m => m.userId == userId)
        if (idx < 0) {
            return
        }
        this.state.editingOptions.memberOptions.autoMembers.splice(idx, 1)

        this.setState({})
    }

    private readonly handleAssigneeChanged = (userId: string, newValue: boolean) => {
        const idx = this.state.editingOptions.memberOptions.autoMembers.findIndex(m => m.userId == userId)
        if (idx < 0) {
            return
        }
        this.state.editingOptions.memberOptions.autoMembers[idx].isAssignee = newValue

        this.setState({})
    }

}

export interface EditColumnSettingsDialogProps {
    model: IColumnSettingsDialogModel
    onCancel: () => void
    onSave: (newOptions: IBoardColumnOptions) => void
}

export interface EditColumnSettingsDialogState {

    editingOptions: IBoardColumnOptions
    isAddActionFormVisible: boolean

}

export interface IColumnSettingsDialogModel {

    currentBoardId: string
    currentColumnId: string
    options: IBoardColumnOptions
    columns: ColumnModel[]

}

class AddActionForm extends React.Component<IAddActionFormProps, IAddActionFormState> {

    private boards: firebase.firestore.QuerySnapshot<IBoardData> = null
    private columns: SelectOption[] = []

    public constructor(props: IAddActionFormProps) {
        super(props)

        this.state = {
            addActionFormModel: {
                titleModel: new FieldModel(""),
                colorModel: new FieldModel(""),
                boardModel: new FieldModel(""),
                columnModel: new FieldModel("")
            }
        }

        this.init()
    }

    private readonly init = async () => {
        this.boards = await CurrentOrgSvc.currentOrg.snapshot.ref.collection("Boards").where("deleted", "==", false).get() as firebase.firestore.QuerySnapshot<IBoardData>
        this.setState({})
    }

    private readonly onSelectedBoardChanged = async () => {
        const boardId = this.state.addActionFormModel.boardModel.value
        if (boardId == "") {
            this.columns = []
            this.state.addActionFormModel.columnModel.value = ""
            this.setState({})
            return
        }

        this.columns = this.boards.docs.find((board) => {
            return board.id == boardId
        }).data().columns.map((col) => {
            return new SelectOption(col.id, col.name)
        })

        this.setState({})
    }

    private readonly handleSubmit = () => {
        const colors: { [name: string]: string } = {
            'blue': WColorPalette.Blue.color,
            'green': WColorPalette.Green.color,
            'orange': WColorPalette.Orange.color,
            'red': WColorPalette.Red.color
        }

        var hasError = false
        var title = this.state.addActionFormModel.titleModel.value
        if (title == null) {
            title = ""
        }
        title = title.trim()
        if (title.length < 1) {
            hasError = true
            this.state.addActionFormModel.titleModel.error = "This field is mandatory!"
        }
        if (title.length > 32) {
            hasError = true
            this.state.addActionFormModel.titleModel.error = "Max 32 characters!"
        }


        var column = this.state.addActionFormModel.columnModel.value
        if (column == null) {
            column = ""
        }
        column = column.trim()
        if (column.length < 1) {
            hasError = true
            this.state.addActionFormModel.columnModel.error = "This field is mandatory!"
        }

        var board = this.state.addActionFormModel.boardModel.value
        if (board == null) {
            board = ""
        }
        if (board == "") {
            hasError = true
            this.state.addActionFormModel.boardModel.error = "This field is mandatory!"
        }

        var color = this.state.addActionFormModel.colorModel.value
        if (color == null) {
            color = ""
        }
        color = color.trim()
        color = colors[color]
        if (color == null) {
            hasError = true
            this.state.addActionFormModel.colorModel.error = "This field is mandatory!"
        }

        if (hasError) {
            this.setState({})
            return
        }

        this.props.onSave(title, color, board, column)

    }

    render() {
        const options: { [key: string]: string } = {}
        var boardOptions: SelectOption[] = []

        if (this.boards != null) {
            boardOptions = this.boards.docs.map((board) => {
                return new SelectOption(board.id, board.data().name)
            })
        }
        return (
            <WVBox spacing={16}>
                <WVBoxCell>
                    <WTextField
                        label="Title"
                        helperText="The title of the action button. Max 32 characters."
                        model={this.state.addActionFormModel.titleModel}
                    />
                </WVBoxCell>
                <WVBoxCell>
                    <WSelect
                        label="Color"
                        helperText="The color of the action button"
                        model={this.state.addActionFormModel.colorModel}
                        options={[
                            new SelectOption("blue", "Blue"),
                            new SelectOption("green", "Green"),
                            new SelectOption("orange", "Orange"),
                            new SelectOption("red", "Red"),
                        ]}
                    />
                </WVBoxCell>
                <WVBoxCell>
                    <WSelect
                        label="Target board"
                        helperText="The board which the card will move after click."
                        model={this.state.addActionFormModel.boardModel}
                        options={boardOptions}
                        onChange={this.onSelectedBoardChanged}
                    />
                </WVBoxCell>
                <WVBoxCell>
                    <WSelect
                        label="Target column"
                        helperText="The column which the card will move after click."
                        model={this.state.addActionFormModel.columnModel}
                        options={this.columns}
                    />
                </WVBoxCell>
                <WVBoxCell hAlign={EHAlign.Right}>
                    <WButton title="Cancel" size="small" variant="text" color="primary" onClick={this.props.onCancel} />
                    <WButton title="Add action" size="small" variant="contained" color="primary" onClick={this.handleSubmit} />
                </WVBoxCell>
            </WVBox>
        )
    }

}

interface OptionType {
    label: string;
    value: string;
}

interface IAddActionFormProps {

    currentColumnId: string
    onCancel: () => void
    onSave: (title: string, color: string, targetBoardId: string, targetColumnId: string) => void

}

interface IAddActionFormState {

    addActionFormModel: IAddActionFormModel

}

interface IAddActionFormModel {
    titleModel: FieldModel<string>,
    colorModel: FieldModel<string>,
    boardModel: FieldModel<string>,
    columnModel: FieldModel<string>
}

const tabDescriptionClass = style({
    width: "100%",
    textAlign: 'center',
    fontSize: "14px",
    color: '#888'
})

const checkboxDescriptionClass = style({
    width: '100%',
    paddingLeft: '32px',
    paddingBottom: '16px',
    fontSize: '12px',
    color: '#888'
})

const sectionTitleStyle = style({
    width: '100%',
    fontSize: '18px',
    fontWeight: EFontWeight.Semibold.value,
    color: '#222',
    marginBottom: '4px',
})

const actionButtonContainerStyle = style({
    width: '100%',
    display: 'flex'
})