import * as React from 'react'
import { ModalButton, WModal } from '../../../../../../wds/Modal'
import { WTextField } from '../../../../../../wds/TextField'
import { ProjectBoardListSceneController } from '../../boardList/ProjectBoardListSceneController'
import { EditBoardNameDialogModel } from '../../boardList/ProjectsBoardListSceneModel'

export class EditBoardNameDialog extends React.Component<EditBoardNameDialogProps> {

    private readonly handleCancelClick = () => {
        this.props.controller.onEditBoardCancelClick()
    }

    private readonly handleSubmit = () => {
        this.props.controller.onEditBoardSubmit()
    }

    public render() {
        return (
            <WModal
                title="Edit board name"
                error={this.props.model.error}
                isLoading={this.props.model.isLoading}
                buttons={[
                    new ModalButton("Cancel", this.handleCancelClick, "text"),
                    new ModalButton("Save", null, "contained", "primary"),
                ]}
                onSubmit={this.handleSubmit}
            >
                <WTextField
                    label="Name"
                    model={this.props.model.nameModel}
                    helperText="The new name of the board."
                    required
                    autoFocus
                />
            </WModal>
        )
    }

}

export interface EditBoardNameDialogProps {

    model: EditBoardNameDialogModel
    controller: ProjectBoardListSceneController

}