import { AController } from "@eagerlogic/react-mvc";
import { WibSvc } from "backend/wib/WibService";
import "firebase/analytics";
import * as firebase from "firebase/app";
import { bugsnagClient } from "index";
import { IWib } from "shared/entities/wib/IWib";
import { IWibMessage } from "shared/entities/wib/IWibMessage";
import { ChangeListenerCanceler, FsEntity } from "utils/Fs";
import { AWibHandler } from "wibib-sdk-fe/WibHandlerBase";
import { Snackbar } from "../../../../wds/Snackbar";
import { WibHandlerRegistry } from "./WibHandlerRegistry";
import { WibSceneModel } from "./WibSceneModel";

export class WibSceneController extends AController<WibSceneModel> {

    private wib: FsEntity<IWib<{}>>
    private messages: firebase.firestore.QuerySnapshot<IWibMessage<{}>>
    private wibListenerCanceler: ChangeListenerCanceler
    private messageListenerCanceler: ChangeListenerCanceler
    private handler: AWibHandler<{}>

    public constructor(
        public readonly wibId: string,
        private readonly closeHandler: () => void
    ) {
        super()
    }

    protected createInitialModel(): WibSceneModel {
        return new WibSceneModel()
    }

    async init() {
        this.wib = await WibSvc.findWibById(this.wibId)

        this.handler = WibHandlerRegistry.getHandlerByType(this.wib.data.type)

        this.wibListenerCanceler = this.wib.addChangeListener((snapshot) => {
            this.wib = snapshot
            this.refresh()
        })

        this.messageListenerCanceler = WibSvc.onWibMessagesChanged(this.wibId).addChangeListener((s) => {
            this.messages = s
            this.refresh()
        })

        firebase.analytics().logEvent("wib_opened", { wibTypeId: this.wib.data.type, wibTypeName: this.handler.name })

    }

    public destroy() {
        if (this.wibListenerCanceler != null) {
            this.wibListenerCanceler.cancel()
        }

        if (this.messageListenerCanceler != null) {
            this.messageListenerCanceler.cancel()
        }
    }

    public readonly handeChangeTitle = async (newTitle: string) => {
        try {
            await WibSvc.changeSubject(this.wibId, newTitle)
            firebase.analytics().logEvent('wib.title_changed')
        } catch (ex) {
            var msg = ex.message != null ? "" + ex.message : "Failed to change subject: Server error occured!"
            Snackbar.show('danger', msg)
            this.refresh()
        }
    }

    public readonly handleDropFile = async (evt: React.DragEvent<HTMLDivElement>) => {
        this.model.isLoading = true
        this.render(this.model)

        try {
            evt.persist()
            if (evt.dataTransfer.items) {
                // Use DataTransferItemList interface to access the file(s)
                for (var i = 0; i < evt.dataTransfer.items.length; i++) {
                    // If dropped items aren't files, reject them
                    if (evt.dataTransfer.items[i].kind === 'file') {
                        var file = evt.dataTransfer.items[i].getAsFile();
                        if (file.size > 25 * 1024 * 1024) {
                            Snackbar.show("danger", "Maximum file size is 25MB: " + file.name)
                            continue;
                        }
                        try {
                            await WibSvc.attachFile(this.wibId, file.name, file)
                        } catch (ex) {
                            Snackbar.show("danger", "Upload failed: " + file.name)
                            console.error(ex)
                            if (bugsnagClient != null) {
                                bugsnagClient.notify(ex)
                            }
                        }
                    }
                }
            } else {
                // Use DataTransfer interface to access the file(s)
                for (var i = 0; i < evt.dataTransfer.files.length; i++) {
                    var file = evt.dataTransfer.files[i]
                    if (file.size > 25 * 1024 * 1024) {
                        Snackbar.show("danger", "Maximum file size is 25MB: " + file.name)
                        continue;
                    }
                    try {
                        await WibSvc.attachFile(this.wibId, file.name, file)
                    } catch (ex) {
                        Snackbar.show("danger", "Upload failed: " + file.name)
                        console.error(ex)
                        if (bugsnagClient != null) {
                            bugsnagClient.notify(ex)
                        }
                    }
                }
            }
        } finally {
            this.model.isLoading = false
            this.render(this.model)
        }
    }

    private refresh() {
        if (this.wib == null) {
            return
        }

        if (this.messages == null) {
            return
        }


        this.model.isLoading = false

        this.model.wib = this.wib.data
        this.model.messages = this.messages

        this.render(this.model)
    }

}