import { CurrentOrgSvc } from "backend/aaa/CurrentOrgSvc"
import { IBoardData } from "shared/entities/projects/IBoardData"
import { ValidationException } from "shared/jalidate/Jalidate"
import { transact } from "utils/Fs"

export const deleteColumnFun = async (boardId: string, colId: string) => {
    await transact(async (t) => {
        const boardRef = CurrentOrgSvc.currentOrg.snapshot.ref
            .collection("Boards")
            .doc(boardId)

        const boardE = await t.get(boardRef)
        if (!boardE.exists) {
            throw new Error("Cant find board with id: " + boardId)
        }
        const boardD = boardE.data() as IBoardData

        const col = boardD.columns.find(col => col.id == colId)
        if (col == null) {
            throw new Error("Can't find column with id: " + colId)
        }

        const hasRef = boardD.columns.filter((c) => {
            if (c.id == col.id) {
                return false
            }
            if (c.options == null || c.options.actionOptions == null || c.options.actionOptions.actions == null) {
                return false
            }
            return c.options.actionOptions.actions.filter((o) => {
                o.targetColumnId == col.id
            }).length > 0
        }).length > 0

        if (hasRef) {
            throw ValidationException.createWithMessage("Can't delete column because some actions referencing this column!")
        }

        if (col.cards.length > 0) {
            const wibs: firebase.firestore.DocumentSnapshot[] = []
            col.cards.forEach(async (cardId) => {
                wibs.push(
                    await CurrentOrgSvc.currentOrg.snapshot.ref
                        .collection("Wibs")
                        .doc(cardId)
                        .get()
                )
            })

            wibs.forEach((wib) => {
                t.update(wib.ref, { deleted: true })
            })
        }

        boardD.columns = boardD.columns.filter(col => col.id != colId)
        t.update(boardRef, { columns: boardD.columns })
    }, null)
}