import * as React from 'react'
import { style } from 'typestyle'
import { HorizontalLoadingIndicator } from 'wds/HorizontalLoadingIndicator'
import { EFontWeight } from 'wds/styles/EFontWeight'
import { WScrollBox } from '../wds/ScrollBox'

export class PageLayout extends React.Component<PageLayoutProps, PageLayoutState> {

    render() {
        return (
            <div className={rootStyle} style={{ backgroundColor: this.props.bgColor != null ? this.props.bgColor : '#f8f8f8' }}>
                <div className={titleBarStyle}>
                    {this.props.title}
                </div>
                <div style={{ width: "100%", height: "8px", zIndex: 900 }}>
                    {this.props.isLoading &&
                        <HorizontalLoadingIndicator />
                    }
                </div>
                <div className={contentStyle} style={{
                    opacity: this.props.isLoading ? 0.2 : 1.0,
                    pointerEvents: this.props.isLoading ? "none" : "all"
                }}>
                    {this.props.disableScroll &&
                        this.props.children
                    }

                    {!this.props.disableScroll &&
                        <WScrollBox>
                            {this.props.children}
                        </WScrollBox>
                    }
                </div>
            </div>
        )
    }

}

export interface PageLayoutProps {

    title: string,
    isLoading: boolean,
    bgColor?: string,
    disableScroll?: boolean

}

interface PageLayoutState {

}

const rootStyle = style({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
})

const titleBarStyle = style({
    width: "100%",
    height: "64px",
    minHeight: "64px",
    display: "flex",
    paddingLeft: "32px",
    paddingRight: "32px",
    backgroundColor: "#fff",
    alignItems: "center",
    boxShadow: "1px 1px 5px rgba(0,0,0, 0.5)",
    zIndex: 1000,
    color: "#222",
    fontSize: "22px",
    fontWeight: EFontWeight.Medium.value,

})

const contentStyle = style({
    width: "100%",
    height: "100%",
    display: "flex",
    overflow: "hidden"
})