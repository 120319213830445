import * as React from 'react'
import { IWib } from 'shared/entities/wib/IWib'
import { UsersSvc } from '../../../../../backend/aaa/UsersSvc'
import { ProfilePicture } from '../../../../../components/ProfilePicture'
import { WScrollBox } from '../../../../../wds/ScrollBox'
import { EFontWeight } from '../../../../../wds/styles/EFontWeight'
import { WTab, WTabbedPane } from '../../../../../wds/TabbedPane'

export class CommonWibSidebarView extends React.Component<CommonWibSidebarViewProps, CommonWibSidebarViewState> {

    render() {
        return (
            <div
                style={{ display: 'flex', width: '100%', flexDirection: 'column', height: '100%', overflow: 'hidden' }}
            >
                {this.props.headRenderer != null &&
                    <div
                        style={{
                            width: '100%',
                        }}
                    >
                        {this.props.headRenderer()}
                    </div>
                }
                {this.props.tabs != null && this.props.tabs.length > 0 &&
                    <div style={{ width: '100%', flex: '1', overflow: 'hidden' }}>
                        <WTabbedPane tabs={this.props.tabs.map((item) => {
                            return new WTab(null, item.icon, 0, () => item.renderContent())
                        })} />
                    </div>
                }
            </div>
        )
    }

}

export interface CommonWibSidebarViewProps {

    headRenderer?: () => React.ReactNode
    tabs?: ACommonWibSidebarTabRenderer[]

}

export abstract class ACommonWibSidebarTabRenderer {

    public constructor(
        public readonly icon: string
    ) {

    }

    abstract renderContent(): React.ReactNode

}

export interface CommonWibSidebarViewState {

}

export class CommonWibWatchersSidebarTabRenderer extends ACommonWibSidebarTabRenderer {

    public constructor(
        public readonly wib: IWib<{}>) {
        super("visibility")
    }

    renderContent(): React.ReactNode {
        return (
            <WScrollBox>
                {this.wib.members.map((userId) => {
                    const user = UsersSvc.usersById[userId]
                    return (
                        <div
                            key={"watcher-" + userId}
                            style={{
                                display: 'flex',
                                width: '100%',
                                padding: '8px',
                                alignItems: 'center'
                            }}
                        >
                            <ProfilePicture
                                name={user.data().name}
                                profilePictureUrl={user.data().profilePictureUrl}
                                size={24}
                                backgroundColor={user.data().color}
                                borderWidth={0}
                            />
                            <div style={{ minWidth: '8px' }}></div>
                            <div
                                style={{
                                    flex: 1,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    fontSize: '16px',
                                    fontWeight: EFontWeight.Semibold.value,
                                    color: '#222'
                                }}
                            >

                            </div>
                        </div>
                    )
                })}
            </WScrollBox>
        )
    }

}