import { AController } from "@eagerlogic/react-mvc";
import 'firebase/analytics';
import * as firebase from "firebase/app";
import "firebase/auth";
import { Navigation } from "utils/Navigation";
import { LoginSceneModel } from "./LoginSceneModel";

export class LoginSceneController extends AController<LoginSceneModel>{

    protected createInitialModel(): LoginSceneModel {
        return new LoginSceneModel()
    }

    init() {
        firebase.analytics().logEvent('login_scene.open')
    }

    public onCancelClick() {
        firebase.analytics().logEvent('login_scene.cancel')
        window.location.href = "/"
    }

    public async onLoginClick() {
        this.model.isLoading = true
        this.render(this.model)
        try {
            await firebase.auth().signInWithEmailAndPassword(this.model.emailModel.value, this.model.passwordModel.value)
            firebase.analytics().logEvent('login_scene.login_success')
            Navigation.pushState(null, "/app/index.rsc")
        } catch (ex) {
            firebase.analytics().logEvent('login_scene.login_failed')
            console.error(ex)
            this.model.globalError = "Invalid email or password!"
            this.model.isLoading = false
            this.render(this.model)
        }
    }

}