import { IUserData } from "shared/entities/aaa/IUserData";
import { Event } from "utils/Event";
import { ChangeListenerCanceler, fs, FsQueryListener } from "utils/Fs";

export class UsersSvc {

    public static readonly onChange = new Event<any>()

    private static orgId: string
    private static canceler: ChangeListenerCanceler
    private static _users: firebase.firestore.QuerySnapshot<IUserData>

    public static get users() {
        if (this.canceler == null) {
            throw Error("This service isn't initialized yet")
        }

        return this._users.docs.map((item) => {
            return item
        })
    }

    public static get usersById() {
        if (this.canceler == null) {
            throw Error("This service isn't initialized yet")
        }

        var res: { [userId: string]: firebase.firestore.DocumentSnapshot<IUserData> } = {}
        this.users.forEach((item) => {
            res[item.id] = item
        })

        return res
    }

    public static async init(orgId: string) {
        if (this.canceler != null) {
            throw Error("This service is already initialized!")
        }
        this.orgId = orgId
        const q = fs().collection("Users").where("organizationId", "==", this.orgId)
        this._users = await q.get() as firebase.firestore.QuerySnapshot<IUserData>
        this.canceler = new FsQueryListener<IUserData>(q).addChangeListener((snapshot) => {
            this._users = snapshot
            this.onChange.fire(null)
        })
    }

    private constructor() { }

}