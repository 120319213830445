import { IBoardColumn, IBoardColumnAutoMemberOptionItem } from "shared/entities/projects/IBoardData";
import { Wib } from "shared/entities/wib/Wib";
import { ICardWibContent } from "shared/entities/wib/wibs/ICardWibContentType";

export const handleAutoMembers = (wib: Wib<ICardWibContent>, nextCol: IBoardColumn, actionUserId: string) => {

    var nextMembers: IBoardColumnAutoMemberOptionItem[] = []
    if (nextCol != null) {
        nextMembers = nextCol.options.memberOptions.autoMembers
    }

    const content = wib.content
    const members: string[] = wib.members
    const assignees: string[] = wib.assignees
    const autoMembers: string[] = content.autoMembers
    const autoAssignees: string[] = content.autoAssignees

    const membersToAdd: string[] = []
    const membersToRemove: string[] = []
    const assigneesToAdd: string[] = []
    const assigneesToRemove: string[] = []

    for (let m of nextMembers) {
        if (members.indexOf(m.userId) < 0) {
            membersToAdd.push(m.userId)
        }
    }

    for (let m of autoMembers) {
        if (nextMembers.findIndex(i => m == i.userId) < 0) {
            membersToRemove.push(m)
        }
    }

    for (let m of nextMembers) {
        if (m.isAssignee && assignees.indexOf(m.userId) < 0) {
            assigneesToAdd.push(m.userId)
        }
    }

    for (let m of autoAssignees) {
        var nm = nextMembers.find(i => m == i.userId)
        if (nm == null || !nm.isAssignee) {
            assigneesToRemove.push(m)
        }
    }

    wib.changeContent((c) => {
        for (let a of assigneesToRemove) {
            c.autoAssignees = c.autoAssignees.filter((cam) => cam != a)
        }
        for (let m of membersToRemove) {
            c.autoMembers = c.autoMembers.filter((cam) => cam != m)
        }
        for (let m of membersToAdd) {
            c.autoMembers.push(m)
        }
        for (let a of assigneesToAdd) {
            c.autoAssignees.push(a)
        }
        return c
    })

    for (let m of membersToAdd) {
        wib.addMember(m, actionUserId)
    }

    for (let a of assigneesToAdd) {
        wib.addAssignee(a)
    }

    for (let a of assigneesToRemove) {
        wib.removeAssignee(a)
    }

    for (let m of membersToRemove) {
        wib.removeMember(m)
    }
}