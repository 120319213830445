import { EUserRole } from "shared/entities/aaa/EUserRole";
import { EmailValidator, NullConverter, StringLengthValidator, StringToEnumConverter, StringToLowerCaseConverter, StringTrimConverter as StringTrimConverter } from "../../jalidate/CommonValidators";
import { JalidatorBuilder } from "../../jalidate/Jalidate";



export const userNameValidator = JalidatorBuilder
    .init(new NullConverter(""))
    .withAction(new StringTrimConverter())
    .withAction(new StringLengthValidator(3, 64, "The name must be minimum 3 maximum 64 charcters!"))
    .build()

export const emailValidator = JalidatorBuilder
    .init(new NullConverter(""))
    .withAction(new StringTrimConverter())
    .withAction(new StringToLowerCaseConverter())
    .withAction(new EmailValidator("Invalid email address!"))
    .withAction(new StringLengthValidator(5, 64, "The email address must be minimum 5 maximum 64 charcters!"))
    .build()

export const userRoleValidator = JalidatorBuilder
    .init(new NullConverter(""))
    .withAction(new StringTrimConverter())
    .withAction(new StringToEnumConverter("Invalid user role!", (input) => {
        var res = EUserRole.findByName(input)
        return res
    }))
    .build()

export const orgNameValidator = JalidatorBuilder
    .init(new NullConverter(""))
    .withAction(new StringTrimConverter())
    .withAction(new StringLengthValidator(3, 32, "The organization name must be minimum 3 maximum 64 charcters!"))
    .build()

export const passwordValidator = JalidatorBuilder
    .init(new NullConverter(""))
    .withAction(new StringTrimConverter())
    .withAction(new StringLengthValidator(6, 32, "The password must be minimum 6 maximum 64 charcters!"))
    .build()
