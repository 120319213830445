import { AScene } from "@eagerlogic/react-mvc";
import * as React from "react";
import { ModalButton, WModal } from "wds/Modal";
import { WTextField } from "wds/TextField";
import { LoginSceneController } from "./LoginSceneController";
import { LoginSceneModel } from "./LoginSceneModel";

export class LoginScene extends AScene<LoginSceneProps, LoginSceneModel, LoginSceneController> {

    protected createControlller(): LoginSceneController {
        return new LoginSceneController()
    }

    private handleCancelClick = () => {
        this.controller.onCancelClick()
    }

    private handleSaveClick = () => {
        this.controller.onLoginClick()
    }

    render(): React.ReactNode {
        return (
            <WModal
                title="Login"
                helperText="Fill the form below to loginxxx"
                error={this.model.globalError}
                isLoading={this.model.isLoading}
                buttons={[
                    new ModalButton("Cancel", this.handleCancelClick, "text"),
                    new ModalButton("Login", this.handleSaveClick, "contained", "primary"),
                ]}
            >
                <WTextField
                    label="Email"
                    required={true}
                    helperText="Your email address"
                    model={this.model.emailModel}
                />
                <WTextField
                    label="Password"
                    required={true}
                    helperText="Your password"
                    model={this.model.passwordModel}
                    password={true}
                />
            </WModal>
        )
    }


}

export class LoginSceneProps {

}