import { AScene } from '@eagerlogic/react-mvc';
import * as React from 'react';
import { PageLayout } from '../../../../../components/PageLayout';
import { BoardSceneController } from './BoardSceneController';
import { BoardSceneModel } from './BoardSceneModel';
import { AddColumnDialog } from './components/AddColumnDialog';
import { BoardView } from './components/BoardView';
import { EditcolumnNameDialog } from './components/EditColumnNameDialog';

export class BoardScene extends AScene<BoardSceneProps, BoardSceneModel, BoardSceneController> {

    protected createControlller(): BoardSceneController {
        return new BoardSceneController(this.props.boardId)
    }

    componentWillUnmount() {
        this.controller.destory()
    }

    render(): React.ReactNode {
        return (
            <>
                <PageLayout
                    title={this.model.isLoading ? "Loading..." : this.model.boardModel.name}
                    isLoading={this.model.isLoading}
                    bgColor="#fff"
                    disableScroll
                >
                    {this.model.boardModel != null &&
                        <BoardView model={this.model.boardModel} controller={this.controller} />
                    }

                    {this.model.addColumnDialogModel != null &&
                        <AddColumnDialog model={this.model.addColumnDialogModel} controller={this.controller} />
                    }
                    {this.model.editColumnNameDialogModel != null &&
                        <EditcolumnNameDialog model={this.model.editColumnNameDialogModel} controller={this.controller} />
                    }
                </PageLayout>
            </>
        )
    }

}

export interface BoardSceneProps {

    boardId: string

}