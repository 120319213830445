import bugsnag, { Bugsnag } from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import "firebase/analytics";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { PresenceHadler as PresenceHandler } from "modules/aaa/PresenceHandler";
import { AcceptInvitationScene } from "modules/aaa/scenes/acceptInvitation/AcceptInvitationScene";
import { LoginScene } from "modules/aaa/scenes/login/LoginScene";
import { RegisterScene } from "modules/aaa/scenes/register/RegisterScene";
import { MainScene } from "modules/app/main/MainScene";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { IntlProvider } from 'react-intl';
import { Navigation } from "utils/Navigation";
import { WSnackBarContainer } from "wds/Snackbar";
import { EColor, WColorPalette } from './wds/styles/WColor';


declare var ENV_MODE: string;



var titleNotificationTimerHandler: number = null
var titleNotificationVisible: boolean = false
const w: any = window
var prevCount = 0;
export function updateNotificationsCount(count: number) {
    if (count < 0) {
        count = 0
    }
    if (prevCount == count) {
        return
    }
    window.postMessage({ type: "updateNotificationCount", count: count }, "*")
    prevCount = count
    if (titleNotificationTimerHandler != null) {
        window.clearInterval(titleNotificationTimerHandler)
    }
    titleNotificationTimerHandler = null

    titleNotificationVisible = false
    if (count < 1) {
        document.title = "Wibib"
    } else {
        titleNotificationTimerHandler = window.setInterval(() => {
            if (titleNotificationVisible) {
                document.title = "Wibib"
                titleNotificationVisible = false
            } else {
                document.title = "(" + count + ") Wibib"
                titleNotificationVisible = true
            }
        }, 1000)
    }
}

const devFbConfig = {
    apiKey: "AIzaSyBLY5sezPJBTdN2aC1D8fMk0cun4RsgP-A",
    authDomain: "wibib-test.firebaseapp.com",
    databaseURL: "https://wibib-test.firebaseio.com",
    projectId: "wibib-test",
    storageBucket: "wibib-test.appspot.com",
    messagingSenderId: "306774627600",
    appId: "1:306774627600:web:233dfe9aea03849b14c195",
    measurementId: "G-9F11M01TEE"
};

const prodFbConfig = {
    apiKey: "AIzaSyC69PRsTjrNgIJNd3UIu7v2zBgo4jrSo6w",
    authDomain: "wibib-prod.firebaseapp.com",
    databaseURL: "https://wibib-prod.firebaseio.com",
    projectId: "wibib-prod",
    storageBucket: "wibib-prod.appspot.com",
    messagingSenderId: "749947271787",
    appId: "1:749947271787:web:13563d9f93ed3d9c664f2e",
    measurementId: "G-5Q4PZQY8TK"
}

const localConfig = {
    fbConfig: devFbConfig,
    useLocalFunctionsEmulator: true,
    bugsnagToken: null
}
const testConfig = {
    fbConfig: devFbConfig,
    useLocalFunctionsEmulator: false,
    bugsnagToken: '68a19eda63e8b09d23fea7e5e0a1afb6'
}
const prodConfig = {
    fbConfig: prodFbConfig,
    useLocalFunctionsEmulator: false,
    bugsnagToken: '64f4e7b70dc6b67d9514b320a6cb608d'
}

//const isDev: boolean = ENV_MODE == "development"

export var currentAuthUser: firebase.User = null
var isAuthfinished: boolean = false

export function clearAuth() {
    isAuthfinished = false
}

export var bugsnagClient: Bugsnag.Client = null
var ErrorBoundary = null

const theme = createMuiTheme({
    palette: {
        primary: { main: EColor.Primary.color, contrastText: "#fff" },
        secondary: { main: WColorPalette.Red_700.color, contrastText: "#fff" },
    },
    zIndex: {
        drawer: 12000,
        modal: 13000,
        snackbar: 14000,
        tooltip: 15000,
    }
})

window.addEventListener("load", () => {
    if (window.innerWidth <= 640) {
        console.log("MOBILE")
        document.getElementById("root").style.display = "none"
        document.getElementById("mobile-warning").style.display = "block"
    }
    init()
})

export var config = localConfig

function init() {
    console.log("App started...")
    console.log("    Mode: " + ENV_MODE)

    var body = document.getElementById("root") as HTMLDivElement
    body.ondragover = (e) => {
        e.preventDefault()
    }
    body.ondrop = (e) => {
        e.preventDefault()
    }

    if (location.hostname == "localhost") {
        console.log("    Environment: LOCAL")
        config = localConfig
    } else if (location.hostname == "test.wibib.com") {
        console.log("    Environment: TEST")
        config = testConfig
    } else if (location.hostname == "app.wibib.com") {
        console.log("    Environment: PROD")
        config = prodConfig
    } else {
        throw Error("Invalid environment: " + location.hostname)
    }
    initBugsnag(config)
    initFirebase(config)
    Navigation.init()
    Navigation.onStateChanged.addListener(() => {
        rerender()
        firebase.analytics().setCurrentScreen(Navigation.currentState.path)
    })
    Navigation.onStateChanged.fire()
}

function initBugsnag(config: { bugsnagToken: string }) {
    if (config.bugsnagToken != null) {
        bugsnagClient = bugsnag(config.bugsnagToken)
        bugsnagClient.use(bugsnagReact, React)
        ErrorBoundary = bugsnagClient.getPlugin('react')
    }
}

function initFirebase(config: { fbConfig: {}, useLocalFunctionsEmulator: boolean }) {

    firebase.initializeApp(config.fbConfig)

    // firebase.firestore().settings({
    //     cacheSizeBytes: 1024 * 1024 * 40 //40mb
    // });

    // firebase.firestore().enablePersistence()
    if (config.useLocalFunctionsEmulator) {
        firebase.app().functions("europe-west1").useFunctionsEmulator("http://localhost:5001")
    }

    firebase.auth().onAuthStateChanged(async (user) => {
        currentAuthUser = user;
        isAuthfinished = true
        if (user == null) {
            PresenceHandler.onSignedOut()
            firebase.analytics().logEvent("session_finished")
            firebase.analytics().setUserId(null)
            rerender()
        } else {
            firebase.analytics().setUserId(user.uid)
            firebase.analytics().logEvent("session_started")
            PresenceHandler.onSignedIn(user.uid)
            rerender()
        }
    })


}

export function rerender() {
    if (ErrorBoundary == null) {
        ReactDOM.render(
            (
                <ThemeProvider theme={theme}>
                    <IntlProvider locale='en'>
                        <div style={{ width: "100%", height: "100%", maxWidth: "100%", maxHeight: "100%" }}>
                            <WSnackBarContainer />
                            {renderScene()}
                        </div>
                    </IntlProvider>
                </ThemeProvider>
            ),
            document.getElementById('root')
        )
    } else {
        ReactDOM.render(
            (
                <ErrorBoundary>
                    <ThemeProvider theme={theme}>
                        <IntlProvider locale='en'>
                            <div style={{ width: "100%", height: "100%", maxWidth: "100%", maxHeight: "100%" }}>
                                <WSnackBarContainer />
                                {renderScene()}
                            </div>
                        </IntlProvider>
                    </ThemeProvider>
                </ErrorBoundary>
            ),
            document.getElementById('root')
        )
    }
}

function renderScene() {
    var path = Navigation.currentState.path
    console.log("path: " + path)

    if (!isAuthfinished) {
        return <div>Loading...</div>
    }

    if (path == "/") {
        window.location.href = "/app/index.rsc"
    } else if (path.startsWith("/app/")) {
        if (currentAuthUser == null) {
            window.location.href = "/login.rsc"
            return
        } else {
            if (path == "/app/index.rsc") {
                window.setTimeout(() => {
                    Navigation.pushState(null, "/app/projects/index.rsc")
                }, 300)
                return
            } else {
                return <MainScene />
            }
        }
    } else {
        if (path == "/register.rsc") {
            return <RegisterScene />
        } else if (path == "/login.rsc") {
            return <LoginScene />
        } else if (path == "/logout.rsc") {
            window.setTimeout(async () => {
                await firebase.auth().signOut()
                window.location.href = "/"
            }, 100)
            return (<></>)
        } else if (path == "/acceptInvitation.rsc") {
            return <AcceptInvitationScene invitationId={Navigation.currentState.params["id"]} />
        }
    }
}


