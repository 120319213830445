import { IWib, IWibNotification } from "shared/entities/wib/IWib"
import { WColorPalette } from "shared/utils/ColorPalette"

export class Wib<$ContentType extends {}> {

    private _updatedData: Partial<IWib<$ContentType>> = {}

    public constructor(
        private _data: IWib<$ContentType>
    ) {

    }




    public get data() {
        return this._data
    }




    public get updatedData() {
        return this._updatedData
    }




    public get parentId() {
        return this._data.parentId
    }

    public changeParentId(newParentId: string) {
        this.changeData({ parentId: newParentId })
    }




    public get subject() {
        return this._data.subject
    }

    public changeSubject(newSubject: string) {
        this.changeData({ subject: newSubject })
    }




    public get members() {
        return this._data.members.map((m) => m)
    }

    public isMember(userId: string): boolean {
        return this.getMemberIndex(userId, false) > -1
    }

    public addMember(userId: string, adderId: string) {
        if (this.isMember(userId)) {
            return
        }

        const members = this._data.members
        const notifications = this._data.memberNotifications
        const lastSeens = this._data.memberLastSeens
        members.push(userId)
        notifications[userId] = {
            notifiedAt: Date.now(),
            reason: {
                message: "added you as a meber",
                color: ENotiReasonColor.AddAsMember.color
            },
            senderId: adderId,
            unreadNotificationsCount: userId == adderId ? 0 : 1
        }
        lastSeens[userId] = -1

        this.changeData({
            members: members,
            memberNotifications: notifications,
            memberLastSeens: lastSeens,
            lastUpdatedAt: Date.now()
        })
    }

    public removeMember(userId: string) {
        const idx = this.getMemberIndex(userId)

        const members = this._data.members
        const notifications = this._data.memberNotifications
        const lastSeens = this._data.memberLastSeens
        members.splice(idx, 1)
        delete notifications[userId]
        delete lastSeens[userId]

        const tidx = this._data.assignees.indexOf(userId)
        if (tidx > -1) {
            this._data.assignees.splice(tidx, 1)
            this.changeData({ assignees: this._data.assignees })
        }

        this.changeData({
            members: members,
            memberNotifications: notifications,
            memberLastSeens: lastSeens
        })
    }




    public get files() {
        return this.data.files.map(f => f)
    }

    public isFileExists(fileName: string): boolean {
        if (this.data.files == null) {
            return false
        }
        fileName = fileName.trim()
        return this.data.files.findIndex((f) => f.fileName == fileName) > -1
    }

    public addFile(fileName: string, downloadUrl: string) {
        fileName = fileName.trim()
        if (this.isFileExists(fileName)) {
            throw new Error("A file with this name already exists!")
        }

        if (this.data.files == null) {
            this.data.files = []
        }

        this.data.files.push({
            fileName: fileName,
            downloadUrl: downloadUrl,
            uploadedAt: Date.now()
        })

        this.changeData({ files: this.data.files })
    }




    public get content() {
        return this._data.content
    }

    public setContent(newContent: $ContentType) {
        this.changeData({ content: newContent })
    }

    public changeContent(fun: (c: $ContentType) => $ContentType) {
        this.setContent(fun(this._data.content))
    }

    public updateContent(partialContent: Partial<$ContentType>) {
        var newContent: $ContentType = {
            ...this._data.content,
            ...partialContent
        }
        this.setContent(newContent)
    }




    public notifyOneMember(userId: string, senderId: string, reason: string, color: ENotiReasonColor) {
        if (!this.isMember(userId)) {
            throw new Error("The given user isn't a member of this wib!")
        }
        const now = Date.now()
        const oldNoti = this.getMemberNotification(userId)
        const newUnreadCount = oldNoti.unreadNotificationsCount + (userId == senderId ? 0 : 1)
        const newNoti: IWibNotification = {
            senderId: senderId,
            reason: {
                message: reason,
                color: color.color
            },
            notifiedAt: now,
            unreadNotificationsCount: newUnreadCount
        }
        this._data.memberNotifications[userId] = newNoti
        this.changeData({ memberNotifications: this._data.memberNotifications })
    }

    public notifyMembers(senderId: string, reason: string, color: ENotiReasonColor) {
        this._data.members.forEach((m) => {
            this.notifyOneMember(m, senderId, reason, color)
        })
    }

    public markAsSeen(memberId: string) {
        if (!this.isMember(memberId)) {
            throw new Error("The given user isn't a member of this wib!")
        }
        const notifications = this._data.memberNotifications
        notifications[memberId].unreadNotificationsCount = 0
        const lastSeens = this._data.memberLastSeens
        lastSeens[memberId] = Date.now()

        this.changeData({
            memberNotifications: notifications,
            memberLastSeens: lastSeens
        })
    }

    public markAsUnread(memberId: string) {
        if (!this.isMember(memberId)) {
            throw new Error("The given user isn't a member of this wib!")
        }

        const n = this._data.memberNotifications[memberId]
        if (n.unreadNotificationsCount < 1) {
            n.unreadNotificationsCount = 1
        }
        n.notifiedAt = Date.now()

        this.changeData({ memberNotifications: this._data.memberNotifications })
    }

    public get assignees() {
        return this._data.assignees.map((m) => m)
    }

    public addAssignee(memberId: string) {
        if (!this.isMember(memberId)) {
            throw new Error("The given user isn't a member of this wib!")
        }

        const idx = this._data.assignees.indexOf(memberId)
        if (idx > -1) {
            return
        }

        this._data.assignees.push(memberId)
        this.changeData({ assignees: this._data.assignees })
    }

    public removeAssignee(memberId: string) {
        if (!this.isMember(memberId)) {
            throw new Error("The given user isn't a member of this wib!")
        }

        const idx = this._data.assignees.indexOf(memberId)
        if (idx < 0) {
            return
        }

        this._data.assignees.splice(idx, 1)
        this.changeData({ assignees: this._data.assignees })
    }






    public touch() {
        this.changeData({
            lastUpdatedAt: Date.now()
        })
    }




    public getMemberNotification(userId: string): IWibNotification {
        if (!this.isMember(userId)) {
            throw new Error("The given user isn't a member of this wib!")
        }
        return this._data.memberNotifications[userId]
    }




    public delete(): void {
        this.changeData({ deleted: true })
    }

    private changeData(newData: Partial<IWib<$ContentType>>) {
        this._data = {
            ...this._data,
            ...newData
        }
        this._updatedData = {
            ...this._updatedData,
            ...newData
        }
    }

    private getMemberIndex(userId: string, safe: boolean = true): number {
        const res = this._data.members.indexOf(userId)
        if (res < 0) {
            if (safe) {
                throw new Error("This user isn't part of this WIB!")
            } else {
                return -1
            }
        }
        return res
    }

}

export class ENotiReasonColor {

    public static readonly AddAsMember = new ENotiReasonColor(WColorPalette.Amber_50.color)
    public static readonly Created = new ENotiReasonColor(WColorPalette.Green_50.color)
    public static readonly ChangeContent = new ENotiReasonColor(WColorPalette.BlueGrey_50.color)
    public static readonly MoveCard = new ENotiReasonColor(WColorPalette.Orange_50.color)
    public static readonly Comment = new ENotiReasonColor(WColorPalette.LightBlue_50.color)
    public static readonly Mention = new ENotiReasonColor(WColorPalette.Red_50.color)

    private constructor(public readonly color: string) {

    }

}