import { rerender } from "index"

export enum EAnimationLoopType {
    None,
    Restart,
    PingPong
}

export class Animation {

    public constructor(
        public readonly startValue: number,
        public readonly endValue: number,
        public readonly duration: number,
        public readonly onUpdate: (value: number) => void,
        public readonly repeat: number = 0,
        public readonly loopType: EAnimationLoopType = EAnimationLoopType.None
    ) { }

    public get isRunning(): boolean {
        return this.startTime != null
    }

    private startTime: number = null

    private _currentValue: number = this.startValue
    public get currentValue(): number {
        return this._currentValue
    }

    public start() {
        if (this.isRunning) {
            return
        }

        this._currentValue = this.startValue
        this.startTime = Date.now()
        Animator.addAnimation(this)
        this.notifyNewValue()
    }

    public stop() {
        if (!this.isRunning) {
            return
        }

        this.startTime = null
        Animator.removeAnimation(this)

        // TODO on stopped?
    }

    public tick() {
        var timeDelta = Date.now() - this.startTime

        if (this.isAnimationEnded(timeDelta)) {
            this._currentValue = this.calculateEndValue()

            this.notifyNewValue()
            this.stop()
            return
        }


        this._currentValue = this.calculateCurrentValue(timeDelta)
        this.notifyNewValue()

    }

    private isAnimationEnded(timeDelta: number): boolean {
        return (this.loopType == EAnimationLoopType.None && timeDelta >= this.duration)
            || (this.repeat > 0 && timeDelta >= this.repeat * this.duration)
    }

    private calculateEndValue(): number {
        var res: number = 0;

        if (this.loopType == EAnimationLoopType.None) {
            return this.endValue
        } else if (this.loopType == EAnimationLoopType.Restart) {
            return this.endValue
        } else if (this.loopType == EAnimationLoopType.PingPong) {
            if (this.repeat % 2 == 0) {
                return this.startValue
            } else {
                return this.endValue
            }
        } else {
            throw Error("Invalid loop type: " + this.loopType)
        }
    }

    private calculateCurrentValue(timeDelta: number): number {
        const iterationTime = timeDelta % this.duration
        var pos = iterationTime / this.duration
        if (this.loopType == EAnimationLoopType.PingPong) {
            if (((timeDelta / this.duration) | 0) % 2 != 0) {
                pos = 1.0 - pos
            }
        }

        return (this.startValue + ((this.endValue - this.startValue) * pos))
    }

    private notifyNewValue() {
        if (this.onUpdate != null) {
            this.onUpdate(this.currentValue)
        }
    }

}

class Animator {

    private constructor() { }

    private static readonly runningAnimations: Animation[] = []
    private static intervalId: number = null

    public static addAnimation(animation: Animation) {
        if (this.isAnimationAdded(animation)) {
            throw Error("This animation is already added!")
        }

        this.runningAnimations.push(animation)

        if (this.intervalId == null) {
            this.intervalId = window.setInterval(this.onTick, 10)
        }
    }

    public static removeAnimation(animation: Animation) {
        var animIndex = this.runningAnimations.indexOf(animation)
        if (animIndex < 0) {
            throw Error("This animation isn't added!")
        }

        this.runningAnimations.splice(animIndex, 1)

        if (this.runningAnimations.length < 1) {
            if (this.intervalId != null) {
                window.clearInterval(this.intervalId)
                this.intervalId = null
            }
        }
    }

    private static isAnimationAdded(animation: Animation): Boolean {
        return this.runningAnimations.indexOf(animation) > -1
    }

    private static onTick = () => {
        for (var animation of Animator.runningAnimations) {
            try {
                animation.tick()
            } catch (ex) {
                console.error(ex)
            }
        }

        //rerender()
    }

}