export class StringUtils {

    public static escapeHtml(input: string): string {
        return input.replace(/\</g, "&lt;").replace(/\>/g, "&gt;")
    }

    public static replaceLink(input: string): string {
        var exp_match = /(\b(https?|):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        var res = input.replace(exp_match, "<a href='$1' target='_blank'>$1</a>");
        return res
    }

    public static convertTimestampToStr(timestamp: number): string {
        const date = new Date(timestamp)
        const currDate = new Date()
        var timeVisible = false

        var time = this.expandLeadingZeros(date.getHours().toString(), 2) + ":" + this.expandLeadingZeros(date.getMinutes().toString(), 2)

        var d = this.expandLeadingZeros(date.getFullYear().toString(), 4) + "-" +
            this.expandLeadingZeros(("" + (date.getMonth() + 1)), 2) + "-" +
            this.expandLeadingZeros(date.getDate().toString(), 2)

        if (date.getDate() == currDate.getDate() && date.getMonth() == currDate.getMonth() && date.getFullYear() == currDate.getFullYear()) {
            d = 'Today'
            timeVisible = true
        }

        currDate.setDate(currDate.getDate() - 1)

        if (date.getDate() == currDate.getDate() && date.getMonth() == currDate.getMonth() && date.getFullYear() == currDate.getFullYear()) {
            d = 'Yesterday'
            timeVisible = true
        }

        return `${d} ${timeVisible ? time : ""}`
    }

    public static expandLeadingZeros(input: string, length: number): string {
        while (input.length < length) {
            input = "0" + input
        }
        return input
    }

    public static annotateFileName(origFn: string, annotation: string): string {
        const dotIdx = origFn.lastIndexOf(".")
        var fn = origFn
        var dot = ""
        var ext = ""
        if (dotIdx > -1 && dotIdx < origFn.length - 1) {
            fn = origFn.substring(0, dotIdx)
            dot = "."
            ext = origFn.substring(dotIdx + 1, origFn.length)
        }

        return fn + annotation + dot + ext
    }

    private constructor() { }

}