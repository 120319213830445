import { FieldModel } from 'components/FieldModel'
import * as React from 'react'
import { WCheckBox } from 'wds/Checkbox'

export class MessageComposer extends React.Component<MessageComposerProps, MessageComposerState> {

    public constructor(props: MessageComposerProps) {
        super(props)

        this.state = {
            isLongMessage: new FieldModel(false)
        }
    }

    componentDidMount() {
        window.setTimeout(() => {
            this.focusInput()
        }, 500)
    }

    private readonly handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.updateHeight()
    }

    private readonly handleKeyUp = (evt: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (this.state.isLongMessage.value) {
            if (evt.keyCode == 13) {
                if (evt.ctrlKey) {
                    this.props.onSend(evt.currentTarget.value)
                    evt.currentTarget.value = ""
                    evt.currentTarget.style.height = "37px"
                }
            }
        } else {
            if (evt.keyCode == 13) {
                if (!evt.shiftKey) {
                    this.props.onSend(evt.currentTarget.value)
                    evt.currentTarget.value = ""
                    evt.currentTarget.style.height = "37px"
                }
            }
        }
    }

    private readonly handleLongChange = (newValue: boolean) => {
        this.setState({})
    }

    private updateHeight() {
        window.setTimeout(() => {
            var e = document.getElementById("messageComposer") as HTMLTextAreaElement
            e.style.height = (e.scrollHeight + 2) + "px"
        }, 0)

    }

    private focusInput() {
        document.getElementById("messageComposer").focus()
    }

    render() {
        return (
            <div
                style={{
                    width: '100%',
                    padding: '8px',
                    borderTop: '1px solid #ccc',
                    backgroundColor: '#eee',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <textarea
                    id="messageComposer"
                    style={{
                        width: '100%',
                        maxHeight: '400px',
                        height: '37px',
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                        padding: '8px',
                        fontSize: '15px',
                        color: '#222',
                        resize: 'none',
                    }}
                    placeholder="Type your message here..."
                    onChange={this.handleChange}
                    onKeyUp={this.handleKeyUp}
                />

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingTop: '4px',
                    }}
                >

                    <div style={{ display: 'inline-flex' }}>
                        <WCheckBox model={this.state.isLongMessage} title="Long message" onChange={this.handleLongChange} />
                    </div>
                    <div
                        style={{
                            flex: '1',
                            paddingLeft: '16px',
                            fontSize: '14px',
                            color: '#888'
                        }}
                    >
                        {this.state.isLongMessage.value &&
                            "Enter starts new line, CTRL+Enter sends the message."
                        }
                        {!this.state.isLongMessage.value &&
                            "Enter sends the message, SHIFT+Enter starts new line."
                        }
                    </div>
                </div>

            </div>
        )
    }

}

export interface MessageComposerProps {

    onSend: (msg: string) => void

}

interface MessageComposerState {

    isLongMessage: FieldModel<boolean>

}