import { AController } from "@eagerlogic/react-mvc";
import { CurrentOrgSvc } from "backend/aaa/CurrentOrgSvc";
import { CurrentUserSvc } from "backend/aaa/CurrentUserSvc";
import { UsersSvc } from "backend/aaa/UsersSvc";
import { currentAuthUser } from "index";
import { IWibSidebarItem, WibSidebar } from "../../../wibib-sdk-fe/WibSidebar";
import { MainSceneModel } from "./MainSceneModel";

export class MainSceneController extends AController<MainSceneModel> {

    protected createInitialModel(): MainSceneModel {
        return new MainSceneModel()
    }

    async init() {
        WibSidebar.onChange.addListener(this.onWibSidebarItemChange)
        await CurrentUserSvc.init(currentAuthUser.uid)
        await CurrentOrgSvc.init(CurrentUserSvc.currentUser.data.organizationId)
        await UsersSvc.init(CurrentUserSvc.currentUser.data.organizationId)
        this.model.isLoading = false
        this.render(this.model)
    }

    private readonly onWibSidebarItemChange = (item: IWibSidebarItem) => {
        this.model.isBlur = item != null
        this.render(this.model)
    }

}