import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import * as React from 'react'
import { style } from 'typestyle'
import { WIcon } from 'wds/Icon'
import { WColorPalette } from 'wds/styles/WColor'
import { UsersSvc } from '../backend/aaa/UsersSvc'
import { ProfilePicture } from '../components/ProfilePicture'
import { IdGenerator } from '../utils/IdGenerator'
import { WIconButton } from './IconButton'
import { WScrollBox } from './ScrollBox'
import { EFontWeight } from './styles/EFontWeight'

export class WMembersList extends React.Component<WMembersListProps, WMembersListState> {

    static getDerivedStateFromProps(props: WMembersListProps, state: WMembersListState): WMembersListState {
        state.selectedMembers = props.defaultSelectedMembers
        return state
    }

    private readonly msId = "ms-" + IdGenerator.getNextId()
    private inputRef: HTMLElement = null

    public constructor(props: WMembersListProps) {
        super(props)

        this.state = {
            selectedMembers: props.defaultSelectedMembers
        }
    }

    private readonly handleUserPickerChange = (event: React.ChangeEvent<{}>, value: MembersListItem) => {
        this.state.selectedMembers.push(value)
        this.setState({})
        this.props.onUserAdded(value.id, this.state.selectedMembers)
        window.setTimeout(() => {
            this.inputRef.blur()
        }, 10)
    }

    private readonly handleOnUserRemoved = (id: string) => {
        this.setState({ selectedMembers: this.state.selectedMembers.filter(m => m.id != id) })
        this.props.onUserRemoved(id, this.state.selectedMembers)
    }

    render() {
        return (
            <div style={{ width: '100%', height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                {this.props.onUserAdded != null &&
                    <div style={{ width: '100%', padding: '8px' }}>
                        <Autocomplete
                            id="combo-box-demo"
                            options={this.props.availableMembers.filter((m) => this.state.selectedMembers.findIndex((sm) => sm.id == m.id) < 0)}
                            getOptionLabel={(option) => option.name}
                            size="small"
                            style={{ width: '100%' }}
                            renderInput={(params) => <TextField {...params} inputRef={(e) => { this.inputRef = e }} variant="outlined" size="small" placeholder="Add participant" value={""} />}
                            onChange={this.handleUserPickerChange}
                            value={null}
                        />
                    </div>
                }
                <div style={{ flex: '1', overflow: 'hidden' }}>
                    <WScrollBox>
                        {this.state.selectedMembers.length < 1 &&
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '16px' }}>
                                <img src="/app/images/es_members.png" />
                            </div>
                        }
                        {this.state.selectedMembers.length > 0 && this.state.selectedMembers.map((member) => {
                            const user = UsersSvc.usersById[member.id]
                            return (
                                <div
                                    key={"member-" + user.id}
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        padding: '8px',
                                        alignItems: 'center'
                                    }}
                                >
                                    <ProfilePicture
                                        name={user.data().name}
                                        profilePictureUrl={user.data().profilePictureUrl}
                                        size={24}
                                        backgroundColor={user.data().color}
                                        borderWidth={0}
                                    />
                                    <div style={{ minWidth: '8px' }}></div>
                                    <div
                                        style={{
                                            flex: 1,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            fontSize: '16px',
                                            fontWeight: EFontWeight.Semibold.value,
                                            color: '#222'
                                        }}
                                    >
                                        {user.data().name}
                                    </div>
                                    <div style={{ minWidth: '4px' }}></div>
                                    {this.props.isAssigneeSelectable &&
                                        <div>
                                            <div
                                                className={assigneeIconClass}
                                                style={{
                                                    borderColor: WColorPalette.Green.color,
                                                    backgroundColor: member.isAssignee ? WColorPalette.Green.color : 'transparent'
                                                }}
                                                onClick={() => { this.props.onAssigneeChanged(member.id, !member.isAssignee) }}
                                                title={member.isAssignee ? "Unassign user" : "Assign user"}
                                            >
                                                <WIcon icon="how_to_reg" color={member.isAssignee ? '#fff' : WColorPalette.Green.color} size="16px" />
                                            </div>
                                            <div style={{ minWidth: '4px' }}></div>
                                        </div>
                                    }
                                    {this.props.onUserRemoved != null &&
                                        <div style={{ paddingLeft: '8px' }}>
                                            <WIconButton
                                                icon="delete"
                                                color="secondary"
                                                size="small"
                                                onClick={() => { this.handleOnUserRemoved(user.id) }}
                                            />
                                        </div>
                                    }
                                </div>
                            )
                        })}
                    </WScrollBox>
                </div>
            </div>
        )
    }

}

export interface WMembersListProps {

    defaultSelectedMembers: MembersListItem[]
    availableMembers: MembersListItem[]
    isAssigneeSelectable: boolean
    onUserAdded?: (id: string, members: MembersListItem[]) => void
    onUserRemoved?: (id: string, members: MembersListItem[]) => void
    onAssigneeChanged?: (userId: string, newValue: boolean) => void

}

interface WMembersListState {

    selectedMembers: MembersListItem[]

}

export interface MembersListItem {

    id: string,
    name: string,
    color: string,
    image: string,
    isAssignee: boolean

}

const assigneeIconClass = style({
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '50%',
    $nest: {
        '&:hover': {
            opacity: 0.7
        },
        '&:active': {
            opacity: 0.5
        }
    }
})