import * as React from 'react'
import { UsersSvc } from '../../../../../backend/aaa/UsersSvc'
import { WMembersList } from '../../../../../wds/MembersList'
import { ACommonWibSidebarTabRenderer } from './CommonWibSidebarView'

export class CommonMembersTabViewRenderer extends ACommonWibSidebarTabRenderer {

    public constructor(
        private readonly isAssigneeSelectable: boolean,
        private readonly members: { id: string, isAssignee: boolean }[],
        private readonly onMemberAdded: (id: string) => void,
        private readonly onMemberRemoved: (id: string) => void,
        private readonly onAssigneeChanged: (userId: string, newValue: boolean) => void
    ) {
        super("group")
    }

    private readonly handleMemberAdded = async (id: string) => {
        this.onMemberAdded(id)
        // const content: IAddMemberWibMsgContent = {
        //     memberId: id
        // }
        // try {
        //     await WibService.sendWibMessage(this.wibId, 'wibib.wib.add_member', content)
        // } catch (ex) {
        //     Snackbar.show("danger", "Add member failed: " + ex.message)
        // }
    }

    private readonly handleMemberRemoved = async (id: string) => {
        this.onMemberRemoved(id)
        // const content: IRemoveMemberWibMsgContent = {
        //     memberId: id
        // }
        // try {
        //     await WibService.sendWibMessage(this.wibId, 'wibib.wib.remove_member', content)
        // } catch (ex) {
        //     Snackbar.show("danger", "Remove member failed: " + ex.message)
        // }
    }

    private readonly handleAssigneeChanged = (userId: string, newValue: boolean) => {
        this.onAssigneeChanged(userId, newValue)
    }

    renderContent(): React.ReactNode {
        return (
            <WMembersList
                defaultSelectedMembers={this.members
                    .filter(p => p.id.indexOf("@") != 0)
                    .map((m) => {
                        const u = UsersSvc.usersById[m.id]
                        return {
                            id: m.id,
                            name: u.data().name,
                            color: u.data().color,
                            image: u.data().profilePictureUrl,
                            isAssignee: m.isAssignee
                        }
                    })}
                availableMembers={UsersSvc.users.map((u) => {
                    return {
                        id: u.id,
                        name: u.data().name,
                        color: u.data().color,
                        image: u.data().profilePictureUrl,
                        isAssignee: false
                    }
                })}
                isAssigneeSelectable={this.isAssigneeSelectable}
                onUserAdded={this.handleMemberAdded}
                onUserRemoved={this.handleMemberRemoved}
                onAssigneeChanged={this.onAssigneeChanged}
            />
        )
    }



}