import { BoardSvc } from "backend/projects/board/BoardService";
import { WibSvc } from "backend/wib/WibService";
import "firebase/analytics";
import * as firebase from "firebase/app";
import { FilesTabRenderer } from "modules/app/comm/privateChat/PrivateChatScene";
import * as React from 'react';
import { IBoardColumnAction, IBoardData } from "shared/entities/projects/IBoardData";
import { IWib } from "shared/entities/wib/IWib";
import { IWibMessage } from "shared/entities/wib/IWibMessage";
import { ICardWibContent } from "shared/entities/wib/wibs/ICardWibContentType";
import { style } from 'typestyle';
import { ChangeListenerCanceler } from "utils/Fs";
import { WCircularProgress } from "../../../../../../wds/CircularProgress";
import { MenuItem } from "../../../../../../wds/DropdownMenu";
import { Snackbar } from '../../../../../../wds/Snackbar';
import { EFontWeight } from '../../../../../../wds/styles/EFontWeight';
import { WColorPalette } from '../../../../../../wds/styles/WColor';
import { AWibController, AWibCreator, AWibHandler, WibActionButton, WibHeaderButtons } from "../../../../../../wibib-sdk-fe/WibHandlerBase";
import { WibSidebar } from '../../../../../../wibib-sdk-fe/WibSidebar';
import { CommonDescriptionView } from '../../../../comm/wibs/components/CommonDescriptionView';
import { CommonWibSidebarView } from '../../../../comm/wibs/components/CommonWibSidebarView';
import { AMessageRenderer, CommonWibView } from '../../../../comm/wibs/components/CommonWibView';
import { CardMembersTabViewRenderer } from './CardMembersTabRenderer';
import { MoveCardDialog } from "./MoveCardDialog";


export class CardWibHandler extends AWibHandler<ICardWibContent> {

    public constructor() {
        super(
            "wibib.projects.board.card",
            "Card",
            "view_column",
            WColorPalette.LightBlue.color,
            "Kanban board card",
            false
        )
    }

    createWibCreator(): AWibCreator {
        throw new Error("Card can not be created from WIB dialog!")
    }

    createWibController(wibId: string, wib: IWib<ICardWibContent>, messages: IWibMessage<{}>[], changeListener: () => void): AWibController<ICardWibContent> {
        return new CardWibController(wibId, wib, messages, true, changeListener)
    }

}

export class CardWibController extends AWibController<ICardWibContent> {

    private boardId: string = null
    private board: IBoardData = null
    private boardChangeListenerCanceler: ChangeListenerCanceler = null
    private isActionInProgress: boolean = false
    private isMoveCardDialogVisible: boolean = false

    public constructor(wibId: string, wib: IWib<ICardWibContent>, messages: IWibMessage<{}>[], isSubjectEditable: boolean, changeListener: () => void) {
        super(wibId, wib, messages, isSubjectEditable, changeListener)

        this.init()
    }

    private readonly init = async () => {
        if (this.boardChangeListenerCanceler != null) {
            this.boardChangeListenerCanceler.cancel()
        }

        this.boardChangeListenerCanceler = (await BoardSvc.getBoardListener(this.wib.parentId)).addChangeListener((snapshot) => {
            this.board = snapshot.data
            this.boardId = snapshot.id
            this.refresh()
        })
    }

    public readonly destroy = () => {
        if (this.boardChangeListenerCanceler != null) {
            this.boardChangeListenerCanceler.cancel()
        }
    }

    private readonly handleDescChange = async (newDesc: string) => {
        await BoardSvc.changeDescription(this.wibId, newDesc)
    }

    private readonly handleSendComment = async (comment: string) => {
        await WibSvc.sendComment(this.wibId, comment)
    }

    private readonly handleArchiveCard = () => {
        if (!confirm("Are you sure you want to delete this card? This can't be undone!")) {
            return
        }

        BoardSvc.deleteCard(this.wibId)
        WibSidebar.close()
    }

    private readonly handleMoveCard = () => {
        this.isMoveCardDialogVisible = true
        this.refresh()
    }

    getHeaderButtons(): WibHeaderButtons {
        var buttons: WibActionButton[] = []
        var moreMenuItems: MenuItem[] = []

        if (!this.wib.deleted) {
            var isMoveVisible = false
            if (this.board != null) {
                isMoveVisible = true
                const cc = this.board.columns.find(col => col.cards.indexOf(this.wibId) > -1)
                if (cc != null && cc.options != null && cc.options.actionOptions != null && cc.options.actionOptions.disableDrag) {
                    isMoveVisible = false
                }
                if (isMoveVisible) {
                    moreMenuItems.push({
                        id: "mi-move-card",
                        title: "Move card",
                        onClick: this.handleMoveCard
                    })
                }
            }
            moreMenuItems.push({
                id: "mi-archive",
                title: "Archive card",
                onClick: this.handleArchiveCard
            })
        }

        return new WibHeaderButtons(buttons, moreMenuItems)
    }

    render(): React.ReactNode {
        if (this.boardId != this.wib.parentId) {
            window.setTimeout(() => {
                this.init()
            }, 100)
        }
        return (
            <>
                <CommonWibView
                    header={() => {
                        var content = ""
                        if (this.wib.content != null && this.wib.content.description != null) {
                            content = this.wib.content.description
                        }
                        return (
                            <CommonDescriptionView isEditable label="Description" content={content} onChange={this.handleDescChange} />
                        )
                    }}
                    messageResolver={this.messageResolver}
                    messages={this.messages}
                    wibId={this.wibId}
                    onSendComment={this.handleSendComment}
                    wib={this.wib}

                />
                {this.isMoveCardDialogVisible &&
                    <MoveCardDialog
                        key={"mcd-" + this.wibId}
                        currentBoardId={this.wib.parentId}
                        wib={this.wib}
                        wibId={this.wibId}
                        onClose={this.handleMoveCardDialogClosed}
                    />
                }
            </>
        )
    }

    renderSidebar(): React.ReactNode {
        return (
            <CommonWibSidebarView
                headRenderer={this.getHeadRenderer()}
                tabs={[
                    new CardMembersTabViewRenderer(this.wibId, this.wib),
                    new FilesTabRenderer(this.wib)
                ]}
            />
        )
    }

    private readonly getHeadRenderer = (): (() => React.ReactNode) => {
        return (() => {
            if (this.board == null) {
                return null
            }

            const col = this.board.columns.find((col) => {
                return col.cards.indexOf(this.wibId) > -1
            })

            if (col == null) {
                return null
            }

            return (
                <div
                    style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '16px' }}
                >
                    <div style={{ fontSize: '16px', fontWeight: EFontWeight.Semibold.value, color: '#666', marginBottom: '4px' }}>Board</div>
                    <div style={{ fontSize: '14px', fontWeight: EFontWeight.Bold.value, color: "#222" }}>{this.board.name}</div>
                    <div style={{ minHeight: '16px', height: '16px' }} />

                    <div style={{ fontSize: '16px', fontWeight: EFontWeight.Semibold.value, color: '#666', marginBottom: '4px' }}>Column</div>
                    <div style={{ fontSize: '14px', fontWeight: EFontWeight.Bold.value, color: "#222" }}>{col.name}</div>

                    {this.isActionInProgress &&
                        <div style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '16px' }}>
                            <WCircularProgress />
                        </div>
                    }
                    {!this.isActionInProgress && col.options && col.options.actionOptions && col.options.actionOptions.actions && col.options.actionOptions.actions.length > 0 &&
                        <>
                            <div style={{ minHeight: '16px', height: '16px' }} />
                            <div style={{ fontSize: '16px', fontWeight: EFontWeight.Semibold.value, color: '#666', marginBottom: '4px' }}>Actions</div>
                            {col.options.actionOptions.actions.map((action) => {
                                return (
                                    <div
                                        key={"ab-" + action.id}
                                        className={actionButtonStyle}
                                        style={{ backgroundColor: action.color }}
                                        onClick={() => { this.handleActionClicked(action) }}
                                    >
                                        {action.title}
                                    </div>
                                )
                            })}
                        </>
                    }
                </div>
            )
        })
    }

    private readonly handleActionClicked = async (action: IBoardColumnAction) => {
        this.isActionInProgress = true
        this.refresh()
        try {
            await BoardSvc.moveCard(this.wibId, this.wib.parentId, action.targetBoardId, action.targetColumnId, 0)
            firebase.analytics().logEvent('projects.board.card.wib.action_clicked')
        } catch (ex) {
            Snackbar.show('danger', "Move card failed: " + ex.message)
            this.refresh()
        } finally {
            this.isActionInProgress = false
            this.refresh()
        }
    }

    private readonly handleMoveCardDialogClosed = () => {
        this.isMoveCardDialogVisible = false
        this.refresh()
    }

    private readonly messageResolver = (message: IWibMessage<{}>): AMessageRenderer => {
        return null
    }

}

const actionButtonStyle = style({
    width: '100%',
    padding: '8px',
    borderRadius: '2px',
    textAlign: 'center',
    fontSize: '14px',
    color: '#fff',
    fontWeight: EFontWeight.Medium.value,
    marginBottom: '4px',
    boxShadow: '1px 1px 4px rgba(0,0,0, 0.3)',
    cursor: 'pointer',
    $nest: {
        '&:hover': {
            opacity: '0.7'
        }
    }
})



