export class AvailableColors {

    private static readonly _colors: string[] = [
        "#f44336",
        "#e91e63",
        "#9c27b0",
        "#673ab7",
        "#3f51b5",
        "#2196f3",
        "#03a9f4",
        "#00bcd4",
        "#009688",
        "#4caf50",
        "#8bc34a",
        "#cddc39",
        "#ffeb3b",
        "#ffc107",
        "#ff9800",
        "#ff5722",
        "#795548",
    ]

    public static get colors(): string[] {
        return this._colors.map((item) => {
            return item
        })
    }

    public static getRandomColor(): string {
        var idx = (Math.random() * this._colors.length) | 0
        if (idx >= this._colors.length) {
            idx = 0
        }

        return this._colors[idx]
    }

    private constructor() { }

}