import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { IUserData } from 'shared/entities/aaa/IUserData';
import { CurrentUserEntity } from './CurrentUserEntity';

export class CurrentUserSvc {

    private static _currentUserEntity: CurrentUserEntity

    public static get currentUser() {
        if (this._currentUserEntity == null) {
            throw new Error("CurrentUserSvc is not initialized yet!")
        }
        return this._currentUserEntity
    }

    public static async init(userId: string) {
        const ref = firebase.firestore().collection("Users").doc(userId) as firebase.firestore.DocumentReference<IUserData>
        const sn = await ref.get()
        this._currentUserEntity = new CurrentUserEntity(sn)
    }

    private constructor() {

    }

}