
export abstract class AAction<GInput, GOutput> {

    abstract execute(input: GInput): GOutput
}

export class ValidationException extends Error {

    public static createWithMessage(message: string): ValidationException {
        return new ValidationException(message, null)
    }

    public static createWithFieldErrors(fieldErrors: { [key: string]: string }): ValidationException {
        return new ValidationException(null, fieldErrors)
    }

    private constructor(message: string, public readonly fieldErrors: { [key: string]: string }) {
        super(message)
    }

}

export class Jalidator<GInput, GOutput> {

    public readonly actions: AAction<any, any>[] = []

    public constructor() {

    }

    public validateSafe(input: GInput): GOutput {
        var res: any = input
        for (var action of this.actions) {
            var a = action as AAction<any, any>
            res = a.execute(res)
        }
        return res as GOutput
    }

    validateToMap(input: GInput, errorKey: string, errors: { [key: string]: string }): GOutput {
        var res: any = input
        for (var action of this.actions) {
            var a = action as AAction<any, any>
            try {
                res = a.execute(res)
            } catch (ex) {
                if (ex instanceof ValidationException) {
                    errors[errorKey] = ex.message!!
                    return null
                } else {
                    throw ex
                }
            }
        }
        return res as GOutput
    }

    validateToBind(input: GInput, bind: { error: string }, hasErrorBind: { hasError: boolean } = null): GOutput {
        var res: any = input
        for (var action of this.actions) {
            var a = action as AAction<any, any>
            try {
                res = a.execute(res)
            } catch (ex) {
                if (ex instanceof ValidationException) {
                    bind.error = ex.message
                    if (hasErrorBind != null) {
                        hasErrorBind.hasError = true
                    }
                    return null
                } else {
                    throw ex
                }
            }
        }
        return res as GOutput
    }

    validateCustom(input: GInput, onError: (error: string) => void): GOutput {
        var res: any = input
        for (var action of this.actions) {
            var a = action as AAction<any, any>
            try {
                res = a.execute(res)
            } catch (ex) {
                if (ex instanceof ValidationException) {
                    onError(ex.message)
                    return null
                } else {
                    throw ex
                }
            }
        }
        return res as GOutput
    }

    addAction(action: AAction<any, any>) {
        if (action == null) {
            throw new Error("The action parameter can not be null!")
        }
        this.actions.push(action)
    }
}

export class JalidatorBuilder<GI, GO> {

    public static init<GNI, GNO>(action: AAction<GNI, GNO>): JalidatorBuilder<GNI, GNO> {
        const jalidator: Jalidator<GNI, GNO> = new Jalidator()
        jalidator.addAction(action)
        return new JalidatorBuilder<GNI, GNO>(jalidator)
    }

    private constructor(private readonly jalidator: Jalidator<GI, GO>) {

    }

    withAction<GNO>(action: AAction<GO, GNO>): JalidatorBuilder<GI, GNO> {
        const v: Jalidator<GI, GNO> = new Jalidator()
        for (var a of this.jalidator.actions) {
            v.addAction(a)
        }
        v.addAction(action)
        return new JalidatorBuilder<GI, GNO>(v)
    }

    build(): Jalidator<GI, GO> {
        return this.jalidator
    }

}
