import { CircularProgress } from '@material-ui/core'
import * as React from 'react'

export class WCircularProgress extends React.Component<WCircularProgressProps> {

    render() {
        return (
            <CircularProgress
                color={this.props.color}
            />
        )
    }

}

export interface WCircularProgressProps {

    color?: "primary" | "secondary"

}