export const VER_Wib = 1

export interface IWib<$ContentType extends {}> {
    version: number
    subject: string
    parentType: string | null,
    parentId: string | null,
    type: string
    content: $ContentType
    allowedMembers: string[]
    members: string[]
    assignees: string[]
    memberNotifications: { [memberId: string]: IWibNotification }
    memberLastSeens: { [memberId: string]: number }
    lastUpdatedAt: number
    createdBy: string
    createdAt: number
    deleted: boolean
    files: IFileDescriptor[]
}

export interface IWibNotificationReason {
    message: string,
    color: string
}

export interface IWibNotification {
    unreadNotificationsCount: number,
    reason: IWibNotificationReason,
    notifiedAt: number
    senderId: string
}

export interface IFileDescriptor {
    fileName: string
    downloadUrl: string
    uploadedAt: number
}