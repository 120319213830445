import * as React from 'react'
import { FieldModel } from 'components/FieldModel'
import { WIcon } from './Icon'
import { EColor } from './styles/WColor'

export class WCheckBox extends React.Component<WCheckBoxProps, WCheckBoxState> {

    private handleClick = (evt) => {
        if (this.props.disabled) {
            return
        }
        this.props.model.value = !this.props.model.value
        this.setState({})
        if (this.props.onChange != null) {
            this.props.onChange(this.props.model.value)
        }
    }

    render() {
        return (
            <div
                style={{
                    display: 'inline-flex',
                    width: '100%',
                    alignItems: "center",
                    cursor: this.props.disabled ? "default" : "pointer",
                    opacity: this.props.disabled ? 0.4 : 1.0
                }}
                onClick={this.props.disabled ? null : this.handleClick}
            >

                <WIcon
                    color={this.props.model.value ? EColor.CheckBoxSelectedColor.color : EColor.CheckBoxNormalColor.color}
                    size="22px"
                    icon={this.props.model.value ? "check_box" : "check_box_outline_blank"}
                />

                <div style={{ width: "8px" }} />

                <div
                    style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        color: EColor.CheckBoxTextColor.color
                    }}
                >
                    {this.props.title}
                </div>

            </div>
        )
    }

}

export interface WCheckBoxProps {

    title: string
    model: FieldModel<boolean>
    disabled?: boolean
    onChange?: (newValue: boolean) => void

}

interface WCheckBoxState {

}