import { AScene } from '@eagerlogic/react-mvc';
import { FieldModel } from 'components/FieldModel';
import * as React from 'react';
import { HorizontalLoadingIndicator } from 'wds/HorizontalLoadingIndicator';
import { ModalButton, WModal } from 'wds/Modal';
import { EColor } from 'wds/styles/WColor';
import { WTextField } from 'wds/TextField';
import { AcceptInvitationSceneController } from './AcceptInvitationSceneController';
import { AcceptInvitationSceneModel } from './AcceptInvitationSceneModel';

export class AcceptInvitationScene extends AScene<AcceptInvitationSceneProps, AcceptInvitationSceneModel, AcceptInvitationSceneController> {
    protected createControlller(): AcceptInvitationSceneController {
        return new AcceptInvitationSceneController(this.props.invitationId)
    }

    render(): React.ReactNode {
        if (this.model.isLoading) {
            return (
                <div style={{ width: "100%", height: "16px" }} ><HorizontalLoadingIndicator /></div>
            )
        } else {
            if (!this.model.isFound) {
                return (
                    <div style={{ width: "100%", padding: "32px", fontSize: "18px", color: "#fff", backgroundColor: EColor.ErrorBg.color }}>
                        Can't find invitation! It may be expired, revoked, or already used up!
                    </div>
                )
            } else {
                return <WModal
                    isLoading={this.model.isModalLoading}
                    error={this.model.error}
                    title="Accept invitation"
                    buttons={[
                        new ModalButton("Accept", this.controller.onAcceptClick)
                    ]}
                >
                    <WTextField
                        label="Your name"
                        model={this.model.nameModel}
                        helperText="This name will be visible to other members."
                    />

                    <WTextField
                        label="Choose a password"
                        model={this.model.passwordModel}
                        helperText="You will use this password to login."
                        password
                    />

                    <WTextField
                        label="Organization"
                        model={new FieldModel(this.model.organizationName)}
                        helperText="The organization which you will join."
                        disabled
                    />

                    <WTextField
                        label="Email"
                        model={new FieldModel(this.model.email)}
                        helperText="Your email which you will use to login."
                        disabled
                    />
                </WModal>
            }
        }

    }


}

export interface AcceptInvitationSceneProps {
    invitationId: string
}