import * as React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { style } from 'typestyle'
import { WColorPalette } from 'wds/styles/WColor'
import { UsersSvc } from '../../../../../../backend/aaa/UsersSvc'
import { ProfilePicture } from '../../../../../../components/ProfilePicture'
import { EFontWeight } from '../../../../../../wds/styles/EFontWeight'
import { WibSidebar } from '../../../../../../wibib-sdk-fe/WibSidebar'
import { CardModel } from '../BoardSceneModel'

export class CardView extends React.Component<CardViewProps, CardViewState> {

    render() {
        return (
            <Draggable draggableId={this.props.model.id} index={this.props.index} isDragDisabled={this.props.dragDisabled}>
                {(provided, snapshot) => {
                    return (
                        <div
                            className={containerStyle}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onClick={() => { WibSidebar.showWib(this.props.model.id) }}
                        >
                            <div className={titleStyle}>
                                {this.props.model.title}
                            </div>

                            <div style={{ minHeight: '8px' }}></div>

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                {this.props.model.members.length > 7 &&
                                    <div style={{ width: '24px', marginLeft: '4px' }}>
                                        <ProfilePicture
                                            name={`+ ${this.props.model.members.length - 7 > 9 ? "+" : "" + (this.props.model.members.length - 7)}`}
                                            backgroundColor="#aaa"
                                            size={24}
                                            borderWidth={0}
                                            borderColor="transparent"
                                            profilePictureUrl={null}
                                        />
                                    </div>
                                }
                                {this.props.model.members.map((item, index) => {
                                    if (index >= 7) {
                                        return
                                    }
                                    const user = UsersSvc.usersById[item].data()
                                    return (
                                        <div key={"pp-" + item} style={{ width: '24px', marginLeft: '4px' }}>
                                            <ProfilePicture
                                                name={user.name}
                                                backgroundColor={user.color}
                                                size={24}
                                                borderWidth={0}
                                                profilePictureUrl={user.profilePictureUrl}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                }}
            </Draggable>
        )
    }

}

export interface CardViewProps {
    index: number
    model: CardModel
    dragDisabled: boolean
}

interface CardViewState {

}

const containerStyle = style({
    width: '100%',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '16px',
    paddingBottom: '16px',
    borderRadius: '4px',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 3px rgba(0,0,0, 0.3)',
    marginBottom: '8px',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    borderLeft: `4px solid ${WColorPalette.LightBlue.color}`,
    $nest: {
        '&:hover': {
            backgroundColor: '#eee'
        }
    }
})

const titleStyle = style({
    fontSize: '14px',
    fontWeight: EFontWeight.Medium.value,
    lineHeight: '18px',
    maxHeight: '36px',
    color: '#001d70'

})