import { AController } from "@eagerlogic/react-mvc";
import "firebase/analytics";
import * as firebase from "firebase/app";
import "firebase/functions";
import { IAcceptInvitationRpcReq } from "shared/modules/aaa/AaaFunctionDescriptors";
import { passwordValidator, userNameValidator } from "shared/modules/aaa/Validators";
import { ICloudFunction } from "utils/FbUtils";
import { fs } from "utils/Fs";
import { AcceptInvitationSceneModel } from "./AcceptInvitationSceneModel";


export class AcceptInvitationSceneController extends AController<AcceptInvitationSceneModel> {

    public constructor(public readonly invitationId: string) {
        super()
    }

    protected createInitialModel(): AcceptInvitationSceneModel {
        return new AcceptInvitationSceneModel()
    }

    async init() {
        const inv = await fs().collection("Invitations").doc(this.invitationId).get()
        if (inv == null) {
            this.model.isLoading = false;
            this.model.isFound = false;
            this.render(this.model)
            return
        }

        this.model.isLoading = false
        this.model.isFound = true;

        this.model.inviterName = inv.data().inviterName
        this.model.organizationName = inv.data().organizationName
        this.model.email = inv.data().email

        this.render(this.model)

        firebase.analytics().logEvent('accept_invitation_scene.open')
    }

    public readonly onAcceptClick = async () => {
        this.model.nameModel.error = null
        this.model.passwordModel.error = null
        this.model.error = null
        this.render(this.model)

        const hasErrorBind: { hasError: boolean } = { hasError: false }

        const vName = userNameValidator.validateToBind(this.model.nameModel.value, this.model.nameModel, hasErrorBind)
        const vPassword = passwordValidator.validateToBind(this.model.passwordModel.value, this.model.passwordModel, hasErrorBind)

        if (hasErrorBind.hasError) {
            this.render(this.model)
            return
        }

        this.model.isModalLoading = true
        this.render(this.model)

        var acceptInvitationFun = firebase.app().functions("europe-west1").httpsCallable("acceptInvitation") as ICloudFunction<IAcceptInvitationRpcReq, {}>
        try {
            await acceptInvitationFun({
                id: this.invitationId,
                name: vName,
                password: vPassword
            })

            await firebase.auth().signInWithEmailAndPassword(this.model.email, vPassword)
            firebase.analytics().logEvent('accept_invitation_scene.accept_success')
            window.location.href = "/app/index.rsc"
        } catch (e) {
            firebase.analytics().logEvent('accept_invitation_scene.accept_failed')
            this.model.error = e.message
            this.model.isModalLoading = false
            this.render(this.model)
        }
    }

}