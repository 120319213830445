import { IOrganizationData } from "shared/entities/aaa/IOrganizationData";
import { Event } from "utils/Event";
import { ChangeListenerCanceler, fs, FsEntity } from "utils/Fs";

export class CurrentOrgSvc {

    public static readonly onChange = new Event<any>()

    private static _currentOrg: FsEntity<IOrganizationData>
    private static orgListenerCanceler: ChangeListenerCanceler

    public static get currentOrg() {
        return this._currentOrg
    }

    public static async init(orgId: string) {
        if (this.orgListenerCanceler != null) {
            throw Error("This service is already initialized!")
        }

        this._currentOrg = new FsEntity(await fs().collection("Organizations").doc(orgId).get())
        this.orgListenerCanceler = this._currentOrg.addChangeListener((snapshot) => {
            this._currentOrg = snapshot
            this.onChange.fire(null)
        })

    }

    public static destroy() {
        if (this.orgListenerCanceler != null) {
            this.orgListenerCanceler.cancel()
            this.orgListenerCanceler = null
            this._currentOrg = null
        }
    }

}