import { PrivateChatListModel } from "../privateChat/components/PrivateChatList"
import { WibsListItemModel } from "../wibs/components/WibsList"


export class CommMainSceneModel {

    public isLoading: boolean = true
    public selfName: string
    public profilePictureUrl: string = null
    public isEverOpened: boolean = false
    public prevSelectedContactModel: SelectedContactModel = null
    public prevSelectedWibModel: SelectedWibModel = null
    public selectedContactModel: SelectedContactModel = null
    public selectedWibModel: SelectedWibModel = null
    public isOpen: boolean = false
    public activeTab: "tasks" | "chats"
    public tasksTabNotificationCount: number = 0
    public wibsTabNotificationCount: number = 0
    public chatsTabNotificationCount: number = 0

    public privateChats: PrivateChatListModel
    public wibs: WibsListItemModel[] = []
    public tasks: WibsListItemModel[] = []

    public newWibDialogModel: NewWibDialogModel


}

export class NewWibDialogModel {

    public constructor(
        public readonly parentType: 'wib' | null,
        public readonly parentId: string | null
    ) { }

}

export class CommMainContactModel {

    public constructor(
        public readonly id: string,
        public name: string,
    ) { }

    public isOnline: boolean = false
    public lastMessage: string = null
    public notificationCount: number = 0

}

export class SelectedContactModel {

    public constructor(
        public readonly contactId: string,
        public readonly pcId: string,
        public readonly remoteUserName: string,
        public readonly remoteProfilePictureUrl: string,
    ) {

    }

}

export class SelectedWibModel {

    public constructor(
        public readonly wibId: string,
        public readonly wibSubject: string,
        public readonly wibIcon: string,
        public readonly wibIconColor: string,
    ) { }

}