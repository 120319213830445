export class WColorPalette {

    public readonly color: string
    public readonly lightText: boolean

    public static Red_100 = new WColorPalette("#ffcdd2", false)
    public static Red_50 = new WColorPalette("#ffebee", false)
    public static Red_200 = new WColorPalette("#ef9a9a", false)
    public static Red_300 = new WColorPalette("#e57373", false)
    public static Red_400 = new WColorPalette("#ef5350", true)
    public static Red_500 = new WColorPalette("#f44336", true)
    public static Red_600 = new WColorPalette("#e53935", true)
    public static Red_700 = new WColorPalette("#d32f2f", true)
    public static Red_800 = new WColorPalette("#c62828", true)
    public static Red_900 = new WColorPalette("#b71c1c", true)
    public static Red_A100 = new WColorPalette("#ff8a80", false)
    public static Red_A200 = new WColorPalette("#ff5252", true)
    public static Red_A400 = new WColorPalette("#ff1744", true)
    public static Red_A700 = new WColorPalette("#d50000", true)
    public static Red = new WColorPalette(WColorPalette.Red_500.color, WColorPalette.Red_500.lightText)

    public static Pink_50 = new WColorPalette("#fce4ec", false)
    public static Pink_100 = new WColorPalette("#f8bbd0", false)
    public static Pink_200 = new WColorPalette("#f48fb1", false)
    public static Pink_300 = new WColorPalette("#f06292", true)
    public static Pink_400 = new WColorPalette("#ec407a", true)
    public static Pink_500 = new WColorPalette("#e91e63", true)
    public static Pink_600 = new WColorPalette("#d81b60", true)
    public static Pink_700 = new WColorPalette("#c2185b", true)
    public static Pink_800 = new WColorPalette("#ad1457", true)
    public static Pink_900 = new WColorPalette("#880e4f", true)
    public static Pink_A100 = new WColorPalette("#ff80ab", false)
    public static Pink_A200 = new WColorPalette("#ff4081", true)
    public static Pink_A400 = new WColorPalette("#f50057", true)
    public static Pink_A700 = new WColorPalette("#c51162", true)
    public static Pink = new WColorPalette(WColorPalette.Pink_500.color, WColorPalette.Pink_500.lightText)

    public static Purple_50 = new WColorPalette("#f3e5f5", false)
    public static Purple_100 = new WColorPalette("#e1bee7", false)
    public static Purple_200 = new WColorPalette("#ce93d8", false)
    public static Purple_300 = new WColorPalette("#ba68c8", true)
    public static Purple_400 = new WColorPalette("#ab47bc", true)
    public static Purple_500 = new WColorPalette("#9c27b0", true)
    public static Purple_600 = new WColorPalette("#8e24aa", true)
    public static Purple_700 = new WColorPalette("#7b1fa2", true)
    public static Purple_800 = new WColorPalette("#6a1b9a", true)
    public static Purple_900 = new WColorPalette("#4a148c", true)
    public static Purple_A100 = new WColorPalette("#ea80fc", false)
    public static Purple_A200 = new WColorPalette("#e040fb", true)
    public static Purple_A400 = new WColorPalette("#d500f9", true)
    public static Purple_A700 = new WColorPalette("#aa00ff", true)
    public static Purple = new WColorPalette(WColorPalette.Purple_500.color, WColorPalette.Purple_500.lightText)

    public static DeepPurple_50 = new WColorPalette("#ede7f6", false)
    public static DeepPurple_100 = new WColorPalette("#d1c4e9", false)
    public static DeepPurple_200 = new WColorPalette("#b39ddb", false)
    public static DeepPurple_300 = new WColorPalette("#9575cd", true)
    public static DeepPurple_400 = new WColorPalette("#7e57c2", true)
    public static DeepPurple_500 = new WColorPalette("#673ab7", true)
    public static DeepPurple_600 = new WColorPalette("#5e35b1", true)
    public static DeepPurple_700 = new WColorPalette("#512da8", true)
    public static DeepPurple_800 = new WColorPalette("#4527a0", true)
    public static DeepPurple_900 = new WColorPalette("#311b92", true)
    public static DeepPurple_A100 = new WColorPalette("#b388ff", false)
    public static DeepPurple_A200 = new WColorPalette("#7c4dff", true)
    public static DeepPurple_A400 = new WColorPalette("#651fff", true)
    public static DeepPurple_A700 = new WColorPalette("#6200ea", true)
    public static DeepPurple = new WColorPalette(WColorPalette.DeepPurple_500.color, WColorPalette.DeepPurple_500.lightText)

    public static Indigo_50 = new WColorPalette("#e8eaf6", false)
    public static Indigo_100 = new WColorPalette("#c5cae9", false)
    public static Indigo_200 = new WColorPalette("#9fa8da", false)
    public static Indigo_300 = new WColorPalette("#7986cb", true)
    public static Indigo_400 = new WColorPalette("#5c6bc0", true)
    public static Indigo_500 = new WColorPalette("#3f51b5", true)
    public static Indigo_600 = new WColorPalette("#3949ab", true)
    public static Indigo_700 = new WColorPalette("#303f9f", true)
    public static Indigo_800 = new WColorPalette("#283593", true)
    public static Indigo_900 = new WColorPalette("#1a237e", true)
    public static Indigo_A100 = new WColorPalette("#8c9eff", false)
    public static Indigo_A200 = new WColorPalette("#536dfe", true)
    public static Indigo_A400 = new WColorPalette("#3d5afe", true)
    public static Indigo_A700 = new WColorPalette("#304ffe", true)
    public static Indigo = new WColorPalette(WColorPalette.Indigo_500.color, WColorPalette.Indigo_500.lightText)

    public static Blue_50 = new WColorPalette("#e3f2fd", false)
    public static Blue_100 = new WColorPalette("#bbdefb", false)
    public static Blue_200 = new WColorPalette("#90caf9", false)
    public static Blue_300 = new WColorPalette("#64b5f6", false)
    public static Blue_400 = new WColorPalette("#42a5f5", false)
    public static Blue_500 = new WColorPalette("#2196f3", true)
    public static Blue_600 = new WColorPalette("#1e88e5", true)
    public static Blue_700 = new WColorPalette("#1976d2", true)
    public static Blue_800 = new WColorPalette("#1565c0", true)
    public static Blue_900 = new WColorPalette("#0d47a1", true)
    public static Blue_A100 = new WColorPalette("#82b1ff", false)
    public static Blue_A200 = new WColorPalette("#448aff", true)
    public static Blue_A400 = new WColorPalette("#2979ff", true)
    public static Blue_A700 = new WColorPalette("#2962ff", true)
    public static Blue = new WColorPalette(WColorPalette.Blue_500.color, WColorPalette.Blue_500.lightText)

    public static LightBlue_50 = new WColorPalette("#e1f5fe", false)
    public static LightBlue_100 = new WColorPalette("#b3e5fc", false)
    public static LightBlue_200 = new WColorPalette("#81d4fa", false)
    public static LightBlue_300 = new WColorPalette("#4fc3f7", false)
    public static LightBlue_400 = new WColorPalette("#29b6f6", false)
    public static LightBlue_500 = new WColorPalette("#03a9f4", false)
    public static LightBlue_600 = new WColorPalette("#039be5", true)
    public static LightBlue_700 = new WColorPalette("#0288d1", true)
    public static LightBlue_800 = new WColorPalette("#0277bd", true)
    public static LightBlue_900 = new WColorPalette("#01579b", true)
    public static LightBlue_A100 = new WColorPalette("#80d8ff", false)
    public static LightBlue_A200 = new WColorPalette("#40c4ff", false)
    public static LightBlue_A400 = new WColorPalette("#00b0ff", false)
    public static LightBlue_A700 = new WColorPalette("#0091ea", true)
    public static LightBlue = new WColorPalette(WColorPalette.LightBlue_500.color, WColorPalette.LightBlue_500.lightText)

    public static Cyan_50 = new WColorPalette("#e0f7fa", false)
    public static Cyan_100 = new WColorPalette("#b2ebf2", false)
    public static Cyan_200 = new WColorPalette("#80deea", false)
    public static Cyan_300 = new WColorPalette("#4dd0e1", false)
    public static Cyan_400 = new WColorPalette("#26c6da", false)
    public static Cyan_500 = new WColorPalette("#00bcd4", false)
    public static Cyan_600 = new WColorPalette("#00acc1", false)
    public static Cyan_700 = new WColorPalette("#0097a7", true)
    public static Cyan_800 = new WColorPalette("#00838f", true)
    public static Cyan_900 = new WColorPalette("#006064", true)
    public static Cyan_A100 = new WColorPalette("#84ffff", false)
    public static Cyan_A200 = new WColorPalette("#18ffff", false)
    public static Cyan_A400 = new WColorPalette("#00e5ff", false)
    public static Cyan_A700 = new WColorPalette("#00b8d4", false)
    public static Cyan = new WColorPalette(WColorPalette.Cyan_500.color, WColorPalette.Cyan_500.lightText)

    public static Teal_50 = new WColorPalette("#e0f2f1", false)
    public static Teal_100 = new WColorPalette("#b2dfdb", false)
    public static Teal_200 = new WColorPalette("#80cbc4", false)
    public static Teal_300 = new WColorPalette("#4db6ac", false)
    public static Teal_400 = new WColorPalette("#26a69a", true)
    public static Teal_500 = new WColorPalette("#009688", true)
    public static Teal_600 = new WColorPalette("#00897b", true)
    public static Teal_700 = new WColorPalette("#00796b", true)
    public static Teal_800 = new WColorPalette("#00695c", true)
    public static Teal_900 = new WColorPalette("#004d40", true)
    public static Teal_A100 = new WColorPalette("#a7ffeb", false)
    public static Teal_A200 = new WColorPalette("#64ffda", false)
    public static Teal_A400 = new WColorPalette("#1de9b6", false)
    public static Teal_A700 = new WColorPalette("#00bfa5", false)
    public static Teal = new WColorPalette(WColorPalette.Teal_500.color, WColorPalette.Teal_500.lightText)

    public static Green_50 = new WColorPalette("#e8f5e9", false)
    public static Green_100 = new WColorPalette("#c8e6c9", false)
    public static Green_200 = new WColorPalette("#a5d6a7", false)
    public static Green_300 = new WColorPalette("#81c784", false)
    public static Green_400 = new WColorPalette("#66bb6a", false)
    public static Green_500 = new WColorPalette("#4caf50", false)
    public static Green_600 = new WColorPalette("#43a047", true)
    public static Green_700 = new WColorPalette("#388e3c", true)
    public static Green_800 = new WColorPalette("#2e7d32", true)
    public static Green_900 = new WColorPalette("#1b5e20", true)
    public static Green_A100 = new WColorPalette("#b9f6ca", false)
    public static Green_A200 = new WColorPalette("#69f0ae", false)
    public static Green_A400 = new WColorPalette("#00e676", false)
    public static Green_A700 = new WColorPalette("#00c853", false)
    public static Green = new WColorPalette(WColorPalette.Green_500.color, WColorPalette.Green_500.lightText)

    public static LightGreen_50 = new WColorPalette("#f1f8e9", false)
    public static LightGreen_100 = new WColorPalette("#dcedc8", false)
    public static LightGreen_200 = new WColorPalette("#c5e1a5", false)
    public static LightGreen_300 = new WColorPalette("#aed581", false)
    public static LightGreen_400 = new WColorPalette("#9ccc65", false)
    public static LightGreen_500 = new WColorPalette("#8bc34a", false)
    public static LightGreen_600 = new WColorPalette("#7cb342", false)
    public static LightGreen_700 = new WColorPalette("#689f38", true)
    public static LightGreen_800 = new WColorPalette("#558b2f", true)
    public static LightGreen_900 = new WColorPalette("#33691e", true)
    public static LightGreen_A100 = new WColorPalette("#ccff90", false)
    public static LightGreen_A200 = new WColorPalette("#b2ff59", false)
    public static LightGreen_A400 = new WColorPalette("#76ff03", false)
    public static LightGreen_A700 = new WColorPalette("#64dd17", false)
    public static LightGreen = new WColorPalette(WColorPalette.LightGreen_500.color, WColorPalette.LightGreen_500.lightText)

    public static Lime_50 = new WColorPalette("#f9fbe7", false)
    public static Lime_100 = new WColorPalette("#f0f4c3", false)
    public static Lime_200 = new WColorPalette("#e6ee9c", false)
    public static Lime_300 = new WColorPalette("#dce775", false)
    public static Lime_400 = new WColorPalette("#d4e157", false)
    public static Lime_500 = new WColorPalette("#cddc39", false)
    public static Lime_600 = new WColorPalette("#c0ca33", false)
    public static Lime_700 = new WColorPalette("#afb42b", false)
    public static Lime_800 = new WColorPalette("#9e9d24", false)
    public static Lime_900 = new WColorPalette("#827717", true)
    public static Lime_A100 = new WColorPalette("#f4ff81", false)
    public static Lime_A200 = new WColorPalette("#eeff41", false)
    public static Lime_A400 = new WColorPalette("#c6ff00", false)
    public static Lime_A700 = new WColorPalette("#aeea00", false)
    public static Lime = new WColorPalette(WColorPalette.Lime_500.color, WColorPalette.Lime_500.lightText)

    public static Yellow_50 = new WColorPalette("#fffde7", false)
    public static Yellow_100 = new WColorPalette("#fff9c4", false)
    public static Yellow_200 = new WColorPalette("#fff59d", false)
    public static Yellow_300 = new WColorPalette("#fff176", false)
    public static Yellow_400 = new WColorPalette("#ffee58", false)
    public static Yellow_500 = new WColorPalette("#ffeb3b", false)
    public static Yellow_600 = new WColorPalette("#fdd835", false)
    public static Yellow_700 = new WColorPalette("#fbc02d", false)
    public static Yellow_800 = new WColorPalette("#f9a825", false)
    public static Yellow_900 = new WColorPalette("#f57f17", false)
    public static Yellow_A100 = new WColorPalette("#ffff8d", false)
    public static Yellow_A200 = new WColorPalette("#ffff00", false)
    public static Yellow_A400 = new WColorPalette("#ffea00", false)
    public static Yellow_A700 = new WColorPalette("#ffd600", false)
    public static Yellow = new WColorPalette(WColorPalette.Yellow_500.color, WColorPalette.Yellow_500.lightText)

    public static Amber_50 = new WColorPalette("#fff8e1", false)
    public static Amber_100 = new WColorPalette("#ffecb3", false)
    public static Amber_200 = new WColorPalette("#ffe082", false)
    public static Amber_300 = new WColorPalette("#ffd54f", false)
    public static Amber_400 = new WColorPalette("#ffca28", false)
    public static Amber_500 = new WColorPalette("#ffc107", false)
    public static Amber_600 = new WColorPalette("#ffb300", false)
    public static Amber_700 = new WColorPalette("#ffa000", false)
    public static Amber_800 = new WColorPalette("#ff8f00", false)
    public static Amber_900 = new WColorPalette("#ff6f00", false)
    public static Amber_A100 = new WColorPalette("#ffe57f", false)
    public static Amber_A200 = new WColorPalette("#ffd740", false)
    public static Amber_A400 = new WColorPalette("#ffc400", false)
    public static Amber_A700 = new WColorPalette("#ffab00", false)
    public static Amber = new WColorPalette(WColorPalette.Amber_500.color, WColorPalette.Amber_500.lightText)

    public static Orange_50 = new WColorPalette("#fff3e0", false)
    public static Orange_100 = new WColorPalette("#ffe0b2", false)
    public static Orange_200 = new WColorPalette("#ffcc80", false)
    public static Orange_300 = new WColorPalette("#ffb74d", false)
    public static Orange_400 = new WColorPalette("#ffa726", false)
    public static Orange_500 = new WColorPalette("#ff9800", false)
    public static Orange_600 = new WColorPalette("#fb8c00", false)
    public static Orange_700 = new WColorPalette("#f57c00", false)
    public static Orange_800 = new WColorPalette("#ef6c00", true)
    public static Orange_900 = new WColorPalette("#e65100", true)
    public static Orange_A100 = new WColorPalette("#ffd180", false)
    public static Orange_A200 = new WColorPalette("#ffab40", false)
    public static Orange_A400 = new WColorPalette("#ff9100", false)
    public static Orange_A700 = new WColorPalette("#ff6d00", false)
    public static Orange = new WColorPalette(WColorPalette.Orange_500.color, WColorPalette.Orange_500.lightText)

    public static DeepOrange_50 = new WColorPalette("#fbe9e7", false)
    public static DeepOrange_100 = new WColorPalette("#ffccbc", false)
    public static DeepOrange_200 = new WColorPalette("#ffab91", false)
    public static DeepOrange_300 = new WColorPalette("#ff8a65", false)
    public static DeepOrange_400 = new WColorPalette("#ff7043", false)
    public static DeepOrange_500 = new WColorPalette("#ff5722", true)
    public static DeepOrange_600 = new WColorPalette("#f4511e", true)
    public static DeepOrange_700 = new WColorPalette("#e64a19", true)
    public static DeepOrange_800 = new WColorPalette("#d84315", true)
    public static DeepOrange_900 = new WColorPalette("#bf360c", true)
    public static DeepOrange_A100 = new WColorPalette("#ff9e80", false)
    public static DeepOrange_A200 = new WColorPalette("#ff6e40", false)
    public static DeepOrange_A400 = new WColorPalette("#ff3d00", true)
    public static DeepOrange_A700 = new WColorPalette("#dd2c00", true)
    public static DeepOrange = new WColorPalette(WColorPalette.DeepOrange_500.color, WColorPalette.DeepOrange_500.lightText)

    public static Brown_50 = new WColorPalette("#efebe9", false)
    public static Brown_100 = new WColorPalette("#d7ccc8", false)
    public static Brown_200 = new WColorPalette("#bcaaa4", false)
    public static Brown_300 = new WColorPalette("#a1887f", true)
    public static Brown_400 = new WColorPalette("#8d6e63", true)
    public static Brown_500 = new WColorPalette("#795548", true)
    public static Brown_600 = new WColorPalette("#6d4c41", true)
    public static Brown_700 = new WColorPalette("#5d4037", true)
    public static Brown_800 = new WColorPalette("#4e342e", true)
    public static Brown_900 = new WColorPalette("#3e2723", true)
    public static Brown = new WColorPalette(WColorPalette.Brown_500.color, WColorPalette.Brown_500.lightText)

    public static Grey_50 = new WColorPalette("#fafafa", false)
    public static Grey_100 = new WColorPalette("#f5f5f5", false)
    public static Grey_200 = new WColorPalette("#eeeeee", false)
    public static Grey_300 = new WColorPalette("#e0e0e0", false)
    public static Grey_400 = new WColorPalette("#bdbdbd", false)
    public static Grey_500 = new WColorPalette("#9e9e9e", false)
    public static Grey_600 = new WColorPalette("#757575", true)
    public static Grey_700 = new WColorPalette("#616161", true)
    public static Grey_800 = new WColorPalette("#424242", true)
    public static Grey_900 = new WColorPalette("#212121", true)
    public static Grey = new WColorPalette(WColorPalette.Grey_500.color, WColorPalette.Grey_500.lightText)

    public static BlueGrey_50 = new WColorPalette("#eceff1", false)
    public static BlueGrey_100 = new WColorPalette("#cfd8dc", false)
    public static BlueGrey_200 = new WColorPalette("#b0bec5", false)
    public static BlueGrey_300 = new WColorPalette("#90a4ae", false)
    public static BlueGrey_400 = new WColorPalette("#78909c", true)
    public static BlueGrey_500 = new WColorPalette("#607d8b", true)
    public static BlueGrey_600 = new WColorPalette("#546e7a", true)
    public static BlueGrey_700 = new WColorPalette("#455a64", true)
    public static BlueGrey_800 = new WColorPalette("#37474f", true)
    public static BlueGrey_900 = new WColorPalette("#263238", true)
    public static BlueGray = new WColorPalette(WColorPalette.BlueGrey_500.color, WColorPalette.BlueGrey_500.lightText)

    public static White = new WColorPalette("#ffffff", false)


    private constructor(color: string, lightText: boolean) {
        this.color = color
        this.lightText = lightText
    }

}