import { CurrentOrgSvc } from "backend/aaa/CurrentOrgSvc"
import { IBoardData } from "shared/entities/projects/IBoardData"
import { ValidationException } from "shared/jalidate/Jalidate"
import { transact } from "utils/Fs"

export const createColumnFun = async (boardId: string, colId: string, colName: string) => {
    if (colName == null) {
        colName = ""
    }
    colName = colName.trim()

    if (colName.length < 2) {
        throw ValidationException.createWithMessage("The column name is mandatory!")
    }

    await transact(async (t) => {
        const boardRef = CurrentOrgSvc.currentOrg.snapshot.ref.collection("Boards").doc(boardId)
        const boardEntity = await t.get(boardRef)
        if (!boardEntity.exists) {
            throw new Error("Can't find board with the given id!")
        }
        const boardData = boardEntity.data() as IBoardData
        boardData.columns.push(
            {
                id: colId,
                name: colName,
                cards: [],
                options: {
                    actionOptions: {
                        actions: [],
                        disableCreate: false,
                        disableDrag: false,
                        disableDrop: false,
                    },
                    memberOptions: {
                        autoMembers: []
                    }
                }
            }
        )

        t.update(boardRef, {
            columns: boardData.columns
        })

    }, null)
}