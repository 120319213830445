import { AScene } from '@eagerlogic/react-mvc';
import { PageLayout } from 'components/PageLayout';
import * as React from 'react';
import { WButton } from 'wds/Button';
import { ModalButton, WModal } from 'wds/Modal';
import { WPaper } from 'wds/Paper';
import { WSelect } from 'wds/Select';
import { WTable, WTableCell, WTableHeadCell, WTableHeadRow, WTableRow } from 'wds/Table';
import { WTextField } from 'wds/TextField';
import { TeamSettingsSceneController } from './TeamSettingsSceneController';
import { TeamSettingsSceneModel } from './TeamSettingsSceneModel';

export class TeamSettingsScene extends AScene<TeamSettingsSceneProps, TeamSettingsSceneModel, TeamSettingsSceneController> {

    protected createControlller(): TeamSettingsSceneController {
        return new TeamSettingsSceneController()
    }

    render(): React.ReactNode {
        return (
            <>
                <PageLayout title="Team settings" isLoading={this.model.isLoading}>
                    <WPaper title="Users">

                        <WButton title="Invite user" color="primary" size="small" onClick={this.controller.handleInviteUserClick} />

                        <WTable>
                            <WTableHeadRow>
                                <WTableHeadCell title="Name" />
                                <WTableHeadCell title="Email" />
                                <WTableHeadCell title="Role" />
                                <WTableHeadCell title="Actions" />
                            </WTableHeadRow>

                            {this.model.invitations.map((item) => {
                                return (
                                    <WTableRow key={"tr-" + item.email}>
                                        <WTableCell><div style={{ color: "#888" }}>Pending invitation</div></WTableCell>
                                        <WTableCell><div style={{ color: "#888" }}>{item.email}</div></WTableCell>
                                        <WTableCell><div style={{ color: "#888" }}>{item.role.name}</div></WTableCell>
                                        <WTableCell></WTableCell>
                                    </WTableRow>
                                )
                            })}

                            {this.model.members.map((item) => {
                                return (
                                    <WTableRow key={"tr-" + item.email}>
                                        <WTableCell>{item.name}</WTableCell>
                                        <WTableCell>{item.email}</WTableCell>
                                        <WTableCell>{item.role.name}</WTableCell>
                                        <WTableCell></WTableCell>
                                    </WTableRow>
                                )
                            })}
                        </WTable>

                    </WPaper>
                </PageLayout>


                {this.model.invitationDialogModel != null &&
                    <WModal
                        title="Invite user"
                        error={this.model.invitationDialogModel.error}
                        isLoading={this.model.invitationDialogModel.isLoading}
                        buttons={[
                            new ModalButton("Cancel", this.controller.handleInviteUserCancelClick, "text"),
                            new ModalButton("Invite", this.controller.handleInviteUserOkClick, "contained", "primary"),
                        ]}
                    >
                        <WTextField
                            model={this.model.invitationDialogModel.emailModel}
                            label="Email"
                            helperText="The email address of the user to invite."
                            required={true}
                        />

                        <WSelect
                            model={this.model.invitationDialogModel.roleModel}
                            label="Role"
                            options={this.model.invitationDialogModel.roleValues}
                            helperText="The role of the user to invite."
                            required={true}
                        />

                        <WTextField
                            model={this.model.invitationDialogModel.messageModel}
                            label="Message"
                            helperText="This message will be included in the invitation mail."
                            multiline={true}
                        />
                    </WModal>
                }
            </>
        )
    }


}

export interface TeamSettingsSceneProps {

}