import { AScene } from "@eagerlogic/react-mvc";
import { PageLayout } from 'components/PageLayout';
import { ProfilePicture } from 'components/ProfilePicture';
import { EHAlign } from 'components/styles';
import * as React from 'react';
import { FileSelectButton } from 'wds/FileUploadButton';
import { WPaper } from 'wds/Paper';
import { EColor } from 'wds/styles/WColor';
import { WVBox, WVBoxCell } from 'wds/VBox';
import { ProfileSettingsSceneController } from "./ProfileSettingsSceneController";
import { ProfileSettingsSceneModel } from "./ProfileSettingsSceneModel";

export class ProfileSettingsScene extends AScene<{}, ProfileSettingsSceneModel, ProfileSettingsSceneController> {
    protected createControlller(): ProfileSettingsSceneController {
        return new ProfileSettingsSceneController()
    }

    componentWillUnmount() {
        this.controller.destroy()
    }

    render(): React.ReactNode {
        return (
            <>
                <PageLayout
                    title="Profile settings"
                    isLoading={false}
                >
                    <WPaper title="Profile picture">
                        <WVBox spacing={32}>
                            <WVBoxCell hAlign={EHAlign.Center}>
                                <ProfilePicture
                                    name={this.model.userName}
                                    profilePictureUrl={this.model.profilePictureUrl}
                                    size={128}
                                    backgroundColor="#0bd"
                                    borderColor="#ccc"
                                    borderWidth={8}
                                />
                            </WVBoxCell>
                            <WVBoxCell hAlign={EHAlign.Center}>
                                {this.model.isUploading &&
                                    <div style={{ display: "inline-flex" }}>Uploading...</div>
                                }
                                {!this.model.isUploading &&
                                    <FileSelectButton title="Change" onChange={this.controller.handleNewPictureSelected} />
                                }
                                {this.model.hasUploadError &&
                                    <div style={{ display: "inline-block", color: EColor.ErrorText.color }} >An error occured while changing teh picture</div>
                                }
                            </WVBoxCell>
                        </WVBox>
                    </WPaper>
                </PageLayout>
            </>
        )
    }


}