import { Event } from "../utils/Event"

export class WibSidebar {

    public static onChange: Event<IWibSidebarItem> = new Event()

    public static showWib(wibId: string): void {
        this.changeSidebar({
            id: wibId,
            type: "wib"
        })
    }

    public static showPrivateChat(remoteUserId: string): void {
        this.changeSidebar({
            id: remoteUserId,
            type: "private_chat"
        })
    }

    public static close() {
        this.changeSidebar(null)
    }

    private static changeSidebar(item: IWibSidebarItem) {
        if (this.onChange != null) {
            this.onChange.fire(item)
        }
    }

    private constructor() { }

}

export interface IWibSidebarItem {
    id: string
    type: "wib" | "private_chat"
}