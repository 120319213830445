import { CurrentOrgSvc } from "backend/aaa/CurrentOrgSvc";
import { CurrentUserSvc } from "backend/aaa/CurrentUserSvc";
import { AvailableColors } from "shared/entities/aaa/AvailableColors";
import { IBoardData, VER_Board } from "shared/entities/projects/IBoardData";
import { fs } from "utils/Fs";
import { v4 as uuid } from 'uuid';

export const createBoardFun = async (name: string): Promise<string> => {
    return fs().runTransaction(async (t) => {
        const boardRef = CurrentOrgSvc.currentOrg.snapshot.ref
            .collection("Boards")
            .doc()

        const boardD: IBoardData = {
            name: name,
            createdAt: Date.now(),
            createdBy: CurrentUserSvc.currentUser.id,
            color: AvailableColors.getRandomColor(),
            deleted: false,
            version: VER_Board,
            columns: [
                {
                    id: uuid(),
                    name: "Todo",
                    cards: [],
                    options: {
                        actionOptions: {
                            actions: [],
                            disableDrag: false,
                            disableDrop: false,
                            disableCreate: false
                        },
                        memberOptions: {
                            autoMembers: []
                        }
                    }
                },
                {
                    id: uuid(),
                    name: "Doing",
                    cards: [],
                    options: {
                        actionOptions: {
                            actions: [],
                            disableDrag: false,
                            disableDrop: false,
                            disableCreate: false
                        },
                        memberOptions: {
                            autoMembers: []
                        }
                    }
                },
                {
                    id: uuid(),
                    name: "Done",
                    cards: [],
                    options: {
                        actionOptions: {
                            actions: [],
                            disableDrag: false,
                            disableDrop: false,
                            disableCreate: false
                        },
                        memberOptions: {
                            autoMembers: []
                        }
                    }
                },
            ]
        }

        t.set(boardRef, boardD)

        return boardRef.id
    })
}