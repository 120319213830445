import { CurrentOrgSvc } from "backend/aaa/CurrentOrgSvc"
import { IBoardData } from "shared/entities/projects/IBoardData"
import { ValidationException } from "shared/jalidate/Jalidate"
import { transact } from "utils/Fs"

export const changeColumnNameFun = async (boardId: string, colId: string, newColName: string) => {
    if (newColName == null) {
        newColName = ""
    }
    newColName = newColName.trim()

    if (newColName.length < 2) {
        throw ValidationException.createWithMessage("The column name can not be empty!")
    }

    await transact(async (t) => {
        const boardRef = CurrentOrgSvc.currentOrg.snapshot.ref.collection("Boards").doc(boardId)
        const boardEntity = await t.get(boardRef)
        if (!boardEntity.exists) {
            throw new Error("Can't find board with the given id!")
        }
        const boardData = boardEntity.data() as IBoardData
        const col = boardData.columns.find((item) => {
            return item.id == colId
        })
        if (col == null) {
            throw new Error("Can't find column with the given id!")
        }
        col.name = newColName
        t.update(boardRef, {
            columns: boardData.columns
        })
    }, null)
}