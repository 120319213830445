import { FieldModel } from "components/FieldModel";


export class AcceptInvitationSceneModel {

    public error: string = null
    public isLoading = true
    public isModalLoading = false
    public isFound: boolean = false
    public readonly nameModel = new FieldModel("")
    public readonly passwordModel = new FieldModel("")
    public inviterName: string
    public organizationName: string
    public email: string

}