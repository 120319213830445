import { FieldModel } from "components/FieldModel"
import { EUserRole } from "shared/entities/aaa/EUserRole"
import { SelectOption } from "wds/Select"


export class TeamSettingsSceneModel {

    public isLoading: boolean = false
    public invitations: TeamMemberInvitationModel[] = []
    public members: TeamMemberModel[] = []
    public invitationDialogModel: InvitationDialogModel = null

}

export class TeamMemberModel {

    public constructor(
        public readonly name: string,
        public readonly email: string,
        public readonly role: EUserRole
    ) { }

}

export class TeamMemberInvitationModel {

    public constructor(
        public readonly email: string,
        public readonly role: EUserRole
    ) { }

}

export class InvitationDialogModel {

    public isLoading: boolean = false
    public error: string = null
    public readonly emailModel = new FieldModel<string>("")
    public readonly roleModel = new FieldModel<string>("User")
    public readonly messageModel = new FieldModel<string>("")

    public constructor(public readonly roleValues: SelectOption[]) { }

}