import * as firebase from 'firebase/app'
import 'firebase/firestore'

export function fs() {
    return firebase.firestore()
}

export const transact = async <$R>(fun: (t: firebase.firestore.Transaction) => Promise<$R>, t: firebase.firestore.Transaction | null): Promise<$R> => {
    if (t == null) {
        return fs().runTransaction(fun)
    } else {
        return fun(t)
    }
}

export class FsEntity<$Data> {

    private listeners: { [id: number]: IListenerReg<FsEntity<$Data>> } = {}
    private caneler: () => void

    public constructor(
        private _sn: firebase.firestore.DocumentSnapshot
    ) { }

    public get id() {
        return this._sn.id
    }

    public get exists(): boolean {
        return this._sn.exists
    }

    public get data(): $Data {
        return this._sn.data() as $Data
    }

    public get snapshot() {
        return this._sn
    }

    public addChangeListener(onChange: (entity: FsEntity<$Data>) => void, onError?: (error: Error) => void): ChangeListenerCanceler {
        const id = Object.keys(this.listeners).length
        this.listeners[id] = {
            onChange: onChange,
            onError: onError
        }
        if (this.caneler == null) {
            this.caneler = this._sn.ref.onSnapshot((snapshot) => {
                this._sn = snapshot
                Object.values(this.listeners).forEach((l) => {
                    l.onChange(this)
                })
            }, (error) => {
                this.caneler = null
                try {
                    Object.values(this.listeners).forEach((l) => {
                        if (l.onError != null) {
                            try {
                                l.onError(error)
                            } catch (ex) {

                            }
                        }
                    })
                } finally {
                    this.listeners = {}
                }
            })
        } else {
            if (this._sn != null) {
                onChange(this)
            }
        }
        return new ChangeListenerCanceler(id, this.cancelListener)
    }

    private readonly cancelListener = (id: number) => {
        if (this.listeners[id] == null) {
            return
        }
        this.listeners[id] = undefined
        delete this.listeners[id]

        if (Object.keys(this.listeners).length < 1) {
            this.caneler()
            this.caneler = null
        }
    }

}

export class ChangeListenerCanceler {

    public constructor(
        public readonly id: number,
        private readonly realCanceler: RealCanceler
    ) { }

    public cancel() {
        this.realCanceler(this.id)
    }

}

interface RealCanceler {

    (id: number): void
}

interface IListenerReg<$Type> {
    onChange: (entity: $Type) => void
    onError?: (error: Error) => void
}

export class FsQueryListener<$Data> {

    private listeners: { [id: number]: IListenerReg<firebase.firestore.QuerySnapshot<$Data>> } = {}
    private caneler: () => void
    private _sn: firebase.firestore.QuerySnapshot<$Data>

    public constructor(
        private readonly q: firebase.firestore.Query
    ) {

    }

    public addChangeListener(onChange: (snapshot: firebase.firestore.QuerySnapshot<$Data>) => void, onError?: (error: Error) => void): ChangeListenerCanceler {
        const id = Object.keys(this.listeners).length
        this.listeners[id] = {
            onChange: onChange,
            onError: onError
        }
        if (this.caneler == null) {
            this.caneler = this.q.onSnapshot((snapshot) => {
                this._sn = snapshot as firebase.firestore.QuerySnapshot<$Data>
                Object.values(this.listeners).forEach((l) => {
                    l.onChange(this._sn)
                })
            }, (error) => {
                this.caneler = null
                try {
                    Object.values(this.listeners).forEach((l) => {
                        if (l.onError != null) {
                            try {
                                l.onError(error)
                            } catch (ex) {

                            }
                        }
                    })
                } finally {
                    this.listeners = {}
                }
            })
        } else {
            if (this._sn != null) {
                onChange(this._sn)
            }
        }
        return new ChangeListenerCanceler(id, this.cancelListener)
    }

    private readonly cancelListener = (id: number) => {
        if (this.listeners[id] == null) {
            return
        }
        this.listeners[id] = undefined
        delete this.listeners[id]

        if (Object.keys(this.listeners).length < 1) {
            this.caneler()
            this.caneler = null
        }
    }

}