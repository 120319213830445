import * as firebase from "firebase/app";
import "firebase/firestore"
import "firebase/database"

export class PresenceHadler {

    private static currentUserId: string = null

    public static onSignedIn(userId: string) {
        if (this.currentUserId != null) {
            this.onSignedOut()
        }

        this.currentUserId = userId

        var userStatusDbRef = firebase.database().ref('/status/' + userId)

        firebase.database().ref('.info/connected').on('value', (snapshot) => {
            if (!snapshot.val()) {
                return
            }

            userStatusDbRef.onDisconnect().set(isOfflineForDatabase).then(() => {
                userStatusDbRef.set(isOnlineForDatabase);
            })
        })
    }

    public static onSignedOut() {
        // if (this.currentUserId == null) {
        //     return
        // }
        // var userId = this.currentUserId
        // this.currentUserId = null
        // var userStatusDbRef = firebase.database().ref('/status/' + userId)
        // userStatusDbRef.set(isOfflineForDatabase);
        // firebase.database().ref('.info/connected').off()

    }

    private constructor() { }

}

const isOfflineForDatabase = {
    state: 'offline',
    last_changed: firebase.database.ServerValue.TIMESTAMP,
};

const isOnlineForDatabase = {
    state: 'online',
    last_changed: firebase.database.ServerValue.TIMESTAMP,
};