import { AScene } from '@eagerlogic/react-mvc';
import { PageLayout } from 'components/PageLayout';
import { BoardListItem } from 'modules/app/projects/scenes/boardList/components/BoardListItem';
import * as React from 'react';
import { style } from 'typestyle';
import { WButton } from 'wds/Button';
import { WPaper } from 'wds/Paper';
import { ProfilePicture } from '../../../../../components/ProfilePicture';
import { EFontWeight } from '../../../../../wds/styles/EFontWeight';
import { EditBoardNameDialog } from '../board/components/EditBoardNameDialog';
import { CreateNewBoardDialog } from './CreateNewBoardDialog';
import { ProjectBoardListSceneController } from './ProjectBoardListSceneController';
import { BoardListItemModel, ProjectBoardListSceneModel } from './ProjectsBoardListSceneModel';

export class ProjectBoardListScene extends AScene<ProjectBoardListSceneProps, ProjectBoardListSceneModel, ProjectBoardListSceneController> {

    protected createControlller(): ProjectBoardListSceneController {
        return new ProjectBoardListSceneController()
    }

    render(): React.ReactNode {
        return (
            <>
                <PageLayout title="Projects" isLoading={this.model.isLoading}>
                    <WPaper title="Boards">
                        <WButton title="Create new" icon="add" color="primary" size="small" onClick={this.controller.onCreateNewBoardClick} />
                        <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                            {this.model.boards.map((board) => {
                                return <BoardListItem
                                    key={"bli-" + board.id}
                                    model={board}
                                    onClick={() => { this.controller.onBoardClick(board.id) }}
                                    onEditBoardClick={() => { this.controller.onEditBoardClick(board.id, board.name) }}
                                    onDeleteBoardClick={() => { this.controller.onDeleteBoard(board.id) }}
                                />
                            })}
                            {/* <WTable>
                                <WTableHeadRow>
                                    <WTableHeadCell title="Name" />
                                    <WTableHeadCell title="Created by" />
                                    <WTableHeadCell title="" />
                                </WTableHeadRow>
                                {this.model.boards.map((board) => {
                                    return (
                                        <WTableRow key={"row-" + board.id} onClick={() => { this.controller.onBoardClick(board.id) }}>
                                            <WTableCell vAlign={EVAlign.Middle}>{board.name}</WTableCell>
                                            <WTableCell vAlign={EVAlign.Middle}>{this.renderCreatedBy(board)}</WTableCell>
                                            <WTableCell vAlign={EVAlign.Middle} hAlign={EHAlign.Right}>
                                                <WIconButton icon="edit" size="small" onClick={() => { this.controller.onEditBoardClick(board.id, board.name) }} />
                                                <WIconButton icon="delete" size="small" onClick={() => { this.controller.onDeleteBoard(board.id) }} />
                                            </WTableCell>
                                        </WTableRow>
                                    )
                                })}

                            </WTable> */}
                        </div>
                    </WPaper>
                </PageLayout>

                {this.model.createNewBoardDialogModel != null &&
                    <CreateNewBoardDialog model={this.model.createNewBoardDialogModel} controller={this.controller} />
                }

                {this.model.editBoardNameDialogModel != null &&
                    <EditBoardNameDialog model={this.model.editBoardNameDialogModel} controller={this.controller} />
                }
            </>
        )
    }

    private readonly renderCreatedBy = (board: BoardListItemModel) => {
        return (
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <ProfilePicture
                    size={16}
                    name={board.ownerName}
                    backgroundColor={board.ownerColor}
                    profilePictureUrl={board.ownerPPUrl}
                    borderWidth={0}
                />
                <div style={{ minWidth: '4px' }} />
                <div>{board.ownerName}</div>
            </div>
        )

    }

}

export interface ProjectBoardListSceneProps {

}

const boardNameStyle = style({
    fontSize: '20px',
    fontWeight: EFontWeight.Semibold.value,
    color: '#222'
})