import * as React from 'react'

export class WIcon extends React.Component<WIconProps, {}> {

    public render() {
        return (
            <div style={{ display: "inline-flex", fontSize: this.props.size, color: this.props.color }} className="material-icons">
                {this.props.icon}
            </div>
        )
    }

}

export interface WIconProps {
    icon: string
    size: string
    color: string
}
