import { EHAlign, EVAlign } from 'components/styles'
import * as React from 'react'
import { style } from 'typestyle'
import { ReactUtils } from 'utils/ReactUtils'
import { EFontWeight } from './styles/EFontWeight'

export class WTable extends React.Component<TableProps> {

    render() {
        return (
            <div style={{ width: "100%", display: "table", borderCollapse: "collapse", border: "none" }}>
                {this.getHeadRow()}
                {this.getRows()}
            </div>
        )
    }

    private getHeadRow(): React.ReactNode {
        var res: React.ReactNode = null
        React.Children.forEach(this.props.children, (item) => {
            if (ReactUtils.checkComponentType(item, WTableHeadRow)) {
                res = item
            }
        })

        return res
    }

    private getRows(): React.ReactNode[] {
        var res: React.ReactNode[] = []

        React.Children.forEach(this.props.children, (item) => {
            if (ReactUtils.checkComponentType(item, WTableRow)) {
                res.push(item)
            }
        })

        return res
    }

}

export interface TableProps {

}

export class WTableHeadRow extends React.Component<TableHeadRowProps, any> {

    render() {
        return (
            <div style={{ display: "table-row", borderBottom: "2px solid #ccc" }}>
                {this.getCells()}
            </div>
        )
    }

    private getCells(): React.ReactNode[] {
        var res: React.ReactNode[] = []

        React.Children.forEach(this.props.children, (item) => {
            if (ReactUtils.checkComponentType(item, WTableHeadCell)) {
                res.push(item)
            }
        })

        return res
    }

}

export interface TableHeadRowProps {

}

export class WTableHeadCell extends React.Component<TableHeadCellProps> {

    render() {
        var haStr = "left"
        if (this.props.hAlign != null) {
            if (this.props.hAlign == EHAlign.Center) {
                haStr = "center"
            } else if (this.props.hAlign == EHAlign.Right) {
                haStr = "right"
            }
        }

        var vaStr = "middle"
        if (this.props.vAlign != null) {
            if (this.props.vAlign == EVAlign.Top) {
                vaStr = "top"
            } else if (this.props.vAlign == EVAlign.Bottom) {
                vaStr = "bottom"
            }
        }

        return (
            <div style={{ display: "table-cell", fontSize: "14px", fontWeight: EFontWeight.Semibold.value, color: "#aaa", padding: "8px" }}>
                {this.props.title}
            </div>
        )
    }

}

export interface TableHeadCellProps {

    title: string
    hAlign?: EHAlign
    vAlign?: EVAlign

}

export class WTableRow extends React.Component<TableRowProps, any> {

    render() {
        return (
            <div className={this.props.onClick != null ? clickableRowStyle : null} style={{ display: "table-row", borderBottom: "1px solid #eee", cursor: this.props.onClick != null ? 'pointer' : null }} onClick={this.props.onClick}>
                {this.getCells()}
            </div>
        )
    }

    private getCells(): React.ReactNode[] {
        var res: React.ReactNode[] = []

        React.Children.forEach(this.props.children, (item) => {
            if (ReactUtils.checkComponentType(item, WTableCell)) {
                res.push(item)
            }
        })

        return res
    }

}

export interface TableRowProps {
    onClick?: () => void
}

const clickableRowStyle = style({
    backgroundColor: 'transparent',
    $nest: {
        '&:hover': {
            backgroundColor: 'rgba(0,0,0, 0.05)'
        }
    }
})

export class WTableCell extends React.Component<TableCellProps, any> {

    render() {
        var haStr = "flex-start"
        if (this.props.hAlign != null) {
            if (this.props.hAlign == EHAlign.Center) {
                haStr = "center"
            } else if (this.props.hAlign == EHAlign.Right) {
                haStr = "flex-end"
            }
        }

        var vaStr = "center"
        if (this.props.vAlign != null) {
            if (this.props.vAlign == EVAlign.Top) {
                vaStr = "flex-start"
            } else if (this.props.vAlign == EVAlign.Bottom) {
                vaStr = "flex=end"
            }
        }

        return (
            <div className={tableCellStyle} style={{ verticalAlign: "top" }}>
                <div className={tableCellInnerStyle} style={{ alignItems: vaStr, justifyContent: haStr }}>
                    {this.props.children}
                </div>
            </div>
        )
    }

}

export interface TableCellProps {

    hAlign?: EHAlign
    vAlign?: EVAlign

}

const tableCellStyle = style({
    display: "table-cell"
})

const tableCellInnerStyle = style({
    display: "flex",
    width: "100%",
    height: "100%",
    padding: "8px"
})