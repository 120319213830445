import { FieldModel } from "components/FieldModel"

export class RegisterSceneModel {

    isLoading: boolean = false

    globalError: string = null

    readonly companyName: FieldModel<string> = new FieldModel("")

    readonly name: FieldModel<string> = new FieldModel("")

    readonly email: FieldModel<string> = new FieldModel("")

    readonly password: FieldModel<string> = new FieldModel("")

}