import { Icon, IconButton } from '@material-ui/core'
import * as React from 'react'
import { MenuItem, WDropdownMenu } from './DropdownMenu'

export class WIconDropdownMenu extends React.Component<WIconDropdownMenuProps, WIconDropdownMenuSate> {

    public constructor(props: WIconDropdownMenuProps) {
        super(props)

        this.state = {
            ref: null
        }
    }

    private readonly handleIconClick = (evt: React.MouseEvent<HTMLElement, MouseEvent>) => {
        this.setState({ ref: evt.currentTarget })
    }

    private readonly handleCloseClick = () => {
        this.setState({ ref: null })
    }

    render() {
        return (
            <>
                <IconButton
                    size={this.props.size}
                    color={this.props.color}
                    onClick={this.handleIconClick}
                >
                    <Icon color={this.props.color}>{this.props.icon}</Icon>
                </IconButton>
                <WDropdownMenu
                    anchorEl={this.state.ref}
                    items={this.props.items}
                    onClose={this.handleCloseClick}
                />

            </>
        )
    }

}

export interface WIconDropdownMenuProps {

    icon: string,
    size?: "small" | "medium"
    color?: 'primary' | "secondary" | 'default'
    items: MenuItem[]

}

interface WIconDropdownMenuSate {

    ref: HTMLElement

}