import "firebase/analytics";
import "firebase/functions";
import { IWib } from "shared/entities/wib/IWib";
import { IWibMessage } from "shared/entities/wib/IWibMessage";
import { MenuItem } from "../wds/DropdownMenu";

export abstract class AWibHandler<$WibContentType> {

    public constructor(
        public readonly type: string,
        public readonly name: string,
        public readonly icon: string,
        public readonly color: string,
        public readonly description: string,
        public readonly creatable: boolean
    ) {

    }

    abstract createWibCreator(): AWibCreator

    abstract createWibController(wibId: string, wib: IWib<$WibContentType>, messages: IWibMessage<{}>[], changeListener: () => void): AWibController<$WibContentType>

}

export abstract class AWibCreator {

    abstract renderDialog(): React.ReactNode

    abstract createWib(subject: string): Promise<string>


}

export abstract class AWibController<$WibContentType> {

    public constructor(
        public readonly wibId: string,
        public wib: IWib<$WibContentType>,
        public messages: IWibMessage<{}>[],
        public readonly isSubjectEditable: boolean,
        private readonly changeListener: () => void
    ) { }

    abstract getHeaderButtons(): WibHeaderButtons

    abstract render(): React.ReactNode

    abstract renderSidebar(): React.ReactNode

    protected readonly refresh = (): void => {
        this.changeListener()
    }

    public destory = () => {

    }

}

export class WibHeaderButtons {

    public constructor(
        public readonly buttons: WibActionButton[],
        public readonly moreMenuItems: MenuItem[]
    ) {

    }

}

export class WibActionButton {

    public constructor(
        public readonly title: string,
        public readonly icon: string,
        public readonly color: string,
        public readonly type: "filled" | "outlined",
        public readonly isDisabled: boolean,
        public readonly onClick: () => void
    ) { }

}